import React from 'react'
import AppBarAdminHeader from '../../Components/AppBars/admin/AppBarAdminHeader'
import AppBarSpeakers from '../../Components/AppBars/admin/AppBarSpeakers'
// import AppBarSpeakers from '../../Components/AppBars/admin/AppBarSpeakers'

function SpeakersPage() {
  return (
	<>
	<AppBarAdminHeader/>	
	<AppBarSpeakers/>
	
	</>
	)
}

export default SpeakersPage