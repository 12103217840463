import React, { useState } from "react";
import { Box, FormLabel, Input, Button } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../Configs/firebase";
import { doc, setDoc } from "firebase/firestore";

function AddAdminPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignUpAdmin = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((user) => {
        const setUser = setDoc(doc(db, "users", user.user.uid), {
          role: "admin",
          uid: user.user.uid,
        });
        console.log(setUser, "ini data user");
      })
      .then(() => navigate("/admin/dashboard", { replace: true }))
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        return errorMessage;
      });
  };
  return (
    <>
      <Box>
        <FormLabel>Email</FormLabel>
        <Input onChange={(e) => setEmail(e.target.value)}></Input>
      </Box>
      <Box>
        <FormLabel>Password</FormLabel>
        <Input onChange={(e) => setPassword(e.target.value)}></Input>
      </Box>
      <Button onClick={() => handleSignUpAdmin()}>Sign Up Admin</Button>
    </>
  );
}

export default AddAdminPage;
