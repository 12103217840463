import React, { useContext, useState } from "react";
import { Flex, Box, FormControl, FormLabel, Input, InputGroup, InputRightElement, Stack, Button, Heading, Text, useColorModeValue, useToast, Spinner, Center } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { sendEmailVerification, updateProfile } from "firebase/auth";
import { auth, db } from "../Configs/firebase";
import { doc, setDoc } from "firebase/firestore";
import colors from "../Utils/colors";
import AuthContext from "../Routes/hooks/AuthContext";
import Tap from "@tapfiliate/tapfiliate-js";
import axios from "axios";
import getDomain from "../Utils/getDomain";

function SignUpPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(0)
  const [cityUser, setCityUser] = useState("")
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast();

  const link = getDomain()

  const navigate = useNavigate();

  const { signUp } = useContext(AuthContext)

  const handleRegister = async () => {
    setLoading(true)
    const displayName = name
    if(email === "" && password === "" || password != confirmationPassword) 
    return toast({
            title: "Something Wrong",
            description: "check your email & password",
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "top-end",
          });
    setLoading(false)
  if(email !== "" || password !== ""){
    try {
      signUp(email, password)
      .then(async(userCredential) => {
        setLoading(true)
        await updateProfile(auth.currentUser, { 
          displayName,
        })
        sendEmailVerification(auth.currentUser)

        // Signed in
        const user = userCredential.user;
        if(user){
          toast({
            title: "Success Create",
            description:
              `Success Create account ${user.displayName}`,
            status: "success",
            duration: 10000,
            isClosable: true,
            position: "top-right",
          });
        }
		Tap.customer(`${user.uid}`);
		// tapCustomer(`${user.uid}`)
		console.log(`this is ${user.uid} for tap`);

        await setDoc(doc(db, "users", user.uid), 
          {
            name: user.displayName, 
            email: user.email,
            uid_user: user.uid,
            role: "user",
            phoneNumber : phoneNumber,
            city : cityUser,
            update: new Date()
          }
        )

        const data = {
          firstname: displayName,
          lastname: link === "algotradingid" ? "ALG" : "TSY",
          email: user.email,
          company: {
            name: link === "algotradingid" ? "Algotrading" : "Tradingsyariah"
          },
          address: {
            city: cityUser,
          },
          program_id: "algotrading",
          affiliate_group_id: link === "algotradingid" ? "algotrading" : "tradingsyariah",
        };

        try {
          const res = await axios.post('https://us-central1-algotradingid.cloudfunctions.net/api/v1/createAffiliate', data)
          if(res){
            navigate('/login')
          }
        } catch (error) {
          console.log(error)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast({
          title: "Something Wrong",
          description:
            `It looks like you don't have account in your browser, please signup and reload this page / ${error.message}`,
          status: "error",
          duration: 10000,
          isClosable: true,
          position: "top-right",
        });
      });     
    } catch (error) {
      setLoading(false)
      toast({
            title: "Something Wrong",
            description : error,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "top-end",
          });
    }
    setLoading(true)
  }
  setLoading(false)
  };


  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")} bgColor="black">
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"} color="white">
            Sign up
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool features ✌️
          </Text>
        </Stack>
        <Box rounded={"lg"} bg={useColorModeValue(colors.theme, colors.theme)} boxShadow={"lg"} p={8} w="350px">
          <Stack spacing={1}>
                    <Text htmlFor="name" color={colors.black}>Name</Text>
                    <Input
                      id="name"
                      type="name"
                      color={colors.black}
                      bgColor={colors.white}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Text htmlFor="email" color={colors.black}>Email</Text>
                    <Input
                      id="email"
                      type="email"
                      color={colors.black}
                      bgColor={colors.white}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Text htmlFor="email" color={colors.black}>Number Phone</Text>
                    <Input
                      id="email"
                      type="number"
                      color={colors.black}
                      bgColor={colors.white}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <Text htmlFor="email" color={colors.black}>City</Text>
                    <Input
                      id="email"
                      type="text"
                      color={colors.black}
                      bgColor={colors.white}
                      onChange={(e) => setCityUser(e.target.value)}
                    />
                    <Text htmlFor="password" color={colors.black}>Password</Text>
                    <Input
                      id="password"
                      type="password"
                      color={colors.black}
                      bgColor={colors.white}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Text htmlFor="password" color={colors.black}>Confirm Password</Text>
                    <Input
                      id="password"
                      type="password"
                      color={colors.black}
                      bgColor={colors.white}
                      onChange={(e) => setConfirmationPassword(e.target.value)}
                    />
            <Stack spacing={10} pt={2}>
              {loading ? (
                <Center>
                 <Spinner color="black"/>
                </Center>
              ) : (
                <Button
                onClick={() => handleRegister()}
                loadingText="Submitting"
                size="lg"
                bg='black'
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Sign up
              </Button>
              )}
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Already a user?{" "}
                <Button bgColor="black" color="white">
                  <Link to="/login">Login</Link>
                </Button>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SignUpPage;
