import React, {useState } from "react";
import { Center, Image,Input, Box, VStack, Button, HStack, Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import algologo from "../Img/algotradinglogo.png";
import tradingsyariahlogo from "../Img/TS_ori_200.png";
import axios from "axios";
import ApiEncrypt from "../Utils/Appgregator";
import { useEffect } from "react";
import {  db } from "../Configs/firebase";
import { addDoc, collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import getDomain from "../Utils/getDomain";
import { Rupiah } from '../Utils/NumberUtils';
import moment from "moment";

function PaymentGlobal() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading,setLoading] = useState(false)
  const [user,setUser] = useState()
  const [data,setData] = useState({})

  const timeNow = moment(new Date()).unix()*1000


  const paymentType=useParams().type
  const typeId=useParams().id

  const link = getDomain()


  const getPaymentType = async () => {

     // get time
     const q = query(collection(db, "events"));
     const querySnapshot = await getDocs(q);
     let promoTime = []
     querySnapshot.forEach((doc) => {
       // data.push({file: doc.data()})
       const timeStart = doc.data().start
       const timeEnd = doc.data().end
       promoTime.push({'start':timeStart,   'end': timeEnd})
     });
 
     if (paymentType === "member") setData({ price: 12000000, type: paymentType, time: 31536000 });

     // jika waktu sekarang === promo time start & promo time end buka 12jt
     for (const iterator of promoTime) {
       if(timeNow >= iterator.start && timeNow <= iterator.end){
         if (paymentType === "member") setData({ price: 3500000, type: paymentType, time: 31536000 });
       }
     }
 
    if (paymentType === "seminar") {
      const docRef = doc(db, "events", typeId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const firebaseData = docSnap.data();
        firebaseData.type = "seminar";
        console.log(firebaseData);
        setData(firebaseData);
      }
    }

    if (paymentType === "tradingplan") {
      const docRef = doc(db, "tradingplan", typeId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const firebaseData = docSnap.data();
        firebaseData.type = "tradingplan";
        console.log(firebaseData);
        setData(firebaseData);
      }
    }
  };


  const handlePayment = () => {
    setLoading(true);
    const dataPayment = {
      external_id: `${link === "algotradingid" ? (`ALG`) : (`TSY`)}-${paymentType}-${typeId}`,
      amount: parseFloat(data.price),
      description: `${link === "algotradingid" ? (`ALG`) : (`TSY`)}-${paymentType}-${typeId}`,
      invoice_duration: 86400,
      // success_redirect_url: `localhost:3000/payment/callback/success/${link === "algotradingid" ? (`ALG`) : (`TS`)}-${paymentType}-${typeId}`,
      success_redirect_url: `https://${window.location.hostname}/paymentglobal/callback/success/${link === "algotradingid" ? (`ALG`) : (`TSY`)}-${paymentType}-${typeId}`,
      // failure_redirect_url: `localhost:3000/payment/callback/failure/${link === "algotradingid" ? (`ALG`) : (`TS`)}-${paymentType}-${typeId}`,
      failure_redirect_url: `https://${window.location.hostname}/paymentglobal/callback/failure/${link === "algotradingid" ? (`ALG`) : (`TSY`)}-${paymentType}-${typeId}`,
      customer: {
        given_names: `${name}`,
        surname: `${paymentType}`,
        email: `${email}`,
        mobile_number: `${phone}`,
      },
    };

    const apikey = ApiEncrypt(process.env.REACT_APP_APPGREGATOR_API, process.env.REACT_APP_APPGREGATOR_SECRET);
    const config = {
      method: "post",
      // url: "https://us-central1-appgregator.cloudfunctions.net/xendit/invoices?name=Algotrading&connection_name=Algotrading", testing
      url: link === "algotradingid" ? ( "https://us-central1-appgregator.cloudfunctions.net/xendit/invoices?name=Algotrading&connection_name=production_payment") : ( "https://us-central1-appgregator.cloudfunctions.net/xendit/invoices?name=Algotrading&connection_name=pembayaran_tsy"),
      headers: {
        "x-api-key": apikey,
        "Content-Type": "application/json",
      },
      data: dataPayment,
    };

    axios(config)
      .then(async function (response) {
        console.log(response.data, "ini response dari appgregator");
        let newData = response.data;
        newData.email = email;
        await addDoc(collection(db, "payments"), response.data);
        return response.data.invoice_url;
      })
      .then((invoice_url) => {
        setLoading(false);
        window.location.href = invoice_url;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getUser = async () => {};

  useEffect(() => {
    getUser();
    getPaymentType();

    return () => {
      setUser();
    };
  }, []);
	
  return (
    <>

      <Center bgColor='black' color='white' height='100vh'>
        {link === "algotradingid" ? (
          	  <Image src={algologo}/>
        ) : (
          <Image src={tradingsyariahlogo}/>
        )}
        <Box w="500px" bg="" p="30px" m="20px" borderWidth="1px" borderRadius="lg">
          <VStack>
            <Heading textTransform="capitalize" fontSize='xl' textAlign='center'>{data?.type === "seminar" ? "webinar" : data?.type}</Heading>
            {data?.price === 3500000 ? (
							<>
							<Heading fontSize="2xl" as='del' color="red.300">{Rupiah(12000000)}</Heading>
							 <Heading fontSize="2xl">{Rupiah(data?.price)}</Heading>
							 </>
						) : (
							<Heading fontSize="2xl">{data ? data.title : ''}</Heading>
						)}

			      <Input onChange={(e) => setName(e.target.value)} placeholder="Name"></Input>
            <Input onChange={(e) => setPhone(e.target.value)} placeholder="Phone"></Input>
            <Input onChange={(e) => setEmail(e.target.value)} placeholder="Email"></Input>

			{loading?
			<Button bgColor={link === "algotradingid" ? ('red') : ('green')} isLoading/>
			:
			<HStack>
        <Button bgColor='green' onClick={()=>handlePayment()}>Bayar</Button>
			</HStack>
			}
          </VStack>
        </Box>
      </Center>
    </>
  );
}

export default PaymentGlobal;
