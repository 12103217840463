import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Box,
  Select,
  useToast,
  Flex,
  Modal,
  HStack, Heading, Spacer, Text,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import Swal from 'sweetalert2'
import React, {useState, useEffect} from 'react'
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import {  addDoc, arrayRemove, arrayUnion, collection, doc, setDoc } from 'firebase/firestore';
import { db, auth } from "../../../Configs/firebase" 

function AppBarAdminAddNewMember() {
    const [loading, setLoading] = useState(false)
    const [dataDoc, setDataDoc] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        paid_date: "",
        email: "",
        packageUser: "",
    });
    const toast = useToast()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleSubmit = () => {
        console.log(formData)
        setLoading(true)
        createUserWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // ...
            console.log(formData)

            try {
                const updateData = setDoc(doc(db, "users", user.uid), {
                    name : formData.name,
                    paid_date : formData.paid_date,
                    email : formData.email,
                    city : formData.city,
                    package : formData.packageUser,
                    role : 'member',
                    expired_user : formData.expired_user,
                    update: new Date()
                });
                setLoading(false)
                Swal.fire(
                `Register member success`,
                `${formData.name} - ${formData.email}, Package : ${(formData.packageUser).toUpperCase()}`,
                'success'
                )
            } catch (error) {
                console.log(error.message, "error 1")
                Swal.fire({
                icon: 'error',
                title: 'Error gan',
                text:  `Error saat menambah user: ${error.message}`
                })
                setLoading(false)
            }
            setLoading(false)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
            console.log(errorMessage, "err 3")
             Swal.fire({
                icon: 'error',
                title: 'Error gan',
                text:  `Error saat menambah user: ${error.message}`
                })
            setLoading(false)
        });
        setLoading(false)
    }


  return (
    <>
        <Box>
            <Container maxW="90%" mt={10}>
                    <FormControl>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input
                        id="name"
                        name="name"
                        placeholder="Enter member name"
                        value={formData.name}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="name">Email</FormLabel>
                        <Input
                        id="email"
                        name="email"
                        placeholder="Enter member Email"
                        value={formData.email}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="name">Password</FormLabel>
                        <Input
                        id="password"
                        name="password"
                        placeholder="Enter password"
                        value={formData.password}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="paid_date">Paid Date</FormLabel>
                        <Input
                        id="paid_date"
                        name="paid_date"
                        type="date"
                        placeholder="Enter the date of payment"
                        value={formData.paid_date}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel color='#ffd600' htmlFor="paid_date">Expired Membership Date</FormLabel>
                        <Input
                        id="expired_user"
                        name="expired_user"
                        type="datetime-local"
                        placeholder="Enter the date of payment"
                        value={formData.expired_user}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="phone">Phone</FormLabel>
                        <Input
                        type='number'
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter member phone number"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="City">City</FormLabel>
                        <Input
                        id="city"
                        name="city"
                        placeholder="Enter member City"
                        value={formData.city}
                        onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="products">Package</FormLabel>
                        <Select
                        id="packageUser"
                        name="packageUser"
                        placeholder="Select a product"
                        value={formData.packageUser}
                        onChange={handleChange}
                        >
                            <option value="silver">Subscriber - Traders Live</option>
                            <option value="gold">Gold Membership</option>
                            <option value="platinum">Platinum Membership</option>
                            <option value="diamond">Diamond Membership</option>
                        </Select>
                    </FormControl>
                    <Flex spacing={5} mt={10}> 
                        <Button
                        mx={0.5}
                        flex={3}
                        bg="green"
                        color="white"
                        w="full"
                        onClick={()=>handleSubmit()}
                        disabled={loading}
                    >
                        Regsiter {!loading ? null : <Spinner ml={5}/>}
                    </Button>
                    <Button mx={0.5} >Cancel</Button>
                    </Flex>
            </Container>
        </Box>
    
    </>
  )
}

export default AppBarAdminAddNewMember;