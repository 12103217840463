import React from "react";
import AppBarAdminEvents from "../../Components/AppBars/admin/AppBarAdminEvents";
import AppBarAdminHeader from "../../Components/AppBars/admin/AppBarAdminHeader";

function EventsAdminPage() {
  return (
    <>
      <AppBarAdminHeader />
	  <AppBarAdminEvents />
    </>
  );
}

export default EventsAdminPage;
