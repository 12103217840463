import { Avatar, Box, Button, Center, Flex, Heading, HStack, Image, Input, SimpleGrid, Spacer, Spinner, Tag, Text, VStack } from '@chakra-ui/react';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Footer from "../Components/AppBars/Footer";
import Header from '../Components/AppBars/Header'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive';
import AppCard169 from '../Components/AppComponents/AppCard169';
import AppCard43 from '../Components/AppComponents/AppCard43';
import { db } from '../Configs/firebase';
import AuthContext from '../Routes/hooks/AuthContext';
import colors from '../Utils/colors';
import getDomain from '../Utils/getDomain';
import { dollar, Rupiah } from '../Utils/NumberUtils';
import UpDownHelper from '../Utils/UpDownHelper';

function SearchPage() {
	const [search,setSearch]=useState("");
	const [dataProfile, setDataProfile]=useState([]);
	const [dataCoinCrypto, setDataCoinCrypto]=useState([]);
	const [dataCoinStock, setDataCoinStock]=useState([]);
	const [dataVideo, setDataVideo]=useState([]);
	const [loading, setLoading]=useState(false);
	const { params }=useParams();
	const navigate = useNavigate()

	const { coinCrypto, coinStocks, stocksApi } = useContext(AuthContext)

	const link = getDomain()

	const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          navigate(`/search/${params}`);
        }
      };

	const getSearchProfile = async () => {
		if(params !== undefined){
			try {
				setSearch(params)
				const str = params.replace(/\s/g, '')
				const ref = collection(db, "speakers");
				const q = query(ref,
				where("keyname", ">=", str ),
				where("keyname", "<=", str+'\uf8ff'),
				limit(15) 
				);
				console.log(str, 'ini search name')
			  let result = [];
			  const querySnapshot = await getDocs(q);
			  querySnapshot.forEach((doc) => {
				result.push(doc.data())
			  });
			  setDataProfile(result)
			  console.log(result, 'profile')
			  if( result.length === 0 ){
				const str = params.replace(/\s/g, '')
				const ref = collection(db, "speakers");
				const q = query(ref,
				where("keycompany", ">=", str ),
				where("keycompany", "<=", str+'\uf8ff'),
				limit(15) 
				);
				console.log(str, 'ini search name')
			  let result = [];
			  const querySnapshot = await getDocs(q);
			  querySnapshot.forEach((doc) => {
				result.push(doc.data())
			  });
			  setDataProfile(result)
			  console.log(result, 'ini result profile')
			  }
			} catch (error) {
				console.log(error, 'ini error')
			}
		}
	}


	const getSearchVideo = async () =>{
		if(params !== undefined){
			try {
				setSearch(params)
				const ref = collection(db, "content");
				const q = query(ref,
				where("tags", "array-contains", params.replace(/\s/g, '')),
				limit(15) 
				);
			  let result = [];
			  const querySnapshot = await getDocs(q);
			  querySnapshot.forEach((doc) => {
				result.push(doc.data())
			  });
			  setDataVideo(result)
			  console.log(result, 'ini result video')
			} catch (error) {
				console.log(error, 'ini error')
			}
		}
	};

	const getSearchCoinCrypto = () => {
		if(params !== undefined) {
			try {
				let result = []
				setSearch(params)
				const dataCrypto = coinCrypto
				const filterData = dataCrypto.find((x) => x.symbol === params.toUpperCase())
				if(filterData !== null){
					result.push(filterData)
					console.log(filterData, 'yyyy')
				}
				setDataCoinCrypto(result)

			} catch (error) {
				console.log(error, 'ini error')
			}
		}
	}

	const getSearchCoinStock = () => {
		if(params !== undefined) {
			try {
				let result = []
				setSearch(params)
				const dataStock = link === "algotradingid" ? (coinStocks) : (stocksApi)
				console.log(dataStock, 'xxxx')
				const filterData = dataStock.find((x) => x.Code === params.toUpperCase())
				result.push(filterData)
				console.log(filterData, 'yyyy')
				setDataCoinStock(result)

			} catch (error) {
				console.log(error, 'ini error')
			}
		}
	}

	useEffect(() => {
		setLoading(true)
		getSearchCoinCrypto()
		getSearchCoinStock()
		setLoading(false)
	
	  return () => {
		setDataCoinCrypto([])
		setDataCoinStock([])
	  }
	}, [params])
	

	useEffect(() => {
		getSearchVideo()
		getSearchProfile()
	
	  return () => {
		setDataProfile([])
		setDataVideo([])
	  }
	}, [params])

	
	
  return (
	<>
	<HeaderResponsive />
		{/* <Text>
			{search?search:<></>}
		</Text>
		<Input
        variant="flushed"
        placeholder="Search wallet address / ENS"
        size="sm"
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={handleKeyDown}
      /> */}
		<Box bgColor="black" py={12} >
			<Heading textAlign='center' fontWeight="extrabold" color={colors.white}>Search Result</Heading>
			<Center>
				<SimpleGrid columns={1} m='2' borderRadius='3xl' spacing={3}>
					<Box>
						<SimpleGrid columns={[1, 3, 5]} spacing={3}>
							{dataProfile.map((x) => 
							<Center>
							<AppCard43 name={x.name} id={x.id} image={x.thumbnail} />
							</Center>
							)}
						</SimpleGrid>
					</Box>

					<Box>
						<SimpleGrid columns={[1, 3, 5]} spacing={3}>
							{dataVideo.map((x) => 
							<AppCard169 title={x.title} video={x.video} image={x.image}/>
							)}
						</SimpleGrid>
					</Box>

					<Box p={10}>
						{loading ? (
							<Center>
								<Spinner/>
							</Center>
						) : (
							<SimpleGrid columns={[1, null, 2]} spacing={3} my={5}> 
							<Box>
							
								{
									dataCoinCrypto.map((x) => 
										x !== undefined && (
											<Box>
											<Heading size="md" py={3}>
												Cryptocurrency
											</Heading>
											<HStack spacing={3} cursor='pointer' onClick={() => navigate(`/market/crypto/${x?.symbol}`)} >
											<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`}/>
											<Text fontWeight="bold">{x?.name}</Text>
											<Tag  fontWeight="bold">{x?.symbol}</Tag>
											<Flex flexDirection="row" alignItems="center" >
											<Box ml='5'>
												<Text>{x?.name} Price</Text>
												<HStack>
												<Heading>{dollar(x?.price)}</Heading>
													<HStack borderRadius='2xl'>
														<Text color='white' fontSize="3xl" fontWeight="bold" >{UpDownHelper(x?.percentChange24h)}</Text>
													</HStack>
												</HStack>
												<HStack>
													<Tag bgColor={colors.theme} fontWeight="bold" color={colors.black}>Rank #{x?.cmcRank}</Tag>
												</HStack>
											</Box>		
											</Flex>
										</HStack>
										</Box>
										)
										)
								 }
								 </Box>
	
								 <Box>
								 {dataCoinStock.map((x) => 
								x !== undefined && (
								<Box>
							<Heading size="md" py={3}>
								Stocks ID
							</Heading>
								<VStack w="full" cursor='pointer' onClick={() => navigate(`/market/stocks-id/${x.Code}`)}>
								<HStack spacing={5} >
								<Image src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`} width='65px' height='65px' />
									<Text fontWeight="bold" fontSize="3xl">{x.Name}</Text>
									<Tag fontSize="md" fontWeight="bold">{x.Code}</Tag>
								</HStack>
								<HStack>
									<Tag bgColor={colors.theme} color="black" fontWeight="bold">Rank #{x.Last}</Tag>
									<Tag bgColor={colors.theme}  color="black" fontWeight="bold">{x.NewIndustryName}</Tag>
									<Tag bgColor={colors.theme}  color="black" fontWeight="bold">{x.NewSectorName}</Tag>
								</HStack>
								<Flex flexDirection="row" alignItems="center" >
								<Box ml='5'>
									<Text>{x.name} Price</Text>
									<HStack>
									<Heading>{Rupiah(x.Value)}</Heading>
										<HStack borderRadius='2xl'>
											<Text color='white' fontSize="3xl" fontWeight="bold" >{UpDownHelper(x.Roe)}</Text>
										</HStack>
									</HStack>
								</Box>
								</Flex>
								</VStack>
								</Box>
								)
								)}
								 </Box>
							</SimpleGrid>
						)}
					</Box>

					{/* <Box p={10}>
						
						<SimpleGrid columns={[1]} spacing={3} my={10}>
							
						
						</SimpleGrid>
					</Box> */}
				</SimpleGrid>
			</Center>
		</Box>
	<Footer/>
	</>
  )
}

export default SearchPage