import { Box, Button, Center, Heading, HStack, Image, Input, Select, SimpleGrid, Tag, Text, useToast, VStack } from '@chakra-ui/react'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../../Configs/firebase'
import colors from '../../Utils/colors'

function EditDataUser({editData, setEditData, data}) {

  const [name, setName] = useState("")
  const [role, setRole] = useState("")
  const [phone, setPhone] = useState("")
  const [time, setTime] = useState("")
  const [cityUser, setCityUser] = useState("")
  const [packageUser, setPackageUser] = useState("")
  const toast = useToast()

  const handleEdit = async () => {
    const addData = {
        name: name? (name) : (editData?.name),
        role : role? (role) : (editData?.role),
        phoneNumber : phone? (phone) : (editData?.phoneNumber),
        expired_user : time? (time) : (editData?.expired_user),
        city : cityUser? (cityUser) : (editData?.city),
        packageUser : packageUser? (packageUser) : (editData?.packageUser),
    }

    console.log(addData, 'ini data')

    try {
            await updateDoc(doc(db, "users", editData?.uid_user), addData);
            toast({
                title: 'Success',
                description: 'Succes Edit Data',
                position: 'top-right',
                status: 'success',
                duration: 3000,
                isClosable: true
            })
        
            data()
    } catch (error) {
       console.log(error) 
    }
}

  

  return (
    <Center>
      <Box width="3xl">
        <VStack align="start" spacing={3} >
        <Text fontWeight="bold" color={colors.white}>Name</Text>
        <Input  color={colors.white} m="1" type="text" placeholder={editData?.name} onChange={(e) => setName(e.target.value)} />

        <Text fontWeight="bold" color={colors.white}>Number Phone</Text>
        <Input  color={colors.white} m="1" type="number" placeholder={editData?.phoneNumber} onChange={(e) => setPhone(e.target.value)} />

        <Text fontWeight="bold" color={colors.white}>City</Text>
        <Input  color={colors.white} m="1" type="text" placeholder={editData?.city} onChange={(e) => setCityUser(e.target.value)} />

        <Text fontWeight="bold" color={colors.white}>Type</Text>
        
      <Select color={colors.white} m="1" placeholder="Type" id="type" onChange={(e) => setRole(e.target.value)}>
          <option value="admin">Admin</option>
          <option value="user">User</option>
          <option value="member">Member</option>
      </Select>

      <Text fontWeight="bold" color={colors.white}>Package</Text>

      <Select color={colors.white} m="1" placeholder="Package" id="type" onChange={(e) => setPackageUser(e.target.value)}>
          <option value="subscriber">Subscriber</option>
          <option value="silver">Expert Trading Plan</option>
          <option value="gold">Gold Membership</option>
          <option value="platinum">Platinum Membership</option>
      </Select>

      <Text fontWeight="bold" color={colors.white}>Expired Account</Text>
      <Input m='1' id='start' type='datetime-local' placeholder='Description' 
			onChange={(e)=>setTime(e.target.value)}
			/>
        </VStack>
        <HStack>
        <Button m="1" bgColor="green" onClick={() => handleEdit()}>
          Update
        </Button>
        <Button m="1" bgColor="red" onClick={() => setEditData(null)}>
          Cancel
        </Button>
        </HStack>
      </Box>
    </Center>
  )
}

export default EditDataUser