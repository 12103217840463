import { Box, Button, Center, Flex, Heading, HStack, VStack } from "@chakra-ui/react"
import { Step, Steps, useSteps } from "chakra-ui-steps"
import { FiClipboard, FiDollarSign, FiUser } from "react-icons/fi"
import { useNavigate, useParams } from "react-router-dom"
import colors from "../../../Utils/colors"
import getDomain from "../../../Utils/getDomain"
import AppBarWatchBoarding from "./AppBarWatchBoarding"
import ContentLogin from "./ContentLogin"




export const StepBoarding = () => {
    const param = useParams()
    const navigate = useNavigate()
    const link = getDomain()


  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  })

  let steps = []
  if(link === "algotradingid"){
    if(param.type === "mindset") {
      steps = [
          { label: "Mindset", icon: FiUser , content: <AppBarWatchBoarding keyword={"mindset"} />},
        ]
      }
  
      if(param.type === "account"){
          steps = [
            { label: "Top Up dan WD", icon: FiClipboard, content: <AppBarWatchBoarding  keyword={"topupwd"}/> },
              { label: "KYC", icon: FiUser , content: <AppBarWatchBoarding keyword={"kyc"}/>},
              { label: "Flow Dana", icon: FiDollarSign, content: <AppBarWatchBoarding  keyword={"flowdana"}/> },
            ]
      }
  
      if(param.type === "trading"){
          steps = [
            { label: "Spot Trading", icon: FiClipboard, content: <AppBarWatchBoarding  keyword={"spottrading"}/> },
            { label: "Futures Trading", icon: FiDollarSign, content: <AppBarWatchBoarding  keyword={"futurestrading"}/> },
            ]
      }
  
      if(param.type === "indicators"){
          steps = [
              { label: "At Lighthouse", icon: FiUser , content: <AppBarWatchBoarding keyword={"lighthouse"}/>},
              { label: "at Traffic Lights", icon: FiClipboard, content: <AppBarWatchBoarding keyword={"trafficlights"}/> },
            ]
      }  
  }else{
    if(param.type === "mindset") {
      steps = [
          { label: "Mindset", icon: FiUser , content: <AppBarWatchBoarding keyword={"mindset"} />},
        ]
      }
  
      if(param.type === "account"){
          steps = [
            // { label: "Top Up dan WD", icon: FiClipboard, content: <AppBarWatchBoarding  keyword={"topupwd"}/> },
              { label: "KYC", icon: FiUser , content: <AppBarWatchBoarding keyword={"kyc"}/>},
              // { label: "Flow Dana", icon: FiDollarSign, content: <AppBarWatchBoarding  keyword={"flowdana"}/> },
            ]
      }
  
      if(param.type === "trading"){
          steps = [
            { label: "Spot Trading", icon: FiClipboard, content: <AppBarWatchBoarding  keyword={"spottrading"}/> },
            // { label: "Futures Trading", icon: FiDollarSign, content: <AppBarWatchBoarding  keyword={"futurestrading"}/> },
            ]
      }
  
      if(param.type === "indicators"){
          steps = [
              { label: "At Lighthouse", icon: FiUser , content: <AppBarWatchBoarding keyword={"lighthouse"}/>},
              { label: "at Traffic Lights", icon: FiClipboard, content: <AppBarWatchBoarding keyword={"trafficlights"}/> },
            ]
      }  
  }
  
  return (
    <Center>
    <VStack flexDir="column" width="100%" bgColor="black" p={20} spacing={10}>
        <Box>
            <Heading>{(param.type).toUpperCase()}</Heading>
        </Box>
      <Steps activeStep={activeStep}>
        {steps.map(({ label, icon, content }, index) => (
          <Step label={label} key={label} icon={icon} color={colors.light}>
            {content}
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length ? (
        <Flex px={4} py={4} width="100%" flexDirection="column">
          <Heading fontSize="xl" textAlign="center" color={colors.light}>
            Woohoo! All steps completed!
          </Heading>
          <Button mx="auto" mt={6} size="sm" onClick={reset} color={colors.light}>
            Reset
          </Button>
          <Button mx="auto" mt={6} size="sm" onClick={navigate('/onboarding')} color={colors.light}>
            Back
          </Button>
        </Flex>
      ) : (
        <Flex width="100%" justify="flex-end">
          <Button
            isDisabled={activeStep === 0}
            mr={4}
            onClick={prevStep}
            size="sm"
            variant="ghost"
            bgColor={colors.theme}
            color={colors.black}
          >
            Prev
          </Button>
          <Button size="sm" onClick={nextStep} bgColor={colors.theme} color={colors.black}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Flex>
      )}
    </VStack>
    </Center>
  )
}

export default StepBoarding