import {
	Table,
	Thead,
	Tbody,
	Tfoot, Checkbox,
	Tr, Input,
	Th, Drawer,
	DrawerBody, ButtonGroup,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton, AvatarGroup, Avatar,
	Td, Text, Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor,
	TableCaption, Button, Image, useDisclosure,
	TableContainer, Box, HStack, SimpleGrid, Heading, Spacer, IconButton, Center, Select, Spinner
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BellIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Stream } from '@cloudflare/stream-react'
import { useRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import colors from '../../../Utils/colors'
import { IoMdReturnLeft } from 'react-icons/io'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../../Configs/firebase'
import TablePatternAll from './TablePattern/TablePatternAll'
import AuthContext from '../../../Routes/hooks/AuthContext'
import getDomain from '../../../Utils/getDomain'



function ExplorePatterns() {
	const link = getDomain()
	const [dataCrypto, setDataCrypto] = useState()
	const [loading, setLoading] = useState(true)
	const [dataStockId, setDataStockId] = useState()
	const [tableCoin, setTableCoin] = useState(link === "algotradingid" ? ("crypto") : ("stockid"))
	const [tableTime, setTableTime] = useState("all")
	const [tableTimeStocks, setTableTimeStocks] = useState("1h")

	const { getUserStorage } = useContext(AuthContext)

	const subscription = getUserStorage()
	const role = subscription.role

	const getData = async () => {
		setLoading(true)
		console.log('getting data')

		onSnapshot(doc(db, "pattern-feeds", tableTime), (doc) => {
			// console.log("Current data: ", doc.data());
			const dataFromFirestore = doc.data().data;
			setDataCrypto(dataFromFirestore);
			console.log(dataFromFirestore);
		})


		setLoading(false)
	}

	const getDataStock = async () => {
		try {
			const res = await axios.get(`https://docial-crypto-h3tahmnrkq-uc.a.run.app/api/v1/pattern?pattern=feed&time=${tableTimeStocks}`)
			setDataStockId(res.data.data)
			console.log(res.data.data, 'ini data stock')
		} catch (error) {
			console.lg(error)
		}
	}

	useEffect(() => {
		getData()

		return () => {
		}
	}, [tableTime])

	useEffect(() => {
		getDataStock()

		return () => {
		}
	}, [tableTimeStocks])


	return (
		<>
			{link === "algotradingid" ? (
				<HStack m={5}>
					<Heading>ALL {tableCoin === "crypto" ? ("Cryptocurrency") : ("Stock ID")}</Heading>
					<Spacer />
					<HStack>
						{tableCoin === "crypto" ? (
							<Select placeholder={tableTime === "all" ? 'All' : ""} onChange={(e) => setTableTime(e.target.value)}>
								<option value='all'>1 Minute</option>
								<option value='minute_15'>15 Minute</option>
								<option value='hour_1'>1 Hour</option>
							</Select>
						) : (
							<Select placeholder={tableTimeStocks === "1h" ? '1 Hour' : ""} onChange={(e) => setTableTimeStocks(e.target.value)}>
								<option value='1h'>1 Hour</option>
								<option value='1d'>1 Day</option>
							</Select>
						)}

						<Select placeholder='Select option' onChange={(e) => setTableCoin(e.target.value)}>
							<option value='crypto'>Cryptocurrency</option>
							<option value='stockid'>Stock ID</option>
						</Select>
					</HStack>
				</HStack>
			) : (
				<HStack m={5}>
					<Heading>ALL Stock ID</Heading>
					<Spacer />
					<Box>
						<Select placeholder={tableTimeStocks === "1h" ? '1 Hour' : ""} onChange={(e) => setTableTimeStocks(e.target.value)}>
							<option value='1h'>1 Hour</option>
							<option value='1d'>1 Day</option>
						</Select>
					</Box>
				</HStack>
			)}

			{loading ? (
				<Center>
					<Spinner />
				</Center>
			) : (
				link === "algotradingid" ? (
					<Box>
						{tableCoin === "crypto" && <TablePatternAll data={dataCrypto} role={role} coin={'crypto'} loading={loading} />}
						{tableCoin === "stockid" && <TablePatternAll data={dataStockId} role={role} coin={'stockid'} />}
					</Box>
				) : (
					<Box>
						{tableCoin === "stockid" && <TablePatternAll data={dataStockId} role={role} coin={'stockid'} />}
					</Box>
				)
			)}
		</>
	)
}

export default ExplorePatterns