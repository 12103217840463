import { Box, Button, Center, Flex, Heading, HStack, SimpleGrid, Spinner, Text, useToast, VStack } from '@chakra-ui/react'
import { Step, Steps, useSteps } from "chakra-ui-steps"
import { FiClipboard, FiDollarSign, FiUser } from "react-icons/fi"
import React, { useContext, useState } from 'react'
import colors from '../../../Utils/colors'
import AppCard169 from '../../AppComponents/AppCard169'
import AppCard43 from '../../AppComponents/AppCard43'
import CardBoarding from './CardBoarding'
import ContentLogin from './ContentLogin'
import AuthContext from '../../../Routes/hooks/AuthContext'
import { db } from '../../../Configs/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import getDomain from '../../../Utils/getDomain'

function AppBarOnBoarding() {

  const navigate = useNavigate()
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const link = getDomain()


  const { currentUser } = useContext(AuthContext);
  //   const steps = [
  //       { label: "Mindset", icon: FiUser , content: <ContentLogin/>},
  //       { label: "Account", icon: FiDollarSign, content: <ContentLogin/> },
  //       { label: "Trading", icon: FiClipboard, content: <ContentLogin/> },
  //       { label: "Indicators", icon: FiClipboard, content: <ContentLogin/> },
  //     ]


  // const { nextStep, prevStep, reset, activeStep } = useSteps({
  //   initialStep: 3,
  // })

  const handleStatus = async() => {
    setLoading(true)
      const refUser = doc(db, "users", currentUser.uid);
		  await updateDoc(refUser, { newmember: true }, { merge: true });
      toast({
				title: 'Your finish the boarding',
				description: "",
				status: 'success',
				duration: 5000,
				isClosable: true,
			  })
      setLoading(false)
      return navigate ("/")
  }


    const dataBoarding = 
    link === "algotradingid" ? ([
        {title: "Mindset", keyword: "mindset"},
        {title: "Account", keyword: "account"},
        {title: "Trading", keyword: "trading"},
        {title: "Indicators", keyword:"indicators"},
        {title: "Affiliate", keyword:"affiliate"},
    ]
  
    ) : (
      [
        {title: "Mindset", keyword: "mindset"},
        {title: "Account", keyword: "account"},
        {title: "Trading", keyword: "trading"},
    ]
  
    )

  return (
    <VStack bgColor="black" p={12} py={20} spacing={10}>
        <Box>
            <Heading>On Boarding</Heading>
        </Box>
        <SimpleGrid columns={link === "algotradingid" ? ([ 1, 3, 5]) : ([ 1, null, 3])} spacing={10} >
            {dataBoarding.map((x, index) => (
                <CardBoarding title={x.title} keyword={x.keyword} key={index}/>
            ))}
       </SimpleGrid>
       <VStack flexDir="column" width="100%" bgColor="black" p={20} spacing={10}>
        {loading ? (
          <Center>
            <Spinner size="xl"/>
          </Center>
        ) : (
          <HStack>
          <Button bgColor={colors.theme} color={colors.black} onClick={() => handleStatus()}>Selesai</Button>
        </HStack>
        )}
      {/* <Steps activeStep={activeStep}>
        {steps.map(({ label, icon, content }, index) => (
          <Step label={label} key={label} icon={icon} color={colors.light}>
            {content}
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length ? (
        <Flex px={4} py={4} width="100%" flexDirection="column">
          <Heading fontSize="xl" textAlign="center" color={colors.light}>
            Woohoo! All steps completed!
          </Heading>
          <Button mx="auto" mt={6} size="sm" onClick={reset} color={colors.light}>
            Reset
          </Button>
        </Flex>
      ) : (
        <Flex width="100%" justify="flex-end">
          <Button
            isDisabled={activeStep === 0}
            mr={4}
            onClick={prevStep}
            size="sm"
            variant="ghost"
            bgColor={colors.theme}
          >
            Prev
          </Button>
          <Button size="sm" onClick={nextStep} bgColor={colors.theme}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Flex>
      )} */}
    </VStack>
    </VStack>
  )
}

export default AppBarOnBoarding