import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Heading, HStack, IconButton, Image, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../Utils/colors';
import TextHelper from '../../../../Utils/TextHelper';

function TableSpeaker({data, EditDataComponent, handleDelete, handleAdd}) {


  const [filter, setFilter] = useState("")
  const [dataFilter, setDataFilter] = useState([])
  const [dataSort, setDataSort] = useState(dataFilter)

  const navigate = useNavigate()

  const getData = () => {
    const dataArr = data
    const filterData = dataArr.filter((x) => x.name === filter)
    if(filter === ""){
      setDataFilter(dataArr)
      console.log("gaada")
    }else{
      setDataFilter(filterData)
      console.log('ada')
    }
  }
  

  useEffect(() => {
    getData()
  
    return () => {
      setDataFilter([])
    }
  }, [filter])


      const [titleTable, setTitleTable] = useState([
        {title: "ID", filter: false},
        {title: "Speaker Name", filter: false},
        {title: "Company", filter: false},
        {title: "Picture", filter: false},
        {title: "Actions", filter: false},
      ]);

      const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 1:
            if (titleTable[index].filter){
              return setDataSort([
                ...dataFilter.sort((a, b) => b.name.localeCompare(a.name)),
              ]);
            }
            return setDataSort([
              ...dataFilter.sort((a, b) => a.name.localeCompare(b.name)),
            ]);

                case 3:
                    if (titleTable[index].filter){
                      return setDataSort([
                        ...dataFilter.sort((a, b) => b.company.localeCompare(a.company)),
                      ]);
                    }
                    return setDataSort([
                      ...dataFilter.sort((a, b) => a.company.localeCompare(b.company)),
                    ]);  


          default:
            return null
        }
      }




  return (
    <Box>
       <HStack m={10}>
      <Box>
        <Heading size="lg">
          Speakers Admin
        </Heading>
      </Box>
      <Spacer />
      <HStack>
        <Box>
        <Button justifyContent="flex-end" onClick={handleAdd}>
          <HStack>
            <FiPlus/>
            <Text>New Account</Text>
            </HStack>
        </Button>
        </Box>
      
      <Select placeholder='Filter by' onChange={(e) => setFilter(e.target.value) }>
      <option value='Dean'>Dean</option>
      <option value='Edwin'>Edwin</option>
      <option value='Ayas'>Ayas</option>
    </Select>
      </HStack>
    </HStack>


    <TableContainer mx={10}>
      <Table variant="simple">
        <Thead bgColor={colors.theme}>
          <Tr>
          {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => sortBy(x.title)}
                   >
           <HStack spacing="1">
             <Text color={colors.black}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.black}/>
           </HStack>
         </Button>
           </Th>
        ))}
          </Tr>
        </Thead>
        <Tbody>
                {dataFilter ? (
                  dataFilter.map((x) => (
                    <Tr key={x.id}>
                      <Td>{TextHelper(x.id)}</Td>
                      <Td>{x.name}</Td>
                      <Td>{x.company} </Td>
                      <Td>
                        <Image width="50px" src={x.thumbnail} />
                      </Td>
                      <Td>
                        <IconButton m="2" aria-label="Search database" colorScheme="green" icon={<SearchIcon />} />
                        <IconButton onClick={() => EditDataComponent(x.id)} m="2" aria-label="Edit database" colorScheme="yellow" icon={<EditIcon />} />
                        <IconButton onClick={() => handleDelete(x.id)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <></>
                )}
              </Tbody>
      </Table>
    </TableContainer>
  </Box>
  )
}

export default TableSpeaker