


const ACTION={
	TRADING_SYARIAH: "TradingSyariah",
	ALGO_TRADING: "AlgoTrading",
}

function getDomain() {
	switch (process.env.REACT_APP_NAME) {
		case ACTION.TRADING_SYARIAH:
			return "tradingsyariahid"
		case ACTION.ALGO_TRADING:
			return "algotradingid"
		default:
			return "algotradingid"
	}
}

export default getDomain;