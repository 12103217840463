import React from "react";
import Footer from "../Components/AppBars/Footer";
import Header from "../Components/AppBars/Header";
import { Box, Center, Text } from "@chakra-ui/react";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function DisclaimerPage() {
  return (
    <>
      <HeaderResponsive />
      {/* <Header /> */}
      <Box bgColor="black" color="white">
        <Box mx="100px">
          <Center>
            <Text p="40px" fontSize="40px">
              Disclaimer
            </Text>
          </Center>
          <Text fontSize="20px" mx="80px">
            Setiap orang dapat memiliki pengalaman, pemahaman, komitmen, dan cara penerapan yang berbeda-beda atas penggunaan edukasi pada website, dan karenanya hasil pembelajaran dari setiap individu berbeda-beda. Kami tidak menjamin
            suatu hasil tertentu dalam kegiatan edukasi di website, dan setiap pernyataan yang bertentangan dengan penolakan jaminan ini (jika ada), adalah semata-mata untuk keperluan edukasi dan bukan merupakan jaminan hasil. Setiap
            testimonial yang terdapat pada website merupakan pendapat pribadi dari masing-masing pemberi testimonial, serta bukan merupakan suatu jaminan untuk mendapat hasil serupa. Testimonial tersebut juga tidak dapat dipergunakan
            sebagai acuan hasil rata-rata yang bisa diperoleh oleh Pengguna Website. {window.location.hostname} juga tidak memberikan saran dalam tindakan investasi yang dilakukan oleh pengguna. Segala keputusan yang dilakukan pengguna adalah 100%
            tanggung jawab pengguna.
          </Text>
          <br />
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default DisclaimerPage;
