import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Center, Heading, HStack, IconButton, Image, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../Utils/colors';
import getDomain from '../../../../Utils/getDomain';
import idrDecimalHelper from '../../../../Utils/idrDecimalHelper';
import TextHelper from '../../../../Utils/TextHelper';

function TableTradingPlan({ data, EditDataComponent, handleDelete, handleAdd }) {


    const [filter, setFilter] = useState("")
    const [dataFilter, setDataFilter] = useState([])
    const [dataSort, setDataSort] = useState(dataFilter)

    const navigate = useNavigate()

    const link = getDomain()



    const getData = () => {
        const dataArr = data
        const filterData = dataArr.filter((x) => x.speakers === filter)
        if (filter === "") {
            setDataFilter(dataArr)
            console.log("gaada")
        } else {
            setDataFilter(filterData)
            console.log('ada')
        }
    }


    useEffect(() => {
        getData()

        return () => {
            setDataFilter([])
        }
    }, [filter])


    const [titleTable, setTitleTable] = useState([
        { title: "ID", filter: false },
        { title: "Title", filter: false },
        { title: "Peserta", filter: false },
        { title: "Image", filter: false },
        { title: "Action", filter: false },
    ]);

    const sortBy = (key) => {
        const temp = titleTable;

        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);

        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
            if (index === i) {
                temp[i] = {
                    title: titleTable[i].title,
                    filter: !titleTable[i].filter,
                };
            } else {
                temp[i] = {
                    title: titleTable[i].title,
                    filter: false,
                };
            }
        }
        setTitleTable(temp)
        console.log(temp)

        // memasang switch case filter
        switch (index) {

            case 1:
                if (titleTable[index].filter) {
                    return setDataSort([
                        ...dataFilter.sort((a, b) => b.title - a.title),
                    ]);
                }
                return setDataSort([
                    ...dataFilter.sort((a, b) => a.title - b.title),
                ]);

            case 2:
                if (titleTable[index].filter) {
                    return setDataSort([
                        ...dataFilter.sort((a, b) => b.email.localeCompare(a.email)),
                    ]);
                }
                return setDataSort([
                    ...dataFilter.sort((a, b) => a.email.localeCompare(b.email)),
                ]);


            default:
                return null
        }
    }




    return (
        <Box>
            <HStack m={10}>
                <Box>
                    <Heading size="lg">
                        Trading Plan Admin
                    </Heading>
                </Box>
                <Spacer />
                <HStack>
                    <Box>
                        <Button justifyContent="flex-end" onClick={handleAdd}>
                            <HStack>
                                <FiPlus />
                                <Text>New trading plan</Text>
                            </HStack>
                        </Button>
                    </Box>
                </HStack>
            </HStack>


            <TableContainer mx={10}>
                <Table variant="simple">
                    <Thead bgColor={colors.theme}>
                        <Tr>
                            {titleTable.map((x) => (
                                <Th>
                                    <Center>
                                        <Button
                                            key={x.title}
                                            variant="ghost"
                                            onClick={() => sortBy(x.title)}
                                        >
                                            <HStack spacing="1">
                                                <Text color={colors.black}>{x.title}</Text>
                                                <BiSort fontWeight="bold" color={colors.black} />
                                            </HStack>
                                        </Button>
                                    </Center>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {dataFilter ? (
                            dataFilter?.map((x, index) => {
                                return (
                                    <Tr cursor="pointer" key={x.id} >
                                        <Td>
                                            <Text align="center">
                                                {TextHelper(x.id)}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <VStack spacing={2}>
                                                <Text fontWeight="bold">{x.title}</Text>
                                                <Text >{x.description}</Text>
                                                <Text >{idrDecimalHelper(x.price)}</Text>
                                            </VStack>
                                        </Td>
                                        <Td>{x?.member?.map((y) =>
                                            <VStack>
                                                <Text>{y.name}</Text>
                                                <Text>{y.numberPhone}</Text>
                                            </VStack>
                                        )}
                                        </Td>

                                        <Td><Image src={x.thumbnail} width="100px" /></Td>
                                        <Td color={colors.white}>
                                            {/* <a href={`/watch/${x.video}`} target="_blank" rel="noreferer noreferrer">
                      <IconButton m="2" aria-label="Search database" colorScheme="green" icon={<SearchIcon />} />
                    </a> */}
                                            <IconButton onClick={() => EditDataComponent(x.id)} m="2" aria-label="Edit database" colorScheme="yellow" icon={<EditIcon />} />
                                            <IconButton onClick={() => handleDelete(x.id)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
                                        </Td>
                                    </Tr>)
                            })
                        ) : (<></>)}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default TableTradingPlan