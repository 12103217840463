import React, { useContext } from 'react'
import { dataLayer } from 'react-gtm-module/dist/Snippets'
import TagManager from 'react-gtm-module'
import AuthContext from '../Routes/hooks/AuthContext'
import getDomain from '../Utils/getDomain'

const GoogleTagManager = () => {
    const link = getDomain()
    const { currentUser } = useContext(AuthContext)

    if(link === "algotradingid" && currentUser !== null ) {
    const tagManagerArgs = {
        gtmId: 'GTM-58P7VTB',
        dataLayer: {
            user: currentUser.uid
        }
    }

    TagManager.initialize(tagManagerArgs)
    }else{
        const tagManagerArgs = {
            gtmId: 'GTM-58P7VTB',
            dataLayer: {
                user: "null"
            }
        }

        TagManager.initialize(tagManagerArgs)
    }

}

export default GoogleTagManager