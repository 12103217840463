import { Box, Button, Center, Heading, HStack, Input, Textarea, Spinner, Text, useToast,useDisclosure,InputGroup,InputRightElement } from '@chakra-ui/react'
import { addDoc, collection } from 'firebase/firestore'
import moment from 'moment'
import React, { useState } from 'react'
import { db } from '../../Configs/firebase'
import { uploadFile } from '../../Utils/FirebaseStorage'
import Filemanager from '../General/Filemanager'

function AddDataTradingPlan({speaker, getData, setAdd, handleCancel}) {
    const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [start, setStart] = useState()
	const [price, setPrice] = useState(0)
	const [image, setImage] = useState("")
	const [tags, setTags] = useState("")
	const [winRate, setWinrate] = useState(0)
	const [netProfit, setNetProfit] = useState(0)
	const [video, setVideo] = useState("")
    const [loading, setLoading] = useState(false)
	const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

	const resetForm = () => {
		setTitle('')
		setDescription('')
		setStart('')
		setPrice(0)
		setImage('')
		setTags('')
		setWinrate(0)
		setNetProfit(0)
		setVideo('')
	}

    const handleNewData=async()=>{
		const addData = {
			title: title,
			description: description,
			start: moment(start).valueOf(), 
			price: price,
			image: image,
			tags: tags,
			win_rate:winRate,
			net_profit:netProfit,
			video
		}
		
		try {
			setLoading(true)
			if(addData.tags){
				let text = (addData.tags)
				const lowerText = text.toLowerCase();
				addData.tags = lowerText.split(",");
			}
			const imageResult = await uploadFile(addData.title, "tradingplan", addData["image"]);
			addData.image = imageResult.image_original;
        	addData.thumbnail = imageResult.image_thumbnail;

			const docRef = await addDoc(collection(db, "tradingplan"), addData);
			setLoading(false)
			resetForm()
			toast({
				title: 'Error in saving data',
				description:'Data has saved',
				status: 'success',
				duration: 9000,
				isClosable: true,
			  })
		} catch (error) {
			console.log(error.message, 'error')
			toast({
				title: 'Error in saving data',
				description: error.message,
				status: 'error',
				duration: 9000,
				isClosable: true,
			  })
		}
		setLoading(false)
	}

  return (
	<>
		<Center>
			<Box width='3xl'>
				<Heading>Add New Trading Plan</Heading>
				
				<Box py="1">
					<Input m='1' id='title' type='text' placeholder='Title' onChange={(e)=>setTitle(e.target.value)}/>
				</Box>

				<Box py="1">
					<Text fontWeight="bold" fontSize="md">Time Start</Text>
					<Input m='1' id='start' type='datetime-local' placeholder='Description' onChange={(e)=>setStart(e.target.value)}/>
				</Box>
				<Box py="1">
					<Input m='1' id='price' type='number' placeholder='Price' onChange={(e)=>setPrice(e.target.value)}/>
				</Box>

				<Box display="flex" flexDirection="row">
					<Input m='1' id='price' type='number' placeholder='Win Rate' 
					onChange={(e)=>setWinrate(e.target.value)}
					/>
					<Input m='1' id='price' type='number' placeholder='Net Profit' 
					onChange={(e)=>setNetProfit(e.target.value)}
					/>
				</Box>
				<Box py="1">
					<Text fontWeight="bold" fontSize="md">Image</Text>
					<Input m='1' id='image' type='file' placeholder='Image URL' onChange={(e)=>setImage(e.target.files[0])}/>
				</Box>

				<Box py="2">
					<InputGroup size='md'>
						<Input pr='4.5rem' value={video} onChange={(e)=>setVideo(e.target.value)} type="text" placeholder='File url'/>
						<InputRightElement width='4.5rem'>
							<Button h='1.75rem' size='sm' onClick={onOpen}>
							Pick file
							</Button>
						</InputRightElement>
					</InputGroup>
				</Box>
				
				<Box py="1">
				<Input m='1' id='tags' type='text' placeholder='Tags(ex seminar, foodpreneur, business)' onChange={(e)=>setTags(e.target.value)}/>
				</Box>
				<Box py="2">
					<Textarea placeholder='Description' onChange={(e)=>setDescription(e.target.value)}/>
				</Box>

				{loading? (
					<Center>
						<Spinner />
					</Center>
				) : (
					<HStack spacing="3" m={3} align="center">
					<Button bgColor='green' onClick={()=>handleNewData()}>Save</Button>
					<Button bgColor='red' onClick={handleCancel}>Cancel</Button>
					</HStack>
				)}
			
				
			</Box>
		</Center>
		<Filemanager 
			 onClose={onClose}
			 isOpen={isOpen}
			 pickFile={(arg) => setVideo(arg)}
		/>
	</>
  )
}

export default AddDataTradingPlan