import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import colors from "./colors";

function UpDownHelper(amount) {
 if(amount > 0){
    return(
        <Flex alignItems="center">
          <IoMdArrowDropup color={colors.green} />
            <Text textAlign="center" fontSize={15} fontWeight="bold" color={colors.green}>
              {(amount.toLocaleString(undefined, {maximumFractionDigits:2}))}%
            </Text>
        </Flex>
    )
 }
 if(amount < 0){
    return(
        <Flex alignItems="center">
        <IoMdArrowDropdown color="red" />
        <Text textAlign="center" fontSize={15} fontWeight="bold" color="red">
        {Math.abs(amount.toLocaleString(undefined, {maximumFractionDigits:2}) )}%
        </Text>
      </Flex>
    )
 }
 if(amount === 0){
        return(
            <Text textAlign="center" fontSize={15} fontWeight="bold" color={colors.light}>
            {Math.abs(amount.toLocaleString(undefined, {maximumFractionDigits:2}) )}%
            </Text>
        )
 }
}

export default UpDownHelper;
