import React from "react";
import { Box, HStack, Image, Spacer, Button, Input, Flex, useColorModeValue, IconButton, useDisclosure, Text, useBreakpointValue, Avatar } from "@chakra-ui/react";
import entrepreneurs from "../../Img/entrepreneurs.png";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../Configs/firebase";
import { useState } from "react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

function Header() {
  const { isOpen, onToggle } = useDisclosure();
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/", { replace: true });
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search/${search}`);
    }
  };


  return (
    <>
      <Box bg="Black" p={4} color="white" height={20}>
        <HStack>
          <Link to="/main">
            <Box w="70px">
              <Image src={entrepreneurs}></Image>
            </Box>
          </Link>
          <Box p="20px">
            <Link to="/">Home</Link>
          </Box>
          {/* <Box p="20px">
            <Link to="/entrepreneurs">Entrepreneurs</Link>
          </Box> */}
          <Box p="20px">
            <Link to="/masterclass">Masterclass</Link>
          </Box>
          <Box p="20px">
            <Link to="/events">Events</Link>
          </Box>
          <Box p="20px">
            <Link to="/market">Market</Link>
          </Box>
          <Box p="20px">
            <Link to="/patterns">Patterns</Link>
          </Box>
          <Box p="20px">
            <Link to="/chart">Chart</Link>
          </Box>
          <Spacer />
          <Box p="20px" objectPosition="right">
            <Input 
            placeholder="Search" 
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            />
          </Box>
          <Avatar name="Kola Tioluwani" src="https://bit.ly/tioluwani-kolawole" />
          <Button p="20px" objectPosition="right" bgColor="red" onClick={() => handleLogOut()}>
            Logout
          </Button>
        </HStack>
      </Box>
    </>
  );
}

export default Header;
