import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons'
import { Box, Button, Heading, HStack, IconButton, Select, Spacer, Stack, Table, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi'
import colors from '../../../../Utils/colors'
import TextHelper from '../../../../Utils/TextHelper'
import idrDecimalHelper from "../../../../Utils/idrDecimalHelper";
import moment from 'moment'

function TablePayment({data, handleDelete}) {


    // const [dataSort, setDataSort] = useState(data)
    const [filter, setFilter] = useState("")
    const [dataFilter, setDataFilter] = useState([])
    const [dataSort, setDataSort] = useState(dataFilter)

    const [titleTable, setTitleTable] = useState([
        {title: "ID", filter: false},
        {title: "Data Customer", filter: false},
        {title: "Description", filter: false},
        {title: "Amount", filter: false},
        {title: "Status", filter: false},
        {title: "Action", filter: false},
      ]);
    
      const getData = () => {
        const dataArr = data
        const filterData = dataArr.filter((x) => x.customer.surname === filter)
        if(filter === ""){
          setDataFilter(dataArr)
        }else{
          setDataFilter(filterData)
        }
      }

      useEffect(() => {
        getData()
      
        return () => {
          setDataFilter([])
        }
      }, [filter])
      
    


    const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 4:
            if (titleTable[index].filter){
              return setDataSort([
                ...dataFilter.sort((a, b) => b.status.localeCompare(a.status)),
              ]);
            }
            return setDataSort([
              ...dataFilter.sort((a, b) => a.status.localeCompare(b.status)),
            ]);
  
          default:
            return null
        }
      }



  return (
    <>
    <HStack m={10}>
      <Box>
        <Heading size="lg">
          Payment Admin
        </Heading>
      </Box>
      <Spacer />
      <Box>


      <Select placeholder='Filter by' onChange={(e) => setFilter(e.target.value) }>
      <option value='member'>Member</option>
      <option value='seminar'>Seminar</option>
    </Select>
      </Box>
    </HStack>

    <TableContainer mx={10}>
    <Table variant="simple">
      <Thead bgColor={colors.theme}>
        <Tr>
        {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => sortBy(x.title)}
                   >
           <HStack spacing="1">
             <Text color={colors.black}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.black}/>
           </HStack>
         </Button>
           </Th>
        ))}
        </Tr>
      </Thead>
      <Tbody>
        {dataFilter ? (
          dataFilter.map((x) => (
            <Tr key={x.id}>
              <Td  color={colors.white}>{TextHelper(x.id)}</Td>
              <Td  color={colors.white}>
                 <Stack>
                  <Text>{x.customer.email}</Text>
                  <Text>{x.customer.given_names}</Text>
                  <Text>{x.customer.mobile_number}</Text>
                  </Stack>
              </Td>
              <Td  color={colors.white}>
                <Stack>
                  <Text>{x.description}</Text>
                  <Text>{new Date(x.updated).toLocaleString()}</Text>
                  <Text>{x.customer.surname}</Text>
                </Stack>
              </Td>
              <Td  color={colors.white}>{idrDecimalHelper(x.amount)}</Td>
              <Td  color={colors.white}>
                {x.status === "success" ? <Tag bgColor="green" p={2}>Success</Tag> : x.status === "PENDING" ? <Tag bgColor="yellow" color="black" p={2}>Pending</Tag> : <Tag bgColor="red" p={2}>Error</Tag>}
              </Td>
              <Td  color={colors.white}>
                <IconButton onClick={() => handleDelete(x.id)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
              </Td>
            </Tr>
          ))
        ) : (
          <></>
        )}
      </Tbody>
    </Table>
  </TableContainer>


  </>
  )
}

export default TablePayment