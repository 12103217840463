import React, { useState, useEffect } from "react";
import { Stream } from "@cloudflare/stream-react";
import { Box, Center, Button, Text, HStack, Image } from "@chakra-ui/react";
import { query, collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../Configs/firebase";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import entrepreneurs from "../Img/entrepreneurs.png";

function Podcast() {
  const videoIdOrSignedUrl = "mediaId";
  const [dataVideo, setDataVideo] = useState("");
  const [videoToPlay, setVideoToPlay] = useState();
  const handleRead = async () => {
    let arr = [];
    try {
      const q = query(collection(db, "entrepreneurvideopodcast"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
        console.log(arr, "nyoba nyari arr");
      });

      setDataVideo(arr);
      console.log(dataVideo, "ini data netpliq");
      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const handleModal = (mediaId) => {
    //tongolin modal
    console.log(mediaId);
  };

  useEffect(() => {
    handleRead();

    return () => {};
  }, []);

  const deleteDocument = async (mediaId) => {
    console.log(mediaId, "mediaId");
    try {
      const docRef = doc(db, "entrepreneurvideopodcast", mediaId);
      const docSnap = await deleteDoc(docRef);
      console.log("Document deleted with ID: ", docSnap);
      return docSnap;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box bg="black" color="white" h={20} p={4}>
        <HStack>
          <Link to="/appbarmenu">
            <Box w="70px">
              <Image src={entrepreneurs}></Image>
            </Box>
          </Link>
          <Box p="20px">
            <Link to="/appbarmenu">Home</Link>
          </Box>
          <Box p="20px">
            <Link to="/filminspiringstory">Film (Inspiring Story)</Link>
          </Box>
          <Box p="20px">
            <Link to="/masterclasscourse">MasterClass (Course)</Link>
          </Box>
          <Box p="20px">
            <Link to="/masterclassseminar">MasterClass (Seminar)</Link>
          </Box>
          <Box p="20px">
            <Link to="/podcast">Podcast</Link>
          </Box>
        </HStack>
      </Box>
      <Center>
        <Box w="480px" h="270px" bgColor="yellow" m="20px">
          {videoToPlay ? (
            <Stream controls src={videoToPlay} />
          ) : (
            <>
              <Text>No Video</Text>
            </>
          )}
          <Text>{videoToPlay}</Text>
        </Box>
      </Center>
      <Link to="/createpodcast">
        <Box>
          <Button>Create</Button>
        </Box>
      </Link>
      <Center>
        <TableContainer w="1200px" border="1px" borderRadius="10px" m="10px" p="10px">
          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                <Th>Speaker Name</Th>
                <Th>Brand Name</Th>
                <Th>Brand category</Th>
                <Th>ImageUrl</Th>
                <Th>Video Title</Th>
                <Th>Video Minutes</Th>
                <Th>Video Description</Th>
                <Th>View Video</Th>
                <Th>Delete Video</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataVideo &&
                dataVideo.map((x) => {
                  return (
                    <Tr key={x.mediaId}>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.speakerName}
                        </Text>
                      </Td>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.brandName}
                        </Text>
                      </Td>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.brandCategory}
                        </Text>
                      </Td>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.imageUrl}
                        </Text>
                      </Td>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.videoTitle}
                        </Text>
                      </Td>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.videoMinutes}
                        </Text>
                      </Td>
                      <Td>
                        <Text w="70px" noOfLines={1}>
                          {x.videoDescription}
                        </Text>
                      </Td>
                      <Td>
                        <Button onClick={() => setVideoToPlay(x.mediaId)}>View Video</Button>
                      </Td>
                      <Td>
                        <Button onClick={() => deleteDocument(x.mediaId)}>Delete Video</Button>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </>
  );
}

export default Podcast;
