import { Box, Image,Text, SimpleGrid } from '@chakra-ui/react'
import React from 'react'

function AppBarBodyFavorites() {
	const data=[
		{id:1,name:'spider man',image:'https://cdn0-production-images-kly.akamaized.net/8W0t4oFGYm4WaH2F8HPaMqi-wsQ=/1200x675/smart/filters:quality(75):strip_icc():format(jpeg)/kly-media-production/medias/3708510/original/033751600_1639534567-PUBG_Mobile_-_SpiderMan.jpg'},
		{id:2,name:'spider man 2',image:'https://cdn0-production-images-kly.akamaized.net/8W0t4oFGYm4WaH2F8HPaMqi-wsQ=/1200x675/smart/filters:quality(75):strip_icc():format(jpeg)/kly-media-production/medias/3708510/original/033751600_1639534567-PUBG_Mobile_-_SpiderMan.jpg'},
		{id:3,name:'spider man 3',image:'https://cdn0-production-images-kly.akamaized.net/8W0t4oFGYm4WaH2F8HPaMqi-wsQ=/1200x675/smart/filters:quality(75):strip_icc():format(jpeg)/kly-media-production/medias/3708510/original/033751600_1639534567-PUBG_Mobile_-_SpiderMan.jpg'}]
  return (
	<Box p='5'>
		<SimpleGrid columns='5'>
			{data.map((x)=>
			<>
			<Box>
				<Text>{x.name}</Text>
				<Image maxWidth='3xs' src={x.image}/>
			</Box>
			</>
			)}
		</SimpleGrid>
	</Box>
  )
}

export default AppBarBodyFavorites