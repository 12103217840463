import * as React from 'react';
import './index.css';
import { widget } from '../../../charting_library';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	console.log(results,'ini di get language')
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		// symbol: 'BTCUSD',
		// interval: '1',
		// datafeedUrl: 'http://localhost:8080',
		// symbol: 'AAPL',
		// interval: 'D',
		// datafeedUrl: 'https://demo_feed.tradingview.com',
		// libraryPath: '/charting_library/',
		// chartsStorageUrl: 'https://saveload.tradingview.com',
		// chartsStorageApiVersion: '1.1',
		// clientId: 'tradingview.com',
		// userId: '123',
		// fullscreen: false,
		// autosize: true,
		// studiesOverrides: {},
		        // symbol: 'Bitfinex:BTC/USD', // default symbol
        // interval: '1D', // default interval
        fullscreen: true, // displays the chart in the fullscreen mode
		// datafeedUrl: 'https://demo_feed.tradingview.com',
        container: 'tv_chart_container',
        datafeedUrl: 'https://demo_feed.tradingview.com',
        symbol: 'AAPL',
		interval: 'D',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		autosize: true,
		studiesOverrides: {},
		
	};

	tvWidget = null;

	constructor(props) {
		super(props);
		console.log(props,'this is props in app component chart basic')
		this.ref = React.createRef();
	}

	componentDidMount() {
		console.log('in component did mount',this.props.datafeedUrl)
		const widgetOptions = {
			symbol: this.props.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			// datafeed:this.props.datafeedUrl,
			datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
			// datafeed:new UDFCompatibleDatafeed(this.props.datafeedUrl),
			// datafeed: new window. UDFCompatibleDatafeed("https://demo_feed.tradingview.com"),
			interval: this.props.interval,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			loading_screen: { backgroundColor: "#000" },
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['move_logo_to_main_pane','eader_settings','header_saveload','left_toolbar','study_templates','header_compare','header_indicators','use_localstorage_for_settings','header_symbol_search','adaptive_logo',],
			enabled_features: [],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
			debug: true,
			theme:"dark",
		};

		// console.log('new TV widget')
		// const tvWidget = new widget(widgetOptions);
		// console.log('pushing tv widget')
		// this.tvWidget = tvWidget;
		// console.log('tv widget pushed')

        console.log(widgetOptions, 'ini widget')

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		
		tvWidget.onChartReady(() => {
			console.log(widgetOptions.datafeed,'tvwidget on chart ready')
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));
				button.innerHTML = 'Check API';
			});
			// tvWidget.activeChart().setTimezone('Asia/Singapore');
			// tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 9 })
			// tvWidget.activeChart().createStudy('Triangle', false, false, { in_0: 26 }, {"%d.color" : "#FF0000"})
			
			// export declare type SupportedLineTools = "text" | "anchored_text" | "note" | "anchored_note" | "signpost" | "double_curve" | "arc" | "icon" | "arrow_up" | "arrow_down" | 
			// "arrow_left" | "arrow_right" | "price_label" | "price_note" | "arrow_marker" | "flag" | "vertical_line" | "horizontal_line" | "cross_line" | "horizontal_ray" | "trend_line" | 
			// "info_line" | "trend_angle" | "arrow" | "ray" | "extended" | "parallel_channel" | "disjoint_angle" | 
			// "flat_bottom" | "pitchfork" | "schiff_pitchfork_modified" | "schiff_pitchfork" | "balloon" | "inside_pitchfork" | "pitchfan" | "gannbox" | "gannbox_square" | "gannbox_fixed" | 
			// "gannbox_fan" | "fib_retracement" | "fib_trend_ext" | "fib_speed_resist_fan" | "fib_timezone" | "fib_trend_time" | "fib_circles" | "fib_spiral" | "fib_speed_resist_arcs" | 
			// "fib_channel" | "xabcd_pattern" | "cypher_pattern" | "abcd_pattern" | "callout" | "triangle_pattern" | "3divers_pattern" | 
			// "head_and_shoulders" | "fib_wedge" | "elliott_impulse_wave" | "elliott_triangle_wave" | "elliott_triple_combo" | "elliott_correction" | "elliott_double_combo" | 
			// "cyclic_lines" | "time_cycles" | "sine_line" | "long_position" | "short_position" | "forecast" | "date_range" | "price_range" | "date_and_price_range" | "bars_pattern" | "ghost_feed" | "projection" | "rectangle" | "rotated_rectangle" | "circle" | "ellipse" | "triangle" | "polyline" | "path" | 
			// "curve" | "cursor" | "dot" | "arrow_cursor" | "eraser" | "measure" | "zoom" | "brush" | "highlighter" | "regression_trend" | "fixed_range_volume_profile";

			// tvWidget.activeChart().createMultipointShape(
			// 	[
			// 		{ time: 1660064400, price: 34 }
			// 	],
			// 	{
			// 		shape: "arrow_right",
			// 		lock: true,
			// 		disableSelection: true,
			// 		disableSave: true,
			// 		disableUndo: true,
			// 		text: "🐑🐑🐑🐑🐑🐑🐑🐑🐑🐑",
			// 	}
			// );
			// tvWidget.activeChart().createMultipointShape(
			// 	[
			// 		{ time: 1660124605, price: 180.10 }, 
			// 		{ time: 1660064400, price: 150.24 }
			// 	],
			// 	{
			// 		shape: "fib_retracement",
			// 		lock: true,
			// 		disableSelection: true,
			// 		disableSave: true,
			// 		disableUndo: true,
			// 		text: "Ini kambing yang beli",
			// 	}
			// );
			// //"arrow_up" | "arrow_down" | "flag" | "vertical_line" | "horizontal_line" | "long_position" | "short_position" | "icon"
			// tvWidget.activeChart().createShape({ time: 1660064400 }, { shape: 'short_position' });



		});
	}

	

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		console.log(this.ref,'ini ref nya')
		return (
			<div
				ref={ this.ref }
				className='TVChartContainer'
			/>
		);
	}
}