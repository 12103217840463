import React from 'react'
// import Header from '../Components/AppBars/Header'
import Footer from '../Components/AppBars/Footer'
import AppBarPattern from '../Components/AppBars/AppBarPattern'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'

function PatternPage() {
  return (
	<>
	<HeaderResponsive/>
	<AppBarPattern/>
	<Footer/>
	</>
	)
}

export default PatternPage