import { HStack, Skeleton, SkeletonCircle, SkeletonText, Spacer } from '@chakra-ui/react'
import React from 'react'

function ExploreSkeleton() {
	const TheSkeleton = ()=>(
	<HStack p='2'>
  		{/* <SkeletonCircle /> */}
		<SkeletonText noOfLines={1} width='150px' height='10px'/>
		<SkeletonText noOfLines={1} width='150px' height='10px'/>
 	</HStack>

	)
  return (
  <>
  	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
	<TheSkeleton/>
  </>
	
  )
}

export default ExploreSkeleton

