import * as React from 'react';
import './index.css';
import { widget } from '../../../charting_library';
import datafeed from './Data/datafeed'

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}


export class TVChartContainerTest extends React.PureComponent {
	static defaultProps = {
        symbol: 'Kucoin:BTC/USDT', // default symbol
        interval: '1h', // default interval
        fullscreen: true, // displays the chart in the fullscreen mode
		// datafeedUrl: 'https://demo_feed.tradingview.com',
        container: 'tv_chart_container',
        // datafeedUrl: datafeed,
        // symbol: 'AAPL',
		// interval: 'D',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		autosize: true,
		studiesOverrides: {},

	};

	tvWidget = null;

	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	componentDidMount() {
		console.log('in component did mount',this.props.datafeedUrl)
		const widgetOptions = {
			symbol: this.props.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			// datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
			datafeed: datafeed,
			interval: this.props.interval,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
            debug: true,
            theme:"dark",
		};

        console.log(widgetOptions, 'ini widget')

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			console.log(widgetOptions.datafeed,'tvwidget on chart ready')
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			});
			
			// tvWidget.activeChart().setTimezone('Asia/Singapore');
			// tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 9 })
			// tvWidget.activeChart().createStudy('MACD', false, false, { length: 9 })
			// tvWidget.activeChart().createStudy('Triangle', false, false, { in_0: 26 }, {"%d.color" : "#FF0000"})

			// 	export declare type SupportedLineTools = "text" | "anchored_text" | "note" | "anchored_note" | "signpost" | "double_curve" | "arc" | "icon" | "arrow_up" | "arrow_down" | 
			// "arrow_left" | "arrow_right" | "price_label" | "price_note" | "arrow_marker" | "flag" | "vertical_line" | "horizontal_line" | "cross_line" | "horizontal_ray" | "trend_line" | 
			// "info_line" | "trend_angle" | "arrow" | "ray" | "extended" | "parallel_channel" | "disjoint_angle" | 
			// "flat_bottom" | "pitchfork" | "schiff_pitchfork_modified" | "schiff_pitchfork" | "balloon" | "inside_pitchfork" | "pitchfan" | "gannbox" | "gannbox_square" | "gannbox_fixed" | 
			// "gannbox_fan" | "fib_retracement" | "fib_trend_ext" | "fib_speed_resist_fan" | "fib_timezone" | "fib_trend_time" | "fib_circles" | "fib_spiral" | "fib_speed_resist_arcs" | 
			// "fib_channel" | "xabcd_pattern" | "cypher_pattern" | "abcd_pattern" | "callout" | "triangle_pattern" | "3divers_pattern" | 
			// "head_and_shoulders" | "fib_wedge" | "elliott_impulse_wave" | "elliott_triangle_wave" | "elliott_triple_combo" | "elliott_correction" | "elliott_double_combo" | 
			// "cyclic_lines" | "time_cycles" | "sine_line" | "long_position" | "short_position" | "forecast" | "date_range" | "price_range" | "date_and_price_range" | "bars_pattern" | "ghost_feed" | "projection" | "rectangle" | "rotated_rectangle" | "circle" | "ellipse" | "triangle" | "polyline" | "path" | 
			// "curve" | "cursor" | "dot" | "arrow_cursor" | "eraser" | "measure" | "zoom" | "brush" | "highlighter" | "regression_trend" | "fixed_range_volume_profile";

			tvWidget.activeChart().createMultipointShape(
				[
					{ time: 1662626500, price: 19323.34 }
				],
				{
					shape: "arrow_right",
					lock: true,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					text: "🐑🐑🐑🐑🐑🐑🐑🐑🐑🐑",
				}
			);
			tvWidget.activeChart().createMultipointShape(
				[	
					{ time: 1663387800, price: 19895.31 } ,
					{ time: 1663387080, price: 19882.82 }
				],
				{
					shape: "trend_line",
					lock: false,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					text: "Ini kambing yang beli",
				}
			);
			tvWidget.activeChart().createMultipointShape(
				[	
					{ time: 1663386600, price: 19894.91 },
					{ time: 1663383660, price: 19911.93 } 
				],
				{
					shape: "trend_line",
					lock: false,
					disableSelection: true,
					disableSave: true,
					disableUndo: true,
					text: "Ini kambing yang beli",
				}
			);
			//"arrow_up" | "arrow_down" | "flag" | "vertical_line" | "horizontal_line" | "long_position" | "short_position" | "icon"
			tvWidget.activeChart().createShape({ time: 16626109500 }, { shape: 'short_position' });
		});



    
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}