import React from "react";
import { SimpleGrid,Box,Image,Badge, Center,Heading} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import getDomain from "../../Utils/getDomain";

function AppBodyMain() {
 
	const domain = getDomain()

	const arrClass = [
		{
			name:'BASIC', 
			cover:domain === 'tradingsyariahid' ? 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/dedy/basic1-2022_10_18_17_11_09.jpg' : 'https://firebasestorage.googleapis.com/v0/b/algotradingid.appspot.com/o/content%2Fthumbnail%2FOnboarding%20-%20Futures%20Trading_400x400?alt=media',
			title:domain === 'tradingsyariahid' ? 'Apa si itu saham syariah' : 'Pengenalan Pasar Crypto Currency'
		},
		{
			name:'INTERMEDIATE', 
			cover:domain === 'tradingsyariahid' ? 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/dedy/intermediate1-2022_10_18_17_11_12.jpg' : 'https://firebasestorage.googleapis.com/v0/b/algotradingid.appspot.com/o/content%2Fthumbnail%2FAlgotrading%20Mastery%20-%20Krypton%20Survival_200x200?alt=media',
			title:domain === 'tradingsyariahid' ? 'Bagaimana cara menggunakan technical analysis?' : 'Krypton Survival'
		},
		{
			name:'ADVANCE', 
			cover:domain === 'tradingsyariahid' ? 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/dedy/advance1-2022_10_18_17_11_06.jpg' : 'https://firebasestorage.googleapis.com/v0/b/algotradingid.appspot.com/o/content%2Fthumbnail%2FAlgotrading%20Mastery%20-%20Bharalogy_400x400?alt=media',
			title:domain === 'tradingsyariahid' ? 'Menggunakan strategi menggunakan algoritma tradingsaham syariah' : 'Chart Pattern, Candle Stick & Teknikal Indicator'
		}
	  ]

	const renderClass = () => {

		  return arrClass.map( x =>

			<Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' mb="1.5rem">
				<Link to={`/masterclass/${x.name.toLowerCase()}`}>
					<Image src={x.cover} w='full'/>
				</Link>

				<Box p='6'>
					<Box display='flex' alignItems='baseline'>
						<Link to={`/masterclass/${x.name.toLowerCase()}`}>
							<Badge borderRadius='full' px='3' py="2" colorScheme='teal'>
								{x.name} CLASS
							</Badge>
						</Link>
					</Box>

					<Box
						mt='1'
						fontWeight='semibold'
						as='h4'
						lineHeight='tight'
						noOfLines={1}
					>
						<Link to={`/masterclass/${x.name.toLowerCase()}`} fontSize={{sm:'xs', md:'sm'}}>{x.title}</Link>
					</Box>
				</Box>
			</Box>
		  )
	}
	

  return (
	<>
		<Center width="80%" margin="auto" marginTop="2rem" marginBottom="5rem" flexDirection="column">
			<Heading as='h2' size={{
				sm:'sm',
				md:'xl'
			}} mb="5" textAlign="center">
				 {domain === 'tradingsyariahid' ? 'TRADING SYARIAH MASTER CLASS' : 'ALGOTRADING MASTER CLASS'}
			</Heading>
			<SimpleGrid columns={{
				sm:2,
				md:3
			}} spacing={{
				sm:2,
				md:3
			}}>
				{renderClass()}
			</SimpleGrid>
		</Center>
	</>
  )
}

export default AppBodyMain;
