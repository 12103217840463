import { Avatar, Box, Button, HStack, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { BiSort } from 'react-icons/bi';
import { Link } from 'react-router-dom'
import colors from '../../../../Utils/colors';

function TablePattern({data, role, coin}) {

    const [dataSort, setDataSort] = useState(data)

    const toast = useToast()

    const [titleTable, setTitleTable] = useState([
        { title: "Date", filter: false },
        { title: "TimeFrame", filter: false },
        { title: "Name", filter: false },
        { title: "Type", filter: false },
        { title: "Symbol", filter: false },
      ]);

    
      const toastAlert = () => {
        toast({
            title: 'Alert !',
            description: 'Oopss.. Only member allowed !',
            position: 'top-right',
            status: 'warning',
            duration: 3000,
            isClosable: true
        })
    }


    const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

            case 1:
                if (titleTable[index].filter){
                  return setDataSort([
                    ...data.sort((a, b) => b.timeframe.localeCompare(a.timeframe)),
                  ]);
                }
                return setDataSort([
                  ...data.sort((a, b) => a.timeframe.localeCompare(b.timeframe)),
                ]);

          case 2:
            if (titleTable[index].filter){
              return setDataSort([
                ...data.sort((a, b) => b.keyword.localeCompare(a.keyword)),
              ]);
            }
            return setDataSort([
              ...data.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            ]);
  
          default:
            return null
        }
      }


  return (
    <TableContainer>
    <Table >
        <Thead>
        <Tr>
        {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => role === "user" ? (
                toastAlert()
            ) : (
                sortBy(x.title)
            )}
                   >
           <HStack spacing="1">
             <Text color={colors.theme}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.theme}/>
           </HStack>
         </Button>
           </Th>
        ))}
        </Tr>
        </Thead>
    <Tbody>
    {data ?
    data.map((x)=>
    {
    return <>
        <Tr key={x.Id}>
            <Td>	
            {coin === "crypto" ? (
              <Box>
                 <Text textAlign='center' fontSize='md'>{x.timestamp} </Text>
              </Box>
            ) : (
              <Box>
                <Text textAlign='center' fontSize='xl'>{moment.unix(x.timestamp._seconds).format("HH:mm:ss")} </Text>
                <Text textAlign='center' fontSize='md'>{moment.unix(x.timestamp._seconds).format("DD/MM/YYYY")}</Text>

                {/* <Text textAlign='center' fontSize='md'>{moment.unix(x.timestamp._seconds).format("DD/MM/YYYY")}</Text> */}
            </Box>
            )}
            </Td>
            <Td>
              {coin === "crypto" ? (
                  <Text>{ x.timeframe === 'minute_1' ? "1 Mnt" :  x.timeframe === 'minute_15' ? "15 Mnt" : x.timeframe === 'hour_1' ? '1 Hr' : ''}</Text>
              ) : (
                <Text>{ x.timeframe === '1m' ? "1 Mnt" :  x.timeframe === '15m' ? "15 Mnt" : x.timeframe === '1h' ? '1 Hour' : x.timeframe === "1d" ? "1 Day" : ""}</Text>
              )}
            </Td>
            <Td>
                <Text>{x.title}</Text>

            </Td>
            <Td>
                <Text>{coin === "crypto" ? ("Cryptocurrency") : ("StockID")}</Text>
            </Td>
            <Td>
            <SimpleGrid columns={30} spacingX='-10px'>
            {coin === "crypto" ? (
                
                  x?.coins?.map((y,index)=>{
                  const symbolName= y.symbol.split("-")
                          if(role === "user") return (
                              <Link to={`/market/crypto/NoPayment`} >
                                  <Avatar 
                                  name={"?"} 
                                  size='sm' 
                                  bgColor={colors.theme}
                                  color={colors.black}
                                  />
                              </Link>)
                          if(symbolName[0]==="USDC" || symbolName[0]==="BUSD" || symbolName[0]==="USDT" ||symbolName[0]==="USDD" ||symbolName[0]==="USDN" )
                              return <></>
                          else{
                            // const timeFrame = x.timeframe === 'minute_1' ? "1" :  x.timeframe === 'minute_15' ? "15" : x.timeframe === 'hour_1' ? '1h' : ''
                            return(
                                // <Link to={`/market/crypto/${symbolName[0]}?price=0000&time=0000&timeframe=1`} >
                                <Link to={`/market/crypto/${symbolName[0]}?timeframe=${x.timeframe}`} >
                                    <Avatar 
                                    name={y.symbol} 
                                    size='sm'
                                    src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fcrypto%2F${symbolName[0]}.png?alt=media`} 
                                    />
                                </Link>)
                          }

                          
                  })
                      
            ) : (
              
                x?.data?.map((y,index)=>{
                const symbolName= y.split("-")
                        if(role === "user") return (
                            <Link to={`/market/crypto/NoPayment`} >
                                <Avatar 
                                name={"?"} 
                                size='sm' 
                                bgColor={colors.theme}
                                color={colors.black}
                                />
                            </Link>)
                        if(symbolName[0]==="USDC" || symbolName[0]==="BUSD" || symbolName[0]==="USDT" ||symbolName[0]==="USDD" ||symbolName[0]==="USDN" )
                            return <></>
                        else{
                          // const timeFrame = x.timeframe === '1hr' ? "1h" :  x.timeframe === '1d' ? '1d'
                          return(
                            <Link to={`/market/stocks-id/${symbolName[0]}?timeframe=${x.timeframe}`} >
                                <Avatar 
                                name={y} 
                                size='sm'
                                src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${symbolName[0]}.png?alt=media`} 
                                />
                            </Link>)
                        }
                }
                )
                    

            )}
          
                </SimpleGrid>
                </Td>
                </Tr>


            </>
    }):<></>}
    </Tbody>
    </Table>
    </TableContainer>
  )
}

export default TablePattern