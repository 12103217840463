import { Box, Button, Center, Checkbox, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import AppBarAdminHeader from '../../Components/AppBars/admin/AppBarAdminHeader'
import AppBarAdminMarketSyariah from '../../Components/AppBars/admin/AppBarAdminMarketSyariah'

function MarketAdminPage() {
  return (
  <>
  	<AppBarAdminHeader/>
	  <SimpleGrid columns={4} gap='2' m='2'>
		  <Button>Syaria</Button>
		  <Button>Syaria</Button>
		  <Button>Syaria</Button>
		  <Button>Syaria</Button>
	  </SimpleGrid>
	<AppBarAdminMarketSyariah/>
	
  </>
	
  )
}

export default MarketAdminPage