import React from "react";
import AppBarBodyEntrepreneurs from "../Components/AppBars/AppBarBodyEntrepreneurs";
import Footer from "../Components/AppBars/Footer";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function EntrepreneursPage() {
  return (
    <>
      <HeaderResponsive />
      <AppBarBodyEntrepreneurs />
      <Footer />
    </>
  );
}

export default EntrepreneursPage;
