import { Avatar, Box, Heading, HStack, SimpleGrid,Spacer,Stack,Text, VStack } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../Routes/hooks/AuthContext'
import colors from '../../../Utils/colors'
import getDomain from '../../../Utils/getDomain'
import { NumberAcronym } from '../../../Utils/NumberUtils'
import UpDownHelper from '../../../Utils/UpDownHelper'

function AppBarDashboard() {

	const [crypto, setCrypto] = useState()
	const [stockId, setStockId] = useState()
	const [stockSyariah, setStocksSyariah] = useState()

	const link = getDomain()

	const { coinCrypto, coinStocks, stocksApi } = useContext(AuthContext)

	const getDataUpdated = () => {
		if(link ==="algotradingid"){
			const cryptoArray = coinCrypto?.sort((a,b) => b.volume24h - a.volume24h )
			setCrypto(cryptoArray?.slice(0, 5))
			console.log(cryptoArray.slice(0, 5))

			console.log(coinStocks, 'stocks')
			const stocksFilter = coinStocks?.filter((x) => x.Capitalization > 10000000000000)
			const stocksArray = stocksFilter?.sort((a,b) => b.Volume > a.Volume)
			setStockId(stocksArray?.slice(0, 5))

			const stocksFilterSyariah = stocksApi?.filter((x) => x.Capitalization > 10000000000000)
			const stocksArraySyariah = stocksFilterSyariah?.sort((a,b) => b.Volume > a.Volume)
			setStocksSyariah(stocksArraySyariah?.slice(0, 5))
		}else{
			console.log(coinStocks, 'stocks')
			const stocksFilter = coinStocks?.filter((x) => x.Capitalization > 10000000000000)
			const stocksArray = stocksFilter?.sort((a,b) => b.Volume > a.Volume)
			setStockId(stocksArray?.slice(0, 5))

			const stocksFilterSyariah = stocksApi?.filter((x) => x.Capitalization > 10000000000000)
			const stocksArraySyariah = stocksFilterSyariah?.sort((a,b) => b.Volume > a.Volume)
			setStocksSyariah(stocksArraySyariah?.slice(0, 5))
		}
	}

	useEffect(() => {
		getDataUpdated()
	
	  return () => {
	  }
	}, [])
	
	if(link === "algotradingid") return (
	<Box>
		<SimpleGrid columns={link === "algotradingid" ? ([1, null, 3]) : ([1, null, 2])} p='5' gap={2}>
			<Stack bgColor="black" p='5' m='5' borderRadius="xl">
				<Heading  size="lg" fontWeight="extrabold" color={colors.theme} mb={5}>Cryptocurrency</Heading>
				{crypto && crypto.map((x, index) =>
					<Link key={index} to={`/market/crypto/${x?.symbol}`}>

					<HStack p='1'>
						<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
						<Text>{x?.symbol}</Text>
						<Spacer />
						<Box>
							<HStack justifyContent="start-end">
								<Text >{UpDownHelper(x?.percentChange24h)}</Text>
							</HStack>
							<Text  size="sm" fontWeight="bold">${NumberAcronym(x?.volume24h)}</Text>
						</Box>
						<Spacer />
						<Text size="sm">{moment(x?.lastUpdated).toLocaleString()}</Text>
					</HStack>
 					</Link>
				)}

			</Stack>


			<Stack bgColor="black" p='5' m='5' borderRadius="xl">
				<Heading  size="lg" fontWeight="extrabold" color={colors.theme} mb={5}>Stock-ID</Heading>
				{stockId && stockId.map((x, index) =>
					<Link key={x.id} to={`/market/stocks-id/${x.Code}`}>

					<HStack p='1'>
					<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
						<Text>{x?.Code}</Text>
						<Spacer />
						<Box>
							<HStack justifyContent="start-end">
								<Text >{UpDownHelper(x.OneDay * 100)}</Text>
							</HStack>
							<Text  size="sm" fontWeight="bold">${NumberAcronym(x.Volume)}</Text>
						</Box>
						<Spacer />
						<Text size="sm">{moment(x?.LastUpdate).toLocaleString()}</Text>
					</HStack>
 					</Link>
				)}

			</Stack>


			<Stack bgColor="black" p='5' m='5' borderRadius="xl">
				<Heading  size="lg" fontWeight="extrabold" color={colors.theme} mb={5}>Emiten Syariah</Heading>
				{stockSyariah && stockSyariah.map((x, index) =>
						<Link key={x.id} to={`/market/stocks-id/${x.Code}`}>

						<HStack p='1'>
						<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
							<Text>{x?.Code}</Text>
							<Spacer />
							<Box>
								<HStack justifyContent="start-end">
									<Text >{UpDownHelper(x.OneDay * 100)}</Text>
								</HStack>
								<Text  size="sm" fontWeight="bold">${NumberAcronym(x.Volume)}</Text>
							</Box>
							<Spacer />
							<Text size="sm">{moment(x?.LastUpdate).toLocaleString()}</Text>
						</HStack>
						 </Link>
				)}

			</Stack>

		</SimpleGrid>
	</Box>
  )

  if(link === "tradingsyariahid") return (
	<Box>
		<SimpleGrid columns={link === "algotradingid" ? ([1, null, 3]) : ([1, null, 2])} p='5' gap={2}>
			{/* <Stack bgColor="black" p='5' m='5' borderRadius="xl">
				<Heading  size="lg" fontWeight="extrabold" color={colors.theme} mb={5}>Cryptocurrency</Heading>
				{crypto && crypto.map((x, index) =>
					<Link key={index} to={`/market/crypto/${x?.symbol}`}>

					<HStack p='1'>
						<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
						<Text>{x?.symbol}</Text>
						<Spacer />
						<Box>
							<HStack justifyContent="start-end">
								<Text >{UpDownHelper(x?.percentChange24h)}</Text>
							</HStack>
							<Text  size="sm" fontWeight="bold">${NumberAcronym(x?.volume24h)}</Text>
						</Box>
						<Spacer />
						<Text size="sm">{moment(x?.lastUpdated).toLocaleString()}</Text>
					</HStack>
 					</Link>
				)}

			</Stack> */}


			<Stack bgColor="black" p='5' m='5' borderRadius="xl">
				<Heading  size="lg" fontWeight="extrabold" color={colors.theme} mb={5}>Stock-ID</Heading>
				{stockId && stockId.map((x, index) =>
					<Link key={x.id} to={`/market/stocks-id/${x.Code}`}>

					<HStack p='1'>
					<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
						<Text>{x?.Code}</Text>
						<Spacer />
						<Box>
							<HStack justifyContent="start-end">
								<Text >{UpDownHelper(x.OneDay * 100)}</Text>
							</HStack>
							<Text  size="sm" fontWeight="bold">${NumberAcronym(x.Volume)}</Text>
						</Box>
						<Spacer />
						<Text size="sm">{moment(x?.LastUpdate).toLocaleString()}</Text>
					</HStack>
 					</Link>
				)}

			</Stack>


			<Stack bgColor="black" p='5' m='5' borderRadius="xl">
				<Heading  size="lg" fontWeight="extrabold" color={colors.theme} mb={5}>Emiten Syariah</Heading>
				{stockSyariah && stockSyariah.map((x, index) =>
						<Link key={x.id} to={`/market/stocks-id/${x.Code}`}>

						<HStack p='1'>
						<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
							<Text>{x?.Code}</Text>
							<Spacer />
							<Box>
								<HStack justifyContent="start-end">
									<Text >{UpDownHelper(x.OneDay * 100)}</Text>
								</HStack>
								<Text  size="sm" fontWeight="bold">${NumberAcronym(x.Volume)}</Text>
							</Box>
							<Spacer />
							<Text size="sm">{moment(x?.LastUpdate).toLocaleString()}</Text>
						</HStack>
						 </Link>
				)}

			</Stack>

		</SimpleGrid>
	</Box>
  )
}

export default AppBarDashboard