// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
	console.log(path, 'ini path')
	try {
		const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}
// make docial new
export async function makeApiRequestDocialNew(path) {
	console.log(path, 'ini path')
	try {
		const response = await fetch(`https://docial-crypto-h3tahmnrkq-uc.a.run.app/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`docial new request error: ${error.status}`);
	}
}

// Make requests to Docial
export async function makeApiRequestDocial(path) {
	console.log(path, 'ini path')
	try {
		const response = await fetch(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}