import "./App.css";
import { Route, Routes, useNavigate, Link } from "react-router-dom";
import { fetchToken, onMessageListener } from "./Configs/firebase";
import { useColorMode, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";

import HomePage from "./Pages/HomePage";
import SignUpPage from "./Pages/SignUpPage";
import SignInPage from "./Pages/SignInPage";
import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
import PaymentPage from "./Pages/PaymentPage";
import CoverSeminar from "./Pages/CoverSeminar";
import EpisodePage from "./Pages/MasterClassPage";
import Podcast from "./Pages/Podcast";
import MainPage from "./Pages/MainPage";
import FilmPage from "./Pages/FilmPage";
import PodcastPage from "./Pages/PodcastPage";
import EventsPage from "./Pages/EventsPage";
import SearchPage from "./Pages/SearchPage"
import FavoritesPage from "./Pages/FavoritesPage";
import MasterClassPage from "./Pages/MasterClassPage";
import MasterClassPageDetail from "./Pages/MasterClassPageDetail";
import EntrepreneursPage from "./Pages/EntrepreneursPage";
import WatchPage from "./Pages/WatchPage";
import CollectionPage from "./Pages/CollectionPage";
import DashboardPage from "./Pages/admin/DashboardPage";
import UserPage from "./Pages/admin/UserPage";
import SpeakersPage from "./Pages/admin/SpeakersPage";
import ContentPage from "./Pages/admin/ContentPage";
import TrailerPage from "./Pages/admin/TrailerPage";
import EventsAdminPage from "./Pages/admin/EventsAdminPage";
import SyaratKetentuanPage from "./Pages/SyaratKetentuanPage";
import KebijakanPrivasiPage from "./Pages/KebijakanPrivasiPage";
import DisclaimerPage from "./Pages/DisclaimerPage";
import AddAdminPage from "./Pages/admin/AddAdminPage";
import PaymentAdminPage from "./Pages/admin/PaymentAdminPage";
import ChartPage from "./Pages/chart/ChartPage";
import MarketPage from "./Pages/MarketPage";
import MarketViewPage from "./Pages/MarketViewPage";
import PatternPage from "./Pages/PatternPage";
import PatternViewPage from "./Pages/PatternViewPage";
import PaymentCallback from "./Pages/admin/PaymentCallback";
import PaymentCallbackGlobal from "./Pages/admin/PaymentCallbackGlobal";
import Error404Page from "./Pages/Error404Page";
import ProfilePage from "./Pages/ProfilePage";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import ProfileUserPage from "./Pages/ProfileUserPage";
import MarketAdminPage from "./Pages/admin/MarketAdminPage";
import getDomain from "./Utils/getDomain";
import NoPaymentPage from "./Pages/NoPaymentPage";
import GoogleTagManager from "./Configs/GoogleTagManager";
import OnBoardingPage from "./Pages/OnBoardingPage";
import StepPage from "./Pages/StepPage";
import PaymentGlobal from "./Pages/PaymentGlobal";
import TradingPlanAdmin from "./Pages/admin/TradingPlanAdmin";
import TradingPlan from './Pages/TradingPlan'
import NewMemberPage from "./Pages/admin/NewMemberPage";

// TagManager.dataLayer({
//   dataLayer: {
//     event: "pageview",
//     path: "/home/",
//     user: ""
//     // page: {
//     //   path: "/home/",
//     //   title:" "
//     // }
//   }
// })

function App() {
  GoogleTagManager();

  const [tokenId, setTokenId] = useState("");
  const toast = useToast();
  const { toggleColorMode } = useColorMode();

  const link = getDomain();

  const getNotif = async () => {
    try {
      onMessageListener(toast);
    } catch (error) {
      toast({
        title: error,
        description: error,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getNotif();

    fetchToken(setTokenId);
  }, [localStorage.getItem("Auth Token")]);

  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/login" element={<SignInPage />} />
      <Route path="/payment/:type/:id" element={<PaymentPage />} />
      <Route path="/paymentglobal/:type/:id" element={<PaymentGlobal />} />
      <Route
        path="/payment/callback/:status/:id"
        element={<PaymentCallback />}
      />
      <Route
        path="/paymentglobal/callback/:status/:id"
        element={<PaymentCallbackGlobal />}
      />
      <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
      <Route path="/" element={<MainPage />} />
      <Route path="/films" element={<FilmPage />} />
      <Route path="/podcasts" element={<PodcastPage />} />
      <Route path="/events" element={<EventsPage />} />
      <Route path="/masterclass" element={<MasterClassPage />} />
      <Route
        path="/masterclass/:level"
        element={
          <ProtectedRoutes>
            <MasterClassPageDetail />
          </ProtectedRoutes>
        }
      />
      <Route path="/search/:params" element={<SearchPage />} />
      <Route path="/favorites" element={<FavoritesPage />} />
      <Route path="/entrepreneurs" element={<EntrepreneursPage />} />
      <Route path="/watch/:id" element={<WatchPage />} />
      <Route path="/collection/:id" element={<CollectionPage />} />
      <Route path="/profile/:id" element={<ProfilePage />} />
      <Route path="/profileuser" element={<ProfileUserPage />} />
      <Route path="/coverseminar" element={<CoverSeminar />} />
      <Route path="/episode" element={<EpisodePage />} />
      <Route path="/podcast" element={<Podcast />} />
      <Route path="/syaratketentuan" element={<SyaratKetentuanPage />} />
      <Route path="/kebijakanprivasi" element={<KebijakanPrivasiPage />} />
      <Route path="/disclaimer" element={<DisclaimerPage />} />
      <Route path="/admin/users/addadmin" element={<AddAdminPage />} />
      <Route path="/chart" element={<ChartPage />} />
      <Route path="/onboarding" element={<OnBoardingPage />} />
      <Route path="/onboarding/:type" element={<StepPage />} />
      <Route path="/error404" element={<Error404Page />} />

      <Route
        path="/market"
        element={
          <ProtectedRoutes>
            <MarketPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/market/:type"
        element={
          <ProtectedRoutes>
            <MarketPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/market/:type/:symbol"
        element={
          <ProtectedRoutes>
            <MarketViewPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/market/:type/NoPayment"
        element={
          <ProtectedRoutes>
            <NoPaymentPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/patterns"
        element={
          <ProtectedRoutes>
            <PatternPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/trading-plan"
        element={
          <ProtectedRoutes>
            <TradingPlan />
          </ProtectedRoutes>
        }
      />
      {/* <Route path="/strategies" element={<ProtectedRoutes><Strategies /></ProtectedRoutes>} /> */}
      <Route
        path="/patterns/:type"
        element={
          <ProtectedRoutes>
            <PatternPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/pattern/:type"
        element={
          <ProtectedRoutes>
            <PatternViewPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/pattern/:type/:name"
        element={
          <ProtectedRoutes>
            <PatternViewPage />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/dashboard"
        element={
          <ProtectedRoutes>
            <DashboardPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/users"
        element={
          <ProtectedRoutes>
            <UserPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/users/add-new-member"
        element={
          <ProtectedRoutes>
            <NewMemberPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/market"
        element={
          <ProtectedRoutes>
            <MarketAdminPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/speakers"
        element={
          <ProtectedRoutes>
            <SpeakersPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/payment"
        element={
          <ProtectedRoutes>
            <PaymentAdminPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/contents"
        element={
          <ProtectedRoutes>
            <ContentPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/trailer"
        element={
          <ProtectedRoutes>
            <TrailerPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/admin/events"
        element={
          <ProtectedRoutes>
            <EventsAdminPage />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/admin/tradingplan"
        element={
          <ProtectedRoutes>
            <TradingPlanAdmin />
          </ProtectedRoutes>
        }
      />
    </Routes>
  );
}

export default App;
