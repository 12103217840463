import React from "react";
import AppBarAdminAddNewMember from "../../Components/AppBars/admin/AppBarAdminAddNewMember";
import AppBarAdminHeader from "../../Components/AppBars/admin/AppBarAdminHeader";

function NewMemberPage() {  
  return (
    <>
      <AppBarAdminHeader />
      <AppBarAdminAddNewMember />
    </>
  );
}

export default NewMemberPage;
