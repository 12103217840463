import { Avatar, Box, Button, Center, Divider, Flex, Heading, HStack, Image, Select, SimpleGrid, Spacer, Spinner, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useQuery, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TVChartContainer } from '../AppComponents/AppComponentChart/AppComponentChartBasic';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
// import TradingViewWidget, { Themes, IntervalTypes } from 'react-tradingview-widget';
import { db } from '../../Configs/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { dollar, FloatRoundUp, NumberAcronym, Rupiah } from '../../Utils/NumberUtils';
import usdDecimalHelper from '../../Utils/usdDecimalHelper';
import UpDownHelper from '../../Utils/UpDownHelper';
import colors from '../../Utils/colors';
import { TVChartContainerSymbol } from '../AppComponents/AppComponentChart/AppComponentChartSymbol';
import { TVChartContainerSymbolStocks } from '../AppComponents/AppComponentChart/AppComponentChartSymbolStocks';
import AuthContext from '../../Routes/hooks/AuthContext';
import { makeApiRequest } from '../AppComponents/AppComponentChart/Data/helpers';
import axios from 'axios';
import getDomain from '../../Utils/getDomain';
import moment from 'moment';
import TablePatternDetail from './patterns/TablePattern/TablePatternDetail';
import datafeed from '../AppComponents/AppComponentChart/Data/datafeed';

function AppBarMarketView() {

	const [query] = useSearchParams()
	const param = useParams();
	const [tab, setTab] = useState('overview')
	const [dataCoin, setDataCoin] = useState("")
	// const [strategyArrFirst, setStrategyArrFirst] = useState()
	const [strategyArr, setStrategyArr] = useState()
	const [strategyArrTwo, setStrategyArrTwo] = useState()
	const [strategyArrThree, setStrategyArrThree] = useState()
	const [time, setTime] = useState('minute_1')
	const [patternList, setPatternList] = useState()
	const [patternListStock, setPatternListStock] = useState()
	const [timeStocks, setTimeStock] = useState('1h')


	const { coinCrypto, coinStocks, stocksApi } = useContext(AuthContext)


	const link = getDomain()

	let exchange = ""
	if (param.type === "crypto")
		exchange = "kucoin"
	else if (param.type === "stocks-id")
		exchange = "idx"

	let symbol = ""
	if (param.type === "crypto")
		symbol = param.symbol + "USDT"
	else if (param.type === "stocks-id")
		symbol = param.symbol

	const navigate = useNavigate()




	

	
	const getData = async () => {
		if (link === "algotradingid") {
			if (param.type === "crypto") {
				const dataCrypto = coinCrypto
				const filterData = dataCrypto.find((x) => x.symbol === param.symbol)
				setDataCoin(filterData)
			}

			if (param.type === "stocks-id") {
				const dataStock = coinStocks
				const filterData = dataStock.find((x) => x.Code === param.symbol)
				setDataCoin(filterData)
			}
		} else {
			if (param.type === "stocks-id") {
				const dataStocksApi = stocksApi
				const filterData = dataStocksApi.find((x) => x.Code === param.symbol)
				setDataCoin(filterData)
			}
		}
	}

	const getDataPatternStocks = async () => {
		if(param.type === "stocks-id"){
			try {
				const res = await axios.get(`https://docial-crypto-h3tahmnrkq-uc.a.run.app/api/v1/pattern?pattern=feed&time=${timeStocks}`)
				const dataArr = res.data.data
				// setPatternListStock(dataArr)

				const arr = dataArr
				const filterArr = arr.filter((x) => x.data.includes(param.symbol))
				console.log(filterArr, 'filter')
				const allPattern = filterArr.map(x => {
					return{
						name:x.keyword,
						candle:x.indicator.filter(y => y.symbol === param.symbol),
						timeframe: x.timeframe
					}
				})
				setPatternListStock(allPattern, 'allpattern')
			} catch (error) {
				console.lg(error)
			}
		}
	}

	useEffect(() => {
		getDataPatternStocks()
	
	  return () => {
		setPatternListStock()
	  }
	}, [timeStocks])
	

    const getDataPattern = async () => {
		if(param.type === "crypto"){
			try {
				const res = await axios.get(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/api/generate-pattern/${param.symbol}?timeFrame=${time}`)
				const data = res.data
				let pattern = []
					for (const iterator of Object.keys(data)) {
						if(data[iterator].length > 0 ){
							pattern.push({'keyword': iterator, 'data': data[iterator]})
						}
					}
					setPatternList(pattern)
				} catch (error) {
				 console.log(error, 'ini error')	
				}
		}
	}
	
	

	useEffect(() => {
		getDataPattern()
	
	  return () => {
        setPatternList()
	  }
	}, [time])

	// const getDataStrategyFirst = async () => {
	// 	if (link === "algotradingid") {
	// 		const timeFrame = query.get("timeframe") === '1' ? 'minute_1' : query.get("timeframe") === '15' ? 'minute_15' : query.get("timeframe") === '1h' ? 'hour_1' : ''

	// 		try {
	// 			console.log(param.symbol)
	// 			const res = await axios.get(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/api/generate-pattern/${(param.symbol).toLowerCase()}?timeFrame=${timeFrame}`)
	// 			setStrategyArrFirst(res.data)
	// 		} catch (error) {
	// 			console.log(error, 'error strategy')
	// 		}
	// 	} else {
	// 		const timeFrame = query.get("timeframe") === '1' ? 'minute_1' : query.get("timeframe") === '15' ? 'minute_15' : query.get("timeframe") === '1h' ? 'hour_1' : ''

	// 		try {
	// 			console.log(param.symbol)
	// 			const res = await axios.get(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/api/generate-pattern/${(param.symbol).toLowerCase()}?timeFrame=${timeFrame}`)
	// 			setStrategyArrFirst(res.data)
	// 		} catch (error) {
	// 			console.log(error, 'error strategy')
	// 		}
	// 	}
	// }

	const getDataStrategy = async () => {
			try {
				const res = await axios.get(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/api/generate-pattern/${(param.symbol).toLowerCase()}?timeFrame=minute_1`)
				setStrategyArr(res.data)
			} catch (error) {
				console.log(error, 'error strategy')
			}
	}

			const getDataStrategyTwo = async () => {
				try {
					const res = await axios.get(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/api/generate-pattern/${(param.symbol).toLowerCase()}?timeFrame=minute_15`)
					setStrategyArrTwo(res.data)
				} catch (error) {
					console.log(error, 'error strategy')
				}
		}

		const getDataStrategyThree = async () => {
			try {
				const res = await axios.get(`https://docial-api-crypto-h3tahmnrkq-uc.a.run.app/api/generate-pattern/${(param.symbol).toLowerCase()}?timeFrame=hour_1`)
				setStrategyArrThree(res.data)
			} catch (error) {
				console.log(error, 'error strategy')
			}
		}

	const handleTime = (x) => {
		setTime(x)
	}

	const handleTimeStock = (y) => {
		setTimeStock(y)
	}
	


	// useEffect(() => {
	// 	// getData()
	// 	getDataStrategyFirst()

	// 	return () => {
	// 		setStrategyArrFirst([])
	// 	}
	// }, [time])

	

	useEffect(() => {
		getData()
		getDataStrategy()
		getDataStrategyTwo()
		getDataStrategyThree()

		return () => {
			setStrategyArr([])
			setStrategyArrTwo([])
			setStrategyArrThree([])
		}
	}, [])


	if (param.type === "crypto") return (

		<Box width='full' height='full' bgColor='black' color='white'>
			{dataCoin ? (
				<SimpleGrid pt='5' columns={[1, null, 2]} >
				<Center>
					<VStack w="full">
						<HStack spacing={5} >
							<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${dataCoin?.id}.png`} />
							<SimpleGrid columns={[1, null, 2]}>
								<Text fontSize={["xl", null, "3xl"]} fontWeight="bold">{dataCoin?.name}</Text>
								<Box>
									<Tag fontSize="md" fontWeight="bold">{dataCoin?.symbol}</Tag>
								</Box>
							</SimpleGrid>
						</HStack>
						<HStack>
							<Tag bgColor={colors.theme} fontWeight="bold" color={colors.black}>Rank #{dataCoin?.cmcRank}</Tag>
						</HStack>
					</VStack>
				</Center>
				<Box p='5' width='full' >
					<Flex flexDirection="row" alignItems="center" >
						<Box ml='5'>
							<Text>{dataCoin?.name} Price</Text>
							<HStack>
								<Heading>{dollar(dataCoin?.price)}</Heading>
								<HStack borderRadius='2xl'>
									<Text color='white' fontSize="3xl" fontWeight="bold" >{UpDownHelper(dataCoin?.percentChange24h)}</Text>
								</HStack>
							</HStack>
						</Box>
					</Flex>
					<SimpleGrid columns={[1, 2, 4]} m='5'>
						<Box>
							<Text>Market Cap</Text>
							<Text fontWeight='bold'>{usdDecimalHelper((dataCoin?.circulatingSupply)*(dataCoin?.price))}</Text>
							<HStack color='red'>
								<Text>{UpDownHelper(dataCoin?.percentChange24h)}</Text>
							</HStack>
						</Box>
						<Box>
							<Text>Fully Diluted Market Cap</Text>
							<Text fontWeight='bold'>{usdDecimalHelper((dataCoin?.totalSupply)*(dataCoin?.price))}</Text>
							<HStack color='red'>
								<Text>{UpDownHelper(dataCoin?.percentChange24h)}</Text>
							</HStack>
						</Box>
						<VStack spacing={3} align="flex-start">
							<Box>
								<Text>Volume 24h</Text>
								<Text fontWeight='bold'>{usdDecimalHelper(dataCoin?.volume24h)}</Text>
								<HStack color='red'>
									<Text>{UpDownHelper(dataCoin?.volume_change_24h)}</Text>
								</HStack>
							</Box>
							<Box>
								<Text>Volume / Market Cap </Text>
								<Text fontWeight='bold'>{((dataCoin?.volume24h) / ((dataCoin?.circulatingSupply)*(dataCoin?.price))).toFixed(6)}</Text>
								<HStack color='red'>
									<Text>{UpDownHelper(dataCoin?.volume_change_24h)}</Text>
								</HStack>
							</Box>
						</VStack>
						<Box>
							<VStack>
								<Box>
									<Text>Circulating Supply</Text>
									<Text fontWeight='bold'>{NumberAcronym(dataCoin?.circulatingSupply)} {(dataCoin?.symbol)}</Text>
								</Box>
								<Box>
									<HStack alignItems="flex-end">
										<Text>Max Supply</Text>
										<Spacer />
										<Text>{NumberAcronym(dataCoin?.maxSupply)}</Text>
									</HStack>
								</Box>
								<Box>
									<HStack alignItems="flex-start">
										<Text>Total Supply</Text>
										<Spacer />
										<Text>{NumberAcronym(dataCoin?.totalSupply)}</Text>
									</HStack>
								</Box>
							</VStack>
						</Box>
					</SimpleGrid>

						<VStack spacing={2}>
							<Select placeholder='Select option' onChange={(e) => handleTime(e.target.value)}>
								<option value='minute_1'>1 Minute</option>
								<option value='minute_15'>15 Minute</option>
								<option value='hour_1'>1 Hour</option>
							</Select>

							<Center>
								<Heading fontSize="md">Please input the time for see the strategy pattern</Heading>
							</Center>
						</VStack>

				</Box>
			</SimpleGrid>
			) : (
				<Center>
					<Heading>
						<Spinner/>
					</Heading>
				</Center>
			)}
			


			<Center>
				<SimpleGrid columns={[1, null, 3]} gap={'2'}>
					<Button color={tab === 'overview' ? ('green') : ('red')} ml='2' onClick={() => setTab('overview')}>Overview</Button>
					<Button color={tab === '4chart' ? ('green') : ('red')} ml='2' onClick={() => setTab('4chart')}>4 Chart</Button>
					<Button color={tab === 'pattern' ? ('green') : ('red')} ml='2' onClick={() => setTab('pattern')}>Patterns</Button>
				</SimpleGrid>

			</Center>

			<Box >
				<Box p='10'>


					{tab === '4chart' ?
						<SimpleGrid columns={[1,null,2]} gap='2' h={'100vh'}>
							<Box h='50vh' >
							<TradingViewWidget
								symbol={`${exchange}:BTCUSDT`}
								theme={Themes.DARK}
								autosize = "true"
								interval= "D"
								timezone= "Asia/Jakarta"
								locale= "en"
							/>
							</Box>

							<Box h='50vh'>
								{strategyArr && (
									<TVChartContainerSymbol
										symbol={`Kucoin:${param.symbol}/USDT`}
										interval={'1'}
										data={strategyArr}
										timezone= "Asia/Jakarta"
										locale= "en"
									/>
								)}
							</Box>

							<Box h='50vh'>
								{strategyArrTwo && (
									<TVChartContainerSymbol
										symbol={`Kucoin:${param.symbol}/USDT`}
										interval={'15'}
										data={strategyArrTwo}
										timezone= "Asia/Jakarta"
										locale= "en"
									/>
								)}
							</Box>

							<Box h='50vh'>
								{strategyArrThree && (
									<TVChartContainerSymbol
										symbol={`Kucoin:${param.symbol}/USDT`}
										interval={'1h'}
										data={strategyArrThree}
										timezone= "Asia/Jakarta"
										locale= "en"
									/>
								)}
							</Box>
						</SimpleGrid>
						: <></>}

					{tab === 'overview' &&
							<Box h="50vh">
							{/* {strategyArrFirst !== undefined && ( */}
									<TVChartContainerSymbol
										symbol={`Kucoin:${param.symbol}/USDT`}
										//   interval={"15"}
										interval={'1'}
										// data={strategyArrFirst !== undefined && strategyArrFirst}
										timezone= "Asia/Jakarta"
										locale= "en"
									/>
								{/* )} */}
							</Box>
					}
					
					{tab === 'pattern' &&
						<Box h="90vh">
							<HStack m={5}>
								<Box>
									<Heading size="lg">
										Pattern
									</Heading>
								</Box>
								<Spacer />
							</HStack>
							{patternList && (
								<TablePatternDetail list={patternList} type={param.type}/>
							)}
						</Box>
					}
				</Box>

			</Box>
		</Box>
	)

	if (param.type === "stocks-id") return (
		<Box width='full' height='full' bgColor='black' color='white'>
			{dataCoin ? (
				<SimpleGrid pt='5' columns={[1, null, 2]}>
				<Center>
					<VStack m={10}>
						<HStack spacing={5} >
							<Image src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${dataCoin?.Code}.png?alt=media`} width='65px' height='65px' />
							<SimpleGrid columns={[1, null, 2]}>
								<Text fontWeight="bold" fontSize={["xl", null, "3xl"]}>{dataCoin?.Name}</Text>
								<Box>
									<Tag fontSize="md" fontWeight="bold">{dataCoin?.Code}</Tag>
								</Box>
							</SimpleGrid>
						</HStack>
						<SimpleGrid columns={[1, null, 3]} gap="2">
							<Tag bgColor={colors.theme} color="black" fontWeight="bold">Rank #{dataCoin?.Last}</Tag>
							<Tag bgColor={colors.theme} color="black" fontWeight="bold">{dataCoin?.NewIndustryName}</Tag>
							<Tag bgColor={colors.theme} color="black" fontWeight="bold">{dataCoin?.NewSectorName}</Tag>
						</SimpleGrid>
					</VStack>
				</Center>
				<Box p='5' width='full' >
					<Flex flexDirection="row" alignItems="center" >
						<Box ml='5'>
							<Text>{dataCoin?.name} Price</Text>
							<HStack>
								<Heading fontSize={["xl", null, "3xl"]}>{Rupiah(dataCoin?.Value)}</Heading>
								<HStack borderRadius='2xl'>
									<Text color='white' fontSize={["xl", null, "3xl"]} fontWeight="bold" >{UpDownHelper(dataCoin?.Roe)}</Text>
								</HStack>
							</HStack>
						</Box>
					</Flex>
					<SimpleGrid columns={[1, 2, 4]} m='5'>
						<VStack spacing={3} align="flex-start">
							<Box>
								<Text>Volume</Text>
								<Text fontWeight='bold'>{Rupiah(dataCoin?.Volume)}</Text>
								<HStack color='red'>
									<Text>{UpDownHelper(dataCoin?.volume_change_24h)}</Text>
								</HStack>
							</Box>
						</VStack>
					</SimpleGrid>
					<VStack spacing={2}>
							<Select placeholder='Select option' onChange={(e) => handleTimeStock(e.target.value)}>
								<option value='1h'>1 Hour</option>
								<option value='1d'>1 Day</option>
							</Select>

							<Center>
								<Heading fontSize="md">please input the time for see the strategy</Heading>
							</Center>
						</VStack>
				</Box>
			</SimpleGrid>
			) : (
				<Center>
					<Heading>
						<Spinner />
					</Heading>
				</Center>
			)}
			
			<Center>
			<SimpleGrid columns={[1, null, 3]} gap={'2'}>
					<Button color={tab === 'overview' ? ('green') : ('red')} ml='2' onClick={() => setTab('overview')}>Overview</Button>
					<Button color={tab === '4chart' ? ('green') : ('red')} ml='2' onClick={() => setTab('4chart')}>4 Chart</Button>
					<Button color={tab === 'pattern' ? ('green') : ('red')} ml='2' onClick={() => setTab('pattern')}>Patterns</Button>
				</SimpleGrid>


			</Center>

			<Box mt='5' >
				<Box p='10' >
					{/* <TVChartContainer /> */}
					{/* <TVChartContainer /> */}



					{tab === 'pattern' ?
						<Box h="90vh">
						<HStack m={5}>
							<Box>
								<Heading size="lg">
									Pattern
								</Heading>
							</Box>
							<Spacer />
						</HStack>
						{patternListStock && (
							<TablePatternDetail list={patternListStock} type={param.type}/>
						)}
					</Box>
						: <></>}

					{tab === '4chart' ?
						<SimpleGrid columns={[1, null, 2]} gap="2" height='100vh'>
							<Box height='50vh'>


								<TradingViewWidget
									symbol={`${exchange}:${symbol}`}
									theme={Themes.DARK}
									locale="en"
									autosize
									interval={IntervalTypes.H}
									timezone= "Asia/Jakarta"
									range= "1D"
									toolbar_bg = "#f1f3f6"
									enable_publishing= "true"
									withdateranges= "true"
									hide_side_toolbar= "false"
									allow_symbol_change= "true"
									details= "true"
									hotlist= "true"
									calendar= "true"
									studies= {[
										"DoubleEMA@tv-basicstudies",
										"MASimple@tv-basicstudies",
										"MAExp@tv-basicstudies",
										"MAWeighted@tv-basicstudies",
									]}
									show_popup_button= "true"
									popup_width= "1000"
									popup_height= "650"
									container_id= "tradingview_be4b8"
								/>
							</Box>
							<Box height='50vh'>
						<TVChartContainerSymbolStocks
						symbol={`IDX:${param.symbol}`}
						 interval={"1"}
						 data={patternListStock}
					/>
					</Box>
						</SimpleGrid>
						: <></>}

					{/* {tab==='overview'?<TVChartContainer symbol={`X:${param.symbol}-USD`} interval='D'/>:<></>} */}
					{/* {tab==='4chart'?<AppBar4Charts symbol={`X:BTC-USD`}/>:
				<></>} */}
				</Box>
			</Box>

		</Box>
	)
}

export default AppBarMarketView