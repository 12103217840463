import React from "react";
import AppBarAdminHeader from "../../Components/AppBars/admin/AppBarAdminHeader";
import AppBarDashboard from "../../Components/AppBars/admin/AppBarDashboard";

function DashboardPage() {
  return (
    <>
      <AppBarAdminHeader />
      <AppBarDashboard />
    </>
  );
}

export default DashboardPage;
