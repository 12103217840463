import React, { useContext } from 'react'
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,Text,
	TableCaption,Button,
	TableContainer,Spinner,
	Box,
	Image,
	HStack,Heading, SimpleGrid, Avatar, Spacer, Center, Flex, Tooltip, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, VStack, Stack,
  } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { InfoOutlineIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { FloatRoundUp, NumberAcronym, Rupiah } from '../../../Utils/NumberUtils'
import colors from '../../../Utils/colors'
import ExploreSkeleton from './skeletons/ExploreSkeleton'
import TableSkeleton from './skeletons/TableSkeleton'
import AuthContext from '../../../Routes/hooks/AuthContext'
import TableStocksId from './TableMarket/TableStocksId'
import UpDownHelper from '../../../Utils/UpDownHelper'
import getDomain from '../../../Utils/getDomain'
import TableTrendingStock from './TableMarket/TableTrendingStock'
import TableGainerLoserStock from './TableMarket/TableGainerLoserStock'
import TableOrdinaryStocks from './TableMarket/TableOrdinaryStock.jsx'


function StocksIDMarket() {
	const [data,setData]=useState()
	const [trendingVolume,setTrendingVolume]=useState()
	const [trendingVolumeSecond,setTrendingVolumeSecond]=useState()
	const [trendingChange,setTrendingChange]=useState()
	const [trendingChangeSecond,setTrendingChangeSecond]=useState()
	const [lossChange,setLossChange]=useState()
	const [lossChangeSecond,setLossChangeSecond]=useState()
	const [ordinaryChange,setOrdinaryChange]=useState()
	const [ordinaryChangeSecond,setordinaryChangeSecond]=useState()
	const [exploreData,setExploreData]=useState({})
	const [trendingData,setTrendingData]=useState([])
	const [gainerData,setGainerData]=useState([])
	const [loserData,setLoserData]=useState([])
	const [ordinaryData,setOrdinaryData]=useState([])
	const [title,setTittle]=useState("")
	// const [total, setTotal] = useState(10);

	const { isOpen, onOpen, onClose } = useDisclosure()

	const { getUserStorage } = useContext(AuthContext)

	const { stocksApi } = useContext(AuthContext)

	const subscription = getUserStorage()
	const role = subscription.role


	// const _addNumber = () => {
	// 	// if (total === 5) {
	// 	//   return null;
	// 	// }
	// 	return setTotal(total - 100);
	//   };

	// console.log(total, 'total')


	const getData=async()=>{
	  let explore={};
	  try {
		const response = stocksApi
		  const reponseData = response

		  const dataPagination = response
		  const data = dataPagination
		  setData(data)
		//   setData(reponseData)


		// ordinary data 

		const dataOrdinary = reponseData.sort((a,b) => b.OneDay - a.OneDay)
		const filterOrdinary = dataOrdinary.filter((x) => x.OneDay > 0)
		const filterVolumeOrdinary = filterOrdinary.sort((a, b) => b.Volume - a.Volume)

		setOrdinaryChange({data:[
			filterVolumeOrdinary[0]
		  ]})
		  setordinaryChangeSecond({data:[
			filterVolumeOrdinary[1],filterVolumeOrdinary[2],filterVolumeOrdinary[3],filterVolumeOrdinary[4]
		  ]})


		setOrdinaryData(filterVolumeOrdinary)
		
		// volume
		  const filterMarket = reponseData.filter((x) => x.Capitalization > 10000000000000)
		  console.log(filterMarket, 'ini filter market')
		  

		  let calculateVolume = 0;
		  let calculateValue =0
		  const trendingVolumeData = filterMarket.sort((a,b) => b.Volume - a.Volume)

			calculateVolume+=trendingVolumeData[(trendingVolumeData.length)-1].Volume
			calculateValue+=trendingVolumeData[(trendingVolumeData.length)-1].Value

		setTrendingVolume({data:[
			trendingVolumeData[0]
		  ]})
		  setTrendingVolumeSecond({data:[
			trendingVolumeData[1],trendingVolumeData[2],trendingVolumeData[3],trendingVolumeData[4]
		  ]})

		  setTrendingData(trendingVolumeData)

		//   gainer
		  const trendingChangesData = filterMarket.sort((a,b) => b.OneDay - a.OneDay)
		  setTrendingChange({data:[
			trendingChangesData[0]
		  ]})
		  setTrendingChangeSecond({data:[
			trendingChangesData[1],trendingChangesData[2],trendingChangesData[3],trendingChangesData[4]
		  ]})

		  const filterDataGainer = trendingChangesData.filter((c) => c.OneDay > 0)
		  setGainerData(filterDataGainer)

		  const filterDataLoser = trendingChangesData.filter((c) => c.OneDay < 0)
		  setLoserData(filterDataLoser)

		//   loser
		  setLossChange({data:[
			trendingChangesData[trendingChangesData.length -1]
		  ]})
		  setLossChangeSecond({data:[
			trendingChangesData[trendingChangesData.length -2],trendingChangesData[trendingChangesData.length -3],trendingChangesData[trendingChangesData.length -4],trendingChangesData[trendingChangesData.length -5]
		  ]})
		  

		explore.lowestChanges = trendingChangesData[(trendingChangesData.length)-1]
		explore.lowestVolume = trendingVolumeData[(trendingVolumeData.length)-1]
		explore.calculateVolume = calculateVolume
		explore.calculateValue=calculateValue
		setExploreData(explore)


		  

	  } catch (error) {
		  console.log(error)
	  }
	}


	const trendingVolumeDummy = [
		{id: "1", Code: "NoPayment", CodeImage:"BUMI", Volume: 100000, price: 1.88888 },
		{id: "2",  Code: "NoPayment", CodeImage:"TLKM", Volume: 100000, price: 1.88888 },
		{id: "3", Code: "NoPayment", CodeImage:"PICO", Volume: 100000, price: 1.88888 },
		{id: "4", Code: "NoPayment", CodeImage:"TLKM", Volume: 100000, price: 1.88888 },
		]

	const trendingChangeDummy = [
		{id: "1", Code: "NoPayment", CodeImage:"TLKM", Volume: 100000, price: 1.88888 },
		{id: "2",  Code: "NoPayment", CodeImage:"BUMI", Volume: 100000, price: 1.88888 },
		{id: "3", Code: "NoPayment", CodeImage:"TLKM", Volume: 100000, price: 1.88888 },
		{id: "4", Code: "NoPayment", CodeImage:"ITMA", Volume: 100000, price: 1.88888 },
	]

	const lossChangeDummy = [
		{id: "1", Code: "NoPayment", CodeImage:"TLKM", Volume: -100000, price: 1.88888 },
		{id: "2",  Code: "NoPayment", CodeImage:"BUMI", Volume: -100000, price: 1.88888 },
		{id: "3", Code: "NoPayment", CodeImage:"TLKM", Volume: -100000, price: 1.88888 },
		{id: "4", Code: "NoPayment", CodeImage:"ITMA", Volume: -100000, price: 1.88888 },
	]





	useEffect(() => {
		getData()
	
	  return () => {
	  }
	}, [])
	
  return (
	<Box p='25' height='full' bgColor='black' color='white'>
	<SimpleGrid columns={[1, 3, 5]} p='3' gap='2'>

		<Box bgColor='gray.900' p='5' borderRadius='3xl'>
		<HStack  spacing={3} >
		<Heading fontSize='larger' my={2}>Market Data</Heading>
		<Tooltip label='Above 10T Market Cap' fontSize='md'>
			<InfoOutlineIcon/>
		</Tooltip>
		</HStack>

			{trendingVolume?
			<>
			<SimpleGrid columns='2'>
				<Box>
					<Text textAlign='center'>Most Profitable</Text>
					<Center>
						<Box style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<HStack>
							<Image src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${trendingChange.data[0].Code}.png?alt=media`} width='20px' height='20px' />
							<Text>{trendingChange.data[0].Code}</Text>
						</HStack>
						<HStack >
							<TriangleUpIcon color={colors.green}/>
							<Text color={colors.green} >{FloatRoundUp(trendingChange.data[0].OneDay*100)}%</Text>
						</HStack>
						</Box>
					</Center>
				</Box>
				<Box>
				<Text textAlign='center'>Most Loss</Text>
					<Center>
						<Box style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
							<HStack>
								<Image src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${exploreData.lowestChanges.Code}.png?alt=media`} width='20px' height='20px' />
								<Text textAlign='end'>{exploreData.lowestChanges.Code}</Text>
							</HStack>
							<HStack>
								<TriangleDownIcon color='red'/>
								<Text color='red' >{FloatRoundUp((exploreData.lowestChanges.OneDay)*100)}%</Text>							</HStack>
						</Box>
					</Center>
				</Box>
			</SimpleGrid>


				<HStack p='1'>
					{/* <Avatar src='https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'/> */}
					<Text>Total Volume (shares):</Text>
					{/* <Text>BTC</Text> */}
					<Spacer/>
					{/* <TriangleUpIcon color={colors.green}/> */}
					<Text style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })} color={colors.green} >{NumberAcronym(exploreData.calculateVolume)}</Text>
				</HStack>

				<HStack p='1'>
					{/* <Avatar src='https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'/> */}
					<Text>Total Volume (value) :</Text>
					{/* <Text>BTC</Text> */}
					<Spacer/>
					{/* <TriangleUpIcon color={colors.green}/> */}
					<Text style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })} color={colors.green} >IDR {NumberAcronym(exploreData.calculateValue)}</Text>
				</HStack>
				</>
				:
				<ExploreSkeleton/>
			}
		</Box>


		<Stack bgColor='gray.900' p='5' borderRadius='3xl'>
			<HStack spacing={3}>
			<Heading fontSize='larger'  my={2}>Trending (24hr)</Heading>
			<Tooltip label='Above 10T Market Cap' fontSize='md'>
				<InfoOutlineIcon/>
			</Tooltip>
			</HStack>
			{trendingVolume&&
			trendingVolume.data.map((x)=>
			<HStack key={x.Id} p='1'>
				<Link to={`/market/stocks-id/${x.Code}`}>
					<HStack>
						<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
						<Text>{x.Code}</Text>
					</HStack>			
				</Link>
				<Spacer/>
				{/* <TriangleUpIcon color='green'/> */}
				<Box>
				<Text textAlign='end' color={colors.green}>IDR {NumberAcronym(x.Value)} </Text>
				<Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
				</Box>
			</HStack>
			)
			}
			
			{role === "user" ? (
				trendingVolumeDummy&&
				trendingVolumeDummy.map((x)=>
				<HStack key={x.id} p='1' style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Link to={`/market/stocks-id/${x.Code}`}>
						<HStack>
							<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.CodeImage}.png?alt=media`}/>
							<Text>{x.Code}</Text>
						</HStack>			
					</Link>
					<Spacer/>
					{/* <TriangleUpIcon color='green'/> */}
					<Box>
					<Text textAlign='end' color={colors.green}>IDR {NumberAcronym(x.price)} </Text>
					<Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
					</Box>
				</HStack>)
			) : (
				trendingVolumeSecond&&
					trendingVolumeSecond.data.map((x)=>
					<HStack key={x.Id} p='1' style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<Link to={`/market/stocks-id/${x.Code}`}>
							<HStack>
								<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
								<Text>{x.Code}</Text>
							</HStack>			
						</Link>
						<Spacer/>
						{/* <TriangleUpIcon color='green'/> */}
						<Box>
						<Text textAlign='end' color={colors.green}>IDR {NumberAcronym(x.Value)} </Text>
						<Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
						</Box>
					</HStack>)
					
			)}
			<Spacer />
			<Center>
				<Text cursor="pointer" onClick={
					() => {
				setTittle("trending")
				onOpen()
				}}>See more..</Text>
			</Center>
		</Stack>

		<Stack bgColor='gray.900' p='5' borderRadius='3xl'>
			<HStack spacing={3}>
			<Heading fontSize='larger'  my={2}>Ordinary Stocks</Heading>
			<Tooltip label='Data is based on the calculation of volume and gainer' fontSize='md'>
				<InfoOutlineIcon/>
			</Tooltip>
			</HStack>
			{ordinaryChange&&
			ordinaryChange.data.map((x)=>
			<HStack key={x.Id} p='1'>
				<Link to={`/market/stocks-id/${x.Code}`}>
					<HStack>
						<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
						<Text>{x.Code}</Text>
					</HStack>			
				</Link>
				<Spacer/>
				{/* <TriangleUpIcon color='green'/> */}
				<Box>
				<Text textAlign='end' color={colors.green} align="end">{UpDownHelper(x.OneDay * 100)} </Text>
				<Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
				</Box>
			</HStack>
			)
			}
			
			{role === "user" ? (
				trendingVolumeDummy&&
				trendingVolumeDummy.map((x)=>
				<HStack key={x.id} p='1' style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Link to={`/market/stocks-id/${x.Code}`}>
						<HStack>
							<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.CodeImage}.png?alt=media`}/>
							<Text>{x.Code}</Text>
						</HStack>			
					</Link>
					<Spacer/>
					{/* <TriangleUpIcon color='green'/> */}
					<Box>
					<Text textAlign='end' color={colors.green} align="end" > IDR {NumberAcronym(x.price)} </Text>
					<Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
					</Box>
				</HStack>)
			) : (
				ordinaryChangeSecond&&
				ordinaryChangeSecond.data.map((x)=>
					<HStack key={x.Id} p='1' style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<Link to={`/market/stocks-id/${x.Code}`}>
							<HStack>
								<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
								<Text>{x.Code}</Text>
							</HStack>			
						</Link>
						<Spacer/>
						{/* <TriangleUpIcon color='green'/> */}
						<Box>
						<Text textAlign='end' color={colors.green} align="end">{UpDownHelper(x.OneDay * 100)}</Text>
						<Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
						</Box>
					</HStack>)
					
			)}
			<Spacer />
			<Center>
				<Text cursor="pointer" onClick={
					() => {
				setTittle("ordinary")
				onOpen()
				}}>See more..</Text>
			</Center>
		</Stack>

		<Stack bgColor='gray.900' p='5' borderRadius='3xl'>
			<HStack spacing={3}>
			<Heading fontSize='larger'  my={2}>Top Gainer (24hr)</Heading>
			<Tooltip label='Above 10T Market Cap' fontSize='md'>
				<InfoOutlineIcon/>
			</Tooltip>
			</HStack>
			{trendingChange&&
			trendingChange.data.map((x)=>
			<HStack key={x.Code} p='1'>
				<Link to={`/market/stocks-id/${x.Code}`}>
					<HStack>
					<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
					<Text>{x.Code}</Text>
					</HStack>
				</Link>
				<Spacer/>
				<Text color={colors.green} >{UpDownHelper(x.OneDay*100)}</Text>
			</HStack>
			)
				}

			{role === "user" ? (
				trendingChangeDummy?
					trendingChangeDummy.map((x)=>
					<HStack key={x.Code} p='1'  style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<Link to={`/market/stocks-id/${x.Code}`}>
							<HStack>
							<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.CodeImage}.png?alt=media`}/>
							<Text>{x.Code}</Text>
							</HStack>
						</Link>
						<Spacer/>
						<Text color={colors.green} >{UpDownHelper(x.Volume)}</Text>
					</HStack>)
					:
					<ExploreSkeleton/>
			) : (
				trendingChangeSecond?
					trendingChangeSecond.data.map((x)=>
					<HStack key={x.Code} p='1'  style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<Link to={`/market/stocks-id/${x.Code}`}>
							<HStack>
							<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
							<Text>{x.Code}</Text>
							</HStack>
						</Link>
						<Spacer/>
						<Text color={colors.green} >{UpDownHelper(x.OneDay*100)}</Text>
					</HStack>)
					:
					<ExploreSkeleton/>
			)}
			<Spacer />
			<Center>
				<Text cursor="pointer" onClick={	() => {
				setTittle("gainer")
				onOpen()
				}}>See more..</Text>
			</Center>
		</Stack>

		<Stack bgColor='gray.900' p='5' borderRadius='3xl'>
			<HStack spacing={3}>
			<Heading fontSize='larger'  my={2}>Top Loser (24hr)</Heading>
			<Tooltip label='Above 10T Market Cap' fontSize='md'>
				<InfoOutlineIcon/>
			</Tooltip>
			</HStack>
			{lossChange&&
			lossChange.data.map((x)=>
			<HStack key={x.Code} p='1'>
				<Link to={`/market/stocks-id/${x.Code}`}>
					<HStack>
					<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
					<Text>{x.Code}</Text>
					</HStack>
				</Link>
				<Spacer/>
				<Text color={colors.green} >{UpDownHelper(x.OneDay*100)}</Text>
			</HStack>)
				}

			{role === "user" ? (
				lossChangeDummy?
					lossChangeDummy.map((x)=>
					<HStack key={x.Code} p='1'  style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<Link to={`/market/stocks-id/${x.Code}`}>
							<HStack>
							<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.CodeImage}.png?alt=media`}/>
							<Text>{x.Code}</Text>
							</HStack>
						</Link>
						<Spacer/>
						<Text color={colors.green} >{UpDownHelper(x.Volume)}</Text>
					</HStack>)
					:
					<ExploreSkeleton/>
			) : (
				lossChangeSecond?
					lossChangeSecond.data.map((x)=>
					<HStack key={x.Code} p='1'  style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
						<Link to={`/market/stocks-id/${x.Code}`}>
							<HStack>
							<Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
							<Text>{x.Code}</Text>
							</HStack>
						</Link>
						<Spacer/>
						<Text color={colors.green} >{UpDownHelper(x.OneDay*100)}</Text>
					</HStack>)
					:
					<ExploreSkeleton/>
			)}
			<Spacer />
			<Center>
				<Text cursor="pointer" onClick={	() => {
				setTittle("loser")
				onOpen()
				}}>See more..</Text>
			</Center>
		</Stack>

		{/* <Box bgColor='gray.900' p='5' borderRadius='3xl'>
			<HStack spacing={3}>
			<Heading fontSize='larger'  my={2}>Most Recent Patterns</Heading>
			<Tooltip label='Up to 10T Market Cap' fontSize='md'>
				<InfoOutlineIcon/>
			</Tooltip>
			</HStack>
				<HStack p='1'>
					<Text>Head & Shoulders :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Text >BUMI</Text>
					<Image src='https://assets.stockbit.com/logos/companies/BUMI.png' width='20px' height='20px' ml={2}/>
					</Flex>
				</HStack>

				<HStack p='1'>
					<Text>Sotuhern Doji :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Text>BBCA</Text>
					<Image src='https://assets.stockbit.com/logos/companies/BBCA.png' width='20px' height='20px' ml={2}/>
					</Flex>

				</HStack>

				<HStack p='1'>
					<Text>Double Bottom :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })} >
					<Text>ACES</Text>
					<Image src='https://assets.stockbit.com/logos/companies/ACES.png' width='20px' height='20px' ml={2}/>
					</Flex>
				</HStack>

				<HStack p='1'>
					<Text>EMA90 :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Text>KRYA</Text>
					<Image src='https://assets.stockbit.com/logos/companies/KRYA.png' width='20px' height='20px' ml={2}/>
					</Flex>
				</HStack>

				<HStack p='1'>
					<Text>Hammer :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Text>BBRI</Text>
					<Image src='https://assets.stockbit.com/logos/companies/BBRI.png' width='20px' height='20px' ml={2}/>
					</Flex>
				</HStack>

				<HStack p='1'>
					<Text>Northern Star :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Text>BUMI</Text>
					<Image src='https://assets.stockbit.com/logos/companies/BUMI.png' width='20px' height='20px' ml={2} />
					</Flex>
				</HStack>

				<HStack p='1'>
					<Text>Tripple Bottom :</Text>
					<Spacer/>
					<Flex flexDirection="row" alignItems="center" style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>
					<Text>BUMI</Text>
					<Image src='https://assets.stockbit.com/logos/companies/BUMI.png' width='20px' height='20px' ml={2} />
					</Flex>
				</HStack>

		</Box> */}
		
	</SimpleGrid>

	<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title === "trending" ? 'Trending (24h)' : title === "gainer" ? "Top Gainer (24h)" : title === "loser" ? "Top Loser (24h)" : title === "ordinary" ? "Ordinary Stocks (24h)" : "" }</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
			{title === "trending" &&  <TableTrendingStock data={trendingData} title={"trending"}/> }
			{title === "ordinary" &&  <TableOrdinaryStocks data={ordinaryData} title={"ordinary"}/> }
			{title === "gainer" &&  <TableGainerLoserStock data={gainerData} title={"gainer"}/> }
			{title === "loser" &&  <TableGainerLoserStock data={loserData} title={"loser"}/> }
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


	{data !== undefined && (
		<>		
		<TableStocksId data={data} role={role} />
		{/* <Center>
		<Button onClick={_addNumber}> Show moree.. </Button>
		</Center> */}
		</>
	)}
	</Box>
  )
}

export default StocksIDMarket