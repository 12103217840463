import React, {useEffect, useState, useRef } from "react";
import "./index.css";
import { widget } from "../../../charting_library";
import datafeed from "./Data/datafeed";
import { Box } from "@chakra-ui/react";


function getLanguageFromURL() {
	const regex = new RegExp("[\\?&]lang=([^&#]*)");
	const results = regex.exec(window.location.search);
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, " "));
}

export class TVChartContainerSymbol extends React.PureComponent {
	static defaultProps = {
        // symbol: 'Kucoin:BTC/USDT', // default symbol
        // interval: '1h', // default interval
        fullscreen: false, // displays the chart in the fullscreen mode
		// datafeedUrl: 'https://demo_feed.tradingview.com',
        container: 'tv_chart_container',
        // datafeed: datafeed,
        // symbol: 'AAPL',
		// interval: 'D',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		autosize: true,
		studiesOverrides: {},

	};

	tvWidget = null;

	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	componentDidMount() {
		const dataStrategy = this.props.data
		const widgetOptions = {
			symbol: this.props.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			// datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
			datafeed: datafeed,
			interval: this.props.interval,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: 'false',
			studies_overrides: this.props.studiesOverrides,
            debug: true,
            theme:"dark",
			
		};


        console.log(widgetOptions, 'ini widget')

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			console.log(widgetOptions.datafeed,'tvwidget on chart ready')
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'Check API';
			}
			);
			
			tvWidget.activeChart().setTimezone('Asia/Singapore');
			// tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 9 })
			// tvWidget.activeChart().createStudy('MACD', false, false, { length: 9 })
			// tvWidget.activeChart().createStudy('Triangle', false, false, { in_0: 26 }, {"%d.color" : "#FF0000"})

			// 	export declare type SupportedLineTools = "text" | "anchored_text" | "note" | "anchored_note" | "signpost" | "double_curve" | "arc" | "icon" | "arrow_up" | "arrow_down" | 
			// "arrow_left" | "arrow_right" | "price_label" | "price_note" | "arrow_marker" | "flag" | "vertical_line" | "horizontal_line" | "cross_line" | "horizontal_ray" | "trend_line" | 
			// "info_line" | "trend_angle" | "arrow" | "ray" | "extended" | "parallel_channel" | "disjoint_angle" | 
			// "flat_bottom" | "pitchfork" | "schiff_pitchfork_modified" | "schiff_pitchfork" | "balloon" | "inside_pitchfork" | "pitchfan" | "gannbox" | "gannbox_square" | "gannbox_fixed" | 
			// "gannbox_fan" | "fib_retracement" | "fib_trend_ext" | "fib_speed_resist_fan" | "fib_timezone" | "fib_trend_time" | "fib_circles" | "fib_spiral" | "fib_speed_resist_arcs" | 
			// "fib_channel" | "xabcd_pattern" | "cypher_pattern" | "abcd_pattern" | "callout" | "triangle_pattern" | "3divers_pattern" | 
			// "head_and_shoulders" | "fib_wedge" | "elliott_impulse_wave" | "elliott_triangle_wave" | "elliott_triple_combo" | "elliott_correction" | "elliott_double_combo" | 
			// "cyclic_lines" | "time_cycles" | "sine_line" | "long_position" | "short_position" | "forecast" | "date_range" | "price_range" | "date_and_price_range" | "bars_pattern" | "ghost_feed" | "projection" | "rectangle" | "rotated_rectangle" | "circle" | "ellipse" | "triangle" | "polyline" | "path" | 
			// "curve" | "cursor" | "dot" | "arrow_cursor" | "eraser" | "measure" | "zoom" | "brush" | "highlighter" | "regression_trend" | "fixed_range_volume_profile";

			// Object.keys(dataStrategy).forEach(x => {
			// 	if(dataStrategy[x].length > 0){
			// 		x.map((z) => console.log(z, 'zzzzz'))

			// 	}

			// });
			let strategyName = []
			for (const iterator of Object.keys(dataStrategy)) {
				if(dataStrategy[iterator].length > 0 ){
					strategyName.push({'keyword': iterator, 'data': dataStrategy[iterator]})
				}
			}

			const strategyNameFilter = strategyName.filter((x) => (x.keyword) !== "tweezerTop" && (x.keyword) !== "tweezerBottom" && (x.keyword) !== "shootingStar" )
			for (const iterator of strategyNameFilter) {
				const dataArr = iterator.data
				dataArr.forEach(element => {

				tvWidget.activeChart().createMultipointShape(
					[
						{ time: Number(element.time_ori), price:Number(element.low) }
					],
					{
						shape: "arrow_up",
						lock: false,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: `${iterator.keyword}`,
					}

				);
				});
			}

			const strategyNameShootingStar = strategyName.find((x) => (x.keyword) === "shootingStar")
			let dataSt = []
			for (const iterator of strategyNameShootingStar.data) {
				dataSt.push([
					{"price": Number(iterator.high), "time" : Number(iterator.time_ori)},
				])
			}
			
			dataSt.forEach((x) => {
				// console.log(x[0].price, 'xxx')
				tvWidget.activeChart().createMultipointShape(
					[	
						{ time: x[0].time, price: x[0].price } ,
					],
					{
						shape: "arrow_down",
						lock: false,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Shooting Star",
					}
				);
			})
			

			const strategyNameTweezerTop = strategyName.find((x) => (x.keyword) === "tweezerTop")

			let dataTt = []
			for (const iterator of strategyNameTweezerTop.data) {
				dataTt.push([
					{"price": Number(iterator[0].high), "time" : Number(iterator[0].time_ori)},
					{"price": Number(iterator[1].high), "time" : Number(iterator[1].time_ori)}
				])
			}
			
			dataTt.forEach((x) => {
				// console.log(x[0].price, 'xxx')
				tvWidget.activeChart().createMultipointShape(
					[	
						{ time: x[0].time, price: x[0].price } ,
						{ time: x[1].time, price: x[1].price }
					],
					{
						shape: "trend_line",
						lock: false,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Ini kambing yang beli",
					}
				);
			})
			
			const strategyNameTweezerBottom = strategyName.find((x) => (x.keyword) === "tweezerBottom")

			let dataTb = []
			for (const iterator of strategyNameTweezerBottom.data) {
				dataTb.push([
					{"price": Number(iterator[0].low), "time" : Number(iterator[0].time_ori)},
					{"price": Number(iterator[1].low), "time" : Number(iterator[1].time_ori)}
				])
			}
			
			dataTb.forEach((x) => {
				// console.log(x[0].price, 'xxx')
				tvWidget.activeChart().createMultipointShape(
					[	
						{ time: x[0].time, price: x[0].price } ,
						{ time: x[1].time, price: x[1].price }
					],
					{
						shape: "trend_line",
						color: "#2A2E39",
						lock: false,
						disableSelection: true,
						disableSave: true,
						disableUndo: true,
						text: "Ini kambing yang beli",
					}
				);
			})

			// widget.chart().createOrderLine()
			// .onMove(function() {
			// 	this.setText("onMove called");
			// })
			// .onModify("onModify called", function(text) {
			// 	this.setText(text);
			// })
			// .onCancel("onCancel called", function(text) {
			// 	this.setText(text);
			// })
			// .setText("STOP: 73.5 (5,64%)")
			// .setQuantity("2");
		});



    
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}

// export const TVChartContainerSymbol = (props) => {
// 	const { 
// 		symbol,
// 		interval,
// 		chartsStorageUrl = "https://saveload.tradingview.com",
// 		container = "tv_chart_container",
// 		// datafeedUrl = "https://demo_feed.tradingview.com",
// 		libraryPath = "/charting_library/",
// 		fullscreen = true, // displays the chart in the fullscreen mode
// 		clientId = "tradingview.com",
// 		userId = "public_user_id",
// 		autosize = true,
// 		chartsStorageApiVersion = "1.1",
// 		studiesOverrides = {},
// 		// dataStrategy
// 	} = props


// 	const ref = useRef(container)
// 	const [data, setData] = useState({
// 		symbol: symbol,
// 		datafeed: datafeed,
// 		interval: interval,
// 		// container: this.ref.current,
// 		library_path: libraryPath,
// 		locale: getLanguageFromURL() || "en",
// 		disabled_features: ["use_localstorage_for_settings"],
// 		enabled_features: ["study_templates"],
// 		charts_storage_url: chartsStorageUrl,
// 		charts_storage_api_version: chartsStorageApiVersion,
// 		client_id: clientId,
// 		user_id: userId,
// 		fullscreen: fullscreen,
// 		autosize: autosize,
// 		studies_overrides: studiesOverrides,
// 		debug: true,
// 		theme: "dark",
// 	});


// 	const runTvWidget = async () => {
// 		const dataStrategy = props.strategy


// 		const tvWidget = new widget({ ...data, container: ref.current });
// 		tvWidget.onChartReady(() => {
// 			tvWidget.headerReady().then(() => {
// 				const button = tvWidget.createButton();
// 				button.setAttribute("title", "Click to show a notification popup");
// 				button.classList.add("apply-common-tooltip");
// 				button.addEventListener("click", () =>
// 					tvWidget.showNoticeDialog({
// 						title: "Notification",
// 						body: "TradingView Charting Library API works correctly",
// 						callback: () => {
// 							console.log("Noticed!");
// 						},
// 					})
// 				);
// 				button.innerHTML = "Check API";
// 			});

// 			tvWidget.activeChart().setTimezone("Asia/Singapore");
// 			// tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 9 })
// 			// tvWidget.activeChart().createStudy('MACD', false, false, { length: 9 })
// 			// tvWidget.activeChart().createStudy('Triangle', false, false, { in_0: 26 }, {"%d.color" : "#FF0000"})

// 			// 	export declare type SupportedLineTools = "text" | "anchored_text" | "note" | "anchored_note" | "signpost" | "double_curve" | "arc" | "icon" | "arrow_up" | "arrow_down" |
// 			// "arrow_left" | "arrow_right" | "price_label" | "price_note" | "arrow_marker" | "flag" | "vertical_line" | "horizontal_line" | "cross_line" | "horizontal_ray" | "trend_line" |
// 			// "info_line" | "trend_angle" | "arrow" | "ray" | "extended" | "parallel_channel" | "disjoint_angle" |
// 			// "flat_bottom" | "pitchfork" | "schiff_pitchfork_modified" | "schiff_pitchfork" | "balloon" | "inside_pitchfork" | "pitchfan" | "gannbox" | "gannbox_square" | "gannbox_fixed" |
// 			// "gannbox_fan" | "fib_retracement" | "fib_trend_ext" | "fib_speed_resist_fan" | "fib_timezone" | "fib_trend_time" | "fib_circles" | "fib_spiral" | "fib_speed_resist_arcs" |
// 			// "fib_channel" | "xabcd_pattern" | "cypher_pattern" | "abcd_pattern" | "callout" | "triangle_pattern" | "3divers_pattern" |
// 			// "head_and_shoulders" | "fib_wedge" | "elliott_impulse_wave" | "elliott_triangle_wave" | "elliott_triple_combo" | "elliott_correction" | "elliott_double_combo" |
// 			// "cyclic_lines" | "time_cycles" | "sine_line" | "long_position" | "short_position" | "forecast" | "date_range" | "price_range" | "date_and_price_range" | "bars_pattern" | "ghost_feed" | "projection" | "rectangle" | "rotated_rectangle" | "circle" | "ellipse" | "triangle" | "polyline" | "path" |
// 			// "curve" | "cursor" | "dot" | "arrow_cursor" | "eraser" | "measure" | "zoom" | "brush" | "highlighter" | "regression_trend" | "fixed_range_volume_profile";

// 			// Object.keys(dataStrategy).forEach(x => {
// 			// 	if(dataStrategy[x].length > 0){
// 			// 		x.map((z) => console.log(z, 'zzzzz'))

// 			// 	}

// 			// });
// 			console.log(dataStrategy, 'xxxx')

// 			let strategyName = []
// 			for (const iterator of Object.keys(dataStrategy)) {
// 				if(dataStrategy[iterator].length > 0 ){
// 					strategyName.push({'keyword': iterator, 'data': dataStrategy[iterator]})
// 				}
// 			}

// 			const strategyNameFilter = strategyName.filter((x) => (x.keyword) !== "tweezerTop" && (x.keyword) !== "tweezerBottom")

// 			for (const iterator of strategyNameFilter) {
// 				const dataArr = iterator.data
// 				dataArr.forEach(element => {

// 				tvWidget.activeChart().createMultipointShape(
// 					[
// 						{ time: Number(element.time_ori), price:parseFloat(element.close) }
// 					],
// 					{
// 						shape: "arrow_up",
// 						lock: false,
// 						disableSelection: true,
// 						disableSave: true,
// 						disableUndo: true,
// 						text: `🐑 ${iterator.keyword}`,
// 					}

// 				);
// 				});
// 			}

// 			const strategyNameTweezerTop = strategyName.find((x) => (x.keyword) === "tweezerTop")

// 			let dataTt = []
// 			for (const iterator of strategyNameTweezerTop.data) {
// 				dataTt.push([
// 					{"price": parseFloat(iterator[0].high), "time" : Number(iterator[0].time_ori)},
// 					{"price": parseFloat(iterator[1].high), "time" : Number(iterator[1].time_ori)}
// 				])
// 			}

// 			dataTt.forEach((x) => {
// 				// console.log(x[0].price, 'xxx')
// 				tvWidget.activeChart().createMultipointShape(
// 					[
// 						{ time: x[0].time, price: x[0].price } ,
// 						{ time: x[1].time, price: x[1].price }
// 					],
// 					{
// 						shape: "trend_line",
// 						lock: false,
// 						disableSelection: true,
// 						disableSave: true,
// 						disableUndo: true,
// 						text: "Ini kambing yang beli",
// 					}
// 				);
// 			})

// 			const strategyNameTweezerBottom = strategyName.find((x) => (x.keyword) === "tweezerBottom")

// 			let dataTb = []
// 			for (const iterator of strategyNameTweezerBottom.data) {
// 				dataTb.push([
// 					{"price": parseFloat(iterator[0].low), "time" : Number(iterator[0].time_ori)},
// 					{"price": parseFloat(iterator[1].low), "time" : Number(iterator[1].time_ori)}
// 				])
// 			}

// 			dataTb.forEach((x) => {
// 				// console.log(x[0].price, 'xxx')
// 				tvWidget.activeChart().createMultipointShape(
// 					[
// 						{ time: x[0].time, price: x[0].price } ,
// 						{ time: x[1].time, price: x[1].price }
// 					],
// 					{
// 						shape: "trend_line",
// 						color: "#2A2E39",
// 						lock: false,
// 						disableSelection: true,
// 						disableSave: true,
// 						disableUndo: true,
// 						text: "Ini kambing yang beli",
// 					}
// 				);
// 			})

// 			// console.log(this.props.time)
// 			// console.log(this.props.price)
// 			// tvWidget.activeChart().createMultipointShape(
// 			// 	[
// 			// 		{ time: 1662626500, price: 19979.53 },
// 			// 		{ time: 1662626500, price: 19679.53 }
// 			// 	],
// 			// 	{
// 			// 		shape: "fib_retracement",
// 			// 		lock: true,
// 			// 		disableSelection: true,
// 			// 		disableSave: true,
// 			// 		disableUndo: true,
// 			// 		text: "Ini kambing yang beli",
// 			// 	}
// 			// );
// 			//"arrow_up" | "arrow_down" | "flag" | "vertical_line" | "horizontal_line" | "long_position" | "short_position" | "icon"
// 			// tvWidget.activeChart().createShape({ time: 16626109500 }, { shape: 'short_position' });
// 		});
// 	}
	
// 	useEffect(() => {
// 		runTvWidget()
	
// 	}, [JSON.stringify(props)])
	


// 	return <div ref={ref} className={"TVChartContainer"} />;

// };

// export class TVChartContainerSymbol extends React.PureComponent {
// 	static defaultProps = {
// 		symbol: "Kucoin:BTC/USDT", // default symbol
// 		interval: "1h", // default interval
// 		fullscreen: true, // displays the chart in the fullscreen mode
// 		datafeedUrl: "https://demo_feed.tradingview.com",
// 		container: "tv_chart_container",
// 		// datafeed: datafeed,
// 		libraryPath: "/charting_library/",
// 		chartsStorageUrl: "https://saveload.tradingview.com",
// 		chartsStorageApiVersion: "1.1",
// 		clientId: "tradingview.com",
// 		userId: "public_user_id",
// 		autosize: true,
// 		studiesOverrides: {},
// 	};

// 	tvWidget = null;

// 	constructor(props) {
// 		super(props);

// 		this.ref = React.createRef();
// 	}

// 	componentDidMount() {
// 		// const dataStrategy = this.props.data
// 		const widgetOptions = {
// 			symbol: this.props.symbol,
// 			// BEWARE: no trailing slash is expected in feed URL
// 			// datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
// 			datafeed: datafeed,
// 			interval: this.props.interval,
// 			container: this.ref.current,
// 			library_path: this.props.libraryPath,
// 			locale: getLanguageFromURL() || "en",
// 			disabled_features: ["use_localstorage_for_settings"],
// 			enabled_features: ["study_templates"],
// 			charts_storage_url: this.props.chartsStorageUrl,
// 			charts_storage_api_version: this.props.chartsStorageApiVersion,
// 			client_id: this.props.clientId,
// 			user_id: this.props.userId,
// 			fullscreen: this.props.fullscreen,
// 			autosize: this.props.autosize,
// 			studies_overrides: this.props.studiesOverrides,
// 			debug: true,
// 			theme: "dark",
// 		};

// 		const tvWidget = new widget(widgetOptions);
// 		this.tvWidget = tvWidget;

// 		tvWidget.onChartReady(() => {
// 			console.log(widgetOptions.datafeed, "tvwidget on chart ready");
// 			tvWidget.headerReady().then(() => {
// 				const button = tvWidget.createButton();
// 				button.setAttribute("title", "Click to show a notification popup");
// 				button.classList.add("apply-common-tooltip");
// 				button.addEventListener("click", () =>
// 					tvWidget.showNoticeDialog({
// 						title: "Notification",
// 						body: "TradingView Charting Library API works correctly",
// 						callback: () => {
// 							console.log("Noticed!");
// 						},
// 					})
// 				);

// 				button.innerHTML = "Check API";
// 			});

// 			tvWidget.activeChart().setTimezone("Asia/Singapore");
// 			// tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, { length: 9 })
// 			// tvWidget.activeChart().createStudy('MACD', false, false, { length: 9 })
// 			// tvWidget.activeChart().createStudy('Triangle', false, false, { in_0: 26 }, {"%d.color" : "#FF0000"})

// 			// 	export declare type SupportedLineTools = "text" | "anchored_text" | "note" | "anchored_note" | "signpost" | "double_curve" | "arc" | "icon" | "arrow_up" | "arrow_down" |
// 			// "arrow_left" | "arrow_right" | "price_label" | "price_note" | "arrow_marker" | "flag" | "vertical_line" | "horizontal_line" | "cross_line" | "horizontal_ray" | "trend_line" |
// 			// "info_line" | "trend_angle" | "arrow" | "ray" | "extended" | "parallel_channel" | "disjoint_angle" |
// 			// "flat_bottom" | "pitchfork" | "schiff_pitchfork_modified" | "schiff_pitchfork" | "balloon" | "inside_pitchfork" | "pitchfan" | "gannbox" | "gannbox_square" | "gannbox_fixed" |
// 			// "gannbox_fan" | "fib_retracement" | "fib_trend_ext" | "fib_speed_resist_fan" | "fib_timezone" | "fib_trend_time" | "fib_circles" | "fib_spiral" | "fib_speed_resist_arcs" |
// 			// "fib_channel" | "xabcd_pattern" | "cypher_pattern" | "abcd_pattern" | "callout" | "triangle_pattern" | "3divers_pattern" |
// 			// "head_and_shoulders" | "fib_wedge" | "elliott_impulse_wave" | "elliott_triangle_wave" | "elliott_triple_combo" | "elliott_correction" | "elliott_double_combo" |
// 			// "cyclic_lines" | "time_cycles" | "sine_line" | "long_position" | "short_position" | "forecast" | "date_range" | "price_range" | "date_and_price_range" | "bars_pattern" | "ghost_feed" | "projection" | "rectangle" | "rotated_rectangle" | "circle" | "ellipse" | "triangle" | "polyline" | "path" |
// 			// "curve" | "cursor" | "dot" | "arrow_cursor" | "eraser" | "measure" | "zoom" | "brush" | "highlighter" | "regression_trend" | "fixed_range_volume_profile";

// 			// Object.keys(dataStrategy).forEach(x => {
// 			// 	if(dataStrategy[x].length > 0){
// 			// 		x.map((z) => console.log(z, 'zzzzz'))

// 			// 	}

// 			// });
// 			// let strategyName = []
// 			// for (const iterator of Object.keys(dataStrategy)) {
// 			// 	if(dataStrategy[iterator].length > 0 ){
// 			// 		strategyName.push({'keyword': iterator, 'data': dataStrategy[iterator]})
// 			// 	}
// 			// }

// 			// const strategyNameFilter = strategyName.filter((x) => (x.keyword) !== "tweezerTop" && (x.keyword) !== "tweezerBottom")

// 			// for (const iterator of strategyNameFilter) {
// 			// 	const dataArr = iterator.data
// 			// 	dataArr.forEach(element => {

// 			// 	tvWidget.activeChart().createMultipointShape(
// 			// 		[
// 			// 			{ time: Number(element.time_ori), price:Number(element.close) }
// 			// 		],
// 			// 		{
// 			// 			shape: "arrow_up",
// 			// 			lock: false,
// 			// 			disableSelection: true,
// 			// 			disableSave: true,
// 			// 			disableUndo: true,
// 			// 			text: `🐑 ${iterator.keyword}`,
// 			// 		}

// 			// 	);
// 			// 	});
// 			// }

// 			// const strategyNameTweezerTop = strategyName.find((x) => (x.keyword) === "tweezerTop")

// 			// let dataTt = []
// 			// for (const iterator of strategyNameTweezerTop.data) {
// 			// 	dataTt.push([
// 			// 		{"price": Number(iterator[0].high), "time" : Number(iterator[0].time_ori)},
// 			// 		{"price": Number(iterator[1].high), "time" : Number(iterator[1].time_ori)}
// 			// 	])
// 			// }

// 			// dataTt.forEach((x) => {
// 			// 	// console.log(x[0].price, 'xxx')
// 			// 	tvWidget.activeChart().createMultipointShape(
// 			// 		[
// 			// 			{ time: x[0].time, price: x[0].price } ,
// 			// 			{ time: x[1].time, price: x[1].price }
// 			// 		],
// 			// 		{
// 			// 			shape: "trend_line",
// 			// 			lock: false,
// 			// 			disableSelection: true,
// 			// 			disableSave: true,
// 			// 			disableUndo: true,
// 			// 			text: "Ini kambing yang beli",
// 			// 		}
// 			// 	);
// 			// })

// 			// const strategyNameTweezerBottom = strategyName.find((x) => (x.keyword) === "tweezerBottom")

// 			// let dataTb = []
// 			// for (const iterator of strategyNameTweezerBottom.data) {
// 			// 	dataTb.push([
// 			// 		{"price": Number(iterator[0].low), "time" : Number(iterator[0].time_ori)},
// 			// 		{"price": Number(iterator[1].low), "time" : Number(iterator[1].time_ori)}
// 			// 	])
// 			// }

// 			// dataTb.forEach((x) => {
// 			// 	// console.log(x[0].price, 'xxx')
// 			// 	tvWidget.activeChart().createMultipointShape(
// 			// 		[
// 			// 			{ time: x[0].time, price: x[0].price } ,
// 			// 			{ time: x[1].time, price: x[1].price }
// 			// 		],
// 			// 		{
// 			// 			shape: "trend_line",
// 			// 			color: "#2A2E39",
// 			// 			lock: false,
// 			// 			disableSelection: true,
// 			// 			disableSave: true,
// 			// 			disableUndo: true,
// 			// 			text: "Ini kambing yang beli",
// 			// 		}
// 			// 	);
// 			// })

// 			// console.log(this.props.time)
// 			// console.log(this.props.price)
// 			// tvWidget.activeChart().createMultipointShape(
// 			// 	[
// 			// 		{ time: 1662626500, price: 19979.53 },
// 			// 		{ time: 1662626500, price: 19679.53 }
// 			// 	],
// 			// 	{
// 			// 		shape: "fib_retracement",
// 			// 		lock: true,
// 			// 		disableSelection: true,
// 			// 		disableSave: true,
// 			// 		disableUndo: true,
// 			// 		text: "Ini kambing yang beli",
// 			// 	}
// 			// );
// 			//"arrow_up" | "arrow_down" | "flag" | "vertical_line" | "horizontal_line" | "long_position" | "short_position" | "icon"
// 			// tvWidget.activeChart().createShape({ time: 16626109500 }, { shape: 'short_position' });
// 		});
// 	}

// 	componentWillUnmount() {
// 		if (this.tvWidget !== null) {
// 			this.tvWidget.remove();
// 			this.tvWidget = null;
// 		}
// 	}

// 	render() {
// 		return <div ref={this.ref} className={"TVChartContainer"} />;
// 	}
// }
