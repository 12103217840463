import React from 'react'
import AppBarAdminHeader from '../../Components/AppBars/admin/AppBarAdminHeader'
import AppBarContent from '../../Components/AppBars/admin/AppBarContent'

function ContentPage() {
  return (
	<>
	<AppBarAdminHeader/>
	<AppBarContent/>
	</>
  )
}

export default ContentPage