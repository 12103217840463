import { Avatar, Box, HStack, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import colors from '../../../../Utils/colors'
import { NumberAcronym } from '../../../../Utils/NumberUtils'

function TableTrendingStock({data}) {
    const dataSort = data.sort((a, b) => b.Volume - a.Volume)

  return (
    <>
    {dataSort&&
        dataSort?.map((x)=>
        <HStack key={x.Id} p='1'>
            <Link to={`/market/stocks-id/${x.Code}`}>
                <HStack>
                    <Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
                    <Text>{x.Code}</Text>
                </HStack>			
            </Link>
            <Spacer/>
            {/* <TriangleUpIcon color='green'/> */}
            <Box>
            <Text textAlign='end' color={colors.green}>IDR {NumberAcronym(x.Value)} </Text>
            <Text textAlign='end' fontSize='smaller'>{NumberAcronym(x.Volume)} shares</Text>
            </Box>
        </HStack>
        )
        }
</>
  )
}

export default TableTrendingStock