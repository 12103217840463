import { Avatar, Box, Button, Heading, HStack, SimpleGrid, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi'
import colors from '../../../../Utils/colors'
import { Select } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'

function TablePatternDetail({list, type}) {

    const [dataPatter, setDataPattern] = useState()

    const param = useParams()
   
    const [titleTable, setTitleTable] = useState([
        { title: "Strategy", filter: false },
        { title: "Coin", filter: false },
        { title: "Time Frame", filter: false },
        { title: "Updated", filter: false },
    ]);

    // const sortBy = () => {
    //     if(type === "crypto"){
    //         const dataArr = list
    //         console.log(dataArr, 'dataArr')
    //         dataArr.forEach(x => {
    //             console.log(Number(x.data[0].length > 0 ? x.data[0][0].time_ori : x.data[0].time_ori) * 1000)
    //         });
    //         const dataSort = dataArr.sort((a,b) => (Number(b.data[0].length > 0 ? b.data[0][0].time_ori : b.data[0].time_ori) * 1000) - (Number(a.data[0].length > 0 ? a.data[0][0].time_ori : a.data[0].time_ori) * 1000) )
    //         console.log(dataSort, 'sort')
    //     }
    //     if(type==="stocks-id"){
    //         const arr = list
    //         const filterArr = arr.filter((x) => x.data.includes(param.symbol))
    //         console.log(filterArr, 'filter')
    //         const allPattern = filterArr.map(x => {
    //             return{
    //                 name:x.keyword,
    //                 candle:x.indicator.filter(y => y.symbol === param.symbol),
    //                 timeframe: x.timeframe
    //             }
    //         })
    //         setDataPattern(allPattern)
    //     }
    // }

    // useEffect(() => {
    //     sortBy()
    
    //   return () => {
    //     setDataPattern()
    //   }
    // }, [])
    


    if(type === "crypto") return (
        <Box>
            <TableContainer mx={10}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            {titleTable.map((x) => (
                                <Th>
                                    <Button
                                        key={x.title}
                                        variant="ghost"
                                    //  onClick={sortBy(x.title)}
                                    >
                                        <HStack spacing="1">
                                            <Text color={colors.theme}>{x.title}</Text>
                                            <BiSort fontWeight="bold" color={colors.theme} />
                                        </HStack>
                                    </Button>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list?.map((x) => {
                            const dataCoin = x?.data?.reverse()
                            const time = dataCoin[0]?.length > 0 ? dataCoin[0][0].time_frame : dataCoin[0].time_frame
                            return (
                                <Tr>
                                    <Td>{x?.keyword}</Td>
                                    <Td>
                                        <Avatar
                                            name={dataCoin[0]?.length > 0 ? dataCoin[0][0].coin_name : dataCoin[0].coin_name}
                                            size='sm'
                                            src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fcrypto%2F${dataCoin[0].length > 0 ? dataCoin[0][0].coin_name : dataCoin[0].coin_name}.png?alt=media`}
                                        />
                                    </Td>
                                    <Td>{time === "minute_1" ? "1 Minute" : time === "minute_15" ? "15 Minute" : time === "hour_1" ? "1 Hour" : ""}</Td>
                                    <Td>{moment(Number(dataCoin[0].length > 0 ? dataCoin[0][0].time_ori : dataCoin[0].time_ori) * 1000).toLocaleString()}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    )

    if(type === "stocks-id") return (
        <Box>
        <TableContainer mx={10}>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        {titleTable.map((x) => (
                            <Th>
                                <Button
                                    key={x.title}
                                    variant="ghost"
                                //  onClick={sortBy(x.title)}
                                >
                                    <HStack spacing="1">
                                        <Text color={colors.theme}>{x.title}</Text>
                                        <BiSort fontWeight="bold" color={colors.theme} />
                                    </HStack>
                                </Button>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {list?.map((x) => {
                        return (
                            <Tr>
                                <Td>{x?.name}</Td>
                                <Td>
                                    <SimpleGrid  columns={30} spacingX='-10px'>
                                    
                                    { x?.candle?.map((y,index)=>{
                                            return (
                                                <Link to={`/market/stocks-id/${y.symbol}?timeframe=${x.timeframe}`} >
                                                    <Avatar
                                                        name={y.symbol}
                                                        size='sm'
                                                        src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${y.symbol}.png?alt=media`}
                                                    />
                                                </Link>)
                                    })}

                                    </SimpleGrid>
                                </Td>
                                <Td>{x.timeframe === "1h" ? "1 Hour" : "1 Day"}</Td>
                                <Td>{x?.candle?.map((y) => {
                                    const time = moment(y.timestamp * 1000).toLocaleString()
                                    return(time)
                                } )}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    </Box>
    )
}

export default TablePatternDetail