import { Heading,
    Button,
    Box,
    Grid,
    GridItem,
    Avatar,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter ,
    useDisclosure
} from "@chakra-ui/react";
import React from "react";
import Footer from "../Components/AppBars/Footer";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";
import { collection,getDocs, orderBy } from "firebase/firestore";
import { db } from '../Configs/firebase'
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import idrDecimalHelper from "../Utils/idrDecimalHelper";
import { Stream } from "@cloudflare/stream-react"

function WatchPage() {

    const navigate = useNavigate()
    const [plan, setPlan] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [initialVideo, setInitialVideo] = useState({
        video: "56dab4a72b87902fe689ba7a28cd10e2",
        image: "https://firebasestorage.googleapis.com/v0/b/algotradingid.appspot.com/o/content%2FAlgotrading%20Mastery%20-%20DCEMA%20Indicator?alt=media",
        thumbnail: "https://firebasestorage.googleapis.com/v0/b/algotradingid.appspot.com/o/content%2Fthumbnail%2FAlgotrading%20Mastery%20-%20DCEMA%20Indicator_200x200?alt=media",
        title: "Algotrading Mastery - DCEMA Indicator"
    })

    const getData = async()=>{
		const querySnapshot = await getDocs(collection(db, "tradingplan"),
		    orderBy("start", "asc")
		);
		let newArr=[]
		querySnapshot.forEach((doc) => {
            let newData =doc.data()
            newData.id=doc.id
            newArr.push(newData)
		});
        console.log(newArr, 'newArr');
        setPlan([...newArr])
	}

    useEffect(() => {
        getData()
    }, [])

    const renderItems = () => {
        return plan.map(x => (
                <GridItem w='100%'>
                    <Box borderWidth='1px' borderRadius='lg' overflow='hidden'>
                        <Box px="5" py="5">
                            <Flex justifyContent="flex-start" flexDirection="row">
                                <Box borderWidth="1" borderColor="white" bg="white" rounded="full" overflow="hidden">
                                    <Avatar name='Dan Abrahmov' src='/gain.png'/>
                                </Box>
                                <Box pl="5">
                                    <Text fontSize="md" fontWeight="semibold" textTransform="uppercase">{x.title}</Text>
                                    <Text fontSize="xs" fontWeight="normal">Timeframe 1 Jam</Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Box px="5" py="5">
                            <Flex justifyContent="flex-start" flexDirection="row" flexWrap="wrap">
                                <Box width="50%">
                                    <Text fontSize="md" fontWeight="semibold" color="teal.500">{x.win_rate} %</Text>
                                    <Text fontSize="xs" fontWeight="normal">Win Rate</Text>
                                </Box>
                                <Box width="50%">
                                    <Text fontSize="md" fontWeight="semibold" color="teal.500">+{idrDecimalHelper(x.net_profit, '')}</Text>
                                    <Text fontSize="xs" fontWeight="normal">Net Profit</Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Box px="5" py="2">
                            <Text fontWeight="semibold" fontSize="sm">{idrDecimalHelper(x.price)}</Text>
                        </Box>
                        <Box px="5" py="3">
                            <Flex justifyContent="space-between">
                                <Button colorScheme='white' variant='outline' onClick={onOpen}>Detail</Button>
                                <Button colorScheme='teal' variant='outline' onClick={() => navigate(`/paymentglobal/tradingplan/${x.id}`)}>Beli</Button>
                            </Flex>
                        </Box>
                    </Box>
                </GridItem>
            )
        )
    }
    
  return (
    <>
      <HeaderResponsive />
      <Box maxW='8xl' m="auto">
            <Box padding='4'>
                <Heading as='h2' size={{
                    sm:'sm',
                    md:'xl'
                }} mb="2" textAlign="center">
                    Trading Plan List
                </Heading>
            </Box>

            <Box padding='4'>
                <Grid templateColumns={{md:'repeat(4, 1fr)', sm:'repeat(1, 1fr)'}} gap={6}>
                    {renderItems()}
                </Grid>
            </Box>
        </Box>
      <Footer />

      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Anaconda algo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width="80%" mx="auto" py="2">
                <Stream 
                    poster={initialVideo.image}
                    src={initialVideo.video}
                    key={initialVideo.video}
                    controls 
                />
                <Heading size="md" textAlign="center" py="1">{initialVideo.title}</Heading>
                <Box mt="5">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi inventore error odio consequuntur vel possimus accusamus, dicta minima optio ratione aut est sed odit doloribus eligendi facilis maxime autem quibusdam.
                    <br/>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Necessitatibus, iure animi nostrum minima facere aut minus repellat! Est eveniet laboriosam assumenda nostrum doloremque dolor, ad distinctio vitae rem. Eum, veniam.
                    <br/>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis alias itaque voluptatem laudantium quaerat repellat, nobis fuga illum magnam soluta. Reiciendis, autem adipisci dolores nesciunt expedita at. Veritatis, dolorem nobis.
                </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default WatchPage;
