import { Box } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function AppCard43(props) {
  let navigate = useNavigate();
  return (
    <Box
      transition={"0.2s ease-in-out"}
      _hover={{ transform: "scale(1.2)" }}
      border="1px"
      borderRadius="10px"
      bgRepeat="no-repeat"
      bgSize="cover"
      h="35vh"
      w="25vh"
      bgImage={props.image}
      // onClick={() => navigate(`/watch/${props.id}?type=speakers`)}
      onClick={() => navigate(`/profile/${JSON.parse((JSON.stringify(props.name).toLowerCase()).replace(/\s/g, ''))}`)}
    />
  );
}

export default AppCard43;
