import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  IconButton,
  Tr,
  Th,
  HStack,
  Td,
  Text,
  TableCaption,
  TableContainer,
  useToast,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Heading,
  Center,
  Divider,
  Image,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { db } from "../../../Configs/firebase";
import { doc, orderBy, query, limit, deleteDoc, setDoc, getDoc, collection, addDoc, getDocs } from "firebase/firestore";

import { uploadFile } from "../../../Utils/FirebaseStorage";
import { useEffect } from "react";
import TableSpeaker from "./TableAdmin/TableSpeaker";

function AppBarSpeakers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState();
  const [addData, setAddData] = useState({});
  const [addFile, setAddFile] = useState([]);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const btnRef = useRef();

  const handleAddData = (key, value) => {
    let newObject = addData;
    newObject[key] = value;
    setAddFile(newObject);
    console.log(newObject);
  };

  const handleNewData = async () => {
    console.log(addData);

    setLoading(true);
    //handle image upload first
    // if(addData.name===undefined || addFile===undefined){
    // toast({
    // 	title: 'Data is incomplete',
    // 	description: "Please make sure to complete your datas",
    // 	status: 'error',
    // 	duration: 9000,
    // 	isClosable: true,
    //   })
    //   setLoading(false)
    //   return
    // }
    try {
      console.log(addFile, "addfile");
      const result = await uploadFile(addData.name, "speakers", addFile["image"][0]);
      if (result) {
        addData.image = result.image_original;
        addData.thumbnail = result.image_thumbnail;
        const docRef = await addDoc(collection(db, "speakers"), addData);
        console.log("Document written with ID: ", docRef.id);
        setAddData({});
        setLoading(false);
        getData();
        onClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const AddModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add new Speakers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input m="1" id="name" type="text" placeholder="Name" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
          <Text>Image (JPG 3:4)</Text>
          <Input m="1" id="image" type="file" placeholder="Image URL (JPG 4:3)" onChange={(e) => handleAddData(e.target.id, e.target.files)} />
          <Input m="1" id="company" type="text" placeholder="Company Name" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
          <Input m="1" id="description" type="text" placeholder="Profile Description" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
          <Input m="1" id="email" type="email" placeholder="email" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
          <Input m="1" id="phone" type="tel" placeholder="Phone Number" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
        </ModalBody>

        <ModalFooter>
          {loading ? (
            <Button isLoading colorScheme="teal" variant="solid" />
          ) : (
            <Button colorScheme="green" onClick={() => handleNewData()}>
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const getData = async () => {
    // const querySnapshot = await getDocs(collection(db, "speakers"));
    // const q = query(citiesRef, orderBy("state"), orderBy("population", "desc"));
    const q = query(collection(db, "speakers"));
    const querySnapshot = await getDocs(q);

    let newArr = [];
    querySnapshot.forEach((doc) => {
      let newData = doc.data();
      newData.id = doc.id;
      newArr.push(newData);
    });
    console.log(newArr, 'this is get data')
    setData(newArr);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "speakers", id));
    toast({
      title: "Data Deleted",
      description: "",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    getData();
  };

  const EditDataComponent = async (id) => {
    console.log(id);
    const docRef = doc(db, "speakers", id);
    const docSnap = await getDoc(docRef);
    let data = {};
    if (docSnap.exists()) {
      data = docSnap.data();
      data.id = id;
      console.log(data);
      setEditData(data);
    } else {
      console.log("No such document!");
    }
  };

  const handleEdit = async () => {
    console.log("in handle edit");
    setEditData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box p="5">
        <Box>
          {editData ? (
            <>
              <Center>
                <Box width="3xl">
                  <Heading>Edit</Heading>
                  <Input m="1" type="text" placeholder={editData.name} />
                  <Input m="1" type="text" placeholder={editData.company} />
                  <Input m="1" type="text" placeholder={editData.email} />
                  <Input m="1" type="text" placeholder={editData.phone} />
                  <Button m="1" bgColor="green" onClick={() => handleEdit()}>
                    Update
                  </Button>
                  <Button m="1" bgColor="red" onClick={() => setEditData(null)}>
                    Cancel
                  </Button>
                </Box>
              </Center>
              <Divider m="5" />
            </>
          ) : (
            <></>
          )}
         	{data && (
             <TableSpeaker data={data} EditDataComponent={EditDataComponent} handleDelete={handleDelete} handleAdd={onOpen} />
          )}
        </Box>
      </Box>

      <AddModal />
    </>
  );
}

export default AppBarSpeakers;
