import { Box, Heading, Text, Center, Image, SimpleGrid, Button, Spacer, Divider, Tag, Tabs, TabList, Tab, TabPanels, TabPanel, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../Components/AppBars/Footer";
import Header from "../Components/AppBars/Header";
import AppScreenFront from "../Components/AppComponents/AppScreenFront";
import AppVideoBackground from "../Components/AppComponents/AppVideoBackground/AppVideoBackground";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../Configs/firebase";
import { useNavigate } from "react-router-dom";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";
import idrDecimalHelper from "../Utils/idrDecimalHelper";
import TagManager from 'react-gtm-module'
import AppBarCalenderEvent from "../Components/AppBars/AppBarCalenderEvent";
import colors from "../Utils/colors";
import moment from "moment";


function EventsPage() {

  const [data, setData] = useState();

  const navigate = useNavigate();

	const timeNow = moment(new Date()).unix()*1000

  const getData = async () => {
    const q = query(collection(db, "events"), 
    orderBy("start", "desc"),
    limit(25)
    );
    const querySnapshot = await getDocs(q);
    let newData = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      let qData = doc.data();
      qData.id = doc.id;
      newData.push(qData);
    });
    console.log(newData)
    setData(newData);
    console.log(newData, 'ini data event')
  };
  useEffect(() => {
    getData();

    return () => {};
  }, []);

  return (
    <>
      <HeaderResponsive />
      <Box bgColor="black" color="white">
        <Heading textAlign="center" p="20px">
          Events
        </Heading>
        <Tabs variant='enclosed'>
          <TabList>
            <Tab w="50%">
              <Text fontWeight="bold" fontSize="md" letterSpacing="1px">Link</Text>
            </Tab>
            <Tab w="50%">
            <Text fontWeight="bold" fontSize="md" letterSpacing="1px">Calendar</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
            <Box>
            {data ? (
          data.map((x) => (
            <Box>
              <Center>
                <SimpleGrid columns={[1, null, 2]}
                  borderRadius="3xl"
                  borderStyle="solid"
                  mb={10}
                >
                  <Center>
                  <Image  borderRadius="3xl" width="50%" src={x.image} />
                  </Center>
                  <Box color="white" p="5">
                    <Heading>{x.title}</Heading>
                    <Text>{x.description}</Text>
                    <Text>Lokasi : {x.location}</Text>
                    <Text>{idrDecimalHelper(x.price)}</Text>
                    <Text>{new Date(x.start).toString()}</Text>
                    <Box>
                      {x.tags.map((y) => (
                        <Tag ml="1">{y}</Tag>
                      ))}
                    </Box>
                    {timeNow >= x.start ? (
                       <Button mt="2" bgColor="red" disabled onClick={() => navigate(`/payment/seminar/${x.id}`)}>
                       Selesai
                     </Button>
                    ) : (
                      <Button mt="2" bgColor="green" onClick={() => navigate(`/payment/seminar/${x.id}`)}>
                      Bayar
                    </Button>
                    )}
                  </Box>
                </SimpleGrid>
              </Center>
              <Center m="2">
                <Divider width="3xl" alignSelf="center" />
              </Center>
            </Box>
          ))
        ) : (
          <Center>
            <Heading>
            <Spinner color="white" />
            </Heading>
          </Center>
        )}
        </Box>
            </TabPanel>
            <TabPanel>
            <Box >
              {data && (
                <AppBarCalenderEvent data={data} />
              )}
            </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Footer />
    </>
  );
}

export default EventsPage;
