import React from 'react'
import AppBarMarketView from '../Components/AppBars/AppBarMarketView'
import Footer from '../Components/AppBars/Footer'
import Header from '../Components/AppBars/Header'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'

function MarketViewPage() {
  return (
  <>
	{/* <Header/> */}
	<HeaderResponsive/>
	<AppBarMarketView/>
	<Footer/>
  </>
  )
}

export default MarketViewPage