import React from "react";
import { Box, SimpleGrid, Image, Center } from "@chakra-ui/react";

function CoverSeminar() {
  return (
    <>
      <Box bg="black" h="100vh">
        <Center>
          <SimpleGrid columns={2} spacing="10px" mx="40px" my="20px">
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://i.ytimg.com/vi/11ACM9N91O4/sddefault.jpg" h="36vh" w="64vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/masterclass-1652712370.jpg" h="36vh" w="64vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://www.masterclass.com/course-images/attachments/mjecqosnryx67fv0iwpvgghhl5zh?width=412&fit=cover&dpr=2" h="36vh" w="64vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://www.masterclass.com/course-images/attachments/mgb5ipimlu9squk5uq51whyoqleb?width=412&fit=cover&dpr=2" h="36vh" w="64vh"></Box>
          </SimpleGrid>
        </Center>
      </Box>
    </>
  );
}

export default CoverSeminar;
