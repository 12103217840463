import { Box, Text, AspectRatio, Heading, Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import AppBodyMain from "../Components/AppBars/AppBodyMain";
import Footer from "../Components/AppBars/Footer";

import video from "../Img/frony.mp4"
import AppVideoBackground from "../Components/AppComponents/AppVideoBackground/AppVideoBackground";
import AppBarSpeakersLimit from "../Components/AppBars/AppBarSpekaersLimit";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";
import TagManager from 'react-gtm-module'
import getDomain from "../Utils/getDomain";
import AuthContext from "../Routes/hooks/AuthContext";

function MainPage() {


    const link = getDomain()
  return (
    <>
      <HeaderResponsive />
      {link === "tradingsyariahid" ? (
        <AppVideoBackground
        tag="New"
        videoUrl={video}
        title="Bersama tradingsyariah.id"
        contentA="Belajar Trading Saham Syariah"
        contentB="Dalam Mencari Keberkahan Rezeki"
        id="evanijesslyn"
      />
      ) : (
        <AppVideoBackground
        tag="New"
        videoUrl={video}
        title="Bersama Algotrading.id"
        contentA="Capek Ngarepin Naik Gaji?"
        contentB="Sudah Saatnya Kamu Jadi Traders Santai dan
        dapatkan Gaji Keduamu Sendiri"
        id="evanijesslyn"
      />
      )}
      <AppBarSpeakersLimit marginTop={{ base: "0px", md: "-50px", lg: "-150px" }} />
      <AppBodyMain />
      <Footer />
    </>
  );
}

export default MainPage;
