import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Box,
  Select,
  useToast,
  Flex,
  HStack, Heading, Spacer, Text,
  Center,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Avatar,
  Stack,
  IconButton,
  Thead,
  Th,
  Tag,
} from "@chakra-ui/react";
import { deleteUser } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  limit
} from "firebase/firestore";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../../Configs/firebase";
import EditDataUser from "../../AppComponents/EditDataUser";
import TableUser from "./TableAdmin/TableUser";
import { ModalRegisterComponent } from "../../AppComponents/ModalAddNewMember";
import { FiPlus } from 'react-icons/fi';
import colors from "../../../Utils/colors";
import { BiSort } from "react-icons/bi";
import moment from "moment";
import TextHelper from "../../../Utils/TextHelper";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

function AppBarUser() {

  const [filter, setFilter] = useState("");
  const [userData, setUserData] = useState([]);
  const [editDataUser, setEditDataUser] = useState(null);
  const [search, setSearch] = useState("")
  const [searchBy, setSearchBy] = useState("")
  const [searchResult, setSearchResult] = useState([])

  const navigate = useNavigate()


  const [titleTable, setTitleTable] = useState([
        {title: "index", filter: false},
        {title: "Name", filter: false},
        {title: "User ID", filter: false},
        {title: "Email", filter: false},
        {title: "Actions", filter: false},
  ]);

  const toast = useToast();

  const EditDataComponent = async (id) => {
    console.log(id);
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    let data = {};
    if (docSnap.exists()) {
      data = docSnap.data();
      setEditDataUser(data);
    } else {
      console.log("No such document!");
    }
  };




  const getDataUser = async () => {
    try {
      const ref = collection(db, "users");
      const q = query(ref, orderBy("update", "desc"), limit(25));
      let result = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push(doc.data());
      });
      setUserData(result);
      console.log(result, "result data user")
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "users", id));
      toast({
        title: "Data Deleted",
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      // getDataUser();
      deleteUser(id);
    } catch (error) {
      console.log(error);
    }
    // getDataUser();
  };

  useEffect(() => {
    getDataUser();

    return () => {
      setUserData([]);
    };
  }, []);


 const handleSearch = async () => {
    if (!searchBy){
        Swal.fire({
            icon: 'error',
            title: 'Silakan pilih field (nama/email/hp)',
            text: 'Kamu belum menentukan field pencarian'
        })
    }else{
        try {
            console.log("Search by :", searchBy)
            console.log("Search  :", search)
          const ref = collection(db, "users");
          const q = query(ref, where(searchBy, "==", search));
          let result = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            result.push(doc.data());
          });
          setSearchResult(result);
          if (result.length == 0){
            Swal.fire({
              icon: 'error',
              title: 'Tidak ditemukan',
              text: 'Perhatikan tanda baca, serta huruf besar kecil berpengaruh terhadap hasil pencaian'
            })
          }
          console.log(result, "search result")
        } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error gan',
              text:  `Pencarian error: ${error.message}`
            })
          console.log(error.message, "error");
        }
    }
 }




  return (
    <>
      <Box p="5">
        <Box width="full">
          <Button onClick={()=>navigate('add-new-member')} bg='green' color="white">
            <FiPlus color='white'/>
            Add New Member
          </Button>
          <HStack m={10}>
              <Box>
                <Heading size="lg">
                  Users Admin
                </Heading>
              </Box>
              <Spacer />
              <HStack>
                <Select placeholder='Filter by' onChange={(e) => setFilter(e.target.value) }>
                  <option value='admin'>Admin</option>
                  <option value='member'>Member</option>
                  <option value='user'>User</option>
                </Select>
              </HStack>
          </HStack>
        </Box>

      <Center mb={100}>
        <Stack>
          <Flex spacing={6} w="full" my={5}>
            <Input flex={5} mx={2} placeholder="Masukkan kata kunci" onChange={e => setSearch(e.target.value)}/>
            <Select onChange={e => setSearchBy(e.target.value)} flex={3} mx={2} placeholder='Pilih Field'>
              <option value="name">Nama</option>
              <option value="email">Email</option>
              <option value="phoneNumber">Nomor Hp</option>
            </Select>
            <Button mx={2} bgColor='yellow' color='gray.800' onClick={handleSearch} flex={1}>Cari</Button>
          </Flex>


       {searchResult.length == 0 ? null : 
        <>
          <Heading my={10} size='md' textAlign='center'>Hasil Pencarian</Heading>
          <TableContainer>
            <Table>
              <Thead bg='gray.100' height='1rem'>
                  <Tr>
                    {titleTable.map((x) => (
                        <Th>
                        <Button
                          key={x.title}
                          variant="ghost"
                          onClick={() => sortBy(x.title)}
                                >
                        <HStack spacing="1">
                          <Text fontSize={11} color={colors.black}>{x.title}</Text>
                          <BiSort fontWeight="bold" color={colors.black}/>
                        </HStack>
                      </Button>
                      </Th>
                    ))}
                  </Tr>
              </Thead>
              <Tbody>
                {searchResult.length == 0 ? null : 
                  searchResult.map((x, index)=>(
                    <Tr cursor="pointer" >
                      <Td>
                        <Text fontSize={11} align="center">
                        {++index}
                        </Text>
                        </Td>
                      <Td>
                      <HStack spacing={3}>
                          <Box>
                          <Avatar size="md" name={x.photoProfile} src={x.photoProfile} />
                          </Box>
                      <Stack spacing={2} >
                          <Text fontSize={11}>{x.name}</Text>
                          <Box>
                          {x.role === "admin" ? (<Tag bgColor="yellow" color="black">Admin</Tag>) : x.role === "member" ? (<Tag bgColor="green">Member</Tag>) : x.role === "user" ? <Tag bgColor="red">User</Tag> : "" }
                          </Box>
                          <Text fontSize={11} size="md">Exp : {x.expired_user && new Date(x.expired_user).toLocaleString()}</Text>
                      </Stack>
                      </HStack>
                      </Td>
                      <Td>
                        <Stack>
                        <Text fontSize={11}>{TextHelper(x.uid_user)}</Text>
                        <Text fontSize={11}>{moment(x.update?.seconds*1000).toLocaleString()}</Text>
                        <Text fontSize={11} textTransform={'capitalize'}>Package : {x.packageUser ? x.packageUser : "Belum Terdaftar"}</Text>
                        </Stack>
                        </Td>
                      <Td>
                          <Stack spacing={2}>
                              <Text fontSize={11} >
                              {x.email}
                              </Text >
                              <Text fontSize={11} >
                              {x.phoneNumber}
                              </Text >
                              <Text fontSize={11} >
                              {x.city}
                              </Text >
                          </Stack>
                      </Td>
                      <Td  color={colors.white}>
                          <IconButton onClick={() => EditDataComponent(x.uid_user)} m="2" aria-label="Edit database" colorScheme="yellow" icon={<EditIcon />} />
                          <IconButton onClick={() => handleDelete(x.uid_user)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
                      </Td>
                </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        </>}
        </Stack>
      </Center>




        {editDataUser !== null && (
          <EditDataUser
            editData={editDataUser}
            setEditData={setEditDataUser}
            // data={getDataUser}
          />
        )}
        {userData.length > 0 && (
          <TableUser
            filter={filter}
            titleTable={titleTable}
            setTitleTable={setTitleTable}
            data={userData}
            EditDataComponent={EditDataComponent}
            handleDelete={handleDelete}
     
          />
        )}
      </Box>
    </>
  );
}

export default AppBarUser;
