import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, Heading, HStack, Image, List, ListIcon, ListItem, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react'
import { Stream } from '@cloudflare/stream-react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../Configs/firebase';
import AuthContext from '../../../Routes/hooks/AuthContext';
import colors from '../../../Utils/colors';

function AppBarWatchBoarding({keyword}) {

    const [dataBoarding, setDataBoarding] = useState([])
    const [membership, setMembership] = useState(true);

    const navigate = useNavigate()

    const { currentUser } = useContext(AuthContext)

   const getDataUser = async() => {
    const user = currentUser
    if(user){
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        let data = []
        if (userData.role === "user") {
          const q = query(collection(db, "content"), where("tags", "array-contains", keyword));
          const docSnap = await getDocs(q);
          docSnap.forEach((doc) => {
            data.push(doc.data())
          });
          setDataBoarding(data)
          setMembership(false);
        }
        if (userData.role === "admin") {
          console.log('admin')
          setMembership(true);
          getVideo();
        }
        if (userData.role === "member") {
          setMembership(true);
          getVideo();
        }
      }
      
    }

   }

    const getVideo = async () => {
		try {
			let data = []
			const q = query(collection(db, "content"), where("tags", "array-contains", keyword));
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				data.push(doc.data())
			});
			setDataBoarding(data)
			console.log(data, 'ini data pattern')
		} catch (error) {
			console.log(error)
		}
	}

  const navigateUnlimited = () => {
    navigate(`/payment/member/${currentUser.uid}`, {replace: true})
    
  }

    useEffect(() => {
        getDataUser()
    
      return () => {
        setDataBoarding([])
      }
    }, [])
    

  return (
    <Box>
      
    {dataBoarding && (
        dataBoarding?.map((x) => (
            <SimpleGrid columns={{base:1,md:2}} p='5'>
              {membership ? (
              <Box width='full' >
                 <Button mb='5' bgColor={colors.green} fontSize='md' size='sm' onClick={()=>navigate(-1)}>Back</Button>
                 <Stream  controls src={x.video} />
             </Box>
              ) : (
                <>
                <Box>
                  <Heading textAlign="center" mt="5" fontSize="3xl">
                    Membership
                  </Heading>
                  <SimpleGrid columns="1">
                    <VStack spacing={3} m="2" p="10" borderStyle="solid" borderWidth="thin" borderRadius="3xl" >
                      <Heading fontSize="2xl">Member</Heading>
                      <Heading fontSize="2xl">Rp3.500.000</Heading>
                      <List spacing={1}>
                      <ListItem mt="2">
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Akses analisis ++10.000 market
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Akses 1 tahun
                        </ListItem>
                        {/* <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
                        </ListItem> */}
                      </List>
                      <Button bgColor="red" onClick={() =>  navigateUnlimited()}>
                        Beli
                      </Button>
                    </VStack>
                  </SimpleGrid>
                </Box>
              </>
              )}
            <Box p='12'>
                <HStack alignItems="start">
                    <VStack alignItems='start' spacing={2}>
                        <Heading>{x.title}</Heading>
                        <HStack spacing={3}>
                        </HStack>
                        
                    </VStack>
                </HStack>
                <Text mt='2'>{x.description}</Text>
            </Box>
        </SimpleGrid>
        ))
    )}
     {!membership && (
                <Alert status="error" bgColor="red.200">
                  <AlertIcon color="red" />
                  <AlertTitle color="black">Kamu belum memiliki akses membership untuk mengakses video ini.</AlertTitle>
                  <AlertDescription color="black">Kamu dapat membeli content atau membership terlebih dahulu.</AlertDescription>
                </Alert>
        )}
    </Box>
  )
}

export default AppBarWatchBoarding