import React from 'react'
import AppBarPatternView from '../Components/AppBars/AppBarPatternView'
import Footer from '../Components/AppBars/Footer'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'

function PatternViewPage() {
	console.log('in pattern view page')
  return (
	<>
	<HeaderResponsive/>
	<AppBarPatternView/>
	<Footer/>
	</>
  )
}

export default PatternViewPage