import { Box,
	useToast,
	} from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react'
import { db } from '../../../Configs/firebase'
import { doc, deleteDoc,getDoc,collection,getDocs, orderBy } from "firebase/firestore";

import { useEffect } from 'react'
import TableTradingPlan from './TableAdmin/TableTradingPlan'
import AddDataTradingPlan from '../../AppComponents/AddDataTradingPlan'
import EditDataTradingPlan from '../../AppComponents/EditDataTradingPlan'


function AppBarAdminTradingPlan() {
	const [add,setAdd]= useState(false)
	const [data,setData]=useState();
	const [speakers,setSpeakers]=useState();
	const [editData,setEditData]=useState(null)
	const toast = useToast()
	const getData = async()=>{
		const querySnapshot = await getDocs(collection(db, "tradingplan"),
		orderBy("start", "asc")
		);
		let newArr=[]
		querySnapshot.forEach((doc) => {
		let newData =doc.data()
		newData.id=doc.id
		newArr.push(newData)
		});
		setData(newArr)
	}


	const handleDelete = async(id)=>{
		await deleteDoc(doc(db, "tradingplan", id));
			toast({
				title: 'Data Deleted',
				description: "",
				status: 'success',
				duration: 9000,
				isClosable: true,
			  })
			  getData()
	}

	const EditDataComponent = async(id)=>{
		console.log(id)
		const docRef = doc(db, "tradingplan", id);
		const docSnap = await getDoc(docRef);
		let data={};
		if (docSnap.exists()) {
		data= docSnap.data()
		data.id=id
		console.log(data)
		setEditData(data)
		} else {
		console.log("No such document!");
		}

	}

	const handleEdit = async()=>{
		console.log('in handle edit')
		setEditData()
	}

	
	useEffect(() => {
	  getData()
	}, [])
	
  return (
	  <>
	<Box p='5'>
		<Box>
		{add?<AddDataTradingPlan speaker={speakers} getData={getData} setAdd={setAdd} handleCancel={() => setAdd(false)}/>:<></>}
		{editData !== null && <EditDataTradingPlan editData={editData} setEditData={setEditData} data={getData}/>}
		{data && (
             <TableTradingPlan data={data} EditDataComponent={EditDataComponent} handleDelete={handleDelete} handleAdd={() => setAdd(true)}/>
          )}
		</Box>
	</Box>

	</>
  )
}

export default AppBarAdminTradingPlan