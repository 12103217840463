import React, { useEffect } from 'react'
import {
 Button,
	Box,
	Image,
	HStack, Heading, SimpleGrid, Spacer, Center,
} from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import colors from '../../Utils/colors';
import ExplorePatterns from './patterns/ExplorePatterns';
import getDomain from '../../Utils/getDomain';
import StrategiesPatterns from './patterns/StrategiesPatterns';
import StrategyPatternCrypto from './patterns/StrategyCryptoPattenr';


function AppBarPattern() {

	const param = useParams();
	const navigate = useNavigate();
	let data = []

	const link = getDomain()


	const dataSet = [
		{
			type: 'chart', name: 'Head and shoulders',
			image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Fhead-and-shoulders.png?alt=media',
			keyword: 'headandshoulders',
			data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' }]
		},
		{ type: 'candle', name: 'Southern Doji', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/MorningDojiStar.png', keyword: 'southerndoji', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' }] },
		{ type: 'candle', name: 'Bullish Engulfing', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/bullishEngulfingPattern.png', keyword: 'bullishengulfing', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' }] },
		{ type: 'candle', name: 'Bearish Engulfing', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/bearishEngulfing.png', keyword: 'bearishengulfing', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' }] },
		{ type: 'chart', name: 'Inverted Head and shoulders', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Finverted-head-and-shoulders.png?alt=media', keyword: 'invertedheadandshoulders', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'chart', name: 'Triple Tops', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Ftriple-tops.png?alt=media', keyword: 'tripletops', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'chart', name: 'Triple Bottoms', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Ftriple-bottoms.png?alt=media', keyword: 'triplebottoms', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'chart', name: 'Bullish Symetrical Triangle', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Fbullish-symetrical-triangle.png?alt=media', keyword: 'bullishsymetricaltriangle', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'chart', name: 'Bearish Symetrical Triangle', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Fbearish-symetrical-triangle.png?alt=media', keyword: 'bearishsymetricaltriangle', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Shooting Star Bearish', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/BearishShootingStar.png', keyword: 'shootingstar', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Bullish Hammer', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/BullishHammerPattern.png', keyword: 'bullishhammer', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Bearish Hammer', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/BearishHammerPattern.png', keyword: 'bearishhammer', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Bearish Harami', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/BearishHarami.png', keyword: 'bearishharami', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Bullish Harami', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/BullishHarami.png', keyword: 'bullishharami', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Northern Doji', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/EveningDojiStar.png', keyword: 'northerndoji', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'technical', name: 'Four X Demand', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Ffourx-demand.png?alt=media', keyword: 'fourxdemand', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Gap Up', image: 'https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/images%2Fpatterns%2Fgap-up.png?alt=media', keyword: 'gapup', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Grave Stone', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/graveStoneDoji.png', keyword: 'gravestone', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Tweezer Bottom', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/TweezerBottom.png', keyword: 'tweezerbottom', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Tweezer Top', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/TweezerTop.png', keyword: 'tweezertop', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
		{ type: 'candle', name: 'Dragon Fly', image: 'https://raw.githubusercontent.com/anandanand84/technicalindicators/master/test/candlestick/images/dragonFlyDoji.png', keyword: 'dragonfly', data: [{ name: 'bitcoin', time: '23/2/12 09:59:02' }, { name: 'ethereum', time: '23/2/12 08:32:23' },] },
	]

	if (param.type === 'chart')
		data = dataSet.filter((x) => x.type === 'chart')
	else if (param.type === 'candle')
		data = dataSet.filter((x) => x.type === 'candle')
	else if (param.type === 'technical')
		data = dataSet.filter((x) => x.type === 'technical')
	else if (param.type === 'strategies')
		data = dataSet.filter((x) => x.type === 'strategies')
	// else data = dataSet;

	return (
		<Box p='25' height='full' bgColor='black' color='white'>
			<Center>
				{link === "algotradingid" ? (
					<SimpleGrid columns={{ base: 2, md: 5, lg: 5 }}>
						<Button m='2' color={param.type === "all" ? "yellow" : "red"} onClick={() => navigate('/patterns/all')}>All Patterns</Button>
						<Button m='2' color={param.type === "chart" ? "yellow" : "red"} onClick={() => navigate('/patterns/chart')}>Chart Patterns</Button>
						<Button m='2' color={param.type === "candle" ? "yellow" : "red"} onClick={() => navigate('/patterns/candle')}>Candle Stick Patterns</Button>
						<Button m='2' color={param.type === "technical" ? "yellow" : "red"} onClick={() => navigate('/patterns/technical')}>Technical Indicators</Button>
						<Button m='2' color={param.type === "strategies" ? "yellow" : "red"} onClick={() => navigate('/patterns/strategies')}>Strategies</Button>
					</SimpleGrid>
				) : (
					<SimpleGrid columns={{ base: 2, md: 5, lg: 5 }}>
						<Button m='2' color={param.type === "all" ? "yellow" : "green"} onClick={() => navigate('/patterns/all')}>All Patterns</Button>
						<Button m='2' color={param.type === "chart" ? "yellow" : "green"} onClick={() => navigate('/patterns/chart')}>Chart Patterns</Button>
						<Button m='2' color={param.type === "candle" ? "yellow" : "green"} onClick={() => navigate('/patterns/candle')}>Candle Stick Patterns</Button>
						<Button m='2' color={param.type === "technical" ? "yellow" : "green"} onClick={() => navigate('/patterns/technical')}>Technical Indicators</Button>
						<Button m='2' color={param.type === "strategies" ? "yellow" : "red"} onClick={() => navigate('/patterns/strategies')}>Strategies</Button>
					</SimpleGrid>
				)}
			</Center>
			{param.type === 'all' ?
				<ExplorePatterns />
				: <></>
			}
			{
				param.type !== 'all'? 
					<SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} p='5' gap='2' >
					{data.map((x) =>
						<Box bgColor='gray.900' p='5' borderRadius='3xl'>
							<HStack>
								<Heading fontSize='larger'>{x.name}</Heading>
								<Spacer />
								<Box>
									<Center mt='2'>
										<Image src={x.image} width='100px' />
									</Center>
									<Center mt='2'>
										<Link to={`/pattern/${x.keyword}`}>
											<Button bgColor={colors.green}>
												View Patterns
											</Button>
										</Link>
									</Center>
								</Box>
							</HStack>
						</Box>
					)}
				</SimpleGrid> : <></>
			}
			{
				console.log(param.type, 'sksksk')
			}
			{
				param.type === 'strategies' ?  <StrategyPatternCrypto /> : <></>
			}
				{/* param.type === 'strategies' && link === 'tradingsyariahid' ?  <StrategiesPatterns /> : <></> */}

		</Box>
	)
}

export default AppBarPattern