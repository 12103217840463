import React from 'react'
import AppBarNoPayment from '../Components/AppBars/AppBarNoPayment'
import Footer from '../Components/AppBars/Footer'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'

function NoPaymentPage() {
  return (
    <>
    <HeaderResponsive/>
	{/* <AppBarNoPayment /> */}
	<Footer/>
    </>
  )
}

export default NoPaymentPage