import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Box, Button, Center, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import colors from '../../../../Utils/colors'
import { dollar, FloatRoundUp, NumberAcronym, Rupiah } from '../../../../Utils/NumberUtils'
import TableSkeleton from '../skeletons/TableSkeleton'
import { BiSort } from 'react-icons/bi'
import UpDownHelper from '../../../../Utils/UpDownHelper'

const dataPerRow = 20;

function TableStocksId({data, role}) {

    const [dataSort, setDataSort] = useState(data)
    const [next, setNext] = useState(dataPerRow);
    const toast = useToast()

    const handleMoreImage = () => {
      setNext(next + dataPerRow);
    };

    const toastAlert = () => {
        toast({
            title: 'Alert !',
            description: 'Oopss.. Only member allowed !',
            position: 'top-right',
            status: 'warning',
            duration: 3000,
            isClosable: true
        })
    }

    const [titleTable, setTitleTable] = useState([
      { title: "Name", filter: false },
      { title: "Price", filter: false },
      { title: "1 day%", filter: false },
      { title: "1 week%", filter: false },
      { title: "1 month%", filter: false },
      { title: "1 year%", filter: false },
      { title: "Volume", filter: false },
      { title: "Chart", filter: false },
    ]);

    const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 0:
            if (titleTable[index].filter){
              return setDataSort([
                ...data.sort((a, b) => b.Name.localeCompare(a.Name)),
              ]);
            }
            return setDataSort([
              ...data.sort((a, b) => a.Name.localeCompare(b.Name)),
            ]);
  
          case 1:
            if (titleTable[index].filter) {
              return setDataSort([
                ...data.sort((a, b) => b.Last - a.Last),
              ]);
            }
            return setDataSort([
              ...data.sort((a, b) => a.Last - b.Last),
            ]);
  
          case 2:
            if (titleTable[index].filter) {
              return setDataSort([
                ...data.sort((a, b) => b.OneDay*100 - a.OneDay*100),
              ]);
            }
            return setDataSort([
              ...data.sort((a, b) => a.OneDay*100 - b.OneDay*100),
            ]);
          
           case 3:
            if (titleTable[index].filter) {
              return setDataSort([
                ...data.sort((a, b) => b.OneWeek*100 - a.OneWeek*100),
              ]);
            }
            return setDataSort([
              ...data.sort((a, b) => a.OneWeek*100 - b.OneWeek*100),
            ]);  
  
            case 4:
              if (titleTable[index].filter) {
                return setDataSort([
                  ...data.sort((a, b) => b.OneMonth*100 - a.OneMonth*100),
                ]);
              }
              return setDataSort([
                ...data.sort((a, b) => a.OneMonth*100 - b.OneMonth*100),
              ]);  
  
              case 5:
                if (titleTable[index].filter) {
                  return setDataSort([
                    ...data.sort((a, b) => b.OneYear*100 - a.OneYear*100),
                  ]);
                }
                return setDataSort([
                  ...data.sort((a, b) => a.OneYear*100 - b.OneYear*100),
                ]); 
                
                case 6:
              if (titleTable[index].filter) {
                return setDataSort([
                  ...data.sort((a, b) => b.Volume - a.Volume),
                ]);
              }
              return setDataSort([
                ...data.sort((a, b) => a.Volume - b.Volume),
              ]); 
  
          default:
            return null
        }
      }

  return (
    <>
    <TableContainer>
		<Table >
			<Thead>
                <Tr>
                {titleTable.map((x) => (
                <Th>
                <Button
                    key={x.title}
                    variant="ghost"
                    onClick={() => role === "user" ? (
                        toastAlert()
                    ) : (
                        sortBy(x.title)
                    )}
                        >
                <HStack spacing="1">
                    <Text color={colors.theme}>{x.title}</Text>
                    <BiSort fontWeight="bold" color={colors.theme}/>
                </HStack>
                </Button>
                </Th>
                ))}
            </Tr>
			</Thead>
			<Tbody>
				{dataSort?dataSort.slice(0, next)?.map((x)=>
					<Tr key={x.Id}>
					<Td >
					<Link to={role === "user" ? (`/market/stocks-id/NoPayment`) : (`/market/stocks-id/${x.Code}`)  }>
					<HStack>
							<Image width='50px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
							<Box p='1'>
							<Text fontSize='2xl'>{x.Code}</Text>
							<Text fontSize='xs'>{x.Name}</Text>
								</Box>
					</HStack>
					</Link>
					</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{Rupiah(x.Last)}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.OneDay*100))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.OneWeek*100))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.OneMonth*100))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.OneYear*100))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{NumberAcronym(role === "user" ? (Math.random() < 0.5 ? -10000 : 10000) : (x.Volume) )}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}><Image width='140' src={`https://api.stockbit.com/v2.4/company/minichart/${x.Code}`}/></Td>
					<Td>
					</Td>
					</Tr>
				):
				<>
					<TableSkeleton/>
				</>}
			</Tbody>
			{/* <Tfoot>
			<Tr>
				<Th>To convert</Th>
				<Th>into</Th>
				<Th isNumeric>multiply by</Th>
			</Tr>
			</Tfoot> */}
		</Table>
	</TableContainer>
  {next < dataSort?.length && (
     <Center py={5}>
          <Button
            onClick={handleMoreImage}
          >
            Load more
          </Button>
      </Center>
        )}
  </>
  )
}

export default TableStocksId