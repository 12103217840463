import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Heading, HStack, IconButton, Image, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../Utils/colors';
import getDomain from '../../../../Utils/getDomain';
import TextHelper from '../../../../Utils/TextHelper';

function TableEvent({data, EditDataComponent, handleDelete, handleAdd}) {


  const [filter, setFilter] = useState("")
  const [dataFilter, setDataFilter] = useState([])
  const [dataSort, setDataSort] = useState(dataFilter)

  const navigate = useNavigate()

  const link = getDomain()



  const getData = () => {
    const dataArr = data
    const filterData = dataArr.filter((x) => x.speakers === filter)
    if(filter === ""){
      setDataFilter(dataArr)
      console.log("gaada")
    }else{
      setDataFilter(filterData)
      console.log('ada')
    }
  }
  

  useEffect(() => {
    getData()
  
    return () => {
      setDataFilter([])
    }
  }, [filter])


      const [titleTable, setTitleTable] = useState([
        {title: "ID", filter: false},
        {title: "Title", filter: false},
        {title: "Speaker", filter: false},
        {title: "Peserta", filter: false},
        {title: "Image", filter: false},
        {title: "Action", filter: false},
      ]);

      const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 1:
            if (titleTable[index].filter){
              return setDataSort([
                ...dataFilter.sort((a, b) => b.start - a.start),
              ]);
            }
            return setDataSort([
              ...dataFilter.sort((a, b) => a.start - b.start),
            ]);

                case 3:
                    if (titleTable[index].filter){
                      return setDataSort([
                        ...dataFilter.sort((a, b) => b.email.localeCompare(a.email)),
                      ]);
                    }
                    return setDataSort([
                      ...dataFilter.sort((a, b) => a.email.localeCompare(b.email)),
                    ]);  


          default:
            return null
        }
      }




  return (
    <Box>
       <HStack m={10}>
      <Box>
        <Heading size="lg">
          Events Admin
        </Heading>
      </Box>
      <Spacer />
      <HStack>
        <Box>
        <Button justifyContent="flex-end" onClick={handleAdd}>
          <HStack>
            <FiPlus/>
            <Text>New Event</Text>
            </HStack>
        </Button>
        </Box>
      {link === "algotradingid" ? (
         <Select placeholder='Filter by' onChange={(e) => setFilter(e.target.value) }>
         <option value='jf8Fwsi1fhQlo3FDDFsa'>Ayas</option>
         <option value='dL9F08Ttgh0i3evO654i'>Dean</option>
         <option value='eTYZCKQqrDluKtoaAWUv'>Edwin</option>
       </Select>
      ) : (
        <Select placeholder='Filter by' onChange={(e) => setFilter(e.target.value) }>
        <option value='0fKRCzv1z1XCF78yvqwS'>Dzikra</option>
        <option value='HwvQC2HKgtGJrcSu9ww6'>Azmi</option>
        <option value='wBbERI5pUmLntW9a7e81'>Edwin</option>
      </Select>
      )}
      </HStack>
    </HStack>


    <TableContainer mx={10}>
      <Table variant="simple">
        <Thead bgColor={colors.theme}>
          <Tr>
          {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => sortBy(x.title)}
                   >
           <HStack spacing="1">
             <Text color={colors.black}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.black}/>
           </HStack>
         </Button>
           </Th>
        ))}
          </Tr>
        </Thead>
        <Tbody>
          {dataFilter ? (
             dataFilter?.map((x, index) => {
              const include = link === "algotradingid" ? ["dean" , "ayas", "edwin"] : ["edwin", "azmi", "dzikra"]
              const data = (x?.tags?.filter((y) => include.includes(y)))
              const speaker = link === "algotradingid" ? (data[0] === "dean" ? "Dean" : data[0] === "edwin" ? "Edwin" : data[0] === "ayas" ? "Ayas" : "") : (data[0] === "azmi" ? "Azmi" : data[0] === "edwin" ? "Edwin" : data[0] === "dzikra" ? "Dzikra" : "")
                return(
              <Tr cursor="pointer" key={x.id} >
                 <Td>
                  <Text align="center">
                  {TextHelper(x.id)}
                  </Text>
                  </Td>
                <Td>
                <VStack spacing={2}>
                    <Text fontWeight="bold">{x.title}</Text>
                    <Text fontSize="sm">{new Date(x.start).toLocaleString()}</Text>
                    <Text>{x.location}</Text>
                </VStack>
                </Td>
                <Td>
                    {speaker}
                </Td>
                <Td>{x?.peserta?.map((y) => 
					<VStack>
					<Text>{y.name}</Text>
					<Text>{y.numberPhone}</Text>
					</VStack>
					)} 
					</Td>

                <Td><Image src={x.thumbnail} width="100px"/></Td>
                <Td  color={colors.white}>
                    {/* <a href={`/watch/${x.video}`} target="_blank" rel="noreferer noreferrer">
                      <IconButton m="2" aria-label="Search database" colorScheme="green" icon={<SearchIcon />} />
                    </a> */}
                    <IconButton onClick={() => EditDataComponent(x.id)} m="2" aria-label="Edit database" colorScheme="yellow" icon={<EditIcon />} />
                    <IconButton onClick={() => handleDelete(x.id)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
                  </Td>
              </Tr>)
                })
          ) : (<></>)}
        </Tbody>
      </Table>
    </TableContainer>
  </Box>
  )
}

export default TableEvent