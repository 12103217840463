import { SkeletonText, Td, Tr } from '@chakra-ui/react'
import React from 'react'

function TableSkeleton() {

	const MyTableSkeleton = ()=>(
		<Tr>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td ><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					<Td><SkeletonText noOfLines={1} width='full' height='10px'/></Td>
					</Tr>
	)

	
  return (
	<>
	<MyTableSkeleton/>
	<MyTableSkeleton/>
	<MyTableSkeleton/>
	<MyTableSkeleton/>
	<MyTableSkeleton/>
	</>
  )
}

export default TableSkeleton