import { SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Header from "../../Components/AppBars/Header";
import HeaderResponsive from "../../Components/AppBars/HeaderResponsive";
import AppComponentChart, {
  TVChartContainerTest,
} from "../../Components/AppComponents/AppComponentChart/AppComponentChart";
import { TVChartContainer } from "../../Components/AppComponents/AppComponentChart/AppComponentChartBasic";
import AppComponentChartWidget from "../../Components/AppComponents/AppComponentChart/AppComponentChartWidget";
import DataConfig from "../../Components/AppComponents/AppComponentChart/Data/DataConfig";
import Main from "../../Components/AppComponents/AppComponentChart/Data/main";

function ChartPage() {
  // const data = DataConfig()
  // const config = data
  // console.log(config, 'ini data config')

  return (
    <>
      <HeaderResponsive />
      <SimpleGrid columns="1">
        <TVChartContainerTest />
      </SimpleGrid>
    </>
  );
}

export default ChartPage;
