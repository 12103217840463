import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Box, Button, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import colors from '../../../../Utils/colors'
import { dollar, FloatRoundUp, NumberAcronym, Rupiah } from '../../../../Utils/NumberUtils'
import TableSkeleton from '../skeletons/TableSkeleton'
import { BiSort } from 'react-icons/bi'
import UpDownHelper from '../../../../Utils/UpDownHelper'
import usdDecimalHelper from '../../../../Utils/usdDecimalHelper'

function TableCryptoMarket({data, role}) {

    const ts = moment().unix()

    // const [dataSort, setDataSort] = useState(data)
    const [coinSort, setCoinSort] = useState()

    const getData = () => {
      const filterData = data.sort((a, b) => a.cmcRank - b.cmcRank)
      setCoinSort(filterData)
    }

    useEffect(() => {
      getData()
    
      return () => {
        setCoinSort()
      }
    }, [])
    

    const toast = useToast()

    const toastAlert = () => {
        toast({
            title: 'Alert !',
            description: 'Oopss.. Only member allowed !',
            position: 'top-right',
            status: 'warning',
            duration: 3000,
            isClosable: true
        })
    }

    const [titleTable, setTitleTable] = useState([
      { title: "Name", filter: false },
      { title: "Price", filter: false },
      { title: "1 hour%", filter: false },
      { title: "1 day%", filter: false },
      { title: "1 week%", filter: false },
      { title: "1 month%", filter: false },
      { title: "Volume (24h)", filter: false },
      { title: "Volume Change (24h)", filter: false },
      { title: "Last 7 days", filter: false },
    ]);



    const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 0:
            if (titleTable[index].filter){
              return setCoinSort([
                ...coinSort.sort((a, b) => b.name.localeCompare(a.name)),
              ]);
            }
            return setCoinSort([
              ...coinSort.sort((a, b) => a.name.localeCompare(b.name)),
            ]);
  
          case 1:
            if (titleTable[index].filter) {
              return setCoinSort([
                ...coinSort.sort((a, b) => b.price - a.price),
              ]);
            }
            return setCoinSort([
              ...coinSort.sort((a, b) => a.price - b.price),
            ]);
          
           case 2:
            if (titleTable[index].filter) {
              return setCoinSort([
                ...coinSort.sort((a, b) => b.percentChange1h - a.percentChange1h),
              ]);
            }
            return setCoinSort([
              ...coinSort.sort((a, b) => a.percentChange1h - b.percentChange1h),
            ]);  
  
            case 3:
              if (titleTable[index].filter) {
                return setCoinSort([
                  ...coinSort.sort((a, b) => b.percentChange24h - a.percentChange24h),
                ]);
              }
              return setCoinSort([
                ...coinSort.sort((a, b) => a.percentChange24h - b.percentChange24h),
              ]);  
  
              case 4:
                if (titleTable[index].filter) {
                  return setCoinSort([
                    ...coinSort.sort((a, b) => b.percentChange7d - a.percentChange7d),
                  ]);
                }
                return setCoinSort([
                  ...coinSort.sort((a, b) => a.percentChange7d - b.percentChange7d),
                ]); 
                
                case 5:
              if (titleTable[index].filter) {
                return setCoinSort([
                  ...coinSort.sort((a, b) => b.percentChange30d - a.percentChange30d),
                ]);
              }
              return setCoinSort([
                ...coinSort.sort((a, b) => a.percentChange30d - b.percentChange30d),
              ]); 
  
              case 6:
                if (titleTable[index].filter) {
                  return setCoinSort([
                    ...coinSort.sort((a, b) => b.volume24h - a.volume24h),
                  ]);
                }
                return setCoinSort([
                  ...coinSort.sort((a, b) => a.volume24h - b.volume24h),
                ]); 
            
            case 7:
                if (titleTable[index].filter) {
                    return setCoinSort([
                    ...coinSort.sort((a, b) => b.circulatingSupply - a.circulatingSupply),
                    ]);
                }
                return setCoinSort([
                    ...coinSort.sort((a, b) => a.circulatingSupply - b.circulatingSupply),
                ]); 
          default:
            return null
        }
      }

  return (
    <TableContainer>
		<Table>
			<Thead>
			<Tr>
        {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => role === "user" ? (
                toastAlert()
            ) : (
                sortBy(x.title)
            )}
                   >
           <HStack spacing="1">
             <Text color={colors.theme}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.theme}/>
           </HStack>
         </Button>
           </Th>
        ))}
      </Tr>
			</Thead>
			<Tbody>
				{coinSort?coinSort.map((x)=>
					<Tr key={x.Id}>
					<Td>
						<Link to={role === "user" ? (`/market/crypto/NoPayment`) : (`/market/crypto/${x.symbol}`)}>
							<HStack>
							<Image width='50px' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x.id}.png`}/>
							<Box p='1'>
							<Text fontSize='2xl'>{x.symbol}</Text>
							<Text fontSize='xs'>{x.name}</Text>
								</Box>
							</HStack>
						</Link>
					</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{dollar(role === "user" ? (Math.random() < 0.5 ? -100000 : 100000) : (x.price) ) }</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.percentChange1h))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.percentChange24h))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.percentChange7d))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{UpDownHelper(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.percentChange30d))}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>${NumberAcronym(role === "user" ? (Math.random() < 0.5 ? -100000000000 : 100000000000) : (x.volume24h)) }</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}>{NumberAcronym(role === "user" ? (Math.random() < 0.5 ? -100 : 100) : (x.circulatingSupply))} {x.symbol}</Td>
					<Td style={role === "user" ? ({ filter:  "blur(5px)" }) : ({ filter:"none" })}><Image width='200px' src={`https://images.cryptocompare.com/sparkchart/${x.symbol}/USD/latest.png?ts=${ts}`}/></Td>
					</Tr>
				):
				<>
				<TableSkeleton/>
				</>}
			
			
			</Tbody>
			
		</Table>
	</TableContainer>
  )
}

export default TableCryptoMarket