import { Box, Button, Center, Heading, HStack, Input, Select, Spinner, Text, useToast } from '@chakra-ui/react'
import { addDoc, collection } from 'firebase/firestore'
import moment from 'moment'
import React, { useState } from 'react'
import { db } from '../../Configs/firebase'
import { uploadFile } from '../../Utils/FirebaseStorage'

function AddDataEvent({speaker, getData, setAdd, handleCancel}) {

    const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [start, setStart] = useState()
	const [end, setEnd] = useState()
	const [location, setLocation] = useState("")
	const [price, setPrice] = useState(0)
	const [speakers, setSpeakers] = useState([])
	const [image, setImage] = useState("")
	const [tags, setTags] = useState("")

    const [loading, setLoading] = useState(false)

    const toast = useToast()

    const dataSpeaker = speaker
    console.log(dataSpeaker, 'ini data speaker')

    const handleNewData=async()=>{
		// console.log(addData,'in handle new data')
		const addData = {
			title: title,
			description: description,
			start: moment(start).valueOf(), 
			end: moment(end).valueOf(), 
			location: location,
			price: price,
			speakers: speakers,
			image: image,
			tags: tags
		}
		console.log(addData, 'ini data')
		//handle image upload first
		if(addData.title===undefined){
			setLoading(true)
		toast({
			title: 'Data is incomplete',
			description: "Please make sure to complete your datas",
			status: 'error',
			duration: 9000,
			isClosable: true,
		  })
		  setLoading(false)
		  return
		}
		try {
			setLoading(true)
			if(addData.tags){
				let text = (addData.tags)
				const lowerText = text.toLowerCase();
				addData.tags = lowerText.split(",");
			}
			const imageResult = await uploadFile(addData.title, "events", addData["image"]);
			addData.image = imageResult.image_original;
        	addData.thumbnail = imageResult.image_thumbnail;

			const docRef = await addDoc(collection(db, "events"), addData);
			console.log("Document written with ID: ", docRef.id);
			setLoading(false)
			getData()
			setAdd()
			setTitle("")
			setDescription("")
			setStart()
			setEnd()
			setLocation("")
			setPrice(0)
			setSpeakers([])
			setImage("")
			setTags("")
		} catch (error) {
			console.log(error.message)
			toast({
				title: 'Error in saving data',
				description: error.message,
				status: 'error',
				duration: 9000,
				isClosable: true,
			  })
		}
		setLoading(false)
		
	}


  return (
    <Center>
			<Box width='3xl'>
			<Heading>Add New Event</Heading>
			
			<Input m='1' id='title' type='text' placeholder='Title' 
			onChange={(e)=>setTitle(e.target.value)}
			/>

			<Input m='1' id='description' type='text' placeholder='Description' 
			onChange={(e)=>setDescription(e.target.value)}
			/>
			
			<Text fontWeight="bold" fontSize="md">Time Start</Text>
			<Input m='1' id='start' type='datetime-local' placeholder='Description' 
			onChange={(e)=>setStart(e.target.value)}
			/>
			
			<Text fontWeight="bold" fontSize="md">Time End</Text>
			<Input m='1' id='end' type='datetime-local' placeholder='Description' 
			onChange={(e)=>setEnd(e.target.value)}
			/>

			<Input m='1' id='location' type='text' placeholder='Location' 
			onChange={(e)=>setLocation(e.target.value)}
			/>

			<Input m='1' id='price' type='number' placeholder='Price' 
			onChange={(e)=>setPrice(e.target.value)}
			/>

			<Select m='1' placeholder='Speakers' id='speakers' onChange={(e)=>setSpeakers(e.target.value)}>
			{dataSpeaker?
			dataSpeaker.map((x)=><option value={x.id}>{x.name}</option>)
			:
			<></>}
			</Select>

			<Text fontWeight="bold" fontSize="md">Image</Text>
			<Input m='1' id='image' type='file' placeholder='Image URL' 
			onChange={(e)=>setImage(e.target.files[0])}
			/>
			
			<Input m='1' id='tags' type='text' placeholder='Tags(ex seminar, foodpreneur, business)' 
			onChange={(e)=>setTags(e.target.value)}
			/>
            {loading? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
				<HStack spacing="3" m={3} align="center">
                <Button bgColor='green' onClick={()=>handleNewData()}>Save</Button>
                <Button bgColor='red' onClick={handleCancel}>Cancel</Button>
				</HStack>
            )}
		
			
			</Box>
			</Center>
  )
}

export default AddDataEvent