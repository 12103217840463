import { Box, Button, Table, Thead, Tbody, Tfoot, IconButton, Tr, Th, HStack, Td, Text, TableCaption, Select, TableContainer, useToast, Input, Spacer, Heading, Center, Divider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, SimpleGrid, VStack, Image, Tag } from "@chakra-ui/react";
import React, { useRef } from "react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { db } from "../../../Configs/firebase";
import { doc, deleteDoc, setDoc, getDoc, collection, addDoc, getDocs } from "firebase/firestore";
import * as tus from "tus-js-client";
import { uploadFile } from "../../../Utils/FirebaseStorage";
import { useEffect } from "react";
import colors from "../../../Utils/colors";
import EditDataContent from "../../AppComponents/EditDataContent";
import TableContent from "./TableAdmin/TableContent";

function AppBarContent() {
  const [add, setAdd] = useState(false);
  const [data, setData] = useState();
  const [addData, setAddData] = useState({});
  const [addFile, setAddFile] = useState();
  const [speakers, setSpeakers] = useState();
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState();

  const toast = useToast();

  const ACCOUNT_ID = process.env.REACT_APP_CLOUDFLARE_ACCOUNT;
  const API_KEY = process.env.REACT_APP_CLOUDFLARE_TOKEN;
  const bearer = `Bearer ${API_KEY}`;

  const handleAddData = (key, value) => {
    let newObject = addData;
    newObject[key] = value;
    setAddData(newObject);
    console.log(newObject);
  };

  const AddComponent = () => {
    return (
      <Center>
        <Box width="3xl">
          <Heading color={colors.white}>Add new Content</Heading>
          <Input color={colors.white} m="1" id="title" type="text" placeholder="Title" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
          <Input color={colors.white} m="1" id="description" type="text" placeholder="Description" onChange={(e) => handleAddData(e.target.id, e.target.value)} />
          <Select color={colors.white} m="1" placeholder="Speakers" id="speakers" onChange={(e) => handleAddData(e.target.id, e.target.value)}>
            {speakers ? speakers.map((x) => <option value={x.id}>{x.name}</option>) : <></>}
          </Select>
          <Select color={colors.white} m="1" placeholder="Type" id="type" onChange={(e) => handleAddData(e.target.id, e.target.value)}>
            <option value="series">Series</option>
            <option value="full">Full</option>
          </Select>

          <Text color="red" fontWeigh="red.300" fontSize="sm" fontWeight="bold" >* Image (JPG 16:9)</Text>
          <Input m="1" id="image" type="file" placeholder="Image URL" onChange={(e) => handleAddData(e.target.id, e.target.files[0])} />
          <Text color="red" fontWeigh="red.300" fontSize="sm" fontWeight="bold"> * Video</Text>
          <Input m="1" id="video" type="file" placeholder="Image URL" onChange={(e) => handleAddData(e.target.id, e.target.files)} />
          <Input color={colors.white} m="1" id="menit" type="text" placeholder="Menit" onChange={(e) => handleAddData(e.target.id, e.target.value)} />

          <Input color={colors.white} m="1" id="tags" type="text" placeholder="Tags(ex seminar, foodpreneur, business)" onChange={(e) => handleAddData(e.target.id, e.target.value)} />

          {loading ? (
            <>
              <Text color={colors.white}>Uploading Video {uploadPercent}</Text>
              <Button isLoading colorScheme="teal" variant="solid" />
            </>
          ) : (
            <HStack>
            <Button bgColor="green" onClick={() => uploadVideo()}>
              Save
            </Button>
            <Button bgColor="red" onClick={() => setAdd(false)}>
            Cancel
          </Button>
          </HStack>
          )}
        </Box>
      </Center>
    );
  };

  const getData = async () => {
    const querySnapshot = await getDocs(collection(db, "content"));
    let newArr = [];
    querySnapshot.forEach((doc) => {
      let newData = doc.data();
      newData.id = doc.id;
      newArr.push(newData);
    });
    setData(newArr);
  };

  const getSpeakers = async () => {
    const querySnapshot = await getDocs(collection(db, "speakers"));
    let newArr = [];
    querySnapshot.forEach((doc) => {
      let newData = doc.data();
      newData.id = doc.id;
      newArr.push(newData);
    });
    setSpeakers(newArr);
    console.log(newArr, 'ini data speaker')
  };

  const handleDelete = async (id) => {
	  try {
		const result = await deleteDoc(doc(db, "content", id));
		// const deleteVideo = await axios.delete(
		// 	`https://api.cloudflare.com/client/v4/accounts/${ACCOUNT_ID}/stream/${id}`,	
		// 	{
		// 		headers: {
		// 		"X-Auth-Email": "faizal.edrus@gmail.com",
		// 		"X-Auth-Key": ",
		// 		"Content-Type": "application/json"
		// 			}
		// 	}
		// 	)
		
		// if(result && deleteVideo)
		toast({
		  title: "Data Deleted",
		  description: "",
		  status: "success",
		  duration: 9000,
		  isClosable: true,
		});
		getData();
		  
	  } catch (error) {
		toast({
			title: "Error",
			description: error.message,
			status: "error",
			duration: 9000,
			isClosable: true,
		  });
		  getData();
	  }
   
  };

  const EditDataComponent = async (id) => {
    console.log(id)
    const docRef = doc(db, "content", id);
    const docSnap = await getDoc(docRef);
    let data = {};
    if (docSnap.exists()) {
      data = docSnap.data();
      data.id = id;
      setEditData(data);
      console.log(data);
    } else {
      console.log("No such document!");
    }
  };


  const uploadVideo = async () => {
    console.log(addData.video, "this is data videl");
    const options = {
      endpoint: `https://api.cloudflare.com/client/v4/accounts/${ACCOUNT_ID}/stream`,
      headers: {
        Authorization: bearer,
      },
      chunkSize: 50 * 1024 * 1024, // Required a minimum chunk size of 5MB, here we use 50MB.
      // resume: true,
      metadata: {
        name: addData.video[0].name,
        filetype: addData.video[0].type,
        defaulttimestamppct: 0.5,
      },
      uploadSize: addData.video[0].size,
      onError: function (error) {
        throw error;
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        //   console.log(bytesUploaded, bytesTotal, percentage + '%');
        setUploadPercent(`${percentage}%`);
      },
      onSuccess: function () {
        console.log("Upload finished");
        handleNewData();
      },
      onAfterResponse: function (req, res) {
        return new Promise((resolve) => {
          var mediaIdHeader = res.getHeader("stream-media-id");
          let mediaId = "";
          if (mediaIdHeader) {
            mediaId = mediaIdHeader;
            console.log(mediaId, "ini ID dari video yang di upload");
            handleAddData("video", mediaId);
          }
          resolve();
        });
      },
    };
    console.log("in tus video");
    setLoading(true);
    var upload = new tus.Upload(addData.video[0], options);
    upload.start();
    console.log("tus start");
  };

  const handleNewData = async () => {
    console.log(addData, "in handle new data");
    //handle image upload first
    if (addData.title === undefined) {
      toast({
        title: "Data is incomplete",
        description: "Please make sure to complete your datas",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    try {
      // await uploadFile(addData.name,'content',addFile[0])
      if (addData.tags) {
        let text = addData.tags;
        const lowerText = text.toLowerCase();
        addData.tags = lowerText.split(",");
      }

      const imageResult = await uploadFile(addData.title, "content", addData["image"]);
      addData.image = imageResult.image_original;
      addData.thumbnail = imageResult.image_thumbnail;

      const docRef = await addDoc(collection(db, "content"), addData);
      console.log("Document written with ID: ", docRef.id);
      setAddData({});
      setLoading(false);
      setUploadPercent();
      getData();
      setAdd();
    } catch (error) {
      console.log(error.message);
      toast({
        title: "Erro in saving data",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
    getSpeakers();
  }, []);

  return (
    <>
      <Box p="5" >
        <Box>
          {add ? <AddComponent /> : <></>}
          {editData !== null && <EditDataContent editData={editData} setEditData={setEditData}/>}
          <Divider m="5" />
          {data && (
             <TableContent data={data} EditDataComponent={EditDataComponent} handleDelete={handleDelete} handleAdd={() => setAdd(true)}/>
          )}
        </Box>
      </Box>
    </>
  );
}

export default AppBarContent;
