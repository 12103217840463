import React from "react";
import Footer from "../Components/AppBars/Footer";
import AppBodyMain from "../Components/AppBars/AppBodyMain";
import AppVideoBackground from "../Components/AppComponents/AppVideoBackground/AppVideoBackground";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";
import imagemcts from "../Img/tradingsyariahid1.JPG"
import imagedean from "../Img/2.png"
import getDomain from "../Utils/getDomain";

function MasterClassPage() {

  return (
    <>
      <HeaderResponsive />
      {/* <Header /> */}
      {getDomain() === "tradingsyariahid" ? (
         <AppVideoBackground
         tag="New"
         title="1-5% / hari dari saham-saham syariah"
         contentA="Azmi Ryan"
         id="azmi"
         image={imagemcts}
       />
      ) : (
        <AppVideoBackground
        tag="New"
        title=""
        contentA="Dean"
        id="dean"
        image={imagedean}
      />
      )}
        <AppBodyMain/>
      <Footer />
    </>
  );
}

export default MasterClassPage;
