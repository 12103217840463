import React from 'react'
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,Text,
	TableCaption,Button,
	TableContainer,
	Box,
	Image,
	HStack,Heading, SimpleGrid, Avatar, Spacer, Center,
  } from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import CryptoMarket from './markets/CryptoMarket'
import ForexMarket from './markets/ForexMarket'
// import { getDataPasarDana } from '../../api/indodana'
import StocksIDMarket from './markets/StocksIDMarket'
import MarketUnderConstruction from './markets/ForexMarket'
import getDomain from '../../Utils/getDomain'
import TagManager from 'react-gtm-module'
import colors from '../../Utils/colors'

function AppBarMarket() {


const param=useParams();
const navigate=useNavigate();

const getPasarDana = async ()=>{
	console.log(' di get pasar dana')
	// const dataPasarDana = await getDataPasarDana();
	// console.log(dataPasarDana,'ini data pasar dana')
}


useEffect(() => {
  getPasarDana()
}, [])


  return (
	  <>
	 
	  	<Center width='full'bgColor='black'> 
		{getDomain() === "tradingsyariahid" ? (
			<SimpleGrid columns={[1]}>
		  {/* <Button m='2'  bgColor={param.type==="green"? colors.theme : "green"} color={colors.light} onClick={()=>navigate('/market/stockid')}>Stock ID</Button> */}
	  
		</SimpleGrid>
		) : (
			<SimpleGrid columns={[1, null, 3]}>
		  	<Button m='2' color={param.type==="all" ||param.type===undefined ?"yellow":"red"}  onClick={()=>navigate('/market/all')}>All Market</Button>
			<Button m='2' color={param.type==="cryptocurrencies"?"yellow":"red"}  onClick={()=>navigate('/market/cryptocurrencies')}>Cryptocurrencies</Button>
			{/* <Button m='2' colorScheme={param.type==="futures"?"yellow":"red"} onClick={()=>navigate('/market/futures')}>Futures</Button> */}
			<Button m='2' color={param.type==="stockid"?"yellow":"red"}  onClick={()=>navigate('/market/stockid')}>Stock ID</Button>
			{/* <Button m='2' colorScheme={param.type==="stockus"?"yellow":"red"} onClick={()=>navigate('/market/stockus')}>Stock US</Button> */}
			{/* <Button m='2' colorScheme={param.type==="forex"?"yellow":"red"} onClick={()=>navigate('/market/forex')}>Forex</Button> */}
		
		  </SimpleGrid>
		)}
		</Center>
		
		{param.type==="all"?<MarketUnderConstruction/>:<></>}
		{param.type==="cryptocurrencies"?<CryptoMarket/>:<></>}
		{param.type==="futures"?<MarketUnderConstruction/>:<></>}
		{param.type==="stockid"?<StocksIDMarket/>:<></>}
		{param.type==="stockus"?<MarketUnderConstruction/>:<></>}
		{param.type==="forex"?<MarketUnderConstruction/>:<></>}
	  
	  </>
  )
}

export default AppBarMarket