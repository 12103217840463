import React, { useContext } from 'react'
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td, Text,
	TableContainer, Tooltip,
	Box,
	Image,
	HStack, Heading, SimpleGrid, Avatar, Spacer, Button, Select, Spinner, Center,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { InfoOutlineIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { dollar, FloatRoundUp, NumberAcronym } from '../../../Utils/NumberUtils'
import colors from '../../../Utils/colors'
import TableSkeleton from './skeletons/TableSkeleton'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { database, db } from '../../../Configs/firebase'
import moment from 'moment'
import AuthContext from '../../../Routes/hooks/AuthContext'
import TableCryptoMarket from './TableMarket/TableCryptoMarket'
import usdDecimalHelper from '../../../Utils/usdDecimalHelper'
import { limitToLast, ref, onValue, query } from "firebase/database";
import UpDownHelper from '../../../Utils/UpDownHelper'


function CryptoMarket() {
	const [data, setData] = useState()
	const [trendingData, setTrendingData] = useState()
	const [trendingDataSecond, setTrendingDataSecond] = useState()
	const [topGain, setTopGain] = useState()
	const [topGainSecond, setTopGainSecond] = useState()
	const [topLose, setTopLose] = useState()
	const [topLoseSecond, setTopLoseSecond] = useState()
	const [filterVolume, setFilterVolume] = useState("24hr")

	const [dataCrypto, setDataCrypto] = useState()

	const [filterGainer, setFilterGainer] = useState("24hr")
	const ts = moment().unix()

	const trendingDataSecondDummy = [
		{ id: "1", symbol: "NoPayment", volume: 100000, price: 1.88888 },
		{ id: "2", symbol: "NoPayment", volume: 100000, price: 1.88888 },
		{ id: "3", symbol: "NoPayment", volume: 100000, price: 1.88888 },
		{ id: "4", symbol: "NoPayment", volume: 100000, price: 1.88888 },
	]

	const topGainSecondDummy = [
		{ id: "1", symbol: "NoPayment", volume: 100000, price: 1.88888 },
		{ id: "2", symbol: "NoPayment", volume: 100000, price: 1.88888 },
		{ id: "3", symbol: "NoPayment", volume: 100000, price: 1.88888 },
		{ id: "4", symbol: "NoPayment", volume: 100000, price: 1.88888 },
	]


	const topLoseSecondDummy = [
		{ id: "1", symbol: "NoPayment", volume: -100000, price: 1.88888 },
		{ id: "2", symbol: "NoPayment", volume: -100000, price: 1.88888 },
		{ id: "3", symbol: "NoPayment", volume: -100000, price: 1.88888 },
		{ id: "4", symbol: "NoPayment", volume: -100000, price: 1.88888 },
	]


	const { getUserStorage, coinCrypto } = useContext(AuthContext)

	const subscription = getUserStorage()
	const role = subscription.role


	const getData = () => {
		try {
			const data = coinCrypto
			setDataCrypto(data)
		} catch (error) {
			console.log(error)
		}
	}



	const getDataTrending = () => {
		try {
			const unsub = onSnapshot(doc(db, "market", "trending"), (doc) => {
				const dataTrending = (doc.data().data)

				const filteredVolume = dataTrending.filter(x => x?.priceChange?.volume24h > 150000000)
				//   const sortFilteered = filteredVolume.sort((a,b)=>FloatRoundUp(filterVolume === "24hr" ? (b.volume_change_24h):(b.percent_change_1h)) - FloatRoundUp(filterVolume === "24hr" ? (a.volume_change_24h):(a.percent_change_1h)))
				const sortFilter = filteredVolume.sort((a, b) => ((b.volume24h)) - ((a.volume24h)))
				setTrendingData(
					{
						data:
							[sortFilter[0]]
					})
				setTrendingDataSecond({
					data:
						[sortFilter[1], sortFilter[2], sortFilter[3], sortFilter[4]]
				})
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getDataGainer = () => {
		try {
			const unsub = onSnapshot(doc(db, "market", "gainer"), (doc) => {
				const dataGainer = (doc.data().data)

				// const filteredVolume = dataGainer.filter(x => x?.priceChange?.volume24h > 150000000)
				//   const sortFilteered = filteredVolume.sort((a,b)=>FloatRoundUp(filterVolume === "24hr" ? (b.volume_change_24h):(b.percent_change_1h)) - FloatRoundUp(filterVolume === "24hr" ? (a.volume_change_24h):(a.percent_change_1h)))
				const topGainer = dataGainer.sort((a, b) => ((filterGainer === "24hr" ? (b?.priceChange?.priceChange24h) : (b?.priceChange?.priceChange1h)) - (filterGainer === "24hr" ? (a?.priceChange?.priceChange24h) : (a?.priceChange?.priceChange1h)))
				)
				setTopGain(
					{
						data:
							[topGainer[0]]
					})
				setTopGainSecond({
					data:
						[topGainer[1], topGainer[2], topGainer[3], topGainer[4]]
				})
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getDataLoser = () => {
		try {
			const unsub = onSnapshot(doc(db, "market", "loser"), (doc) => {
				const dataLoser = (doc.data().data)

				// const filteredVolume = dataLoser.filter(x => filterGainer === "24h" ? (x?.priceChange?.priceChange24h ) : (x?.priceChange?.priceChange1h ) > 0)
				//   const sortFilteered = filteredVolume.sort((a,b)=>FloatRoundUp(filterVolume === "24hr" ? (b.volume_change_24h):(b.percent_change_1h)) - FloatRoundUp(filterVolume === "24hr" ? (a.volume_change_24h):(a.percent_change_1h)))
				const topLoser = dataLoser.sort((a, b) => ((filterGainer === "24hr" ? (a?.priceChange?.priceChange24h) : (a?.priceChange?.priceChange1h)) - (filterGainer === "24hr" ? (b?.priceChange?.priceChange24h) : (b?.priceChange?.priceChange1h)))
				)
				setTopLose(
				{
					data:
						[topLoser[0]]
				})
			setTopLoseSecond({
				data:
					[topLoser[1], topLoser[2], topLoser[3], topLoser[4]]
			})
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getDataTrending()
		getDataGainer()
		getDataLoser()

		return () => {
		}
	}, [filterGainer])

	useEffect(() => {
		getData()
	
	  return () => {
	  }
	}, [])
	



	return (
		<Box p='25' height='full' bgColor='black' color='white'>
			<SimpleGrid columns={[1, null, 3]}  p='3' gap='2' >
				<Box bgColor='gray.900' p='5' mr='5' borderRadius='3xl'>
					<HStack m={3}>
						<HStack>
							<Heading fontSize='larger'>Trending Volume(24h)</Heading>
							<Tooltip label='Volume>$150m' fontSize='md'>
								<InfoOutlineIcon />
							</Tooltip>
						</HStack>
						<Spacer />
						<Box>
							<Select placeholder='Sort by' size="sm" onChange={(e) => setFilterVolume(e.target.value)}>
								<option value='1hr'>1 hr</option>
								<option value='24hr'>24 hr</option>
							</Select>
						</Box>
					</HStack>
					{trendingData ? (
						<>
							{trendingData ?
						trendingData.data.map((x) => <>
							<Link to={`/market/crypto/${x?.symbol}`}>

								<HStack p='1'>
									<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
									<Text>{x?.symbol}</Text>
									<Spacer />
									<Box>
										<HStack justifyContent="flex-end">
											<Text >{UpDownHelper(x?.priceChange?.priceChange24h)}</Text>
										</HStack>
										<Text textAlign='end'>${NumberAcronym(x?.priceChange?.volume24h)}</Text>
									</Box>
								</HStack>
							</Link>

						</>)
						:
						<></>
					}

					{role === "user" ? (
						trendingDataSecondDummy &&
						trendingDataSecondDummy.map((x) => <>
							<Link to={`/market/crypto/${x?.symbol}`} >

								<HStack p='1' style={role === "user" ? ({ filter: "blur(5px)" }) : ({ filter: "none" })}>
									<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
									<Text>{x?.symbol}</Text>
									<Spacer />
									<Box>
										<HStack justifyContent="flex-end">
											<Text  >{UpDownHelper(x.volume)}</Text>
										</HStack>
										<Text textAlign='end'>${NumberAcronym(x.price)}</Text>
									</Box>
								</HStack>
							</Link>

						</>)
					) : (
						trendingDataSecond &&
						trendingDataSecond.data.map((x) => <>
							<Link to={`/market/crypto/${x?.symbol}`} >

								<HStack p='1' style={role === "user" ? ({ filter: "blur(5px)" }) : ({ filter: "none" })}>
									<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
									<Text>{x?.symbol}</Text>
									<Spacer />
									<Box>
										<HStack justifyContent="flex-end">
											<Text  >{UpDownHelper(x?.priceChange?.priceChange24h)}</Text>
										</HStack>
										<Text textAlign='end'>${NumberAcronym(x?.priceChange?.volume24h)}</Text>
									</Box>
								</HStack>
							</Link>

						</>)
					)}
						</>
					) : (
						<Center>
							<Heading>
								<Spinner />
							</Heading>
						</Center>
					)}
					



				</Box>
				<Box bgColor='gray.900' p='5' borderRadius='3xl'>
					<HStack m={3}>
						<HStack>
							<Heading fontSize='larger'>Top Gainer ({filterGainer})</Heading>
							<Tooltip label='by price change' fontSize='md'>
								<InfoOutlineIcon />
							</Tooltip>
						</HStack>
						<Spacer />
						<Box>
							<Select placeholder='Sort by' size="sm" onChange={(e) => setFilterGainer(e.target.value)}>
								<option value='1hr'>1 hr</option>
								<option value='24hr'>24 hr</option>
							</Select>
						</Box>
					</HStack>
					{topGain ? (
						<>
						<Box>
							{topGain ? topGain.data.map((x) =>
								<>
									<Link to={`/market/crypto/${x?.symbol}`}>
										<HStack p='1'>
											<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
											<Text>{x?.symbol}</Text>
											<Spacer />
											<Box>
												<HStack justifyContent="flex-end">
													<Text >{UpDownHelper(filterGainer === "24hr" ? (x?.priceChange?.priceChange24h) : (x?.priceChange?.priceChange1h))}</Text>
												</HStack>
												<Text textAlign='end'>{dollar(x?.priceChange?.price)}</Text>
											</Box>
										</HStack>
									</Link>
	
								</>
							)
								: <></>
							}
						</Box>
	
						{role === "user" ? (
							<Box>
								{topGainSecondDummy ? topGainSecondDummy.map((x) =>
									<>
										<Link to={`/market/crypto/${x.symbol}`}>
											<HStack p='1' style={role === "user" ? ({ filter: "blur(5px)" }) : ({ filter: "none" })}>
												<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
												<Text>{x.symbol}</Text>
												<Spacer />
												<Box>
													<HStack justifyContent="flex-end">
														<Text >{UpDownHelper(x.volume)}</Text>
													</HStack>
													<Text textAlign='end'>{dollar(x.price)}</Text>
												</Box>
											</HStack>
										</Link>
	
									</>
								)
									: <></>
								}
							</Box>
						) : (
							<Box>
								{topGainSecond ? topGainSecond.data.map((x) =>
									<>
										<Link to={`/market/crypto/${x?.symbol}`}>
											<HStack p='1' style={role === "user" ? ({ filter: "blur(5px)" }) : ({ filter: "none" })}>
												<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
												<Text>{x?.symbol}</Text>
												<Spacer />
												<Box>
													<HStack justifyContent="flex-end">
														<Text >{UpDownHelper(filterGainer === "24hr" ? (x?.priceChange?.priceChange24h) : (x?.priceChange?.priceChange1h))}</Text>
													</HStack>
													<Text textAlign='end'>{dollar(x?.priceChange?.price)}</Text>
												</Box>
											</HStack>
										</Link>
	
									</>
								)
									: <></>
								}
							</Box>
						)}
						</>
					) : (
						<Center>
							<Heading>
								<Spinner />
							</Heading>
						</Center>
					)}
					


				</Box>
				<Box bgColor='gray.900' p='5' ml='5' borderRadius='3xl'>

					<HStack m={3}>
						<HStack>
							<Heading fontSize='larger'>Top Losers ({filterGainer})</Heading>
							<Tooltip label='by price change' fontSize='md'>
								<InfoOutlineIcon />
							</Tooltip>
						</HStack>
						<Spacer />
						<Box>
							<Select placeholder='Sort by' size="sm" onChange={(e) => setFilterGainer(e.target.value)}>
								<option value='1hr'>1 hr</option>
								<option value='24hr'>24 hr</option>
							</Select>
						</Box>
					</HStack>
					{topLose ? (
						<>
						{topLose && topLose.data.map((x, index) =>
							<>			<Link to={`/market/crypto/${x?.symbol}`} key={index}>
	
								<HStack p='1'>
									<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
									<Text>{x?.symbol}</Text>
									<Spacer />
									<Box>
										<HStack justifyContent="flex-end">
											<Text>{UpDownHelper(filterGainer === "24hr" ? (x?.priceChange?.priceChange24h) : (x?.priceChange?.priceChange1h))}</Text>
										</HStack>
										<Text textAlign='end'>{dollar(x?.priceChange?.price)}</Text>
									</Box>
								</HStack>
							</Link>
							</>
						)}
						{role === "user" ? (
							topLoseSecondDummy && topLoseSecondDummy.map((x, index) =>
								<>			<Link to={`/market/crypto/${x?.symbol}`} key={index}>
	
									<HStack p='1' style={role === "user" ? ({ filter: "blur(5px)" }) : ({ filter: "none" })}>
										<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
										<Text>{x?.symbol}</Text>
										<Spacer />
										<Box>
											<HStack justifyContent="flex-end">
												<Text color={x.volume > 0 ? 'green' : 'red'} >{UpDownHelper(x.volume)}</Text>
											</HStack>
											<Text textAlign='end'>{dollar(x?.priceChange?.price)}</Text>
										</Box>
									</HStack>
								</Link>
								</>
							)
						) : (
							topLoseSecond && topLoseSecond.data.map((x, index) =>
								<>			<Link to={`/market/crypto/${x?.symbol}`} key={index}>
	
									<HStack p='1' style={role === "user" ? ({ filter: "blur(5px)" }) : ({ filter: "none" })}>
										<Avatar size='md' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x?.id}.png`} />
										<Text>{x?.symbol}</Text>
										<Spacer />
										<Box>
											<HStack justifyContent="flex-end">
												<Text>{UpDownHelper(filterGainer === "24hr" ? (x?.priceChange?.priceChange24h) : (x?.priceChange?.priceChange1h))}</Text>
											</HStack>
											<Text textAlign='end'>{dollar(x?.priceChange?.price)}</Text>
										</Box>
									</HStack>
								</Link>
								</>
							)
						)}
						</>
					) : (
						<Center>
							<Heading>
								<Spinner />
							</Heading>
						</Center>
					)}
				</Box>
			</SimpleGrid>
			{dataCrypto !== undefined && (
				<TableCryptoMarket data={dataCrypto} role={role} />
			)}
		</Box>
	)
}

export default CryptoMarket