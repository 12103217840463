import { getMetadata } from 'firebase/storage';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../Configs/firebase';
import { doc, setDoc,getDocs,query,where,collection, updateDoc, arrayUnion } from "firebase/firestore";
import Tap from '@tapfiliate/tapfiliate-js';
import { Button, Center, Text, Flex, Heading, VStack } from '@chakra-ui/react';
import store from "store"
import { BsFillTelephoneFill } from "react-icons/bs";

import ApiEncrypt from '../../Utils/Appgregator';
import axios from 'axios';
import AuthContext from '../../Routes/hooks/AuthContext';
import HeaderResponsive from '../../Components/AppBars/HeaderResponsive';
import Footer from '../../Components/AppBars/Footer';
import getDomain from '../../Utils/getDomain';

function PaymentCallbackGlobal() {
	const param = useParams();
	const status = param.status;
	const id = param.id;
	const date = new Date();
	const [statusPayment, setStatusPayment] = useState("")

	const link = getDomain()

	const navigation = useNavigate();

	const { currentUser, signOut } = useContext(AuthContext);


	const getDataExist = () => {
		const apikey = ApiEncrypt(process.env.REACT_APP_APPGREGATOR_API, process.env.REACT_APP_APPGREGATOR_SECRET);
		console.log(apikey, 'this api key')
		const config = {
		  method: "get",
		  url: link === "algotradingid" ? ( `https://us-central1-appgregator.cloudfunctions.net/xendit/find?external_id=${id}&name=Algotrading&connection_name=production_payment`) : ( `https://us-central1-appgregator.cloudfunctions.net/xendit/find?external_id=${id}&name=Algotrading&connection_name=pembayaran_tsy`),
		  headers: {
			"x-api-key": apikey,
		  },
		};
		console.log(apikey, 'api')
	
		axios(config)
		  .then(function (response) {
			console.log(JSON.stringify(response.data));
			console.log(response.data[0].status, 'ini status')
			if (response.data[0].status === "SETTLED" || response.data[0].status === "PAID") {
			  getData();
			  setStatusPayment(response.data[0].status)
			}
			if(response.data[0].status === "PENDING"){
				setStatusPayment(response.data[0].status)
			}else{
				setStatusPayment(response.data[0].status)
			}
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  };
	
	  const getData = async () => {
		const citiesRef = collection(db, "payments");
		const q = query(citiesRef, where("external_id", "==", id));
		const querySnapshot = await getDocs(q);
		let newArr = [];
		querySnapshot.forEach((doc) => {
		  console.log(doc.id, " => ", doc.data());
		  let newData = doc.data();
		  newData.id = doc.id;
		  newArr.push(newData);
		});
		
		console.log(newArr[0].id, 'ini update')
		const ref = doc(db, "payments", newArr[0].id);
		await setDoc(ref, { status: status }, { merge: true });
	
		// if (newArr[0].customer.surname === "basic" && status === "success") {
		//   console.log("masuk basic");
		//   const refUser = doc(db, "users", currentUser.uid);
		//   await updateDoc(refUser, { subscription: newArr[0].customer.surname, expired_user: (date.getTime() / 1000 + 2630000) * 1000 }, { merge: true });
		// }
		
		// if (newArr[0].customer.surname === "member" && status === "success") {
		//   console.log("masuk member");
		//   const refUser = doc(db, "users", currentUser.uid);
		//   await updateDoc(refUser, { role: newArr[0].customer.surname, expired_user: (date.getTime() / 1000 + 31536000) * 1000 }, { merge: true });
		// }
		if (newArr[0].customer.surname === "seminar" && status === "success") {
			console.log("masuk seminar");
			console.log(id.slice(12), 'ini id')
			console.log(newArr, 'ini newArr')
			const refUser = doc(db, "events", id.slice(12));
			await updateDoc(refUser, { peserta: arrayUnion({ name: newArr[0].customer.email, numberPhone: newArr[0].customer.mobile_number}) });
		  }

		  if (newArr[0].customer.surname === "tradingplan" && status === "success") {
			const refUser = doc(db, "tradingplan", id.slice(16));
			await updateDoc(refUser, { member: arrayUnion({ name: newArr[0].customer.email, numberPhone: newArr[0].customer.mobile_number}) });
		  }

		  			// affiliate payment
		// console.log(`${id}`, newArr[0].amount, {customer_id: newArr[0].id }, 'ini affiliate')
		Tap.conversion(`${id}`, newArr[0].amount, {customer_id: `${newArr[0].id}` });
	  };

	  const handleLogOut = () => {
		signOut()
		  .then(() => {
			navigation("/", { replace: true });
			store.clearAll()
		  })
		  .catch((error) => {
			console.log(error)
		  });
	  };

	  useEffect(() => {
		getDataExist();
	
		return () => {};
	  }, []);
	

	
  return (
	<>
	<HeaderResponsive/>
	<Center h="80vh"> 
	<VStack>
		{console.log(statusPayment)}
	<Heading>Thank you, your status payment is {statusPayment}</Heading>
	{link === "algotradingid" ? (
                     <a href={`https://wa.me/088971055864?text=Halo`}>
                     <Flex flexDirection="row" alignItems="center" justifyContent="center" >
                       <BsFillTelephoneFill /> 
                       <Text ml={3}>+62 889-7105-5864</Text>
                     </Flex>
                     </a>
                  ) : (
                    <a href={`https://wa.me/087714757944?text=Halo`}>
                    <Flex flexDirection="row" alignItems="center" justifyContent="center" >
                      <BsFillTelephoneFill /> 
                      <Text ml={3}>+62 877-1475-7944</Text>
                    </Flex>
                    </a>
                  )}
	<Button onClick={() => handleLogOut()}>Back</Button>
	</VStack>
	</Center>
	<Footer/ >
	</>

  )
}

export default PaymentCallbackGlobal