import { Avatar, Box, HStack, Spacer, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import colors from '../../../../Utils/colors'
import { NumberAcronym } from '../../../../Utils/NumberUtils'
import UpDownHelper from '../../../../Utils/UpDownHelper'

function TableGainerLoserStock({data, title }) {
  const [sortEmiten, setSortEmiten] = useState([])
  
  const getData = () => {
    if(title === "gainer") {
      const dataSort = data.sort((a,b) => b.OneDay - a.OneDay)
      setSortEmiten(dataSort)
    }else{
      const dataSort = data.sort((a,b) => a.OneDay - b.OneDay)
      setSortEmiten(dataSort)
    }
  }
 
  useEffect(() => {
    getData()
  
    return () => {
      setSortEmiten([])
    }
  }, [title])
  



  return (
    <>
    {sortEmiten&&
        sortEmiten?.map((x)=>
        <HStack key={x.Code} p='1'>
        <Link to={`/market/stocks-id/${x.Code}`}>
            <HStack>
            <Avatar height='40px' width='40px' src={`https://firebasestorage.googleapis.com/v0/b/docial-com.appspot.com/o/logos%2Fstocks-id%2F${x.Code}.png?alt=media`}/>
            <Text>{x.Code}</Text>
            </HStack>
        </Link>
        <Spacer/>
        <Text color={colors.green} >{UpDownHelper(x.OneDay*100)}</Text>
    </HStack>
        )
        }
</>
  )
}

export default TableGainerLoserStock