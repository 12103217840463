import { Box, Center, Flex, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import Footer from '../Components/AppBars/Footer'
import Header from '../Components/AppBars/Header'

function PodcastPage() {
  return (
	<>
	<Header/>
	<Center bgColor='black' >
          <SimpleGrid columns={2} spacing="40px" mx="40px" my="30px">
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://i.ytimg.com/vi/29V4yiZg_Tk/maxresdefault.jpg" h="40vh" w="70vh"></Box>
            <Flex border="1px" borderRadius="10px" bgRepeat="no-repeat" 
			bgSize="cover" 
			justifyContent='flex-end'
			flexDirection={"column"}
			
			bgImage="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/masterclass-1652712370.jpg" h="40vh" w="70vh"> 
				<Heading textAlign='end'  color='white'>Kodok</Heading>
			</Flex>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://www.masterclass.com/course-images/attachments/mjecqosnryx67fv0iwpvgghhl5zh?width=412&fit=cover&dpr=2" h="40vh" w="70vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://www.masterclass.com/course-images/attachments/mgb5ipimlu9squk5uq51whyoqleb?width=412&fit=cover&dpr=2" h="40vh" w="70vh"></Box>
          </SimpleGrid>
    </Center>
	<Footer/>
	</>
  )
}

export default PodcastPage