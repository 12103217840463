import { SearchIcon } from '@chakra-ui/icons';
import { Table,
	Thead,
	Tbody,
	Tfoot,IconButton,
	Tr,
	Th,HStack,TableContainer,Image,
	Td} from '@chakra-ui/react';
import { collection, doc, getDocs, limit, orderBy, query } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import AppBarAdminHeader from '../../Components/AppBars/admin/AppBarAdminHeader'
import AppBarAdminPayment from '../../Components/AppBars/admin/AppBarAdminPayment';
import { db } from '../../Configs/firebase';

function PaymentPage() {

  return (
	<>
	<AppBarAdminHeader />
	<AppBarAdminPayment/>
  </>
	)
}

export default PaymentPage