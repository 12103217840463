import { getConfig } from "@testing-library/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DataConfig from "./DataConfig.js";
import {
  makeApiRequest,
  generateSymbol,
  parseFullSymbol,
  makeApiRequestDocial,
  makeApiRequestDocialNew,
} from "./helpers.js";

// import {
// 	subscribeOnStream,
// 	unsubscribeFromStream,
// } from './streaming.js';

const lastBarsCache = new Map();


const findSymbol = () =>{
  const url = window.location.href
  const arrSymbol = url.split('/')
  const fullLink = arrSymbol[arrSymbol.length - 1].split('?')
  const symbol = fullLink[0]
  const query = new URLSearchParams(fullLink[1])
  console.log(query.get('timeframe'), 'quer')
  return {
    symbol,
    query
  }
}

const configurationData = {
  supports_search: true,
  supports_group_request: true,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  supported_resolutions: ["60", "D"],
  // supported_resolutions: ["15", "1", "60", "D"],
  exchanges: [
    {
      value: "IDX",
      name: "IDX",
      desc: "IDX",
    },
    {
      value: "Bitfinex",
      name: "Bitfinex",
      desc: "Bitfinex",
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: "Kraken",

      // filter name
      name: "Kraken",

      // full exchange name displayed in the filter popup
      desc: "Kraken bitcoin exchange",
    },
  ],
  symbols_types: [
    {
      name: "All types",
      value: "",
    },
    {
      name: "Stock",
      value: "stock",
    },
    {
      name: "Crypto",
      value: "crypto",
    },
    {
      name: "Index",
      value: "index",
    },
    // ...
  ],
};

function getAllSymbols() {
  

  // const data = await makeApiRequest("data/v3/all/exchanges");
  // let allSymbols = [];

  // for (const exchange of configurationData.exchanges) {
  //   const pairs = data.Data[exchange.value].pairs;

  //   for (const leftPairPart of Object.keys(pairs)) {
  //     const symbols = pairs[leftPairPart].map((rightPairPart) => {
  //       const symbol = generateSymbol(
  //         exchange.value,
  //         leftPairPart,
  //         rightPairPart
  //       );
  //       return {
  //         symbol: symbol.short,
  //         full_name: symbol.full,
  //         description: symbol.short,
  //         exchange: exchange.value,
  //         type: "crypto",
  //       };
  //     });
  //     allSymbols = [...allSymbols, ...symbols];
  //   }
  // }
  const symbol = findSymbol()
  const s = [
    {
      symbol: `${symbol.symbol}`,
      full_name: `IDX:${symbol.symbol}`,
      description: `${symbol.symbol}`,
      exchange: "IDX",
      type: "stocks",
    },

    {
      symbol: "PNBS",
      full_name: "IDX:PNBS",
      description: "PNBS",
      exchange: "IDX",
      type: "stocks",
    },
  ];


  return s;
}

const initModule =  {

  onReady: async (callback) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
    console.log(configurationData, "ini data config");
  },

  // searchSymbols: async (
  //   userInput,
  //   exchange,
  //   symbolType,
  //   onResultReadyCallback
  // ) => {
  //   console.log("[searchSymbols]: Method call");

  //   const s = [
  //     {
  //       symbol: "GOTO",
  //       full_name: "IDX:GOTO",
  //       description: "GOTO",
  //       exchange: "IDX",
  //       type: "stocks",
  //     },
  //     {
  //       symbol: "PNBS",
  //       full_name: "IDX:PNBS",
  //       description: "PNBS",
  //       exchange: "PNBS",
  //       type: "PNBS",
  //     },
  //   ];
  //   // const symbols = await getAllSymbols();
  //   // const newSymbols = symbols.filter((symbol) => {
  //   //   const isExchangeValid = exchange === "" || symbol.exchange === exchange;
  //   //   const isFullSymbolContainsInput =
  //   //     symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
  //   //   return isExchangeValid && isFullSymbolContainsInput;
  //   // });l
  //   // console.log(newSymbos)
  //   onResultReadyCallback(s);
  // },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    const symbol = findSymbol()
    console.log("[resolveSymbol]: Method call", symbolName);
    const symbols = getAllSymbols()

    console.log(symbols, 'symbolssymbolssymbols')
    const symbolItem = symbols.find((res) => res.full_name === `IDX:${symbol.symbol}`);
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolItem);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_no_volume: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
    };

    console.log("[resolveSymbol]: Symbol resolved", symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    try {
      const symbol = findSymbol()
      const { from, to, firstDataRequest } = periodParams;
      console.log(
        "[getBars]: Method call",
        symbolInfo,
        "info",
        resolution,
        "reso",
        from,
        "from",
        to,
        "to",
        firstDataRequest,
        "fr"
      );
      const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
      // const urlParameters = {
      // 	e: parsedSymbol.exchange,
      // 	fsym: parsedSymbol.fromSymbol,
      // 	tsym: parsedSymbol.toSymbol,
      // 	toTs: to,
      // 	limit: 2000,
      // };
      // console.log(urlParameters, 'ini urlparameters')
      // const query = Object.keys(urlParameters)
      // 	.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
      // 	.join('&');
      console.log(symbolInfo, "resolution");
      const urlParameters = {
        // e: parsedSymbol.exchange,
        fsym: symbolInfo.full_name.toLowerCase(),
        // tsym: parsedSymbol.toSymbol,
        time:
          resolution === "60"
            ? `hour_1`
            : resolution === "15"
            ? `minute_15`
            : resolution === "1"
            ? `minute_1`
            : "",
        // limit: 2000,
      };
      console.log(urlParameters, "ini url");
      console.log(periodParams, "ini periode");
      const queryCoin = urlParameters.fsym;
      const queryTime = urlParameters.time;
      console.log(queryCoin, "ini query");

      // console.log(query, 'ini query')
      // const data = await makeApiRequest(`data/histoday?${query}`);
      const data = await makeApiRequestDocialNew(
        `api/v1/stocks?symbol=${symbol.symbol}&time=1h`
      );
      console.log(data, "data stocks");
      if (
        (data.Response && data.Response === "Error") ||
        data.Data.length === 0
      ) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }
      console.log("tarik api");
      let bars = [];
      const dataBar = data.Data;
      // const arrayRev = dataBar.reverse();
      // console.log(arrayRev, 'ini array')
      dataBar.forEach((bar) => {
        // if (bar.time >= from && bar.time < to) {
          // console.log('test')
          bars = [
            ...bars,
            {
              time: bar.time * 1000,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close,
            },
          ];
        // }
        // console.log(bars, 'ini data bars')
      });
      if (firstDataRequest) {
        // console.log(lastBarsCache, 'ini data chace')
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  // subscribeBars: (
  // 	symbolInfo,
  // 	resolution,
  // 	onRealtimeCallback,
  // 	subscribeUID,
  // 	onResetCacheNeededCallback,
  // ) => {
  // 	console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
  // 	subscribeOnStream(
  // 		symbolInfo,
  // 		resolution,
  // 		onRealtimeCallback,
  // 		subscribeUID,
  // 		onResetCacheNeededCallback,
  // 		lastBarsCache.get(symbolInfo.full_name),
  // 	);
  // },

  // unsubscribeBars: (subscriberUID) => {
  // 	console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
  // 	unsubscribeFromStream(subscriberUID);
  // },
};

export default initModule;