import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../Configs/firebase';
import AppCard169 from '../AppComponents/AppCard169';
import AppBar169 from "./AppBar169";

function AppBarCollection() {

	const params = useParams()
	console.log(params.id, 'ini data params')


	const [dataCollection, setDataCollection] = useState([])
 
	const getData = async () => {
		let data = []
		const q = query(collection(db, "content"), where("tags", "array-contains", params.id));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			data.push({file: doc.data()})
		});
		setDataCollection(data)
		console.log(data, 'ini data arr')
	}

		useEffect(() => {
			getData()
		
		return () => {
		}
		}, [])

	
  return (
	
	<>
	 <Box p="5" bgColor="black">
          <Heading mt="5" p="5" color="white" fontSize="2xl">
		  {params.id}
          </Heading>
          <SimpleGrid gap="2" columns={{ base: "2", md: "3", lg: "5" }}>
            {dataCollection.map((x) => (
              <AppCard169 video={x.file.video} image={x.file.image} />
            ))}
          </SimpleGrid>
    </Box>
	</>
  )
}

export default AppBarCollection