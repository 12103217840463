import { Box, Button, Center, Heading, HStack, Image, Input, Select, SimpleGrid, Tag, Text, useToast, VStack } from '@chakra-ui/react'
import { Stream } from '@cloudflare/stream-react'
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { db } from '../../Configs/firebase'
import colors from '../../Utils/colors'
import { uploadFile } from '../../Utils/FirebaseStorage'
import idrDecimalHelper from '../../Utils/idrDecimalHelper'
import { Rupiah } from '../../Utils/NumberUtils'

function EditDataContent({editData, setEditData}) {

    const [title, setTitle] = useState("")
    const [speakers, setSpeakers] = useState("")
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [price, setPrice] = useState(0)
    const [tags, setTags] = useState("")
    const [location, setLocation] = useState("")
    const [image, setImage] = useState("")
    const [desciption, setDescription] = useState("")
    const [listSpeakers, setListSpeakers] = useState([])
    const toast = useToast()
    

    const getSpeakers = async () => {
        const querySnapshot = await getDocs(collection(db, "speakers"));
        let newArr = [];
        querySnapshot.forEach((doc) => {
          let newData = doc.data();
          newData.id = doc.id;
          newArr.push(newData);
        });
        setListSpeakers(newArr);
        console.log(newArr, 'ini data speaker')
      };  
    
    useEffect(() => {
        getSpeakers()
    
      return () => {
        setListSpeakers([])
      }
    }, [])
    

    const handleEdit = async () => {
        const addData = {
            title: title? (title) : (editData?.title),
            speakers : speakers? (speakers) : (editData?.speakers),
            start : start? (moment(start).valueOf()) : (editData?.start),
            end : end? (moment(end).valueOf()) : (editData?.end),
            price : price? (price) : (editData?.price),
            location : location? (location) : (editData?.location),
            tags: tags,
            image: image,
            description : desciption? (desciption) : (editData?.description),
        }

        try {
            if (addData.tags) {
                let text = addData.tags;
                const lowerText = text.toLowerCase();
                addData.tags = lowerText.split(",");
              }else{
                addData.tags = editData.tags
              }

            if(addData.image){
                const imageResult = await uploadFile(addData.title, "content", addData["image"]);
                addData.image = imageResult.image_original;
                addData.thumbnail = imageResult.image_thumbnail;
            }else{
                addData.image = editData?.image
            }

                await updateDoc(doc(db, "events", editData?.id), addData);
                toast({
                    title: 'Success',
                    description: 'Succes Edit Data',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                })
            

        } catch (error) {
           console.log(error) 
        }
    }

  return (
    <SimpleGrid columns={[1, null, 2]}>
              <Center >
                <VStack w={"80%"}>
                    <Heading  color={colors.white}>Edit</Heading>
                <Image src={editData?.thumbnail}/>
                <Heading>{editData?.title}</Heading>
                    <Text>{editData?.description}</Text>
                    <Text>Lokasi : {editData?.location}</Text>
                    <Text>{Rupiah(editData?.price)}</Text>
                    <Text>Start : {moment(editData?.start).toLocaleString()}</Text>
                    <Text>End : {moment(editData?.end).toLocaleString()}</Text>
                    <Box>
                      {editData?.tags.map((y) => (
                        <Tag ml="1">{y}</Tag>
                      ))}
                    </Box>
                </VStack>
              </Center>
              <Center>
                <Box width="3xl">


                  <VStack align="start" spacing={2} >
                  <Text fontWeight="bold" color={colors.white}>Title</Text>
                  <Input  color={colors.white} m="1" type="text" placeholder={editData?.title} onChange={(e) => setTitle(e.target.value)} />

                  <Text fontWeight="bold" color={colors.white}>Speaker</Text>
                  <Select color={colors.white} m="1" placeholder="Speakers" id="speakers" onChange={(e) => setSpeakers(e.target.value)}>
                    {listSpeakers ? listSpeakers.map((x) => <option key={x.id}value={x.id}>{x.name}</option>) : <></>}
                </Select>

                        <Text fontWeight="bold" fontSize="md">Time Start</Text>
                    <Input m='1' id='start' type='datetime-local' placeholder='Description' 
                    onChange={(e)=>setStart(e.target.value)}
                    />
                    
                    <Text fontWeight="bold" fontSize="md">Time End</Text>
                    <Input m='1' id='end' type='datetime-local' placeholder='Description' 
                    onChange={(e)=>setEnd(e.target.value)}
                    />

                  <Text fontWeight="bold" color={colors.white}>Price</Text>
                  <Input  color={colors.white}  m="1" type="number" placeholder={editData?.price} onChange={(e) => setPrice(e.target.value)} />

                  <Text fontWeight="bold" color={colors.white}>Location</Text>
                  <Input  color={colors.white}  m="1" type="text" placeholder={editData?.location} onChange={(e) => setLocation(e.target.value)} />

                  <Text fontWeight="bold" color={colors.white}>Tag</Text>
                  <Input  color={colors.white}  m="1" type="text" placeholder={editData?.tags} onChange={(e) => setTags(e.target.value)} />
                  <Text fontSize="xs" color="red" fontWeight="bold">* Image (JPG 16:9)</Text>
                    <Input m="1" id="image" type="file" placeholder="Image URL" onChange={(e) => setImage(e.target.files[0])} />

                    <Text fontWeight="bold" color={colors.white}>Deskripsi</Text>
                  <Input  color={colors.white}  m="1" type="text" placeholder={editData?.description === undefined ? ("deskripsi") : (editData?.description)} onChange={(e) => setDescription(e.target.value)} />
                  </VStack>


                  <HStack>
                  <Button m="1" bgColor="green" onClick={() => handleEdit()}>
                    Update
                  </Button>
                  <Button m="1" bgColor="red" onClick={() => setEditData(null)}>
                    Cancel
                  </Button>
                  </HStack>
                </Box>
              </Center>
            </SimpleGrid>
  )
}

export default EditDataContent