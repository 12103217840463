import React, { useEffect, useState } from 'react'
import Footer from '../Components/AppBars/Footer'
import Header from '../Components/AppBars/Header'
import AppbarBodyCollection from '../Components/AppBars/AppBarBodyCollection'
import AppBarCollection from '../Components/AppBars/AppBarBodyCollection'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoChevronBackOutline } from 'react-icons/io5'
import { db } from '../Configs/firebase'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { Badge, Box, Center, Heading, HStack, Image, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react'
import AppCard169 from '../Components/AppComponents/AppCard169'
import colors from '../Utils/colors'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'


function ProfilePage() {
    const params = useParams()
    const navigate = useNavigate()

    const [dataProfile, setDataProfile] = useState("")
    const [dataCollection, setDataCollection] = useState([])
 
	const getData = async () => {
        try {
            let data = []
            const q = query(collection(db, "content"), where("tags", "array-contains", params.id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                data.push({file: doc.data()})         
                const dataId = doc.data().speakers  
                getProfile(dataId)
            });
            setDataCollection(data)
        } catch (error) {
            console.log(error, 'ini data error')
        }
	}

    const getProfile = async (id) => {
        console.log(id)
        try {
            const docRef = doc(db, "speakers", id);
            const docSnap = await getDoc(docRef);  
            if(docSnap.exists()){
                setDataProfile(docSnap.data())
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getProfile()
    
      return () => {
        
      }
    }, [dataCollection])
    

		useEffect(() => {
			getData()
		
		return () => {
            setDataProfile("")
		}
		}, [])


        const TopContent = () => {
            return (
                <SimpleGrid columns={[1, null, 2]} mx={5}>
    
                    <Center mt={10}>
                        <Box overflowX="auto"
                            bg="blackAlpha.300"
                            maxWidth="100%"
                            width="100%"
                            height={350}
                            marginX={4}
                            px={2}
                            py={4}
                            borderRadius={10}
                            marginBottom={4}
                            key={Math.random()}
                        >
                        <Image src={dataProfile.image} alt={dataProfile.name}/>
                        </Box>
                    </Center>
    
                    <Center mt={10}>
                        <Box overflowX="auto"
                            bg="blackAlpha.300"
                            maxWidth="100%"
                            width="100%"
                            height={350}
                            marginX={4}
                            px={2}
                            py={4}
                            borderRadius={10}
                            marginBottom={4}
                            key={Math.random()}
                        >
                            <HStack spacing={5}>
                                <Box>
                                    <Text fontWeight="bold" textAlign={"center"} fontSize={30} color={colors.white}>{dataProfile.name}</Text>
                                    <Text fontWeight="bold" fontSize={20} color={colors.white}>{dataProfile.company}</Text>
                                </Box>
                                <Spacer />
                                {/* <Box >
                                    <Image 
                                        borderRadius="full" 
                                        alt={'charts'}
                                        src={chartProfile.image}
                                    />
                                </Box> */}
                            </HStack>
                            <Box mx={2} mt={3}>
                                <Badge fontWeight="bold" bgColor={colors.light}>
                                    {params.id}
                                </Badge>
                                {dataProfile.description ? (
                                 <Text color={colors.white}>
                                 {dataProfile.description}
                              </Text>
                                ) : (
                                <Text color={colors.white}>
                                   Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam aliquam repellendus, numquam hic vel unde laudantium qui id quasi soluta officiis obcaecati veritatis nulla minus consequatur ea necessitatibus, quaerat facilis animi nemo debitis. Amet, atque id doloribus assumenda reprehenderit adipisci. Voluptatum asperiores ut, sed saepe vitae ducimus dolorum obcaecati! Commodi?
                                </Text>
                                )}
                            </Box>
                        </Box>
                    </Center>
                </SimpleGrid>
            )
        }


  return (
    <>
    <HeaderResponsive/>
    <Stack bgColor="black" cursor="pointer" p={5} onClick={() => navigate("/", { replace: true })}>
                <IoChevronBackOutline size="30" color={colors.light} />
    </Stack>
        <Box bgColor="black">
            <TopContent/>
        </Box>
        <Box p="5" bgColor="black">
          <Heading mt="5" p="5" color="white" fontSize="2xl">
		  Collection
          </Heading>
          {dataCollection.length > 0 ? (
            <SimpleGrid gap="2" columns={{ base: "2", md: "3", lg: "5" }}>
            {dataCollection.map((x) => (
            <Link to={`/watch/${x.title}`}>
              <AppCard169 title={x.file.video} image={x.file.image} />
            </Link>
            ))}
          </SimpleGrid>
          ) : (
            <Center>
                <Heading color={colors.white}>No Tracked Data</Heading>
            </Center>
          )}
        </Box>
    <Footer/>
    </>
  )
}

export default ProfilePage