import React from 'react'
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,Text,
	TableCaption,Button,
	TableContainer,
	Box,
	Image,
	HStack,Heading, SimpleGrid, Avatar, Spacer, Center,
  } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'


function MarketUnderConstruction() {
	const [data,setData]=useState()

	const getData=()=>{
		const config = {
		method: 'get',
		url: 'https://docial-crypto-h3tahmnrkq-uc.a.run.app/api/v1/latest',
	  };
	  axios(config)
	  .then((response) =>{
		  console.log(response.data.data)
		  setData(response.data.data)
	
	  })
	  .catch((err)=> console.log(err.message))
	
	}
	
	useEffect(() => {
		getData()
	
	  return () => {
		setData()
	  }
	}, [])
	
  return (
	<Box p='25' height='full' bgColor='black' color='white'>
		<Heading textAlign='center'>MARKET IS UNDER CONSTRUCTION</Heading>
		<Center>
			<Image src='https://img.freepik.com/free-vector/construction-with-black-yellow-stripes_1017-30755.jpg' />

		</Center>

	{/* <SimpleGrid columns='3' p='5' >
		<Box height='200px' bgColor='gray.900' p='5' mr='5'borderRadius='3xl'>
			<Heading fontSize='larger'>Trending</Heading>
				<HStack p='5'>
					<Avatar src='https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'/>
					<Text>Bitcoin</Text>
					<Text>BTC</Text>
					<Spacer/>
					<TriangleUpIcon color='green'/>
					<Text color='green' >4.5%</Text>
				</HStack>
		</Box>
		<Box height='200px' bgColor='gray.900' p='5' borderRadius='3xl'>
			<Heading fontSize='larger'>Trending</Heading>
				<HStack p='5'>
					<Avatar src='https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'/>
					<Text>Bitcoin</Text>
					<Text>BTC</Text>
					<Spacer/>
					<TriangleUpIcon color='green'/>
					<Text color='green' >4.5%</Text>
				</HStack>
		</Box>
		<Box height='200px' bgColor='gray.900' p='5' ml='5' borderRadius='3xl'>
			<Heading fontSize='larger'>Patterns</Heading>
				<HStack p='5'>
					<Avatar src='https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'/>
					<Text>Bitcoin</Text>
					<Text>BTC</Text>
					<Spacer/>
					<TriangleUpIcon color='green'/>
					<Text color='green' >4.5%</Text>
				</HStack>
		</Box>
	</SimpleGrid> */}
	{/* <TableContainer>
		<Table >
			<Thead>
			<Tr >
				<Th color='white'>Name</Th>
				<Th color='white'>Price</Th>
				<Th color='white'>1 minute%</Th>
				<Th color='white'>1 hour%</Th>
				<Th color='white'>1 day%</Th>
				<Th color='white'>1 week%</Th>
				<Th color='white'>1 month%</Th>
				<Th color='white'>Volume</Th>
				<Th color='white'>Last 7 days</Th>
			</Tr>
			</Thead>
			<Tbody>
				{data?data.map((x)=>
					<Tr key={x.id}>
					<Td>
						<Link to={`/market/crypto/${x.symbol}`}>
							<HStack>
							<Image width='50px' src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${x.id}.png`}/>
							<Text>{x.name} - {x.symbol}</Text>
							</HStack>
						</Link>
					</Td>
					<Td>{x.quote.USD.price}</Td>
					<Td>{x.m>0?<Text color='green.900' fontWeight='extrabold' >{x.m}%</Text>:<Text color='red'>{x.m}%</Text>}</Td>
					<Td>{x.quote.USD.percent_change_1h>0?<Text color='green'><TriangleUpIcon/> {x.quote.USD.percent_change_1h}%</Text>:<Text color='red'><TriangleDownIcon/> {x.quote.USD.percent_change_1h}%</Text>}</Td>
					<Td>{x.quote.USD.percent_change_24h>0?<Text color='green'><TriangleUpIcon/> {x.quote.USD.percent_change_24h}%</Text>:<Text color='red'><TriangleDownIcon/> {x.quote.USD.percent_change_24h}%</Text>}</Td>
					<Td>{x.quote.USD.percent_change_7d>0?<Text color='green'><TriangleUpIcon/> {x.quote.USD.percent_change_7d}%</Text>:<Text color='red'><TriangleDownIcon/> {x.quote.USD.percent_change_7d}%</Text>}</Td>
					<Td>{x.quote.USD.percent_change_30d>0?<Text color='green'><TriangleUpIcon/> {x.quote.USD.percent_change_30d}%</Text>:<Text color='red'><TriangleDownIcon/> {x.quote.USD.percent_change_30d}%</Text>}</Td>
					<Td >{x.quote.USD.volume_24h}</Td>
					<Td><Image color='red' src={x.stats}/></Td>
					</Tr>
				):
				<></>}
			
			
			</Tbody>
			
		</Table>
	</TableContainer> */}
	</Box>
  )
}

export default MarketUnderConstruction