import { Avatar, Box, Button, Center, Flex, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SkeletonCircle, Spinner, Stack, Tab, Table, TabList, TabPanel, TabPanels, Tabs, Tag, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { MdAccountBalanceWallet } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import Footer from "../Components/AppBars/Footer";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";
import AuthContext from "../Routes/hooks/AuthContext";
import colors from "../Utils/colors";
import { db } from "../Configs/firebase";
import { collection, doc, getDocs, limit, query, updateDoc, where } from "firebase/firestore";
import { auth } from "../Configs/firebase";
import idrDecimalHelper from "./../Utils/idrDecimalHelper";
import moment from "moment";
import { uploadPhotoProfile } from "../Utils/FirebaseStorage";
import AppBarResetPassword from "../Components/AppBars/AppBarResetPassword";

function ProfileUserPage() {
  const [profileData, setProfileData] = useState("");
  const [paymentList, setPaymentList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [upload, setUpload] = useState(false);
  const [addData, setAddData] = useState({});
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const { currentUser, getUserStorage } = useContext(AuthContext);

  const getUserData = () => {
    const data = getUserStorage();
    setProfileData(data);
  };

  console.log(auth.currentUser, "ini mau nyari data user yang login");
  const getDataPayment = async () => {
    try {
      const ref = collection(db, "payments");
      // const q = query(ref, where("email", "==", "immanuelmanurung08@gmail.com"), limit(15));
      const q = query(ref, where("email", "==", auth.currentUser.email), limit(15));
      let result = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log(doc.data(), "ini result yang login");
        result.push(doc.data());
      });
      setPaymentList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddData = (key, value) => {
    let newObject = addData;
    newObject[key] = value;
    setAddData(newObject);
    console.log(newObject);
  };

  const handleAddPhotoProfile = async () => {
    setLoading(true)
    try {
      const user = currentUser.uid;
      console.log(addData["photoProfile"], "ini apaan");
      const imageResult = await uploadPhotoProfile(user, "photoProfile", addData["photoProfile"]);
      addData.image = imageResult.image_original;
      addData.thumbnail = imageResult.image_thumbnail;
  
      await updateDoc(doc(db, "users", currentUser.uid), {
        photoProfile: imageResult,
      });
        toast({
          title: "Success Login",
          description:
            `Success Upload Image`,
          status: "success",
          duration: 10000,
          isClosable: true,
          position: "top-right",
          })
    } catch (error) {
      console.log(error)
    }
    setAddData({});
    getUserData()
    setUpload(false)
    setLoading(false)
  };


  useEffect(() => {
    getUserData();
    getDataPayment();

    return () => {};
  }, []);

  const PaymentList = () => {
    return (
      <Table>
        <Thead backgroundColor="blackAlpha.500">
          <Tr>
            <Th>
              <Text fontWeight="bold" color={colors.theme}>
                Product
              </Text>
            </Th>
            <Th>
              <Text fontWeight="bold" color={colors.theme}>
                Price
              </Text>
            </Th>
            <Th>
              <Text fontWeight="bold" color={colors.theme}>
                Email
              </Text>
            </Th>
            <Th>
              <Text fontWeight="bold" color={colors.theme}>
                Status
              </Text>
            </Th>
            <Th>
              <Text fontWeight="bold" color={colors.theme}>
                Update
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {paymentList.length > 0 ? (
            paymentList.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Text fontWeight="bold" fontSize={14} color={colors.light}>
                      {item.external_id}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontWeight="bold" fontSize={14} color={colors.light}>
                      {idrDecimalHelper(item.amount)}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontWeight="bold" fontSize={14} color={colors.light}>
                      {item.email}
                    </Text>
                  </Td>
                  <Td>
                    {item.status === "PENDING" ? (
                      <VStack >                      
                      <Text fontWeight="bold" fontSize={14} color={colors.light}>
                        {item.status}
                      </Text>
                      <a href={`${item.invoice_url}`} target="_blank" rel="noreferrer">
                       <Tag>Click Here !</Tag>
                      </a>
                      </VStack>
                    ) : (
                      <Text fontWeight="bold" fontSize={14} color={colors.light}>
                        {item.status}
                      </Text>
                    )}
                  </Td>
                  <Td>
                    <Text fontWeight="bold" fontSize={14} color={colors.light}>
                      {moment(item.updated).fromNow()}
                    </Text>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Center>
              <Heading>No Recognize Data</Heading>
            </Center>
          )}
        </Tbody>
      </Table>
    );
  };

  const SettingTab = () => {
    return (
      <>
      <Button onClick={onOpen}>Change Password</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AppBarResetPassword onClose={onClose}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
    )
  };

  const CenterContent = () => {
    return (
      <>
        <Box backgroundColor="black" h="40vh">
          {console.log(currentUser, 'ini current')}
          <Flex align="center" h="30%" justifyContent="center"></Flex>
          <Flex justifyContent="center">
            <VStack>
            {profileData.photoProfile ? <Avatar size="xl" name={profileData.photoProfile} src={profileData.photoProfile} /> : <Avatar size="xl" name={currentUser.displayName} src={currentUser.displayName} />}
            <Box>
              <Text fontSize="sm" color={colors.light} cursor="pointer" fontStyle="italic" onClick={() => setUpload(true)}>Upload image profile</Text>
            </Box>
            {upload && (
              loading ? (
                <Spinner />
              ) : (
                <VStack spacing={3} w="50%">
                <Input id="photoProfile" color={colors.white} size="sm" type="file" placeholder="Image URL"  onChange={(e) => handleAddData(e.target.id, e.target.files[0])}></Input>
                <HStack>
                <Button color="red" size="sm" onClick={() =>setUpload(false)}>
                  Cancel
                </Button>
                <Button color="red" size="sm" onClick={() => handleAddPhotoProfile()}>
                  Upload
                </Button>
                </HStack>
                </VStack>
              )
            )}
            </VStack>
            <Box ml="3">
              <Text fontSize={20} fontWeight="bold" mt={1} color={colors.light}>
                {profileData.name}
              </Text>
              <Text fontSize={15} mt={1} color={colors.light}>
                Email: {profileData.email}
              </Text>
              {/* <Text fontSize={15} mt={1} color={colors.light}>
                {profileData.role}
              </Text> */}
              <Text fontSize={15} mt={1} color={colors.light}>
                ID : {profileData.uid_user}
              </Text>
              <Text fontSize={15} mt={1} color={colors.light}>
                Expired Account : {new Date(profileData?.expired_user).toString()}
              </Text>
              <Tag backgroundColor={colors.theme} color={colors.black} w="auto" p={2} borderRadius={10} mt="1">
                {profileData.role}
              </Tag>
            </Box>
          </Flex>
        </Box>
        <Tabs isFitted variant="enclosed" bgColor="black">
          <TabList mb="1em">
            <Tab _selected={{ color: "black", bg: colors.light }} color={colors.light} fontWeight="bold" fontSize="90%">
              <IoMdSettings size={30} />
              Account Setting
            </Tab>
            <Tab _selected={{ color: "black", bg: colors.light }} color={colors.light} fontWeight="bold" fontSize="90%">
              <MdAccountBalanceWallet size={30} />
              Payment
            </Tab>
          </TabList>
          <TabPanels bgColor="black">
            <TabPanel>
              <SettingTab />
            </TabPanel>
            <TabPanel>
              <PaymentList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
    );
  };

  return (
    <>
      <HeaderResponsive />
      <CenterContent />
      <Footer />
    </>
  );
}

export default ProfileUserPage;
