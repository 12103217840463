import { Box, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import AppBarBodyFavorites from "../Components/AppBars/AppBarBodyFavorites";
import AppBarSpeaker from "../Components/AppBars/AppBarSpeaker";
import AppBodyMain from "../Components/AppBars/AppBodyMain";
import Footer from "../Components/AppBars/Footer";
import Header from "../Components/AppBars/Header";
import AppScreenFront from "../Components/AppComponents/AppScreenFront";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function FavoritesPage() {
  return (
    <>
      <HeaderResponsive />
      {/* <Header /> */}
      {/* <AppBodyMain/> */}
      <AppBarBodyFavorites />
      <Spacer />
      <Footer />
    </>
  );
}

export default FavoritesPage;
