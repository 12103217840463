import React from "react";
import AppBarAdminHeader from "../../Components/AppBars/admin/AppBarAdminHeader";
import AppBarAdminTradingPlan from "../../Components/AppBars/admin/AppBarAdminTradingPlan";

function TradingPlanAdmin() {
  return (
    <>
      <AppBarAdminHeader />
	  <AppBarAdminTradingPlan />
    </>
  );
}

export default TradingPlanAdmin;
