import React from "react";
import Footer from "../Components/AppBars/Footer";
import Header from "../Components/AppBars/Header";
import { Box, Text, Center } from "@chakra-ui/react";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function SyaratKetentuanPage() {
  return (
    <>
      <HeaderResponsive />
      {/* <Header /> */}
      <Box bgColor="black" color="white">
        <Box mx="100px">
          <Center>
            <Text p="40px" fontSize="40px">
              Syarat dan Ketentuan
            </Text>
          </Center>
          <Text fontSize="30px" p="20px">
            1. Ketentuan Layanan {window.location.hostname}
          </Text>
          <Text fontSize="20px" mx="80px">
            Ketentuan Layanan ini merupakan perjanjian antara pengguna (“Kamu” atau “-mu”) dan {window.location.hostname} (“Kami”) sehubungan dengan penggunaan layanan pada situs web www.{window.location.hostname} , serta setiap dan seluruh produk, fitur,
            layanan, dan konten yang tersedia pada Website dan terkait dengan Website.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Mohon membaca Ketentuan Layanan ini dengan seksama sehingga Kamu dapat memahaminya. Dengan mengunjungi, mendaftar pada, maupun menggunakan Website, maka jika Kamu telah membaca, memahami, dan menyetujui menyetujui untuk tunduk
            pada Ketentuan Layanan ini dan seluruh ketentuan terkait lainnya yang menjadi bagian yang tidak terpisahkan dari Ketentuan Layanan ini, termasuk namun tidak terbatas pada Kebijakan Privasi dan Kebijakan Refund; beserta setiap
            dan seluruh perubahan, penambahan, dan/atau pembaruan dari waktu ke waktu.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Jika Kamu tidak setuju dengan Ketentuan Layanan ini, mohon untuk tidak mengunjungi, mendaftar pada, memberikan informasi melalui, maupun menggunakan Website.
          </Text>
          <Text fontSize="30px" p="20px">
            2. Pendaftaran Akun
          </Text>
          <Text fontSize="20px" mx="80px">
            Sebelum dapat mengakses konten kami pada website {window.location.hostname}, Kamu harus terlebih dahulu memasukkan nama dan informasi pribadi lainnya, memberikan persetujuan untuk menerima komunikasi pemasaran dari Kami.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Setelah mengakses website kami dan masuk sebagai member dengan cara mendaftar melalui website serta membayar biaya berlangganan sebagaimana ditentukan dalam Website (Kamu akan diberikan pilihan jangka waktu sebagai Member)
            setelah itu kamu dapat mengakses konten kami, dalam jangka waktu yang terbatas sebagaimana Kamu tentukan, Kamu akan memiliki kesempatan untuk memperpanjang berlangganan konten eksklusif tersebut dengan adanya pengingat pada
            website dan email yang masuk sebelum masa berlanggananmu habis.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Dengan melakukan pendaftaran untuk mengakses Konten Berlangganan, maupun menggunakan Website secara umum:
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            1. Kamu mengakui dan menyatakan bahwa Kamu telah menyampaikan informasi yang sesuai dengan data diri yang asli.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            2. ketika Kamu mendaftar pada Konten Berlangganan, Kami akan meminta Kamu untuk menyediakan informasi tertentu yang Kami butuhkan (“Informasi Pendaftaran”) sebagaimana ditentukan pada halaman pendaftaran Website yang dapat
            berubah dari waktu ke waktu.
          </Text>
          <Text fontSize="30px" p="20px">
            3. Informasi Pendaftaran
          </Text>
          <Text fontSize="20px" mx="80px">
            Dengan memberikan Informasi Pendaftaran melalui Website, Kamu menjamin bahwa seluruh Informasi Pendaftaran yang diberikan adalah informasi benar, akurat, terbaru, dan lengkap mengenai dirimu. Kamu juga menjamin bahwa Kamu tidak
            meniru seseorang atau suatu entitas; memberikan pernyataan yang tidak benar mengenai keterkaitanmu dengan seseorang, suatu entitas, atau suatu perkumpulan; atau melakukan cara apapun juga yang bertujuan untuk merahasiakan
            identitasmu yang sebenarnya dari Kami.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kami peduli terhadap privasimu, dan karenanya Informasi Pendaftaran beserta dengan informasi pribadi lainnya yang Kamu berikan kepada Kami akan Kami perlakukan sesuai dengan Kebijakan Privasi yang merupakan bagian tidak
            terpisahkan dari Ketentuan Layanan ini.
          </Text>
          <Text fontSize="30px" p="20px">
            4. Hak Akses
          </Text>
          <Text fontSize="20px" mx="80px">
            Dengan melakukan pendaftaran untuk Konten Eksklusif dan mengisi feedback form, Kamu berhak atas akses atas Konten eksklusif {window.location.hostname}. Hak Akses ini akan berakhir apabila hingga berakhirnya Periode Berlangganan.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Adapun Hak Akses tersebut hanya mencakup hak untuk melihat konten video yang terdapat dalam Website serta menggunakan produk, fitur, dan layanan Website. Untuk menghindari keragu-raguan, Hak Akses tidak menjadikan Kamu sebagai
            pemegang atau pemilik dari konten video apapun yang tersedia pada website, dan karenanya TIDAK MEMBERIKAN hak untuk mengunduh, menyimpan, membuat salinan, membagikan, mengunggah ulang, maupun menyebarluaskan dengan cara apapun
            juga dan media apapun juga (misalnya, memfoto atau merekam sebagian dari konten Portal dan membagikannya melalui media sosial).
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kamu merupakan satu-satunya pihak yang dapat menggunakan Hak Akses, dan karenanya Hak Akses tersebut tidak dapat digunakan, dipinjamkan, dibagikan, disewakan, atau dipindahtangankan kepada siapapun juga
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Untuk kepentinganmu dan kepentingan Kami, Kami melarang Kamu untuk membagikan Informasi Pendaftaran (termasuk username maupun password) dengan siapapun juga untuk memungkinkan mereka secara tidak sah dan tanpa izin dari Kami
            mengakses dan menggunakan website.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kamu bertanggung jawab untuk menjaga keamanan dan kerahasiaan dari Informasi Pendaftaranmu. Kamu setuju untuk: (a) segera memberitahu Kami dalam hal terjadi penggunaan yang tidak sah dari Informasi Pendaftaran, dan (b) dalam hal
            Kamu mengakses website menggunakan perangkat yang digunakan bersama-sama dengan orang lain atau dapat diakses oleh orang lain, memastikan bahwa Kamu telah keluar dari akunmu dengan benar setelah selesai menggunakan akunmu serta
            memastikan tidak ada akses atau penggunaan tidak sah terhadap Portal oleh orang lain selain dirimu.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kami tidak bertanggung jawab, dan Kamu sepakat untuk melepaskan segala tuntutan terhadap Kami dalam hal terjadinya, setiap kerugian yang muncul akibat kegagalanmu untuk memenuhi bagian ini.
          </Text>
          <Text fontSize="30px" p="20px">
            5. Penggunaan yang Dilarang
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu hanya boleh menggunakan fitur pada website sesuai untuk hal dan tujuan yang tidak bertentangan dengan hukum dan peraturan perundang-undangan yang berlaku, serta sesuai dengan Ketentuan Layanan dibawah ini.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Dalam menggunakan website, Kamu dilarang untuk:
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            1. mengakses dan menggunakan website dengan cara, perangkat, dan media apapun juga yang melanggar hukum dan peraturan perundang-undangan yang berlaku;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            2. menggunakan website untuk tujuan-tujuan yang bertentangan dengan hukum, kesusilaan maupun kepatutan di dalam masyarakat;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            3. memberikan informasi atau yang tidak benar, tidak akurat, tidak terbaru, dan tidak lengkap mengenai dirimu;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            4. memberikan informasi dengan bertindak seolah-olah orang atau pihak lain, atau meniru seseorang atau suatu pihak;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            5. memberikan pernyataan yang tidak benar mengenai keterkaitanmu dengan seseorang, suatu entitas, atau suatu perkumpulan;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            6. melakukan cara apapun juga yang bertujuan untuk merahasiakan identitasmu yang sebenarnya dari Kami;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            7. mengunduh, menyimpan, membuat salinan, membagikan, mengunggah ulang, maupun menyebarluaskan dengan cara apapun juga dan media apapun juga isi dari website (misalnya,memfoto atau merekam sebagian dari konten edukasi yang ada
            dan membagikannya melalui media sosial) baik tanpa atau dengan mendapatkan keuntungan komersial;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            8. menyebabkan Hak Akses dapat digunakan oleh pihak lain selain dari dirimu, baik dengan cara dipinjamkan, dibagikan, disewakan, dipindahtangankan, atau dengan cara apapun lainnya;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            9. membagikan Informasi Pendaftaran (termasuk username maupun password) dengan siapapun juga;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            10. menyebabkan, baik sengaja maupun tidak sengaja, seseorang dapat secara tidak sah dan tanpa izin dari Kami mengakses dan menggunakan Portal menggunakan Informasi Pendaftaran milikmu;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            11. mengakses website menggunakan akun milik orang lain;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            12. melanggar hak kekayaan intelektual milik Kami atau pihak ketiga manapun yang terdapat dalam website;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            13. mengganggu atau menghalangi pengoperasian atau server atau jaringan yang terhubung ke website, termasuk memasang, mengirimkan, mentransmisikan, mengunggah, atau menyediakan materi apa pun yang berisi virus piranti lunak,
            worms atau kode komputer, file, atau program lain yang dirancang untuk mengganggu, menghancurkan atau membatasi kegunaan piranti lunak komputer atau perangkat keras atau peralatan telekomunikasi;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            14. membuat tiruan dari website maupun sebagian atau seluruh konten, produk, atau layanan dari website;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            15. melakukan intersepsi atau penyadapan informasi, data, dan dokumen apapun;
          </Text>
          <Text fontSize="30px" p="20px">
            6. Pelanggaran Ketentuan Layanan
          </Text>
          <Text fontSize="20px" mx="80px">
            Apabila di kemudian hari Kami menemukan bahwa Kamu telah melakukan pelanggaran atas Ketentuan Layanan ini, Kami berhak berdasarkan direksi Kami untuk mencabut Hak Akses yang Kamu miliki, baik sementara atau permanen.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Adapun pencabutan Hak Akses Membership sebagai akibat pelanggaran atas Ketentuan Layanan ini tidak memberikan hak bagi Kamu untuk meminta pengembalian biaya pendaftaran maupun tuntutan apapun juga akibat dicabutnya Hak Akses
            tersebut.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Pencabutan Hak Akses tidak membatasi atau mengurangi hak Kami untuk mengambil setiap langkah dan seluruh tindakan hukum yang diperlukan untuk menegakkan hak-hak Kami.
          </Text>
          <Text fontSize="30px" p="20px">
            7. Hak Kekayaan Intelektual
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu mengakui dan menyetujui bahwa website berikut seluruh isi yang ada di dalamnya, termasuk namun tidak terbatas pada nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses, dan model bisnis, dilindungi
            oleh hak cipta, merek, paten, hak moral, hak ekonomi, dan hak kekayaan intelektual lainnya yang dipegang dan dimiliki oleh Kami. Akses dan penggunaan website tunduk dan mengacu pada Ketentuan Layanan ini. Sebagai catatan, untuk
            keperluan pengajaran dalam website, website dapat memuat konten yang bersumber dari pencipta ataupun pemegang hak cipta pihak ketiga berdasarkan prinsip fair use dengan tidak merugikan kepentingan yang wajar dari pencipta
            ataupun pemegang hak cipta konten tersebut, sejauh batas dimungkinkan oleh hukum dan peraturan perundang-undangan yang berlaku.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kamu juga mengakui dan sepenuhnya sadar bahwa setiap pelanggaran atas hak kekayaan intelektual Kami dapat menimbulkan kerugian baik materil maupun imateril, dan karenanya Kami berhak untuk menegakkan hak-hak Kami melalui
            prosedur hukum yang dimungkinkan.
          </Text>
          <Text fontSize="30px" p="20px">
            8. Tanggung Jawab Pengguna
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu bertanggung jawab secara penuh atas setiap kerugian atau kerusakan yang mungkin Kamu atau pihak lainnya derita dari penggunaan website olehmu maupun oleh pihak lain yang menggunakan akun milikmu.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Setiap tindakan yang Kamu ambil atas dasar informasi yang terdapat dalam website adalah tanggung jawab dan risiko pribadimu dan Kami tidak bertanggung jawab atas konsekuensi tindakan yang Kamu lakukan.
          </Text>
          <Text fontSize="30px" p="20px">
            9. Batasan Tanggung Jawab
          </Text>
          <Text fontSize="20px" mx="80px">
            Kami berusaha dalam batas yang wajar dan komersil untuk menyediakan konten edukasi melalui website Kami. Meskipun demikian, Kamu paham dan setuju bahwa produk, konten, layanan, fitur website tersebut bersifat “apa adanya” dan
            “sebagaimana tersedia”
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kamu juga paham dan setuju bahwa Kami tidak menyatakan atau menjamin keandalan, ketepatan waktu, kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan, atau keamanan dari website akan sesuai dengan kebutuhan atau ekspektasi
            Kamu. Kami tidak menjamin bahwa website akan aman atau terbebas dari virus maupun bug.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Sejauh dimungkinkan oleh hukum yang berlaku, Kami tidak bertanggung jawab kepada Kamu atau pihak mana pun juga atas kerugian atau kerusakan dalam bentuk apapun juga yang timbul akibat penggunaan website ini, termasuk namun tidak
            terbatas akibat virus komputer, kehilangan data, penggunaan, maupun setiap kerugian berwujud maupun tidak berwujud lainnya, baik yang terjadi sebagai akibat langsung maupun tidak langsung atas penggunaan website. Dalam hal hukum
            mengharuskan pertanggungjawaban, Kamu sepakat bahwa total tanggung jawab Kami atas segala klaim yang timbul dari pelanggaran Ketentuan Layanan ini adalah terbatas pada jumlah yang telah Kamu bayarkan kepada Kami untuk
            mendapatkan Hak Akses.
          </Text>
          <Text fontSize="30px" p="20px">
            10. Perubahan Dalam Ketentuan Layanan
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu mengakui dan menyetujui bahwa Kami dapat melakukan perubahan, penambahan, dan/atau pembaruan sebagaimana diperlukan dan/atau sebagaimana diwajibkan oleh hukum yang berlaku, terhadap Ketentuan Layanan ini. Sebagai
            konsekuensi, Kamu setuju untuk tunduk pada setiap perubahan Ketentuan Layanan.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Perubahan apa pun yang Kami lakukan terhadap Ketentuan Layanan Kami di masa mendatang akan diterbitkan melalui halaman ini dan, ketika dibutuhkan, diberitahukan kepada Kamu melalui surat elektronik. Mohon kunjungi kembali
            halaman ini dari waktu ke waktu untuk melihat adanya perubahan pada Ketentuan Layanan ini.
          </Text>
          <Text fontSize="30px" p="20px">
            11. Hukum yang Berlaku dan Penyelesaian Sengketa
          </Text>
          <Text fontSize="20px" mx="80px">
            Ketentuan Layanan ini diatur berdasarkan dan ditafsirkan sesuai dengan hukum Republik Indonesia. Kamu setuju bahwa setiap dan seluruh tuntutan yang mungkin timbul sehubungan dengan Ketentuan Layanan ini (“Sengketa”) akan
            diupayakan untuk diselesaikan secara damai dalam waktu 60 (enam puluh) hari sejak Kamu memberikan pemberitahuan kepada Kami. Apabila hal tersebut tidak dapat diselesaikan dalam jangka waktu tersebut, Kamu sepakat bahwa Sengketa
            akan diselesaikan melalui Pengadilan Jakarta Selatan, dengan ketentuan hal tersebut tidak membatasi hak Kami untuk memulai tindakan hukum atau proses hukum atas Sengketa di hadapan pengadilan negeri lain manapun juga,
            sebagaimana dapat Kami tentukan berdasarkan diskresi Kami.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Cara Menghubungi Kami
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kamu dapat menghubungi Kami melalui info@{window.location.hostname} / Whatsapp {window.location.hostname} +62 812-4167-8382 jika Kamu memiliki pertanyaan mengenai Ketentuan Layanan ini.
          </Text>
          <br />
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default SyaratKetentuanPage;
