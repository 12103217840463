import { getConfig } from "@testing-library/react";
import axios from "axios";
import { useEffect, useState } from "react";
import DataConfig from "./DataConfig.js";
import {
  makeApiRequest,
  generateSymbol,
  parseFullSymbol,
  makeApiRequestDocial,
} from "./helpers.js";
// import {
// 	subscribeOnStream,
// 	unsubscribeFromStream,
// } from './streaming.js';

const lastBarsCache = new Map();

const configurationData = {
  supports_search: true,
  supports_group_request: true,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  supported_resolutions: ['15', '1', '60'],
  exchanges: [
    {
      value: "Kucoin",
      name: "Kucoin",
      desc: "Kucoin",
    },
    {
      value: "Bitfinex",
      name: "Bitfinex",
      desc: "Bitfinex",
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: "Kraken",

      // filter name
      name: "Kraken",

      // full exchange name displayed in the filter popup
      desc: "Kraken bitcoin exchange",
    },
  ],
  symbols_types: [
    {
      name: "All types",
      value: "",
    },
    {
      name: "Stock",
      value: "stock",
    },
    {
      name: "Crypto",
      value: "crypto",
    },
    {
      name: "Index",
      value: "index",
    },
    // ...
  ],
};

async function getAllSymbols() {
  const data = await makeApiRequest("data/v3/all/exchanges");
  let allSymbols = [];

  for (const exchange of configurationData.exchanges) {
    const pairs = data.Data[exchange.value].pairs;

    for (const leftPairPart of Object.keys(pairs)) {
      const symbols = pairs[leftPairPart].map((rightPairPart) => {
        const symbol = generateSymbol(
          exchange.value,
          leftPairPart,
          rightPairPart
        );
        return {
          symbol: symbol.short,
          full_name: symbol.full,
          description: symbol.short,
          exchange: exchange.value,
          type: "crypto",
        };
      });
      allSymbols = [...allSymbols, ...symbols];
    }
  }
  console.log(allSymbols,"allsymbols")
  return allSymbols;
}

export default {
  onReady: async (callback) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
    console.log(configurationData, "ini data config");
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    const symbols = await getAllSymbols();

    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });


    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    console.log("[resolveSymbol]: Method call", symbolName);
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find(
      ({ full_name }) => full_name === symbolName
    );
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_no_volume: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
    };

    console.log("[resolveSymbol]: Symbol resolved", symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to, firstDataRequest } = periodParams;
    console.log(
      "[getBars]: Method call",
      symbolInfo,
      "info",
      resolution,
      "reso",
      from,
      "from",
      to,
      "to",
      firstDataRequest,
      "fr"
    );
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    // const urlParameters = {
    // 	e: parsedSymbol.exchange,
    // 	fsym: parsedSymbol.fromSymbol,
    // 	tsym: parsedSymbol.toSymbol,
    // 	toTs: to,
    // 	limit: 2000,
    // };
    // console.log(urlParameters, 'ini urlparameters')
    // const query = Object.keys(urlParameters)
    // 	.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
    // 	.join('&');
    console.log(resolution, "resolution");
    const urlParameters = {
      // e: parsedSymbol.exchange,
      fsym: parsedSymbol.fromSymbol.toLowerCase(),
      // tsym: parsedSymbol.toSymbol,
      time: resolution === '60' ? `hour_1` : resolution === '15' ? `minute_15` : resolution === "1" ? `minute_1` : "",
      // limit: 2000,
    };
    console.log(urlParameters, "ini url");
    console.log(periodParams, "ini periode");
    const queryCoin = urlParameters.fsym;
    const queryTime = urlParameters.time;
    console.log(queryCoin, "ini query");

    try {
      // console.log(query, 'ini query')
      // const data = await makeApiRequest(`data/histoday?${query}`);
      const data = await makeApiRequestDocial(
        `api/candle/${symbolInfo.name.slice(0, -5)}?timeFrame=${queryTime}`
      );
      if (
        (data.Response && data.Response === "Error") ||
        data.Data.length === 0
      ) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }
      console.log("tarik api");
      let bars = [];
      const dataBar = data.Data;
      const arrayRev = dataBar.reverse();
      // console.log(arrayRev, 'ini array')
      arrayRev.forEach((bar) => {
        if (bar.time >= from && bar.time < to) {
          // console.log('test')
          bars = [
            ...bars,
            {
              time: bar.time * 1000,
              low: bar.low,
              high: bar.high,
              open: bar.open,
              close: bar.close,
            },
          ];
        }
        // console.log(bars, 'ini data bars')
      });
      if (firstDataRequest) {
        // console.log(lastBarsCache, 'ini data chace')
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  // subscribeBars: (
  // 	symbolInfo,
  // 	resolution,
  // 	onRealtimeCallback,
  // 	subscribeUID,
  // 	onResetCacheNeededCallback,
  // ) => {
  // 	console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
  // 	subscribeOnStream(
  // 		symbolInfo,
  // 		resolution,
  // 		onRealtimeCallback,
  // 		subscribeUID,
  // 		onResetCacheNeededCallback,
  // 		lastBarsCache.get(symbolInfo.full_name),
  // 	);
  // },

  // unsubscribeBars: (subscriberUID) => {
  // 	console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
  // 	unsubscribeFromStream(subscriberUID);
  // },
};
