import React from "react";
import Header from "../Components/AppBars/Header";
import Footer from "./../Components/AppBars/Footer";
import { Box, Center, Text } from "@chakra-ui/react";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function KebijakanPrivasiPage() {
  return (
    <>
      <HeaderResponsive />
      {/* <Header /> */}
      <Box bgColor="black" color="white">
        <Box mx="100px">
          <Center>
            <Text p="40px" fontSize="40px">
              Kebijakan Privasi
            </Text>
          </Center>
          <Text fontSize="30px" p="20px">
            1. Keberlakuan Kebijakan Privasi
          </Text>
          <Text fontSize="20px" mx="80px">
            Kami tim {window.location.hostname} sangat peduli terhadap privasimu saat mengunjungi, mendaftar, memberikan informasi, maupun menggunakan website/aplikasi maupun menggunakan layanan Kami sehubungan dengan penggunaan website/aplikasi
            tersebut.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kebijakan Privasi ini merupakan bagian yang tidak terpisahkan dari Ketentuan Layanan, yang khusus menjelaskan bagaimana Kami menggunakan informasi pribadimu yang Kamu berikan, Kami kumpulkan dan/atau Kami terima dari sumber lain
            (“Informasi Pribadi”).
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Mohon membaca Kebijakan Privasi Kami dengan seksama sehingga Kamu dapat memahami pendekatan dan cara Kami dalam menggunakan informasi tersebut. Dengan mengunjungi, mendaftar, memberikan informasi, maupun menggunakan setiap dan
            seluruh fitur dari dan layanan yang terkait dengan Portal, maka Kamu telah menyetujui Kebijakan Privasi ini. Jika Kamu tidak setuju dengan Kebijakan Privasi ini, mohon untuk tidak mengunjungi, mendaftar, memberikan informasi,
            maupun menggunakan fitur apapun pada website/aplikasi
          </Text>
          <Text fontSize="30px" p="20px">
            2. Persetujuan
          </Text>
          <Text fontSize="20px" mx="80px">
            Dengan mengunjungi, mendaftar, memberikan informasi, menggunakan website/aplikasi, menggunakan layanan Kami terkait website/aplikasi, maupun berkorespondensi dengan Kami dengan media apapun juga, maka Kamu menyetujui bahwa Kami
            dapat mengumpulkan Informasi Pribadi untuk kegunaan Kami dalam bentuk pemasaran maupun monetisasi data dengan pihak luar perusahaan.
          </Text>
          <Text fontSize="30px" p="20px">
            3. Informasi Pribadi yang Dapat Kami Kumpulkan
          </Text>
          <Text fontSize="20px" mx="80px">
            Informasi Pribadi yang Dapat Kami Kumpulkan adalah sebagai berikut:
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            1. Informasi yang Kamu Berikan
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kamu dapat memberikan informasi melalui formulir elektronik pada website/aplikasi Kami maupun dengan berkorespondensi melalui telepon, surat elektronik, media sosial, maupun dengan metode lainnya.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Informasi ini termasuk namun tidak terbatas pada informasi yang Kamu berikan ketika mengisi survei (baik melalui website/aplikasi, surat elektronik, sosial media, maupun dengan metode lainnya), mendaftar pada website/aplikasi,
            menggunakan setiap dan seluruh fitur website/aplikasi, berlangganan layanan Kami, berdiskusi dengan Kami, informasi sehubungan dengan permohonan refund atas biaya berlangganan pada website/aplikasi Kami, testimoni, dan
            sebagainya.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Informasi yang Kamu berikan dapat meliputi nama, alamat surat elektronik, informasi seputar media data diri kamu, informasi nomor telepon, surat elektronik dan data lainnya. Kami dapat meminta Kamu untuk melakukan verifikasi
            terhadap informasi yang Kamu berikan, untuk memastikan akurasi dari informasi tersebut.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            2. Informasi yang Kami Terima dari Sumber Lain
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kami juga dapat mengumpulkan informasi pribadi melalui teknologi deteksi lainnya yang umum digunakan pada situs web, surat elektronik, maupun media sosial.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Kami juga dapat bekerja sama dengan pihak ketiga (termasuk, namun tidak terbatas pada misalnya, mitra bisnis, sub-kontraktor dalam pelayanan teknis, jasa pembayaran, jaringan periklanan, penyedia analisis, penyedia pencarian
            informasi) (“Mitra Pihak Ketiga”) dan dapat menerima Informasi Pribadi dari mereka.
          </Text>
          <Text fontSize="30px" p="20px">
            4. Informasi yang Dikecualikan
          </Text>
          <Text fontSize="20px" mx="80px">
            Kami bekerja sama dengan penyedia layanan payment gateway pihak ketiga sehubungan dengan pembayaran yang Kamu lakukan Ketika berlangganan layanan Kami melalui website/aplikasi. Selain dari informasi ringkasan transaksi yang Kami
            dapatkan dari penyedia layanan payment gateway, Kami tidak mengumpulkan maupun menyimpan informasi pembayaran (misalnya informasi mengenai kartu kredit) yang Kamu masukkan melalui sistem yang disediakan masing-masing layanan
            payment gateway.Setiap informasi pembayaran yang Kamu berikan saat melakukan pembayaran melalui layanan payment gateway merupakan informasi yang dikecualikan dari Kebijakan Privasi ini dan tunduk pada kebijakan privasi dari
            masing-masing penyedia layanan payment gateway. Kami tidak bertanggung jawab atas kebijakan privasi dari setiap penyedia layanan payment gateway.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Sebagaimana dijelaskan di atas, Kami juga dapat bekerja sama dengan Mitra Pihak Ketiga dalam menyediakan layanan Portal. Setiap dan seluruh informasi yang Kamu berikan kepada Mitra Pihak Ketiga sehubungan dengan hal tersebut
            merupakan informasi yang dikecualikan dari Kebijakan Privasi ini dan tunduk pada kebijakan privasi dari masing-masing penyedia layanan pihak ketiga.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Untuk menghindari keraguan, Kami tidak bertanggung jawab untuk setiap dan seluruh penyalahgunaan informasi yang dilakukan oleh penyedia layanan pihak ketiga.
          </Text>
          <Text fontSize="30px" p="20px">
            5. Penyimpanan Informasi Pribadi
          </Text>
          <Text fontSize="20px" mx="80px">
            Kami dapat menggunakan layanan pihak ketiga, baik yang disediakan di dalam maupun di luar negeri, untuk melakukan penyimpanan Informasi Pribadi, dalam hal mana Kami akan mengambil langkah-langkah yang wajar dan komersil untuk
            memastikan bahwa Informasi Pribadi tersebut disimpan dengan aman dan sesuai dengan Kebijakan Privasi serta hukum yang berlaku (termasuk dengan cara menunjuk pihak ketiga yang kredibel untuk melakukan penyimpanan hal tersebut).
            Kami dapat, sewaktu-waktu sesuai dengan direksi Kami, menghapus permanen Informasi Pribadi yang Kami simpan. Ketentuan penyimpanan Informasi Pribadi berdasarkan bagian ini dimulai saat Kami telah menerima Informasi Pribadimu.
            Adapun seluruh risiko atas transmisi Informasi Pribadi yang Kamu lakukan berada di luar kuasa Kami dan bukan merupakan tanggung jawab Kami, untuk itu, mohon untuk memperhatikan keamanan atas transmisi informasi melalui internet
            pada perangkat maupun jaringan internet yang Kamu pergunakan.
          </Text>
          <Text fontSize="30px" p="20px">
            6. Hak Kamu atas Informasi Pribadi
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu memiliki hak-hak berikut atas Informasi Pribadi:
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            1. Mengubah Informasi Pribadi tertentu melalui website/aplikasi sesuai dengan prosedur yang telah Kami sediakan;
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            2. Memilih untuk tidak menerima komunikasi pemasaran dari Kami dengan menggunakan opsi yang Kami sediakan pada website/aplikasi maupun pada surat elektronik, dengan catatan, Kami tetap dapat menghubungi Kamu untuk keperluan
            non-promosi, seperti memberikan tanda terima atau meminta informasi tentang penggunaan website/aplikasi
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            3. Memohon untuk penghapusan Informasi Pribadi yang Kami simpan, dengan memberikan kepada Kami pemberitahuan yang wajar secara tertulis melalui kontak yang tercantum pada bagian akhir Kebijakan Privasi ini. Kamu sepenuhnya
            mengerti dan mengakui bahwa, bergantung pada keadaan atau sifat serta penggunaan Informasi Pribadi oleh Kami: (i) Kamu mungkin tidak dapat lagi menggunakan layanan website/aplikasi, (ii) Informasi Pribadi yang telah diungkapkan
            kepada dan beredar luas di publik sebagai bagian dari pemasaran (misalnya, testimoni) tidak dapat dihapus, (iii) Agregat dari informasi, hasil analisis, statistik, maupun bentuk-bentuk informasi lainnya yang tidak mengandung
            informasi personal dirimu merupakan bagian yang dapat terus Kami pergunakan sesuai ketentuan Kebijakan Privasi ini.
          </Text>
          <Text fontSize="30px" p="20px">
            7. Perubahan dalam Kebijakan Privasi
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu mengakui dan menyetujui bahwa Kami dapat melakukan perubahan sebagaimana diperlukan dan/atau sebagaimana diwajibkan oleh hukum yang berlaku, terhadap Kebijakan Privasi ini. Sebagai konsekuensi, Kamu setuju untuk tunduk pada
            setiap perubahan Kebijakan Privasi.
          </Text>
          <br />
          <br />
          <Text fontSize="20px" mx="80px">
            Perubahan apapun yang Kami lakukan terhadap Kebijakan Privasi Kami di masa mendatang akan diterbitkan melalui halaman ini dan, ketika dibutuhkan, diberitahukan kepada Kamu melalui surat elektronik. Mohon kunjungi kembali halaman
            ini dari waktu ke waktu untuk melihat adanya perubahan pada Kebijakan Privasi ini.
          </Text>
          <Text fontSize="30px" p="20px">
            CARA MENGHUBUNGI KAMI
          </Text>
          <Text fontSize="20px" mx="80px">
            Kamu dapat menghubungi Kami melalui info@{window.location.hostname} jika Kamu memiliki pertanyaan mengenai Kebijakan Privasi ini dan/atau melakukan koreksi terhadap Informasi Pribadi Kamu.
          </Text>
          <br />
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default KebijakanPrivasiPage;
