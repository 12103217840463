import axios from "axios";


export const getDataPasarDana = async ()=>{
	var config = {
		method: 'get',
		url: 'https://docial-crypto-h3tahmnrkq-uc.a.run.app/api/v1/saham-indonesia',
	  };
	  
	  try {
		  const result = axios(config)
		  return result
		  
	  } catch (error) {
		  console.log(error)
		  
	  }
	//   axios(config)
	//   .then(function (response) {
	// 	console.log(response.data);
	// 	return response.data
	//   })
	//   .catch(function (error) {
	// 	console.log(error);
	//   });
}
