// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getFunctions, } from 'firebase/functions';
import song from "../assets/CoinDrop-Notification.mp3"

const algoTrading = {
	//algotrading
	apiKey: "AIzaSyCyaG-hz_NRGRWEKjKaGYAbqfnSDZCK4l8",
	databaseURL: "https://algotradingid-default-rtdb.asia-southeast1.firebasedatabase.app",
	authDomain: "algotradingid.firebaseapp.com",
	projectId: "algotradingid",
	storageBucket: "algotradingid.appspot.com",
	messagingSenderId: "578252014255",
	appId: "1:578252014255:web:0391a94a6d01457af04a5a",
	measurementId: "G-QW1XKC2XEF",
	token_option:"BMtZ_RUdLM94egcL0EZ8tuJlY4pE7exw2iLnlmrKdDDO77OV5qimEGJE3A9Dj63hAXIac65jVWGsGlUEeIosIpk"
}
const tradingSyariah = {
	//tradingsyariah
	apiKey: "AIzaSyAQA9weSbwgkupfQO2kQeodYUI0COSTU4Y",
	authDomain: "tradingsyariahid.firebaseapp.com",
	databaseURL: "https://tradingsyariahid-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "tradingsyariahid",
	storageBucket: "tradingsyariahid.appspot.com",
	messagingSenderId: "72696042993",
	appId: "1:72696042993:web:c57c57354e51ae9ba66050",
	measurementId: "G-0CYB4R3E19",
	token_option: "BOe6p-lGg4hDV_EH6rQbHio_9NAfO-_TDkT40LwzzydRdEa008NXL6lu69FAKavgSFd1J-qLKIHi6pGE-1KM7MA" // your vapid key
}

let firebaseConfig={}
if(window.location.hostname ==='algotrading.id'){
	firebaseConfig=algoTrading
}
else if(window.location.hostname ==='tradingsyariah.id'){
	firebaseConfig=tradingSyariah
}
else{
	firebaseConfig=algoTrading
}



const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage(app);
const configMessage = getMessaging(app)
const functions = getFunctions(app);
const database = getDatabase(app)


export { auth, db, app, storage,functions, database };

export const fetchToken = async (setTokenId) => {
	try {
		const token = await getToken(configMessage, { vapidKey: firebaseConfig.token_option })
		if (token) {
			// console.log(token,'this is push notif token')
			setTokenId(token);
			localStorage.setItem("token", token);
		}
		else{
			console.log('no push notif token for now')
		}
	} catch (error) {
	}
}

export const onMessageListener = (toast) => {
	onMessage(configMessage, (payload) => {
		const notif = new Audio(song)
		notif.play();
		const { notification } = payload
		const { title, body } = notification
		toast({
			title: title,
			description: body,
			position: 'top-right',
			isClosable: true,
		})
	});
};
