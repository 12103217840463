import { Box, Heading,Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function AppCard169(x) {
	// console.log(x,'ini appcard169')
	let width =400
	if(x.w) width = x.w
  return (
		// <Link to={x.title}>
		<Link to={`/watch/${x.video}`}>
			{/* <Heading color='white'  fontSize='large'>{x.title}</Heading> */}
			<Box _hover={{ transform: "scale(1.1)" }} transition={"0.2s ease-in-out"}>
				<Image w={`${width}px`} src={x.image}/>
			</Box>
		</Link>			  
	
  )
}

export default AppCard169