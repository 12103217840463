import React from "react";
import AppBarAdminHeader from "../../Components/AppBars/admin/AppBarAdminHeader";
import AppBarTrailer from "../../Components/AppBars/admin/AppBarTrailer";

function TrailerPage() {
  return (
    <>
      <AppBarAdminHeader />
      <AppBarTrailer />
    </>
  );
}

export default TrailerPage;
