import React from 'react'
import Footer from '../Components/AppBars/Footer'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'
import StepBoarding from '../Components/AppBars/OnBoarding/StepBoarding'

function StepPage() {
  return (
    <>
    <HeaderResponsive/>
	<StepBoarding />
	<Footer/>
    </>
  )
}

export default StepPage