import { Box } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import AppBarWatch from "../Components/AppBars/AppBarWatch";
import AppBodyMain from "../Components/AppBars/AppBodyMain";
import Footer from "../Components/AppBars/Footer";
// import Header from '../Components/AppBars/Header'
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function WatchPage() {
  const param = useParams();
  return (
    <>
      <HeaderResponsive />
      {/* <Header/> */}
      <AppBarWatch id={param.id} />
      <AppBodyMain />
      <Footer />
    </>
  );
}

export default WatchPage;
