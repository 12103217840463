import React, { useEffect, useState, useContext } from "react";
import { Box, Flex, Text, IconButton, Button, Stack, Collapse, Icon, Popover, PopoverTrigger, PopoverContent, useColorModeValue, useDisclosure, Image, Input, Center, Avatar, Toast, HStack, useColorMode, MenuButton, MenuList, MenuItem, Menu, MenuGroup, MenuDivider, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon, } from "@chakra-ui/icons";
import store from "store"
import tradingSyariahImage from "../../Img/TS_ori_200.png"
import algoTradingImage from "../../Img/algotradinglogo.png"
import { Link, useNavigate } from "react-router-dom";
import colors from "../../Utils/colors";
import AuthContext from "../../Routes/hooks/AuthContext";
import getDomain from "../../Utils/getDomain";
import { AiOutlineLogout } from "react-icons/ai";

export default function HeaderResponsive() {
  const { isOpen, onToggle } = useDisclosure();
  const [search, setSearch] = useState("");
  const [subscripUser, setSubscripUser] = useState("");
  const { currentUser, signOut, getUserStorage } = useContext(AuthContext)
  
  let navigate = useNavigate();

  const getUserData = () => {
    const data = getUserStorage()
    setSubscripUser(data)
    console.log(data, 'data storage')
  }

  useEffect(() => {
    getUserData()

    return () => {
    }
  }, [])

  const handleLogOut = () => {
    signOut()
      .then(() => {
        navigate("/", { replace: true });
        store.clearAll()
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search/${search}`);
    }
  };

  return (
    <Box>
      <Flex bgColor="black" color="white" minH={"60px"} py={{ base: 2 }} px={{ base: 4 }} borderBottom={1} borderStyle={"solid"} align={"center"}>
        <Flex flex={{ base: 1, md: "auto" }} ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
          <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={"ghost"} aria-label={"Toggle Navigation"} />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }} alignItems="center">
          <Box w={{ base: '100px', md: '200px' }} x mr='5'>
            <Link to="/">
              <Image src={getDomain() === "tradingsyariahid" ? tradingSyariahImage : algoTradingImage} />
            </Link>
          </Box>
          <Flex display={{ base: "none", md: "flex" }}>
            <Center>
              <DesktopNav />
            </Center>
          </Flex>
        </Flex>
        <Stack flex={{ base: 1, md: 0 }} justify={"flex-end"} alignItems="center" direction={"row"} spacing={2}>
          <Box p="2" objectPosition="right">
            <Input
              width={{ base: '20', sm: 'min' }}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Box>
          {currentUser ? (
            <>
              <Menu>
                <MenuButton rightIcon={<ChevronDownIcon />}>
                  {subscripUser?.photoProfile ? <Avatar size={["sm", null, "md"]} name={subscripUser?.photoProfile} src={subscripUser?.photoProfile} /> : <Avatar size="md" name={currentUser.displayName} src={currentUser.displayName} />}
                </MenuButton>
                <MenuList>
                  <MenuGroup title='Profile'>
                    <MenuItem onClick={() => navigate('/profileuser', { replace: true })}>
                      <Text fontSize="sm">My Account</Text>
                    </MenuItem>
                    {subscripUser?.role !== "user" &&
                      <a href={'https://algotrading.tapfiliate.com/ '}>
                        <MenuItem>
                          <Text fontSize="sm">Affiliate Account</Text>
                        </MenuItem>
                      </a>
                    }
                    {subscripUser?.role === "user" && (
                      <MenuItem onClick={() => navigate(`/payment/member/${currentUser.uid}`, { replace: true })}>
                        <Text fontSize="sm">Payment Member</Text>
                      </MenuItem>

                    )}
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup title='Help'>
                    <MenuItem onClick={() => navigate('/onboarding', { replace: true })}>
                      <Text fontSize="sm">OnBoarding</Text>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/kebijakanprivasi', { replace: true })}>
                      <Text fontSize="sm">Docs</Text>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/syaratketentuan', { replace: true })}>
                      <Text fontSize="sm">FAQ</Text>
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
              <Box onClick={() => handleLogOut()} cursor="pointer" px={1}>
                <AiOutlineLogout color="red" size="20px" />
              </Box>
            </>
          ) : (
            <Button color={'black'} bgColor={colors.theme} fontSize={"sm"} fontWeight={600} onClick={() => navigate('/login', { replace: true })}>
              Login
            </Button>
          )}
        </Stack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
      {
        subscripUser?.role === "user" && (
          <Alert status="error" bgColor="red.200" onClick={() => navigate(`/payment/member/${currentUser.uid}`, { replace: true })} cursor="pointer">
            <AlertIcon color="red" />
            <AlertTitle color="black">Kamu belum memiliki akses membership untuk mengakses fitur ini.</AlertTitle>
            <AlertDescription color="black">Kamu dapat membeli membership terlebih dahulu disini.</AlertDescription>
          </Alert>
        )}
    </Box>
  );
}



const DesktopNav = () => {
  const linkColor = useColorModeValue("white");
  const linkHoverColor = useColorModeValue("white");
  const popoverContentBgColor = useColorModeValue("grey");
  const link = getDomain()

  return (
    <Stack direction={"row"} spacing={4}>
      {link === 'algotradingid' ? (
        NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Link
                  p={2}
                  to={navItem.to}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  <Text fontSize="xl" fontFamily="Bebas Neue">
                    {navItem.label}
                  </Text>
                </Link>
              </PopoverTrigger>
              {navItem.children && (
                <PopoverContent border={0} boxShadow={"xl"} bg={popoverContentBgColor} p={4} rounded={"xl"} minW={"sm"}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))
      ) : (
        NAV_ITEMS_TS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Link
                  p={2}
                  to={navItem.to}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  <Text fontSize="xl" fontFamily="Bebas Neue">
                    {navItem.label}
                  </Text>
                </Link>
              </PopoverTrigger>
              {navItem.children && (
                <PopoverContent border={0} boxShadow={"xl"} bg={popoverContentBgColor} p={4} rounded={"xl"} minW={"sm"}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))
      )}
    </Stack>
  );
};

const DesktopSubNav = ({ label, to, subLabel }) => {
  return (
    <Link to={to} role={"group"} display={"block"} p={2} rounded={"md"} _hover={{ bg: useColorModeValue("black") }}>
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text transition={"all .3s ease"} _groupHover={{ color: "white" }} fontSize="xl" fontFamily="Bebas Neue">
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex transition={"all .3s ease"} transform={"translateX(-10px)"} opacity={0} _groupHover={{ opacity: "100%", transform: "translateX(0)" }} justify={"flex-end"} align={"center"} flex={1}>
          <Icon color={"white"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  const link = getDomain()
  return (
    <Stack bg={useColorModeValue("black")} p={4} display={{ md: "none" }}>
      {link === "algotradingid" ? (
        NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))
      ) : (
        NAV_ITEMS_TS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))
      )}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, to }) => {
  const { isOpen, onToggle } = useDisclosure();

  const link = getDomain()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        to={to ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontSize="xl" fontFamily="Bebas Neue" color={useColorModeValue("white")}>
          {label}
        </Text>
        {children && <Icon as={ChevronDownIcon} transition={"all .25s ease-in-out"} transform={isOpen ? "rotate(180deg)" : ""} w={6} h={6} />}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={"solid"} align={"start"}>
          {children &&
            children.map((child, index) => (
              <Text key={index}>
                <Link
                  //    py={2} 
                  to={child.href}>
                  {child.label}
                </Link>
              </Text>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS =
  [
    {
      label: "Home",
      to: "/",
    },
    {
      label: "Master Class",
      to: "/masterclass",
    },
    {
      label: "Events",
      to: "/events",
    },
    {
      label: "Market",
      to: "/market/all",

    },
    {
      label: "Patterns",
      to: "/patterns/all",
    },
    {
      label: "Trading Plan",
      to: "/trading-plan",
    },
  ];

const NAV_ITEMS_TS =
  [
    {
      label: "Home",
      to: "/",
    },
    {
      label: "Master Class",
      to: "/masterclass",
    },
    {
      label: "Events",
      to: "/events",
    },
    {
      label: "Market",
      to: "/market/stockid",
    },
    {
      label: "Patterns",
      to: "/patterns/all",
    },
    {
      label: "Trading Plan",
      to: "/trading-plan",
    },
  ];

