import { storage } from "../Configs/firebase";
import { ref, uploadString, uploadBytes, getDownloadURL } from "firebase/storage";
import getDomain from "./getDomain";

//using uploadString
//data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAA

export const upload = async (title, type, image) => {
  console.log(title, "in upload image.js");

  // you can use parent/child/grandchild/greatgrandchild format
  const path = `${type}/${title}`;

  const storageRef = ref(storage, path);
  uploadString(storageRef, image, "data_url")
    .then((snapshot) => {
      console.log("Uploaded a data_url string!", snapshot);
      return path;
    })
    .catch((error) => error.message);
};

export const uploadFile = async (title, type, file) => {

  const url = getDomain()
  const path = `${type}/${title}`;
  //speakers%2Fthumbnail-square%2FDanu%20Sofwan_400x400
  const thumbnailPath = `${type}/thumbnail/${title}_400x400`;
  const storageRef = ref(storage, path);
  console.log(file, "file");
  try {
    console.log(url, 'ini url')
    console.log(getDomain(), 'ini domain')
    const data = await uploadBytes(storageRef, file);
    console.log(data, "in upload file");
    const returnData = {
      image_original: `https://firebasestorage.googleapis.com/v0/b/${url}.appspot.com/o/${encodeURIComponent(path)}?alt=media`,
      image_thumbnail: `https://firebasestorage.googleapis.com/v0/b/${url}.appspot.com/o/${encodeURIComponent(thumbnailPath)}?alt=media`,
    };
    return returnData;
  } catch (error) {
    console.log(error.message);
  }
  // uploadBytes(storageRef, file).then((snapshot) => {
  // 	console.log('Uploaded a blob or file!',snapshot);
  // })
  //   .catch((error)=>console.log(error.message))
  //   ;
};

export const uploadPhotoProfile = async (title, type, file) => {
  console.log(title, "in upload photoProfile");
  const path = `${type}/${title}`;
  const storageRef = ref(storage, path);

  const uploadImage = await uploadBytes(storageRef, file);
  console.log(uploadImage, "data upload image");

  const imageURL = await getDownloadURL(uploadImage.ref);
  console.log(imageURL, "data imageURL");

  return imageURL;
};
