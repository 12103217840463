import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, theme, extendTheme, } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { AuthProvider } from "./Routes/AuthProvider";
import TagManager from 'react-gtm-module'
import Tap from "@tapfiliate/tapfiliate-js";

import { onBackgroundMessage, getMessaging } from "firebase/messaging/sw";

// Initialize the Firebase app in the service worker by passing the generated config
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('../firebase-messaging-sw.js')
    .then(function (registration) {
      // Successful registration
      console.log('Hooray. Registration successful, scope is:', registration.scope);
    }).catch(function (error) {
      // Failed registration, service worker won’t be installed
      console.log('Whoops. Service worker registration failed, error:', error);
    });
  });
}


const themes = extendTheme({
  config:{
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },

  components: {
    Steps,
  },
  shadows: {
    purple: '0 0 0 3px rgba(159, 122, 234, 0.6)'
  },
  colors: {
    brand: {
      100: "#ffd600",
      900: "#1a202c",
    },
  },
});
Tap.init('35266-eec8f4');


document.title = process.env.REACT_APP_NAME;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(  
  <BrowserRouter>
    <ChakraProvider theme={themes}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ChakraProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
