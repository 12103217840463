import React from "react"
import {Modal,Input, Progress, ModalOverlay,ModalContent,ModalCloseButton, ModalFooter,ModalHeader,ModalBody,Button,Box} from "@chakra-ui/react"
import { useState } from "react"
import * as tus from "tus-js-client";

export default function Filemanager({isOpen, onClose, pickFile}){
    const finalRef = React.useRef(null)
    const ACCOUNT_ID = process.env.REACT_APP_CLOUDFLARE_ACCOUNT;
    const API_KEY = process.env.REACT_APP_CLOUDFLARE_TOKEN;
    const bearer = `Bearer ${API_KEY}`;
    const [progress, setProgress] = useState(0)
    const [asset, setAsset] = useState(null)
    const [hasFinished, setHasFinished] = useState(false)

    const uploadVideo = async (arg) => {
        const options = {
          endpoint: `https://api.cloudflare.com/client/v4/accounts/${ACCOUNT_ID}/stream`,
          headers: {
            Authorization: bearer,
          },
          chunkSize: 50 * 1024 * 1024,
          metadata: {
            name: arg[0].name,
            filetype: arg[0].type,
            defaulttimestamppct: 0.5,
          },
          uploadSize: arg[0].size,
          onError: function (error) {
            throw error;
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setProgress(percentage)
          },
          onSuccess: function (req,) {
            console.log("Upload finished");
            setHasFinished(true)
          },
          onAfterResponse: function (req, res) {
            return new Promise((resolve) => {
              var mediaIdHeader = res.getHeader("stream-media-id");
              let mediaId = "";
              if (mediaIdHeader) {
                mediaId = mediaIdHeader;
                setAsset(mediaId)
              }
              resolve();
            });
          },
        };
        var upload = new tus.Upload(arg[0], options);
        upload.start();
      };

    const onSetFile = () => {
        pickFile(asset)
        onClose()
    }

    return(
        <>
            <Modal finalFocusRef={finalRef}onClose={onClose} isOpen={isOpen} size="xl">
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Modal Title</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                   <Box border="1px" rounded="lg" borderColor="coolGray.100">
                        <Input  onChange={(e) => uploadVideo( e.target.files)} type="file"  height="100%" width="100%" py="10" cursor="pointer" border="0"/>
                   </Box>
                   <Box py="5">
                        <Progress value={progress} />
                   </Box>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='ghost' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button colorScheme='blue' disabled={!hasFinished} onClick={() => onSetFile()} >Use file</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}