import { Box, Text, SimpleGrid, Flex, Button, Center, Spinner } from "@chakra-ui/react";
import { collection, getDocs, limit, query } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../Configs/firebase";
import AppCard43 from "../AppComponents/AppCard43";

function AppBarSpeakersLimit(props) {
  let navigate = useNavigate();
  const [data, setData] = useState();

  const getData = async () => {
    const q = query(collection(db, "speakers"), limit(4));
    const querySnapshot = await getDocs(q);
    let newArr = [];
    querySnapshot.forEach((doc) => {
      let newData = doc.data();
      newData.id = doc.id;
      newArr.push(newData);
      console.log(newData, "ini mau liat data yang di kirim");
    });
    setData(newArr);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box bgColor="black">
        <Flex flexWrap="wrap" spacing="24px" gap="16px" justifyContent="space-evenly">
          <SimpleGrid columns={[1, null, 3]} gap="5" marginTop={props.marginTop} position="relative">
            {data ? data.map((x) => <AppCard43 name={x.name} id={x.id} image={x.thumbnail} />) : 
            <Center>
                <Spinner color="white"/>
            </Center>
            }
          </SimpleGrid>
        </Flex>
      </Box>
    </>
  );
}

export default AppBarSpeakersLimit;
