import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Heading, HStack, IconButton, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../Utils/colors';
import TextHelper from '../../../../Utils/TextHelper';

function TableUser({filter, data, EditDataComponent, handleDelete, titleTable, setTitleTable}) {
  const navigate = useNavigate()
  const [dataFilter, setDataFilter] = useState([])
  const [dataSort, setDataSort] = useState([])


  const sortBy = (key) => {
        const temp = titleTable; 
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 1:
            if (titleTable[index].filter){
              return setDataSort([
                ...dataFilter.sort((a, b) => b.name.localeCompare(a.name)),
              ]);
            }
            return setDataSort([
              ...dataFilter.sort((a, b) => a.name.localeCompare(b.name)),
            ]);

                case 3:
                    if (titleTable[index].filter){
                      return setDataSort([
                        ...dataFilter.sort((a, b) => b.email.localeCompare(a.email)),
                      ]);
                    }
                    return setDataSort([
                      ...dataFilter.sort((a, b) => a.email.localeCompare(b.email)),
                    ]);  


          default:
            return null
        }
  }

  const getData = () => {
    const dataArr = data
    const filterData = dataArr.filter((x) => x.role === filter)
    if(filter === ""){
      setDataFilter(dataArr)
      console.log("gaada")
    }else{
      setDataFilter(filterData)
      console.log('ada')
    }
  }

  // console.log(data, 'ini data')

  useEffect(() => {
    getData()
  
    return () => {
      setDataFilter([])
    }
  }, [filter])

  return (
    <Box>
    <TableContainer mx={10}>
      <Table variant="simple">
        <Thead bgColor={colors.theme}>
          <Tr>
          {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => sortBy(x.title)}
                   >
           <HStack spacing="1">
             <Text color={colors.black}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.black}/>
           </HStack>
         </Button>
        </Th>
        ))}
          </Tr>
        </Thead>
        <Tbody>
          
          {dataFilter ? (
             dataFilter?.map((x, index) => (
              <Tr cursor="pointer" >
                 <Td>
                  <Text align="center">
                  {++index}
                  </Text>
                  </Td>
                <Td>
                <HStack spacing={3}>
                    <Box>
                    <Avatar size="md" name={x.photoProfile} src={x.photoProfile} />
                    </Box>
                <Stack spacing={2} >
                    <Text>{x.name}</Text>
                    <Box>
                    {x.role === "admin" ? (<Tag bgColor="yellow" color="black">Admin</Tag>) : x.role === "member" ? (<Tag bgColor="green">Member</Tag>) : x.role === "user" ? <Tag bgColor="red">User</Tag> : "" }
                    </Box>
                    <Text size="md">Exp : {x.expired_user && new Date(x.expired_user).toLocaleString()}</Text>
                </Stack>
                </HStack>
                </Td>
                <Td>
                  <Stack>
                  <Text>{TextHelper(x.uid_user)}</Text>
                  <Text>{moment(x.update.seconds*1000).toLocaleString()}</Text>
                  <Text textTransform={'capitalize'}>Package : {x.packageUser ? x.packageUser : "Belum Terdaftar"}</Text>
                  </Stack>
                  </Td>
                <Td>
                    <Stack spacing={2}>
                        <Box>
                        {x.email}
                        </Box>
                        <Box>
                        {x.phoneNumber}
                        </Box>
                        <Box>
                        {x.city}
                        </Box>
                    </Stack>
                </Td>
                <Td  color={colors.white}>
                    {/* <a href={`/watch/${x.video}`} target="_blank" rel="noreferer noreferrer">
                      <IconButton m="2" aria-label="Search database" colorScheme="green" icon={<SearchIcon />} />
                    </a> */}
                    <IconButton onClick={() => EditDataComponent(x.uid_user)} m="2" aria-label="Edit database" colorScheme="yellow" icon={<EditIcon />} />
                    <IconButton onClick={() => handleDelete(x.uid_user)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
                  </Td>
              </Tr>
               ))
          ) : (<></>)}
        </Tbody>
      </Table>
    </TableContainer>
  </Box>
  )
}

export default TableUser