import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons'
import { Box, Button, Heading, HStack, IconButton, Image, Select, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi'
import { FiPlus } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import colors from '../../../../Utils/colors'
import getDomain from '../../../../Utils/getDomain'
import TextHelper from '../../../../Utils/TextHelper'

function TableContent({data, EditDataComponent, handleDelete, handleAdd}) {

  const [filter, setFilter] = useState("")
  const [dataFilter, setDataFilter] = useState([])
  const [dataSort, setDataSort] = useState(dataFilter)

  const navigate = useNavigate()
  const link = getDomain()


  const getData = () => {
    const dataArr = data
    console.log(dataArr, 'dataarr')
    const filterData = dataArr.filter((x) => x.tags.includes(filter))
    console.log(filterData, 'filter')
    if(filter === ""){
      setDataFilter(dataArr)
    }else{
      setDataFilter(filterData)
    }
  }

  useEffect(() => {
    getData()
  
    return () => {
      setDataFilter([])
    }
  }, [filter])

    const [titleTable, setTitleTable] = useState([
        {title: "ID", filter: false},
        {title: "Title", filter: false},
        {title: "Speaker", filter: false},
        {title: "Video ID", filter: false},
        {title: "Action", filter: false},
      ]);


    const sortBy = (key) => {
        const temp = titleTable;
  
        // set tiap data title mempunyai angka key
        const index = temp.findIndex((data) => data.title === key);
  
        // ini di jadiin angka terus ngerubah filter true false
        for (let i = 0; i < titleTable.length; i++) {
          if (index === i) {
            temp[i] = {
              title: titleTable[i].title,
              filter: !titleTable[i].filter,
            };
          } else {
            temp[i] = {
              title: titleTable[i].title,
              filter: false,
            };
          }
        }
        setTitleTable(temp)
        console.log(temp)
  
        // memasang switch case filter
        switch (index) {

          case 1:
            if (titleTable[index].filter){
              return setDataSort([
                ...dataFilter.sort((a, b) => b.title.localeCompare(a.title)),
              ]);
            }
            return setDataSort([
              ...dataFilter.sort((a, b) => a.title.localeCompare(b.title)),
            ]);
  
          default:
            return null
        }
      }



  return (
    <Box>

    <HStack m={10}>
      <Box>
        <Heading size="lg">
          Content Admin
        </Heading>
      </Box>
      <Spacer />
      <HStack>

      <Box>
        <Button justifyContent="flex-end" onClick={handleAdd}>
          <HStack>
            <FiPlus/>
            <Text>New Content</Text>
            </HStack>
        </Button>
        </Box>

      <Select placeholder='Filter by' onChange={(e) => setFilter(e.target.value) }>
      <option value='basic'>Basic</option>
      <option value='intermediate'>Intermediate</option>
      <option value='advance'>Advance</option>
      <option value='candlestick'>Candle Stick</option>
    </Select>
      </HStack>
    </HStack>

    <TableContainer  mx={10}>
    <Table variant="simple">
      <Thead>
        <Tr bgColor={colors.theme}>
        {titleTable.map((x) => (
           <Th>
           <Button
            key={x.title}
             variant="ghost"
             onClick={() => sortBy(x.title)}
                   >
           <HStack spacing="1">
             <Text color={colors.black}>{x.title}</Text>
             <BiSort fontWeight="bold" color={colors.black}/>
           </HStack>
         </Button>
           </Th>
        ))}
        </Tr>
      </Thead>
      <Tbody>
        {dataFilter ? (
          dataFilter.map((x) => {
            const include = link === "algotrading" ? ["dean" , "ayas", "edwin"] : ["edwin", "azmi", "dzikra"]
            const data = (x?.tags?.filter((y) => include.includes(y)))
            const speaker = link === "algotradingid" ? (data[0] === "dean" ? "Dean" : data[0] === "edwin" ? "Edwin" : data[0] === "ayas" ? "Ayas" : "") : (data[0] === "azmi" ? "Azmi" : data[0] === "edwin" ? "Edwin" : data[0] === "dzikra" ? "Dzikra" : "")
            return(
            <Tr key={x.id}>
              <Td  color={colors.white}>{TextHelper(x.id)}</Td>
              <Td  color={colors.white}>{x.title}</Td>
              <Td  color={colors.white}>{speaker} </Td>
              <Td  color={colors.white}>
                <VStack>
              <Image src={x.thumbnail} width="200px"/>
                {TextHelper(x.video)}
                </VStack>
              </Td>
              <Td  color={colors.white}>
                <a href={`/watch/${x.video}`} target="_blank" rel="noreferer noreferrer">
                  <IconButton m="2" aria-label="Search database" colorScheme="green" icon={<SearchIcon />} />
                </a>
                <IconButton onClick={() => EditDataComponent(x.id)} m="2" aria-label="Edit database" colorScheme="yellow" icon={<EditIcon />} />
                <IconButton onClick={() => handleDelete(x.id,x.video)} m="2" aria-label="Delete database" colorScheme="red" icon={<DeleteIcon />} />
              </Td>
            </Tr>
          )})
        ) : (
          <></>
        )}
      </Tbody>
    </Table>
  </TableContainer>
  </Box>
  )
}

export default TableContent