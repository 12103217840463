import React from 'react'
import Footer from '../Components/AppBars/Footer'
import Header from '../Components/AppBars/Header'
import AppbarBodyCollection from '../Components/AppBars/AppBarBodyCollection'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'

function CollectionPage() {
  return (
	  <>
	  <HeaderResponsive/>
	  <AppbarBodyCollection/>
	  <Footer/>
	  </>
  )
}

export default CollectionPage