import { Box,Button,Table,
	Thead,
	Tbody,
	Tfoot,IconButton,
	Tr,
	Th,HStack,
	Td,Text,
	TableCaption,Select,
	TableContainer,
	useToast,
	Input,
	Spacer,
	Heading,
	Center,
	Divider,
	Image,
	VStack, } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons'
import { useState } from 'react'
import { db } from '../../../Configs/firebase'
import { doc, deleteDoc,setDoc,getDoc,collection, addDoc,getDocs, where, orderBy } from "firebase/firestore";

import { uploadFile } from '../../../Utils/FirebaseStorage'
import { useEffect } from 'react'
import AddDataEvent from '../../AppComponents/AddDataEvent'
import TableEvent from './TableAdmin/TableEvent'
import EditDataEvent from '../../AppComponents/EditDataEvent'


function AppBarAdminEvents() {
	const [add,setAdd]= useState(false)
	const [data,setData]=useState();
	// const [addData,setAddData] = useState({});
	// const [addFile,setAddFile]=useState();
	const [speakers,setSpeakers]=useState();
	const [editData,setEditData]=useState(null)



	const toast = useToast()


	// const handleAddData = (key,value)=>{
	// 	let newObject = addData
	// 	newObject[key]=value;
	// 	setAddData(newObject)
	// 	console.log(newObject)
	// }

	// const AddComponent = ()=>{
	// 	return(
			
	// )}
	
	const getData = async()=>{
		const querySnapshot = await getDocs(collection(db, "events"),
		orderBy("start", "asc")
		);
		let newArr=[]
		querySnapshot.forEach((doc) => {
		let newData =doc.data()
		newData.id=doc.id
		newArr.push(newData)
		});
		setData(newArr)
	}

	const getSpeakers = async()=>{
		const querySnapshot = await getDocs(collection(db, "speakers"));
		let newArr=[]
		querySnapshot.forEach((doc) => {
		let newData =doc.data()
		newData.id=doc.id
		newArr.push(newData)
		});
		setSpeakers(newArr)
	}

	const handleDelete = async(id)=>{
		const result = await deleteDoc(doc(db, "events", id));
			toast({
				title: 'Data Deleted',
				description: "",
				status: 'success',
				duration: 9000,
				isClosable: true,
			  })
			  getData()
	}

	const EditDataComponent = async(id)=>{
		console.log(id)
		const docRef = doc(db, "events", id);
		const docSnap = await getDoc(docRef);
		let data={};
		if (docSnap.exists()) {
		data= docSnap.data()
		data.id=id
		console.log(data)
		setEditData(data)
		} else {
		console.log("No such document!");
		}

	}

	const handleEdit = async()=>{
		console.log('in handle edit')
		setEditData()
	}

	
	useEffect(() => {
	  getData()
	  getSpeakers();
	}, [])
	
  return (
	  <>
	<Box p='5'>
		<Box>
		{add?<AddDataEvent speaker={speakers} getData={getData} setAdd={setAdd} handleCancel={() => setAdd(false)}/>:<></>}
		{editData !== null && <EditDataEvent editData={editData} setEditData={setEditData} data={getData}/>}
		{data && (
             <TableEvent data={data} EditDataComponent={EditDataComponent} handleDelete={handleDelete} handleAdd={() => setAdd(true)}/>
          )}
		</Box>
	</Box>

	</>
  )
}

export default AppBarAdminEvents