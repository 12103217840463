import { Box, Center, Heading, Spacer, Stack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import colors from '../../../Utils/colors'
import mindset from '../../../Img/mindset.png'
import account from '../../../Img/account.png'
import trading from '../../../Img/trading.png'
import indicators from '../../../Img/indicators.png'
import affiliate from '../../../Img/indicators.png'
import getDomain from '../../../Utils/getDomain'

function CardBoarding({title, keyword}) {
    const navigate = useNavigate()
    const link = getDomain()

    const mindsetImage = mindset
    const accountImage = account
    const tradingImage = trading
    const indicatorsImage = indicators
    const affiliateImage = affiliate

  return (
    <VStack
    transition={"0.2s ease-in-out"}
    _hover={{ transform: "scale(1.2)" }}
    border="1px"
    borderRadius="10px"
    bgRepeat="no-repeat"
    bgSize="cover"
    h="35vh"
    w="25vh"
    alignItems="center"
    // bgImage={props.image}
    onClick={() => navigate(`/onboarding/${keyword}`)}
  >
    {keyword === "mindset" && (
      <Box
      transition={"0.2s ease-in-out"}
      _hover={{ transform: "scale(1.2)" }}
      border="1px"
      borderRadius="10px"
      bgRepeat="no-repeat"
      bgSize="cover"
      h="35vh"
      w="25vh"
      bgImage={mindsetImage}
    />
    )}
     {keyword === "account" && (
      <Box
      transition={"0.2s ease-in-out"}
      _hover={{ transform: "scale(1.2)" }}
      border="1px"
      borderRadius="10px"
      bgRepeat="no-repeat"
      bgSize="cover"
      h="35vh"
      w="25vh"
      bgImage={accountImage}
    />
    )}
     {keyword === "trading" && (
      <Box
      transition={"0.2s ease-in-out"}
      _hover={{ transform: "scale(1.2)" }}
      border="1px"
      borderRadius="10px"
      bgRepeat="no-repeat"
      bgSize="cover"
      h="35vh"
      w="25vh"
      bgImage={tradingImage}
    />
    )}
     {keyword === "indicators" && (
      <Box
      transition={"0.2s ease-in-out"}
      _hover={{ transform: "scale(1.2)" }}
      border="1px"
      borderRadius="10px"
      bgRepeat="no-repeat"
      bgSize="cover"
      h="35vh"
      w="25vh"
      bgImage={indicatorsImage}
    />
    )}
       {keyword === "affiliate" && (
      <Box
      transition={"0.2s ease-in-out"}
      _hover={{ transform: "scale(1.2)" }}
      border="1px"
      borderRadius="10px"
      bgRepeat="no-repeat"
      bgSize="cover"
      h="35vh"
      w="25vh"
      bgImage={affiliateImage}
    />
    )}
    {link  === "algotradingid" ? (
        <Text pb={5} color={colors.light} fontSize="md" fontWeight="bold">Algotrading.id</Text>
    ) : (
        <Text pb={5} color={colors.light} fontSize="md" fontWeight="bold">Tradingsyariah.id</Text>
    )}
  </VStack>
  )
}

export default CardBoarding