import React, { useContext, useState } from "react";
import { Flex, Box, FormControl, FormLabel, Input, Center, Stack, Button, Heading, Text, useColorModeValue, useToast, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import colors from "../Utils/colors";
import AuthContext from "../Routes/hooks/AuthContext";
import { db, fetchToken } from "../Configs/firebase";
import { arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import store from "store"

function SignInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const [statusMember, setStatusMember] = useState(false)
  const toast =useToast()

  const { login, currentUser } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleLogIn = () => {
    login(email, password)
    .then(async(response) => {
      setLoading(true)
      try {
          const docRef = doc(db, "users", response.user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap) {
            store.set("user_data", docSnap.data())

          console.log('getting token')
          let tokenData = ""
          await fetchToken((res)=>
          tokenData = res
          )
        //   console.log(tokenData, 'ini data token')
          const ref= doc(db, "notification",response.user.uid);
          await setDoc(ref, {tokens:arrayUnion(tokenData) }, { merge: true });

          toast({
          title: "Success Login",
          description:
            `Success Login account ${response.user.email} `,
          status: "success",
          duration: 10000,
          isClosable: true,
          position: "top-right",
          })

          const q = query(collection(db, "users"), where("email", "==", email));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const data = doc.data().newmember
            if(data === true){
                return navigate("/");
            }else{
              return navigate("/")
            }
          });
          // return navigate("/");
          } else {
            console.log("No such document!");
            toast({
              title: "No Such User",
              description:
                `No Such user under your email `,
              status: "error",
              duration: 10000,
              isClosable: true,
              position: "top-right",
              })
          }
        } catch (error) {
          console.log(error, 'ini error')
          toast({
            title: "Error",
            description:
            error.message,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "top-right",
            })
        }

     
    })
    .catch((error) => {
      toast({
        title: "Something wrong !",
        description:
          error.message,
        status: "error",
        duration: 10000,
        isClosable: true,
        position: "top-right",
      })
    });
    setLoading(false)
  };

  const keyDown = (e) => {
    if (e.key === "Enter"){
      handleLogIn();
    };
  };

  return (
    <>
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")} bgColor="black">
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} color="white">
              Sign in to your account
            </Heading>
            <Text fontSize={"lg"} color={"gray.600"}></Text>
          </Stack>
          <Box rounded={"lg"} bg={useColorModeValue(colors.theme, colors.theme)} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel color={colors.black}>Email address</FormLabel>
                <Input bgColor={colors.light} color={colors.black} onChange={(e) => setEmail(e.target.value)} type="email" />
              </FormControl>

              <FormControl id="password">
                <FormLabel color={colors.black}>Password</FormLabel>
                <Input bgColor={colors.light} color={colors.black}  onChange={(e) => setPassword(e.target.value)} type="password" onKeyDown={keyDown}/>
              </FormControl>
              <Stack spacing={10}>
                <Stack direction={{ base: "column", sm: "row" }} align={"start"} justify={"space-between"}>
                  <Link to="/forgetpassword" >
                    <Button bgColor={colors.black} color="white">Forgot Password?</Button>
                  </Link>
                  <Link to="/signup">
                    <Button color="white" bgColor={colors.black}> Sign Up</Button>
                  </Link>
                </Stack>
                <Center>
                  {loading ? (
                    <Center>
                  <Spinner color="black"/>
                  </Center>
                  )
                  : (
                    <Button
                   w="250px"
                   onClick={() => handleLogIn()}
                   bg={colors.black}
                   color={"white"}
                 >
                   Sign in
                 </Button>
                 )   
                }
                </Center>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}

export default SignInPage;
