import React from "react";
import AppBarAdminHeader from "../../Components/AppBars/admin/AppBarAdminHeader";
import AppBarUser from "../../Components/AppBars/admin/AppBarUser";

function UserPage() {  
  return (
    <>
      <AppBarAdminHeader />
      <AppBarUser />
    </>
  );
}

export default UserPage;
