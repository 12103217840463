import React from 'react'
import Footer from '../Components/AppBars/Footer'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'
import AppBarOnBoarding from '../Components/AppBars/OnBoarding/AppBarOnBoarding'

function OnBoardingPage() {
  return (
    <>
    <HeaderResponsive/>
	<AppBarOnBoarding />
	<Footer/>
    </>
  )
}

export default OnBoardingPage