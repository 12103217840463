import React from "react";
import video from "./rain.mp4";
import "./AppVideoBackground.css";
import { AbsoluteCenter, Box, Button, Heading, Text, AspectRatio, Tag, Badge, Image, VStack } from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import getDomain from "../../../Utils/getDomain";
import colors from "../../../Utils/colors";
// import Hls from "https://unpkg.com/hls.js/dist/hls.min.js"
// @import url("https://unpkg.com/hls.js/dist/hls.min.js")
function AppVideoBackground(props) {
  const navigate = useNavigate()

  const link = getDomain()

  const Video = async () => {
    // var hls = new Hls();
    // await hls.loadSource("https://videodelivery.net/5d5bc37ffcf54c9b82e996823bffbb81/manifest/video.m3u8");
    // hls.attachMedia(document.getElementById("cover-video"));
    //   console.log(hls)
  };
  return (
    <>
      <Box>
        {/* <AspectRatio maxW="560px" ratio={1}>
          <iframe title="naruto" src="https://www.youtube.com/embed/QhBnZ6NPOY0" allowFullScreen />
        </AspectRatio> */}
        <div class="video-wrapper">
        {props.videoUrl && (
            <video playsinline autoPlay muted loop poster="cake.jpg">
            <source src={props.videoUrl} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
          )}
          {props.image &&  (
            <Box 
            position='absolute'
            object-fit= "fill"
            bgSize={'cover'}
            >
            <Image src={props.image}/>
          </Box>
          )}
          <VStack
            bgGradient="revert"
            position="relative"
            // position={{ base: "Absolute", lg: "relative" }}
            color="white"
              ml={{ base: "8px", md: "20px", lg: "80px" }}

            textShadow="1px 1px 8px #000"
            dropShadow="xl"
            alignItems="start"
            // width="500px"
          >
            <Tag 
              textShadow="none"  
              bgColor={link === "algotradingid" ? (colors.theme) : ('#04AB0F')}
              color={link === "algotradingid" ? ("black") : ("white")}
              shadow='dark-lg'
              size="lg"
              >
                <Text fontWeight="bold" letterSpacing="1px">{props.tag}</Text>
            </Tag>
            {/* <Heading fontSize={{ base: "sm", md: "lg", lg: "6xl" }}>{props.title}</Heading> */}
            {/* <Text fontWeight="bold" fontSize="20px" >{props.contentB}</Text>
            <Text fontWeight="bold" fontSize="20px" mt={2} >{props.contentA}</Text>
            <Heading fontSize={{ base: "sm", md: "lg", lg: "5xl" }}>{props.title}</Heading> */}
            <VStack spacing={-1} align="start" w={link === "algotradingid" ? ("70%") : ("100%") }>
            <Text textAlign="start" fontWeight="bold" fontSize={{ base: "sm", md: "lg", lg: "5xl" }} >{props.contentA}</Text>
            <Text textAlign="start" fontWeight="bold" fontSize={{ base: "sm", md: "lg", lg: "5xl" }} >{props.contentB}</Text>
            <Text textAlign="start" fontWeight="light" letterSpacing="2px" text  fontSize={{ base: "sm", md: "lg", lg: "3xl" }}>{props.title}</Text>
            </VStack>
          </VStack>
        </div>
      </Box>
    </>
  );
}

export default AppVideoBackground;
