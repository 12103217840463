import React from "react";
import { Box, Text, Button, Center, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Error404Page() {
  return (
    <>
      <Box bgColor="black" color="white" h="100vh">
        <Center pt="100px">
          <VStack>
            <Text>404 Page Not Found</Text>
            <Text>Under Construction</Text>
            <Link to="/">
              <Button colorScheme="green">Home Page</Button>
            </Link>
          </VStack>
        </Center>
      </Box>
    </>
  );
}

export default Error404Page;
