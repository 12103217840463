import React from 'react'
import AppBarMarket from '../Components/AppBars/AppBarMarket'
import Footer from '../Components/AppBars/Footer'
import HeaderResponsive from '../Components/AppBars/HeaderResponsive'


function StocksPage() {

	return (
		<>
			<HeaderResponsive />
			<AppBarMarket />
			<Footer />
		</>

	)
}

export default StocksPage