import React, { useState, useEffect, useContext } from "react";
import { Box, Center, SimpleGrid, Text, Button, List, ListItem, ListIcon, HStack, VStack, Heading,Spinner } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Stream } from "@cloudflare/stream-react";
import AppCard169 from "../AppComponents/AppCard169";
import { MdCheckCircle } from "react-icons/md";
import { collection, query, doc, where, getDocs, getDoc } from "firebase/firestore";
import {  db } from "../../Configs/firebase";
import AuthContext from "../../Routes/hooks/AuthContext";

function AppBarWatch(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [membership, setMembership] = useState(false);
  const [dataContent, setDataContent] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [tagsData, setTagsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useContext(AuthContext)

  let navigate = useNavigate();
  let param = useParams();
  console.log(param, 'ini data param')
  //   console.log(searchParams.get("type"), "this is the params");

  const getUser = async () => {
    const user = currentUser;

    if (user) {

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();

        if (userData.role === "user") {
          const q = query(collection(db, "content"), where("video", "==", param.id));
          const docSnap = await getDocs(q);
          docSnap.forEach((doc) => {
            setDataContent(doc.data());
          });
          console.log(doc.data(), "doc.data");
          setMembership(false);
        }
        if (userData.role === "admin") {
          console.log('admin')
          setMembership(true);
          videoID();
        }
        if (userData.role === "member") {
          setMembership(true);
          videoID();
        }
      }
    }else{
      navigate("/login", { replace: true });
    }
  };

  // const link = getDomain()

  // const getDataGTMLayer = () => {
  //   if(link === "algotradingid"){
  //     const tagManagerArgs = {
  //       gtmId: 'GTM-58P7VTB',
  //       dataLayer: {
  //           js: new Date(),
  //           config: 'AW-10974677091'
  //       }
  //     }
     
  //     TagManager.initialize(tagManagerArgs) 
  //   }
  // }

  // const navigateMaster = () => {
  //   // getDataGTMLayer()
  //   navigate(`/payment/member/${param.id}`)
  // }

  const navigateUnlimited = () => {
    // getDataGTMLayer()
    navigate(`/payment/member/${currentUser.uid}`, {replace: true})
    
  }


  

  const videoID = async () => {
  setLoading(true)
    if (searchParams.get("type") === "speakers") {
      console.log("type = speakers param = ", param.id);
      const q = query(collection(db, "content"), where("speakers", "==", param.id));
      const querySnapshot = await getDocs(q);
      console.log("waiting query snapshot");
      querySnapshot.forEach((doc) => {
        setDataContent(doc.data());
        setVideoId(doc.data().video);
      });
    } else {
      const q = query(collection(db, "content"), where("video", "==", param.id));
      const docSnap = await getDocs(q);
      docSnap.forEach((doc) => {
        setDataContent(doc.data());
        setVideoId(doc.data().video);
      });
    }
    setLoading(false)
  };


  useEffect(() => {
    getUser();

    return () => {
      setDataContent([])
      setTagsData([])
    }
  }, [param])

  
  useEffect(() => {
    videoID()
  
    return () => {
    }
  }, [videoId])
  
  

  return (
    <>
      <Box bg="black" color="white" width="full">
        {/* {!membership && (
          <Alert status="error" bgColor="red.200">
            <AlertIcon color="red" />
            <AlertTitle color="black">Kamu belum memiliki akses membership untuk mengakses video ini.</AlertTitle>
            <AlertDescription color="black">Kamu dapat membeli content atau membership terlebih dahulu.</AlertDescription>
          </Alert>
        )} */}
        <Center>
          <Box pt={25}>
            {/* <SimpleGrid columns={2}> */}
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }}>
              {loading ? (
                <Box p={5}>
                  <Spinner size="lg"/>
                </Box>
              ) : (
                membership ? (
                  <Box py={5}>
                    {console.log(videoId, 'slslsl')}
                    <Stream controls src={videoId} />
                    {/* <Text textAlign="center">{videoId ? videoId : <></>}</Text> */}
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Heading textAlign="center" mt="5" fontSize="3xl">
                        Membership
                      </Heading>
                      <SimpleGrid columns="1">
                        <VStack spacing={3} m="2" p="10" borderStyle="solid" borderWidth="thin" borderRadius="3xl" >
                          <Heading fontSize="2xl">Member</Heading>
                          <Heading fontSize="2xl">Rp3.500.000</Heading>
                          <List spacing={1}>
                          <ListItem mt="2">
                              <ListIcon as={MdCheckCircle} color="green.500" />
                              Akses analisis ++10.000 market
                            </ListItem>
                            <ListItem>
                              <ListIcon as={MdCheckCircle} color="green.500" />
                              Akses 1 tahun
                            </ListItem>
                          </List>
                          <Button bgColor="red" onClick={() =>  navigateUnlimited()}>
                            Beli
                          </Button>
                        </VStack>
                      </SimpleGrid>
                    </Box>
                  </>
                )  
              )}
              <Box bgGradient="linear(to-t, blackAlpha.300 20%, blackAlpha.700)" py={5}>
                <VStack spacing="20px" m="20px">
                  <Box>
                    <Text align="center" fontWeight="bold" fontSize={30} mx={5}>{dataContent.title}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">durasi : {dataContent.menit}</Text>
                  </Box>
                  <HStack>
                  </HStack>
                  <Box>
                    <Text>{dataContent.description}</Text>
                  </Box>
                </VStack>
                <SimpleGrid columns="5" gap="1" p="5">
                  {dataContent.length > 1 ? (
                    dataContent.map((x) => (
                      <AppCard169 w="150" title={x.title} image="https://images.unsplash.com/photo-1659314292830-b61add891cac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60" />
                    ))
                  ) : (
                    <></>
                  )}
                </SimpleGrid>
              </Box>
            </SimpleGrid>
          </Box>
        </Center>
      </Box>
    </>
  );
}

export default AppBarWatch;
