import React, { useContext, useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import { Box, Button, Container, FormControl, FormHelperText, FormLabel, Heading, HStack, Input, Stack, Select, Text, useBreakpointValue, useToast, Toast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { doc } from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import AuthContext from "../../Routes/hooks/AuthContext";
import colors from "../../Utils/colors";

function AppBarResetPassword({onClose}) {

    const { currentUser } = useContext(AuthContext);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmationNewPassword, setConfirmationNewPassword] = useState("");


    const toast = useToast();
    const navigate = useNavigate()
  
    const user = currentUser;
  
    const handleResetPassword = () => {
      console.log(user, "this is current user");
      if (password === newPassword) {
        toast({
          title: "Error",
          description: "Password lama sama dengan password baru!!",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
  
      if (newPassword !== confirmationNewPassword) {
        toast({
          title: "Error",
          description: "Password dan konfirmasi password tidak sama",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
  
      updatePassword(user, newPassword)
        .then((result) => {
            toast({
                title: "Success",
                description:
                  `Success Change Password`,
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "top-right",
                })
          // Update successful.
          navigate('/login')
        })
        .catch((error) => {
          // An error ocurred
          // ...
          console.log(error.message, "ini error update password");
        });
    };

  return (
    <>
      <Box  color="white">
            <Stack spacing="6" my={5}>
              <Stack >
                <FormControl isRequired>
                  <FormLabel htmlFor="password">Old Password</FormLabel>
                  <Input id="password" type="password" onChange={(e) => setPassword(e.target.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="password">New Password</FormLabel>
                  <Input id="password" type="password" onChange={(e) => setNewPassword(e.target.value)} />
                  <FormHelperText color="muted">At least 8 characters long</FormHelperText>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="password">Confirm New Password</FormLabel>
                  <Input id="password" type="password" onChange={(e) => setConfirmationNewPassword(e.target.value)} />
                  <FormHelperText color="muted">At least 8 characters long</FormHelperText>
                </FormControl>
              </Stack>
              <HStack spacing="4">
              <Button bgColor={colors.theme} color="black" onClick={() => onClose()} variant="primary">
                 Cancel
                </Button>
                <Button bgColor="red.300" color="black" onClick={() => handleResetPassword()} variant="primary">
                  Change Password
                </Button>
              </HStack>
            </Stack>
      </Box>
    </>
  )
}

export default AppBarResetPassword