import { Box, Button, Table, Thead, Tbody, Tfoot, IconButton, Tr, Th, HStack, Td, Text, TableCaption, Select, TableContainer, useToast, Input, Spacer, Heading, Center, Divider } from "@chakra-ui/react";
import React, { useRef } from "react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { db } from "../../../Configs/firebase";
import { doc, deleteDoc, setDoc, getDoc, collection, addDoc, getDocs } from "firebase/firestore";
import * as tus from "tus-js-client";
import { uploadFile } from "../../../Utils/FirebaseStorage";
import { useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

function AppBarTrailer() {
  const storage = getStorage();

  const [name, setName] = useState("");
  const [video, setVideo] = useState("");

  const onSetFile = (e) => {
    const file = e.target.files[0];
    setVideo(file);
  };

  const onSubmit = async () => {
    const fileName = video.name;
    const storageRef = ref(storage, `trailers/${fileName}`);
    try {
      const res = await uploadBytes(storageRef, video);
      //   const getDownloadUrl = await getDownloadURL(storageRef);

      const params = {
        name: name,
        video: `trailers/${fileName}`,
      };
      console.log(params, "ini params");
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Center>
        <Box>
          <Heading>Add New Content</Heading>
          <Input m="1" value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Nama" />
          <Text m="1">Video Trailer</Text>
          <Input m="1" id="trailer" type="file" onChange={(e) => onSetFile(e)} placeholder="VideoTrailer" />
          <Button onClick={() => onSubmit()}>Add</Button>
        </Box>
      </Center>
    </>
  );
}

export default AppBarTrailer;
