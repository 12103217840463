import { Table,
	Thead,
	Tbody,
	Tfoot,IconButton,
	Tr,
	Th, TableContainer,
	Td,
  Box,
  Button,
  useToast} from '@chakra-ui/react';
import { collection, deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { db } from '../../../Configs/firebase';
import TablePayment from './TableAdmin/TablePayment';



function AppBarAdminPayment() {
  const [dataPayment, setDataPayment] = useState();

  const toast = useToast()

	const getData = async () => {
	  console.log("getting data");
	  const ref = collection(db, "payments");
	  const q = query(ref, orderBy("updated", "desc"));
	  const querySnapshot = await getDocs(q);
  
	  console.log("getting querySnapshot");
	  let newArr = [];
	  querySnapshot.forEach((doc) => {
		let newData = doc.data();
		newData.id = doc.id;
		newArr.push(newData);
	  });
	  console.log(newArr, 'xxxx')
	  setDataPayment(newArr);
	};

  const handleDelete = async (id) => {
    try {
      const result = await deleteDoc(doc(db, "payments", id));
        toast({
          title: "Data Deleted",
          description: "",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      getData()
    } catch (error) {
      console.log(error)
    }
    getData()
  }
  
	useEffect(() => {
	  getData();
  
	  return () => {
		setDataPayment();
	  };
	}, []);

  return (
    <>
    <Box p="5">
        <Box width="full">
        </Box>
        {/* {editDataUser !== null && <EditDataUser editData={editDataUser} setEditData={setEditDataUser} data={getDataUser}/>} */}
        {dataPayment && (
             <TablePayment data={dataPayment} handleDelete={handleDelete}/>
          )}
      </Box>
      </>
  )
}

export default AppBarAdminPayment