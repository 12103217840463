import { 	Table,
	Thead,
	Tbody,
	Tfoot,Checkbox,
	Tr,Input,
	Th,  Drawer,
	DrawerBody,ButtonGroup,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,AvatarGroup,Avatar,
	Td,Text,  Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor,
	TableCaption,Button,Image,useDisclosure,useToast,
	TableContainer,Box, HStack, SimpleGrid, Heading, Spacer, IconButton, Center, Divider, VStack, Switch, Tag, Select, Spinner } from '@chakra-ui/react'
import React, { useContext, useEffect,useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BellIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Stream } from '@cloudflare/stream-react'
import colors from '../../Utils/colors'
import { useRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import { db, fetchToken,  } from '../../Configs/firebase'
import AuthContext from '../../Routes/hooks/AuthContext'
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import getDomain from '../../Utils/getDomain'
import TablePattern from './patterns/TablePattern/TablePattern'
import { makeApiRequestDocial, makeApiRequestDocialNew } from '../AppComponents/AppComponentChart/Data/helpers'

function AppBarPatternView() {
	const [dataCrypto,setDataCrypto]=useState()
	const [dataStockId,setDataStockId]=useState()
	const [crypto, setCrypto] = useState(false)
	const [loading, setLoading] = useState(false)
	const [ notification,setNotification]=useState()
	const [dataPattern, setDataPattern] = useState()
	const [tokenId, setTokenId] = useState("");

	const link = getDomain()

	const [tableCoin, setTableCoin] = useState(link === "algotradingid" ? ("crypto") : ("stockid"))
	const [tableTime, setTableTime] = useState("all")
	const [tableTimeStocks, setTableTimeStocks] = useState("1h")

	const param = useParams();
	const { currentUser } = useContext(AuthContext)
	const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef()
	const initialFocusRef = useRef()
	const toast = useToast();

	const { getUserStorage } = useContext(AuthContext)

	const subscription = getUserStorage()
	const role = subscription.role



	const getDataPattern = async () => {
		try {
			let data = []
			const q = query(collection(db, "content"), where("tags", "array-contains", param.type));
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				data.push(doc.data())
			});
			setDataPattern(data)
			console.log(data, 'ini data pattern')
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getDataPattern()
		fetchToken(setTokenId)
	
	  return () => {
		setDataPattern()
	  }
	}, [])

	const handleNotificationStocks = async (market, data) => {
		console.log(tokenId, 'this token')
		console.log(param.type, 'this param')
		try {
			if(data === true){
				const res = await axios.post(`https://docial-crypto-h3tahmnrkq-uc.a.run.app/api/v1/notif?type=subscribe`, {
					"token" : tokenId,
					"pattern" : param.type
				})
				console.log(res, 'res')
				if(res.status === 200){
					toast({
					title: 'Notification saved',
					description: `${data?'SUBSCRIBE':'UNSUBSCRIBE'} to ${param.type} - ${market}`,
					status: 'success',
					duration: 9000,
					isClosable: true,
					})
				}
			}else{
				console.log(data, 'data')
				console.log(param.type, 'param')
				console.log(tokenId, 'token')
			}
		} catch (error) {
			toast({
				title: 'Error',
				description: `${error.message}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
				})
		}

	}
	

	const handleNotification=async (market,data)=>{
		console.log('this is in handle notification')
			const uid= await currentUser.uid;
			const type = param.type;
			const status=data ;
		 console.log(uid,type,status,market)
		//http://localhost:8081/subscribeNotification?market=${market}&type=${type}&uid=${uid}&status=${status}
			console.log(tokenId, 'tttt')
		  try {
			const result = await axios.get(`https://algo-trading-cloud-kyx2p4tlea-et.a.run.app/subscribeNotification?market=${market}&type=${type}&uid=${uid}&status=${status}`)
			// console.log('running subscribe notification')
			if(result)
			// console.log(result)
			toast({
				title: 'Notification saved',
				description: `${data?'SUBSCRIBE':'UNSUBSCRIBE'} to ${param.type} - ${market}`,
				status: 'success',
				duration: 9000,
				isClosable: true,
			  })
			  
		  } catch (error) {
			toast({
				title: 'Error',
				description: `${error.message}`,
				status: 'error',
				duration: 9000,
				isClosable: true,
			  })
			  
		  }
	}

	const PatternDrawer = ()=>{
		return(
		<Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
	
        <DrawerContent bgColor='black' color='white'>
          <DrawerCloseButton />
          <DrawerHeader>Filter</DrawerHeader>

          <DrawerBody >
            {/* <Input placeholder='Type here...' /> */}
			{/* <Heading>Type</Heading> */}
			<Text>Type</Text>
			<SimpleGrid columns='1' gap='1'>
			<Switch>Kodok</Switch>

				<Checkbox onChange={(e)=>setCrypto(e.target.checked)} >Cryptocurrency</Checkbox>
				<Checkbox defaultChecked={false} onChange={(e)=>{
					e.preventDefault()
					console.log(e)
				}}>Futures</Checkbox>
				<Checkbox>Stocks ID</Checkbox>
				<Checkbox>Stocks US</Checkbox>
				<Checkbox>Forex</Checkbox>
			</SimpleGrid>
{/* 
			<Text mt='5'>Time Frame</Text>
			<SimpleGrid columns='1' gap='1'>
				<Checkbox>1m</Checkbox>
				<Checkbox>15m</Checkbox>
				<Checkbox>1h</Checkbox>
				<Checkbox>1D</Checkbox>
			</SimpleGrid> */}
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme='yellow'>Filter</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
		)
	}

	const PatternPopOver = ()=>(
		<Popover
      initialFocusRef={initialFocusRef}
      placement='right'
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button leftIcon={<BellIcon />} color={colors.theme}>Notification </Button>
      </PopoverTrigger>
      <PopoverContent color='white' bg='black' borderColor='blue.800'>
        <PopoverHeader pt={4} fontWeight='bold' border='0'>
           Set notification for Head & Shoulder
        </PopoverHeader>
        <PopoverArrow color='blue.800'/>
        <PopoverCloseButton />
        <PopoverBody>
			{link === "algotradingid" ? (
				<SimpleGrid>
				{/* <Switch onChange={(e)=>console.log(e.target.checked)}>Kodok</Switch> */}
				<Checkbox defaultChecked={checkNotification('crypto')} id='crypto' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Cryptocurrency</Checkbox>
				<Checkbox defaultChecked={checkNotification('stocks-id')} id='stocks-id' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Stocks Indonesia</Checkbox>
				{/* <Checkbox defaultChecked={checkNotification('stocks-us')} id='stocks-us' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Stocks USA</Checkbox>
				<Checkbox defaultChecked={checkNotification('forex')}id='forex' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Forex</Checkbox>
				<Checkbox defaultChecked={checkNotification('futures')}id='futures' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Futures</Checkbox> */}
			</SimpleGrid>
			) : (
				<SimpleGrid>
				{/* <Switch onChange={(e)=>console.log(e.target.checked)}>Kodok</Switch> */}
				<Checkbox defaultChecked={checkNotification('stocks-id')} id='stocks-id' onChange={(e)=>handleNotificationStocks(e.target.id,e.target.checked)}>Stocks Indonesia</Checkbox>
				{/* <Checkbox defaultChecked={checkNotification('stocks-us')} id='stocks-us' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Stocks USA</Checkbox>
				<Checkbox defaultChecked={checkNotification('forex')}id='forex' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Forex</Checkbox>
				<Checkbox defaultChecked={checkNotification('futures')}id='futures' onChange={(e)=>handleNotification(e.target.id,e.target.checked)}>Futures</Checkbox> */}
			</SimpleGrid>
			)}
        </PopoverBody>
        <PopoverFooter
          border='0'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          pb={4}
        >
          {/* <Button colorScheme='yellow'>Save</Button> */}
          
        </PopoverFooter>
      </PopoverContent>
    </Popover>
	)

	const checkNotification = (data)=>{
		// console.log(typeof notification.topics, 'this is typeof')
		if(notification && typeof notification.topics==="object"){
			// console.log(param.type,data)
			const res= notification.topics.filter((x)=> x===`${param.type}-${data}` )
			if(res.length) return true
			else return false
			// return res
		}
	}

	const getData = async()=>{
		console.log('getting data')
		try {
			onSnapshot (doc(db , "pattern-feeds", tableTime), (doc) => {
				// console.log("Current data: ", doc.data());
				const dataFromFirestore = doc.data().data;
				const filterData = dataFromFirestore.filter((x) => x.keyword === `${param.type}` )
				setDataCrypto(filterData)
				console.log(dataFromFirestore, 'ini data crypto')
			})
	
		} catch (error) {
			console.log(error, 'this is error')
		}
	}

	const getDataStock = async () => {
		console.log('getting data')

		
		const config = {
			method: 'get',
			url: `https://docial-crypto-h3tahmnrkq-uc.a.run.app/api/v1/pattern?pattern=feed&time=${tableTimeStocks}`,
		  }
		  try {
			  const result = await axios(config)
			  if(result){
				//   setData(result.data)
				  const dataCoin = result.data.data
				  const filterData = dataCoin.filter((x) => x.keyword === `${param.type}` )
				  setDataStockId(filterData)
			  }
			  
		  } catch (error) {
			  console.log(error.message)
		  }
	}

	const getNotification = async()=>{
		const uid= await currentUser.uid;

		const docRef = doc(db, "notification", uid);
		const docSnap = await getDoc(docRef);
		
		if (docSnap.exists()) {
			console.log("Document data:", docSnap.data());
			setNotification(docSnap.data())
		  }
	}

	useEffect(() => {
	  getData()
	  getNotification()
	  getDataStock()
	
	  return () => {
		setDataCrypto()
		setDataStockId()
	  }
	}, [tableTime, tableTimeStocks])
	
  return (
	<Box p='25' width='full' height='full' bgColor='black' color='white'>
			{dataPattern ? (
				dataPattern.map((x) => (
					<SimpleGrid columns={{base:1,md:2}} p='5'>
					<Box width='full' >
						<Button mb='5' bgColor={colors.green} fontSize='md' size='sm' onClick={()=>navigate(-1)}>Back</Button>
						<Stream  controls src={x.video} />
					</Box>
					<Box p='12'>
						<HStack alignItems="start">
							<VStack alignItems='start' spacing={2}>
								<Heading>{x.title}</Heading>
								<HStack spacing={3}>
								</HStack>
								{/* <IconButton aria-label='Add Notification' colorScheme='yellow'  icon={<BellIcon />} /> */}
								<PatternPopOver/>
								{/* <VStack>
									<Switch>Kodok</Switch>
									<Checkbox defaultChecked={true} onChange={(e)=>setCrypto(e.target.checked)} >Cryptocurrency</Checkbox>
									<Checkbox >Futures</Checkbox>
									<Checkbox>Stocks ID</Checkbox>
									<Checkbox>Stocks US</Checkbox>
									<Checkbox>Forex</Checkbox>
								</VStack> */}
								
							</VStack>
							<Spacer/>
							<Box w="25%">
							<Image src={x.image}/>
							</Box>
						</HStack>
						<Text mt='2'>{x.description}</Text>
						{/* {link === "algotradingid" && (
						<Center>
							<Button m='2' color={colors.theme}>Crypto</Button>
							<Button m='2' color={colors.theme}>Stocks ID</Button>
						</Center>
								)} */}

					</Box>
				</SimpleGrid>
				))
			) : (
				<Center>
					<Heading>
						<Spinner color='white'/>
					</Heading>
				</Center>
			)}
	 	{link === "algotradingid" ? (
				  <HStack m={5}>
				  <Heading size="lg">ALL {tableCoin === "crypto" ? ("Cryptocurrency") : ("Stock ID")} - {param.type}</Heading>
				  <Spacer />
				  <HStack>
				{tableCoin === "crypto" ? (
					 <Select placeholder={tableTime === "all" ? 'All' : ""} onChange={(e) => setTableTime(e.target.value)}>
					 <option value='all'>1 Minute</option>
					 <option value='minute_15'>15 Minute</option>
					 <option value='hour_1'>1 Hour</option>
				 </Select>
				) : (
					<Select placeholder={tableTimeStocks === "1h" ? '1 Hour' : ""} onChange={(e) => setTableTimeStocks(e.target.value)}>
					<option value='1h'>1 Hour</option>
					<option value='1d'>1 Day</option>
				</Select>
				)}

				  <Select placeholder='Select option' onChange={(e) => setTableCoin(e.target.value)}>
					<option value='crypto'>Cryptocurrency</option>
					<option value='stockid'>Stock ID</option>
				</Select>
				</HStack>
				  </HStack>
		) : (
			<HStack m={5}>
			<Heading size="lg">ALL Stock ID - {param.type}</Heading>
			<Spacer />
			<Box>
			<Select placeholder={tableTimeStocks === "1h" ? '1 Hour' : ""} onChange={(e) => setTableTimeStocks(e.target.value)}>
					<option value='1h'>1 Hour</option>
					<option value='1d'>1 Day</option>
				</Select>
				</Box>
			</HStack>
		)}

	  {loading ? (
		<Center>
			<Spinner/>
		</Center>
	  ) : (
		link === "algotradingid" ? (
		<Box>
			{tableCoin === "crypto" && <TablePattern data={dataCrypto} role={role} coin={'crypto'} loading={loading} /> }
			{tableCoin === "stockid" && <TablePattern data={dataStockId} role={role} coin={'stockid'}/> }
		</Box>
		) : (
			<Box>
			{tableCoin === "stockid" && <TablePattern data={dataStockId} role={role} coin={'stockid'}/> }
		</Box>
		)
	  )}
	
		
	
		{/* <TablePattern data={data} role={role}/> */}

		<PatternDrawer/>
	</Box>
  )
}

export default AppBarPatternView