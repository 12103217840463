
import HeaderResponsive from "../Components/AppBars/HeaderResponsive"
import Footer from "../Components/AppBars/Footer"
import { Grid,GridItem, Container,Box, OrderedList, ListItem,Heading,Link,Spinner, Center } from "@chakra-ui/react"
import { Stream } from "@cloudflare/stream-react"
import { useEffect, useState } from "react"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from "../Configs/firebase"
import { useParams } from "react-router-dom"

export default function MasterClassPageDetail(){

    const [videos, setVideos] = useState([])
    const [initialVideo, setInitialVideo] = useState(null)
    const [loading, setLoading] = useState(true)
    const {level} = useParams()

    const fetchVideos = async() => {
        try {
            const q = query(
                collection(db, "content"), 
                where("tags", "array-contains", level),
                // limit(10)
            )
            const result = []
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                result.push(doc.data())
            })
            setLoading(false)
            setVideos(result)
            setInitialVideo(result[0])
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        fetchVideos()
    }, [])

    const handleClickVideoList = (index) => {
        setLoading(true)
        setInitialVideo(videos[index])
        console.log(videos[index]);
    }

    const renderPlayList = () => {
        return videos.map((x,index) => (
            <ListItem py="4" fontWeight="bold" fontSize={{sm:'sm', md:'md'}} 
                px="0.8rem"
                borderColor="white"
                borderWidth={
                initialVideo && initialVideo.video === x.video ? '0.5px' : '0px'
            }>
                <Link onClick={() => handleClickVideoList(index)}>{x.title}</Link>
            </ListItem>
        ))
    }

    const hasLoadedVideo = () => {
        setLoading(false)
    }

    return(
        <>
            <HeaderResponsive/>
                <Container maxW={'8xl'} py={12}>
                    <Grid
                        templateColumns={{
                            base:'repeat(1, 1fr)',
                            md:'repeat(6, 1fr)'
                        }}
                        gap={4}
                    >
                    <GridItem 
                        colSpan={2}  
                        borderRightWidth={{
                            sm:'0',
                            md:'0.5px'
                        }}
                        borderColor="white"
                    >
                        <Box px="5">
                            <Heading as='h4' size='md' mb="5">
                                Playlist {level} levels
                            </Heading>
                            <OrderedList>
                                {renderPlayList()}
                            </OrderedList>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={4} px="3">
                        <Box width="100%">
                            <Stream 
                                poster={initialVideo ? initialVideo.thumbnail : '-'}
                                src={initialVideo ? initialVideo.video : ''}  
                                key={initialVideo ? initialVideo.video : '-'}
                                onLoadedData={() => hasLoadedVideo()}
                                controls 
                            />
                            {
                                loading ? (<Box width="100%" height="300px" mx="auto"> <Center><Spinner size='lg' color="white" /></Center></Box>) : (<></>)
                            }
                           
                        </Box>
                        <Box py="3">
                            <Heading as='h4' size='sm' mb="5" textAlign="center">
                               {initialVideo ? initialVideo.title : '-'}
                            </Heading>
                        </Box>
                    </GridItem>
                    </Grid>
                </Container>
            <Footer />
        </>
    )

}