import React, { useState } from "react";
import { Button,useToast, FormControl, Flex, Heading, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Configs/firebase";
import { Link } from "react-router-dom";

function ForgetPasswordPage() {
  const [email, setEmail] = useState("");
  const toast = useToast()

  const handleForgetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
		 toast({
          title: 'Email Sent.',
          description: "We've sent an email to your account.",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
		toast({
			title: 'Error',
			description: errorMessage,
			status: 'error',
			duration: 9000,
			isClosable: true,
		  })
      });
  };
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack spacing={4} w={"full"} maxW={"md"} bg={useColorModeValue("white", "gray.700")} rounded={"xl"} boxShadow={"lg"} p={6} my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Forgot your password?
        </Heading>
        <Text fontSize={{ base: "sm", sm: "md" }} color={useColorModeValue("gray.800", "gray.400")}>
          You&apos;ll get an email with a reset link
        </Text>
        <FormControl id="email">
          <Input onChange={(e) => setEmail(e.target.value)} placeholder="your-email@example.com" _placeholder={{ color: "gray.500" }} type="email" />
        </FormControl>
        <Stack spacing={6}>
          <Button
            onClick={() => handleForgetPassword()}
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
          >
            <Link to="/">Request Reset</Link>
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default ForgetPasswordPage;
