import React from "react";
import { VStack, Box, SimpleGrid, Button, Input, Center, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Container, Stack, Text, Flex, useColorModeValue, Image, HStack, Grid, Spacer } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import entrepreneurs from "../Img/entrepreneurs.png";
import watchanywhere from "../Img/watchanywhere.png";
import followentrepreneurs from "../Img/followentrepreneurs.png";
import divedeeper from "../Img/divedeeper.png";
import bgweb from "../Img/bgweb.png";
import Footer from "../Components/AppBars/Footer";

function HomePage() {
  return (
    <>
      {/* Box 1 */}
      <Box bgPosition="center" bgColor="black" h="100vh" bgRepeat="no-repeat" bgImage={bgweb} color={useColorModeValue("white")} bgSize="cover">
        <HStack>
          <Box w="90px" mx="30px">
            <Image src={entrepreneurs}></Image>
          </Box>
          <Spacer />
          <Box m="20px" px="40px">
            <Link to={"/login"}>
              <Button bg={useColorModeValue("red")}>Sign In</Button>
            </Link>
          </Box>
        </HStack>

        {/* <Center> */}
        <Box>
          <Center>
            <Box m="15px" fontSize="35px" fontFamily="Bebas Neue">
              1000+ STRATEGIES FROM ALL INDONESIAN SUCCESSFULL ENTREPRENEURS
            </Box>
          </Center>
          <Center>
            <Box fontSize="30px" fontFamily="Bebas Neue">
              WATCH, LEARN, AND APPLY
            </Box>
          </Center>
          <Box m="15px" fontSize="25px" textAlign="center" fontFamily="Bebas Neue">
            GET POWERFUL INSIGHTS, START WITH CONCEPTS, MARKETING STRATEGIES AND THE SECRETS BEHIND THEIR SUCCESSFUL BUSINESS
          </Box>
          <Center>
            <Box m="15px" fontSize="20px" fontFamily="Bebas Neue">
              READY TO WATCH? ENTER YOUR EMAIL AND START SUBSCRIBE
            </Box>
          </Center>
        </Box>
        {/* </Center> */}
        <Center>
          <HStack>
            <Box>
              <Input placeholder="Email address" />
            </Box>
            <Box>
              <Link to="/signup">
                <Button justify="center" bg={useColorModeValue("red")}>
                  Get Started
                </Button>
              </Link>
            </Box>
          </HStack>
        </Center>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>

      {/* {Box CoverSeminar} */}
      <Box bg="black" h="100vh">
        <Center>
          <SimpleGrid columns={2} spacing="40px" mx="40px" my="30px">
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://i.ytimg.com/vi/29V4yiZg_Tk/maxresdefault.jpg" h="40vh" w="70vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/masterclass-1652712370.jpg" h="40vh" w="70vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://www.masterclass.com/course-images/attachments/mjecqosnryx67fv0iwpvgghhl5zh?width=412&fit=cover&dpr=2" h="40vh" w="70vh"></Box>
            <Box border="1px" borderRadius="10px" bgRepeat="no-repeat" bgSize="cover" bgImage="https://www.masterclass.com/course-images/attachments/mgb5ipimlu9squk5uq51whyoqleb?width=412&fit=cover&dpr=2" h="40vh" w="70vh"></Box>
          </SimpleGrid>
        </Center>
        <Center>
          <Box fontSize="30px" color="white" mt="-490px" fontFamily="Bebas Neue">
            Business seminars and webinars from All Indonesian Entrepreneurs
          </Box>
        </Center>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>
      {/* Box 2 */}
      <Box bg={useColorModeValue("black")} color={useColorModeValue("white")} h="70vh">
        <SimpleGrid columns={2}>
          <Center>
            <Box alignItems="center">
              <Box m="30px" fontSize="40px" fontFamily="Bebas Neue">
                Watch anywhere and anytime
              </Box>
              <Box m="30px" fontSize="20px" fontFamily="helvetica">
                Learn 1000+ business strategies from the experts anywhere and anytime (Phone, tablet, Laptop){" "}
              </Box>
            </Box>
          </Center>
          {/* <Center> */}
          <Box h="60vh">
            <Image mt="25px" src={watchanywhere}></Image>
          </Box>
          {/* </Center> */}
        </SimpleGrid>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>
      {/* Box 3 */}
      <Box bg={useColorModeValue("black")} color={useColorModeValue("white")} h="70vh">
        <SimpleGrid columns={2}>
          <Center>
            <Box>
              <Image mt="12px" ml="10px" src={followentrepreneurs} />
            </Box>
          </Center>
          <Center>
            <Box>
              <Box m="30px" fontSize="40px" fontFamily="Bebas Neue">
                Follow your favorite Entrepreneurs
              </Box>
              <Box m="30px" fontSize="20px" fontFamily="helvetica">
                You will never miss any information from your beloved entrepreneurs{" "}
              </Box>
            </Box>
          </Center>
        </SimpleGrid>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>
      {/* Box 4 */}
      <Box bg={useColorModeValue("black")} color={useColorModeValue("white")} h="70vh">
        <SimpleGrid columns={2}>
          <Center>
            <Box>
              <Box m="30px" fontSize="40px" fontFamily="Bebas Neue">
                Dive deeper into their heads
              </Box>
              <Box m="30px" fontSize="20px" fontFamily="helvetica">
                Learn more about point of view of 1000+ indonesia's most successful businessmen through courses and class guide{" "}
              </Box>
            </Box>
          </Center>
          <Box>
            <Center>
              <Image h="60vh" src={divedeeper} mt="25px" mr="10px" />
            </Center>
          </Box>
        </SimpleGrid>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>
      {/* Box 5 */}
      <Box bg={useColorModeValue("black")} color={useColorModeValue("white")} h="70vh">
        <SimpleGrid columns={2}>
          <Center>
            <Box>
              <Image h="60vh" mt="25px" src="https://images.unsplash.com/photo-1488521787991-ed7bbaae773c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80" />
            </Box>
          </Center>
          <Center>
            <Box>
              <Box m="30px" fontSize="40px" fontFamily="Bebas Neue">
                Helping those in need
              </Box>
              <Box m="30px" fontSize="20px" fontFamily="helvetica">
                By just enjoying all of this, you have contributed to providing a proper education for underprivileged children{" "}
              </Box>
            </Box>
          </Center>
        </SimpleGrid>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>
      {/* Box 6 */}
      <Box bg={useColorModeValue("black")} color={useColorModeValue("white")} p="30px" fontSize="20px">
        <Center>
          <Box m="30px">Frequently Asked Questions</Box>
        </Center>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  What is {window.location.hostname}?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              In order to realize the vision of helping one million entrepreneurs,
              <br />
              <br />
              {window.location.hostname} was created as a business education media that provides thousands of stories and strategies from Indonesia's most successful businessmen
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  How much does {window.location.hostname} cost?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              You can watch and learn, All for one fixed monthly fee.
              <br />
              Plans range from IDR 250K to IDR 2,5M a year. No extra costs, no contracts.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  What makes {window.location.hostname} different from others
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>We present strategies and stories from thousands of the best entrepreneurs in Indonesia, using an attractive and easy-to-understand interface.</AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  What will I get from {window.location.hostname}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Watch the stories of all the best Indonesian entrepreneurs to build your mindset.
              <br />
              <br />
              Apply the right business strategy according to your business niche.
              <br />
              <br />
              Watch seminars and webinars from the most successful entrepreneurs in Indonesia.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Is there anything else beyond this i can get?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>By just watching and learning through {window.location.hostname}, you become part of Indonesian entrepreneurs and contribute to helping children who need a proper education.</AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box>
          <Center>
            <Box m="30px">Ready to watch? Enter your email to create or restart your membership</Box>
          </Center>
          <Box>
            <Center>
              <HStack>
                <Box>
                  <Input placeholder="Email address"></Input>
                </Box>

                <Link to="/signup">
                  <Box>
                    <Button bg={useColorModeValue("red")}>Get Started</Button>
                  </Box>
                </Link>
              </HStack>
            </Center>
          </Box>
        </Box>
      </Box>
      <Box bg={useColorModeValue("grey")} h="5px"></Box>
      <Footer />
    </>
  );
}

export default HomePage;
