import { Box, Center, Checkbox, Heading, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import {  arrayRemove, arrayUnion, doc, setDoc } from 'firebase/firestore';
import React, { useEffect,useState } from 'react'
import { getDataPasarDana } from '../../../api/indodana'
import { db } from '../../../Configs/firebase';

function AppBarAdminMarketSyariah() {
	const [data,setData]=useState();

	const getEmiten= async()=>{
		const result = await getDataPasarDana()
		setData(result.data)
	}

	const handleCheck = async(data,code)=>{
		console.log('in handle check')
		if(data){
			const docRef = doc(db, 'basic-data', 'syariah-emiten');
			await setDoc(docRef, {data:arrayUnion(code)},{merge:true});			
		}
		else{
			const docRef = doc(db, 'basic-data', 'syariah-emiten');
			await setDoc(docRef, {data:arrayRemove(code)},{merge:true});		
		}	
	}

	useEffect(() => {
	  getEmiten()
	}, [])
	
  return (
  <>
  	{/* <AppBarAdminHeader/> */}
	  
	  <Heading textAlign='center'>Lists of Syaria</Heading>

	  <Center m='5'>
	  <SimpleGrid width='3xl' columns={{base:8}}>
		{
		data?data.map((x)=>
		<Checkbox onChange={(e)=>handleCheck(e.target.checked,x.Code)}>{x.Code}</Checkbox>)
		:
		<Spinner/>
		}
	</SimpleGrid>

	  </Center>
	
  </>
	
  )
}

export default AppBarAdminMarketSyariah