import { Box, Text, SimpleGrid, Flex, Button, Center } from "@chakra-ui/react";
import { collection, getDocs, limit, query } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../../Configs/firebase";
import AppCard43 from "../AppComponents/AppCard43";

function AppBarBodyEntrepreneurs(props) {
  const [data, setData] = useState();

  const getData = async () => {
    const q = query(collection(db, "speakers"), limit(100));
    const querySnapshot = await getDocs(q);
    let newArr = [];
    querySnapshot.forEach((doc) => {
      let newData = doc.data();
      newData.id = doc.id;
      newArr.push(newData);
    });
    console.log(newArr);
    setData(newArr, "ini data dari speakers");
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Box bgColor="black" pt="20">
        <Flex flexWrap="wrap" spacing="24px" gap="16px" justifyContent="space-evenly">
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} gap="1" marginTop={props.marginTop} position="relative">
            {data ? data.map((x) => <AppCard43 name={x.name} image={x.thumbnail} />) : <></>}
          </SimpleGrid>
        </Flex>
        <Center></Center>
      </Box>
    </>
  );
}

export default AppBarBodyEntrepreneurs;
