import { Box, Container, LinkBox ,Heading,LinkOverlay, Text} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {onSnapshot,doc} from "firebase/firestore"
import { db } from "../../../Configs/firebase"

export default function StrategyPatternCrypto() {

    const [strategys, setStrategys] = useState([])

    const getData = async () => {
        try {
            onSnapshot(doc(db, "strategy-feeds", 'logs'), (doc) => {
                const dataFromFirestore = doc.data().data;
                console.log(dataFromFirestore, 'dataFromFirestore')
                setStrategys([...dataFromFirestore])
            })
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const renderItems = () =>{
        return strategys.map(x => (
            <LinkBox as='article' p='5' borderWidth='1px' rounded='md' mb="2" key={x.created_at}>
                <Box as='time' dateTime='2021-01-15 15:30:00 +0000 UTC'>
                    {x.created_at}
                </Box>
                <Heading size='md' my='2'>
                    <LinkOverlay href='#'>
                    New Signal
                    </LinkOverlay>
                </Heading>
                <Box dangerouslySetInnerHTML={{__html:x.message}}/>
            </LinkBox>
        ))
    }

    return(
        <Container maxW={'5xl'} py={3}>
            <Heading size='lg' my='2'>
                Strategies Notification
            </Heading>
            {renderItems()}
        </Container>
    )
}