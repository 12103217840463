
import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box } from "@chakra-ui/react";
import getDomain from "../../Utils/getDomain";


const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);


function AppBarCalenderEvent({data}) {

  const date = moment(new Date()).valueOf()
  const dataFilter = data.filter((x) => x.start >= date)


  const link = getDomain()
  

  let events = []
  if(data!== undefined) {
    for (const iterator of dataFilter) {
      const dataArrEvent = {
        start: new Date(iterator.start),
        end: new Date(iterator.end),
        title: (iterator.title)
        }
        events.push(dataArrEvent)
      }
  }

  const state = { events }

  // const  state = {
  //   events: [
  //     {
  //       start: new Date(1663070400000),
  //       end:new Date(1663113600000),
  //       title: "blabla",
  //     },
  //   ],
    
  // };

  console.log(state.events, 'ini event')

  const onEventResize = (data) => {
    const { start, end } = data;

    this.setState((state) => {
      state.events[0].start = start;
      state.events[0].end = end;
      return { events: [...state.events] };
    });
  };

  const onEventDrop = (data) => {
    console.log(data);
  };


  return (
    <Box m={20} borderRadius="xl">
      <DnDCalendar
          defaultDate={moment().toDate()}
          defaultView="month"
          events={state.events}
          localizer={localizer}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          resizable
          style={link === "algotradingid" ? ({ height: "100vh", color: "white", backgroundColor: "black" }) : ({ height: "100vh", color: "black", backgroundColor: "white" })}
        />
    </Box>
  )
}

export default AppBarCalenderEvent