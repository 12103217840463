import { Box, Text } from "@chakra-ui/react";
import React from "react";
import AppBodyMain from "../Components/AppBars/AppBodyMain";
import Footer from "../Components/AppBars/Footer";
import Header from "../Components/AppBars/Header";
import AppScreenFront from "../Components/AppComponents/AppScreenFront";
import AppVideoBackground from "../Components/AppComponents/AppVideoBackground/AppVideoBackground";
import HeaderResponsive from "../Components/AppBars/HeaderResponsive";

function FilmPage() {
  return (
    <>
      <HeaderResponsive />
      {/* <Header /> */}
      <AppVideoBackground videoUrl="https://assets.codepen.io/6093409/river.mp4" title="Blueberry" content="Cerita tentang blueberry cheesecake yang akan menggetarkan kepala bawah anda.." id="123124123123" />
      <AppBodyMain />
      <Footer />
    </>
  );
}

export default FilmPage;
